import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Badge } from 'primereact/badge';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';

import { RequestMessages } from '@components/RequestMessages';
import { DownloadButton, ExpandButtons, RefreshButton, ShareButton } from '@components/buttons';
import { useWindowWidth } from '@hooks/useWindowDimensions';
import { genericRequestErrors } from '@services/index';

import { NewPaymentButton } from '@accounting/receipts/newPayment';
import { transactionLink } from '@accounting/templates';
import { useAuth } from '@hooks/use-auth.js';
import { personFormalNameLink } from '@persons/templates';
import { AccountingService } from '@services/accounting';
import { downloadBlob, shareDownloadBlob } from '@services/sharing';

const REQUIRED_DETAIL_WINDOW_WIDTH = 600;

export const SiteTransactionsDue = () => {
    const auth = useAuth();
    const params = useParams();
    const [details, setDetails] = useState();
    const [summary, setSummary] = useState();
    const [service] = useState(new AccountingService());
    const [expandedRows, setExpandedRows] = useState([]);
    const [searchTimestamp, setSearchTimestamp] = useState();
    const [requestMessages, setRequestMessages] = useState();
    const windowWidth = useWindowWidth();
    const [hideDetailColumns, setHideDetailColumns] = useState(
        windowWidth < REQUIRED_DETAIL_WINDOW_WIDTH,
    );

    // Effects ----------------------------------------------------------------

    const search = useCallback(() => {
        const processData = (data) => {
            const { siteAmount, siteCanceledAmount, siteDueAmount, details } = data;
            const summary = { siteAmount, siteCanceledAmount, siteDueAmount };
            details.forEach((detail) => {
                detail.hasUncanceled = Boolean(
                    Array.isArray(detail.transactions) && detail.transactions.length,
                );
            });
            setSummary(summary);
            setDetails(details);
            expandRows(details);
            // Used in NewPaymentButton.forceUpdate to sync data
            setSearchTimestamp(Date.now());
        };

        setRequestMessages(null);
        service
            .siteTransactionsDueSummary(params.id)
            .then((response) => processData(response.data))
            .catch((error) => setRequestMessages(genericRequestErrors(error)));
    }, [service, params.id]);

    useEffect(() => {
        search();
    }, [search]);

    useEffect(() => {
        setHideDetailColumns(windowWidth < REQUIRED_DETAIL_WINDOW_WIDTH);
    }, [windowWidth]);

    // Events -----------------------------------------------------------------

    const download = () => {
        downloadBlob(service.siteTransactionsDueSummaryPDF(params.id))
            // .then((response) => alert(`Downloaded=${response.filename}`))
            .catch((error) => setRequestMessages(genericRequestErrors(error)));
    };

    const share = () => {
        shareDownloadBlob({
            ...service.siteTransactionsDueSummaryPDF(params.id),
            title: `Saldos ${auth.site.name}`,
            text: `Adjuntamos los saldos de su cuenta. Atentamente, ${auth.site.name}`,
        }).catch((error) => setRequestMessages(genericRequestErrors(error)));
    };

    const expandRows = (details) => {
        const _expandedRows = {};
        details.forEach((detail) => (_expandedRows[`${detail.person.id}`] = true));
        setExpandedRows(_expandedRows);
    };

    const expandAll = () => {
        expandRows(details);
    };

    const collapseAll = () => {
        setExpandedRows(null);
    };

    // Rendering --------------------------------------------------------------

    const header = (
        <div className="split-table-header">
            <div className="table-actions-header">
                <NewPaymentButton
                    beneficiaryId={params.id}
                    forceUpdate={searchTimestamp}
                    showBalance={false}
                />
                <DownloadButton onDownload={download} />
                <ShareButton onClick={share} />
            </div>
            <RefreshButton onRefresh={search} className="hide-constrained-400" />
        </div>
    );

    const expanderHeader = <ExpandButtons onExpand={expandAll} onCollapse={collapseAll} />;

    const allowExpansion = (detail) => {
        return detail.hasUncanceled;
    };

    const headerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column className="table-column-left" header={expanderHeader} colSpan={2} />
                <Column
                    className="table-column-money"
                    header="Importe"
                    hidden={hideDetailColumns}
                />
                <Column
                    className="table-column-money"
                    header="Cancelado"
                    hidden={hideDetailColumns}
                />
                <Column className="table-column-money" header="Saldo" />
                <Column className="table-column-badge" />
            </Row>
        </ColumnGroup>
    );

    const footerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column className="table-column-right" footer="TOTAL" colSpan={2} />
                {/* Aggregated siteAmount & cancelAmount
                    These values are confusing when two different persons are aggregated
                    and redundant when dealing with a single person (duplicated data)
                    TODO: Consider removing both of them */}
                <Column
                    // className="table-column-money"
                    // footer={summary?.siteAmount}
                    hidden={hideDetailColumns}
                />
                <Column
                    // className="table-column-money"
                    // footer={summary?.siteCanceledAmount}
                    hidden={hideDetailColumns}
                />
                <Column className="table-column-money" footer={summary?.siteDueAmount} />
                <Column className="table-column-badge" />
            </Row>
        </ColumnGroup>
    );

    // const personTemplate = (data) => data.person.formalName;
    const personTemplate = (data) => personFormalNameLink(data.person);

    const rowExpansionTemplate = (data) => {
        const descriptionTemplate = (transaction) => {
            return (
                <>
                    {transaction?.valueDate} <br />
                    {transactionLink(transaction)}
                </>
            );
        };

        const badgeTemplate = (transaction) =>
            transaction.isOverdue ? <Badge className="x-small" severity="danger" /> : null;

        return (
            <DataTable value={data.transactions} emptyMessage="No hay saldos pendientes">
                <Column className="table-column-left" body={descriptionTemplate} />
                <Column
                    className="table-column-money"
                    field="siteAmount"
                    hidden={hideDetailColumns}
                />
                <Column
                    className="table-column-money"
                    field="siteCanceledAmount"
                    hidden={hideDetailColumns}
                />
                <Column className="table-column-money" field="siteDueAmount" />
                <Column className="table-column-badge" body={badgeTemplate} />
            </DataTable>
        );
    };

    return (
        <Card>
            <RequestMessages messages={requestMessages} />

            <DataTable
                value={details}
                dataKey="person.id"
                header={header}
                className="table-expandable-details"
                headerColumnGroup={headerColumnGroup}
                footerColumnGroup={footerColumnGroup}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={rowExpansionTemplate}
            >
                <Column expander={allowExpansion} className="table-column-button-expander" />
                <Column className="table-column-left" body={personTemplate} />
                <Column
                    className="table-column-money"
                    field="siteAmount"
                    hidden={hideDetailColumns}
                />
                <Column
                    className="table-column-money"
                    field="siteCanceledAmount"
                    hidden={hideDetailColumns}
                />
                <Column className="table-column-money" field="siteDueAmount" />
                <Column className="table-column-badge" />
            </DataTable>
        </Card>
    );
};
