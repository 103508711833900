import { AccountView } from './';

const BASE_PATH = '/account';

export const AccountPaths = Object.freeze({
    Account: {
        dashboard: BASE_PATH,
    },
});

export const routesInfo = [
    {
        path: AccountPaths.Account.dashboard,
        title: 'Mi Cuenta',
        exact: false,
        component: AccountView,
    },
];
