const COLUMN_STYLE = { width: '8em' };

export const ResponsibilityView = ({ responsibility }) => (
    <div>
        <div className="grid">
            <div className="col-fixed" style={COLUMN_STYLE}>
                Responsable:
            </div>
            <div className="col">
                {responsibility.responsible.formalName}
                {` (${responsibility.responsibleRole})`}
            </div>
        </div>
        <div className="grid">
            <div className="col-fixed" style={COLUMN_STYLE}>
                Beneficiario:
            </div>
            <div className="col">
                {responsibility.beneficiary.formalName}
                {` (${responsibility.beneficiaryRole})`}
            </div>
        </div>
    </div>
);
