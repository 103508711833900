// SAMPLE SCAFFOLD SERVICE to load static files data
// export class CountryService {
//     getCountries() {
//         return fetch('assets/demo/data/countries.json')
//             .then(res => res.json())
//             .then(d => d.data);
//     }
// }
// useEffect(() => {
//     countryService.getCountries().then(data => setCountries(data));
// }, []); // eslint-disable-line react-hooks/exhaustive-deps

export const PlaygroundOne = () => {
    return (
        <div>
            <h2>PlaygroundOne</h2>
        </div>
    );
};
