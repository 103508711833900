import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import { isNotEmpty } from '@utils/string-utils';
import { AccountingPaths } from './routes';

export const revokedClassName = (revocableItem) => (revocableItem.isRevoked ? 'revoked' : '');

const DESCRIPTION_SEPARATOR = ' - ';

export const transactionDescription = (transaction) => {
    if (transaction == null) return null;
    const elements = [transaction.description, transaction.timePeriod];
    return elements.filter(isNotEmpty).join(DESCRIPTION_SEPARATOR);
};

export const transactionTypeDescription = (transaction) => {
    if (transaction == null) return null;
    const elements = [transaction.type.label];
    elements.push(transactionDescription(transaction));
    return elements.filter(isNotEmpty).join(DESCRIPTION_SEPARATOR);
};

export const transactionLink = (transaction) =>
    transaction ? (
        <>
            <Link to={generatePath(AccountingPaths.Transaction.item, { id: transaction.id })}>
                {transactionTypeDescription(transaction)}
            </Link>

            {transaction?.isRevoked && (
                <>
                    <span style={{ marginLeft: '0.5em', marginRight: '0.5em' }}>|</span>
                    {'ANULADA'}
                </>
            )}

            {transaction?.reversedBy && (
                <>
                    <span style={{ marginLeft: '0.5em', marginRight: '0.5em' }}>|</span>
                    <Link
                        to={generatePath(AccountingPaths.Transaction.item, {
                            id: transaction.reversedBy,
                        })}
                    >
                        {'REVERTIDA'}
                    </Link>
                </>
            )}
        </>
    ) : null;

export const cancelationLink = (cancelation) =>
    cancelation ? (
        <>
            <Link
                to={generatePath(AccountingPaths.Transaction.item, {
                    id: cancelation.transaction.id,
                })}
            >
                {transactionTypeDescription(cancelation.transaction)}
            </Link>

            {cancelation?.isRevoked && (
                <>
                    <span style={{ marginLeft: '0.5em', marginRight: '0.5em' }}>|</span>
                    {'ANULADA'}
                </>
            )}
        </>
    ) : null;

/* Multi or single line description */

export const paymentModeDescription = (paymentMode) => {
    const description = paymentMode?.description;
    // TODO: Pre append type.description to this strings
    if (Array.isArray(description) && description.length) {
        return (
            <div>
                {description.map((line, index) => (
                    <div key={index}>{line}</div>
                ))}
            </div>
        );
    }
    return description;
};

export const ReceiptLink = ({ receipt, text = null }) => {
    if (receipt == null) return null;

    return (
        <>
            <Link to={generatePath(AccountingPaths.Receipt.item, { id: receipt.id })}>
                {text || receipt.id}
            </Link>

            {receipt?.isRevoked && (
                <>
                    <span style={{ marginLeft: '0.5em', marginRight: '0.5em' }}>|</span>
                    {'ANULADO'}
                </>
            )}
        </>
    );
};
