import { useCallback, useEffect, useState } from 'react';

import { generatePath, Link, useHistory, useLocation } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { RequestMessages } from '@components/RequestMessages';
import { RefreshButton } from '@components/buttons';
import { PersonFormalNameLink } from '@persons/templates';
import { genericRequestErrors } from '@services/index';
import { moneyOrEmpty } from '@utils/money';
import { appClickOpenPush } from '@utils/nav-utils';

import { CommissionsPath } from '@commissions/routes';
import { CommissionService } from '@services/commissionsService';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

export const CommissionList = () => {
    const history = useHistory();
    const location = useLocation();
    const [service] = useState(new CommissionService());

    // Params: Pagination
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);

    // Search results
    const [items, setItems] = useState([]);
    const [requestMessages, setRequestMessages] = useState();

    // Callbacks --------------------------------------------------------------

    // search can be called:
    // - On demand: repeatSearch
    // - On change: searchConfig
    // - On change: pagination (rows/page)
    // When searchConfig changes, pagination hast to change too: Page == 0

    const search = useCallback(
        (first, page, rows) => {
            const searchParams = {
                pageSize: rows,
                page: page + 1,
            };
            setRequestMessages(null);
            service
                .search(searchParams)
                .then((response) => {
                    const data = response.data;
                    setFirst(first);
                    setPage(page);
                    setRows(rows);
                    setItems(data.results);
                    setTotalRecords(data.count ? data.count : 0);
                })
                .catch((error) => setRequestMessages(genericRequestErrors(error)));
        },
        [service],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        search(0, 0, rows);
    }, [search, rows]);

    // Events -----------------------------------------------------------------

    const onPage = (event) => {
        search(event.first, event.page, event.rows);
    };

    const repeatSearch = () => {
        search(first, page, rows);
    };

    const rowClick = ({ data, originalEvent }) => {
        const url = generatePath(CommissionsPath.Commissions.item, { id: data.id });
        appClickOpenPush(url, originalEvent, history);
    };

    // Rendering --------------------------------------------------------------

    const descriptionTemplate = (commission) => (
        <>
            <div className="font-bold">{commission.name}</div>
            <div className="text-color-secondary">{commission.description}</div>
            <PersonFormalNameLink person={commission?.person} />
            <div>{commission.dueDate}</div>
        </>
    );

    const pledgesTemplate = (commission) => (
        <>
            <div>{moneyOrEmpty(commission.incomingTotal)}</div>
            <div>
                {commission.pledgesCount > 0 ? `(Cantidad ${commission.pledgesCount})` : 'Ninguno'}
            </div>
            <Link
                to={{
                    pathname: CommissionsPath.Pledges.create,
                    search: `?commission=${commission.id}`,
                    state: { from: location },
                }}
            >
                <Button
                    label="Compromiso"
                    icon="pi pi-plus"
                    style={{ minWidth: '125px' }}
                    className="mt-2"
                />
            </Link>
        </>
    );

    const descriptionHeader = (
        <div className="flex align-items-center gap-2">
            <span>Descripción</span>
            <RefreshButton onRefresh={repeatSearch} />
        </div>
    );

    return (
        <div className="commission-list">
            <RequestMessages messages={requestMessages} />

            <DataTable
                value={items}
                dataKey="id"
                // --------------------------------------------------------------------------
                // TODO: Implement an event-less navigation option so that right-click works
                selectionMode="single"
                onRowClick={rowClick}
                // --------------------------------------------------------------------------
                emptyMessage="No hay recaudaciones"
                lazy
                // Use this to show paginator only when required
                paginator={Boolean(totalRecords && totalRecords > MIN_PAGINATION_COUNT)}
                rows={rows}
                first={first}
                onPage={onPage}
                totalRecords={totalRecords}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            >
                <Column header={descriptionHeader} body={descriptionTemplate} />
                <Column
                    header="Compromisos"
                    body={pledgesTemplate}
                    className="table-column-money"
                />
            </DataTable>
        </div>
    );
};
