import suma from '@apis/Suma';
import { paramsString } from '.';

const APP_PATH = 'persons/responsibility/';

export class ResponsibilityService {
    search({ personId = null, beneficiaryId = null, active = true }) {
        const params = [];
        if (personId) {
            params.push(`person=${personId}`);
        }
        if (beneficiaryId) {
            params.push(`beneficiary=${beneficiaryId}`);
        }
        if (active != null) {
            params.push(`active=${active}`);
        }
        return suma.get(`${APP_PATH}${paramsString(params)}`);
    }

    // request = { responsibleId, beneficiaryId, responsibleRole, beneficiaryRole }
    create(request) {
        return suma.post(APP_PATH, request);
    }

    // request = {responsibleRole, beneficiaryRole}
    update(responsibilityId, request) {
        return suma.patch(`${APP_PATH}${responsibilityId}/`, request);
    }

    delete(responsibilityId) {
        return suma.delete(`${APP_PATH}${responsibilityId}/`);
    }
}
