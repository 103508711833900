import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Fieldset } from 'primereact/fieldset';
import { Row } from 'primereact/row';

import { cancelationLink } from '@accounting/templates';
import { RequestMessages } from '@components/RequestMessages';
import { DownloadButton, PopupItemsButton, RefreshButton, ShareButton } from '@components/buttons';
import { PersonIdentityLink } from '@persons/templates';
import { genericRequestErrors } from '@services/index';
import { isoLocalDateTimeTemplate, revocableMoneyTemplate } from '@utils/money';

import { useAuth } from '@hooks/use-auth.js';
import { AccountingService } from '@services/accounting';
import { downloadBlob, shareDownloadBlob } from '@services/sharing';
import { ReceiptAction } from './actions';
import { ChangeComment } from './change-comment';

const ReceiptHeader = ({ receipt }) => {
    if (!receipt) return null;

    const labelStyle = { width: '100px', fontWeight: 600 };

    const row = (label, value) => (
        <div className="grid">
            <div className="col-fixed" style={labelStyle}>
                {label}:
            </div>
            <div className="col">{value}</div>
        </div>
    );

    return (
        <Fieldset>
            {row('Persona', <PersonIdentityLink person={receipt.person} bestDocumentOnly={true} />)}
            {row('Emitido', isoLocalDateTimeTemplate(receipt, 'issuedTime'))}
            {row('Número', receipt.id)}
            {row('Comentario', receipt.comment)}
        </Fieldset>
    );
};

/* Transaction Details are not displayed

const TransactionsDetails = ({ receipt }) => {
    // Consider removing some types of transactions
    // e.g. Transfer from and to account transactions
    // Then all transactions would be of receipt.person

    if (!Array.isArray(receipt?.transactions) || receipt.transactions.length < 1) return null;

    const headerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column className="table-column-date" header="Fecha" />
                <Column className="table-column-left" header="Persona" />
                <Column className="table-column-left" header="Transacción" />
                <Column className="table-column-money" header="Importe" />
            </Row>
        </ColumnGroup>
    );

    const footerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column
                    className="table-column-left"
                    footer="Total"
                    colSpan={3}
                    footerStyle={{ textAlign: 'right' }}
                />
                <Column
                    className="table-column-money"
                    footer={revocableMoneyTemplate(receipt, 'total')}
                />
            </Row>
        </ColumnGroup>
    );

    return (
        <DataTable
            value={receipt.transactions}
            className="hide-table-header"
            rowGroupMode="rowspan"
            sortMode="single"
            sortField="person.formalName"
            sortOrder={1}
            headerColumnGroup={headerColumnGroup}
            footerColumnGroup={footerColumnGroup}
            emptyMessage="No hay transacciones."
        >
            <Column className="table-column-date" field="valueDate" />
            <Column className="table-column-left" field="person.formalName" />
            <Column className="table-column-left" body={transactionLink} />
            <Column
                className="table-column-money"
                body={(transaction) => revocableMoneyTemplate(transaction, 'amount')}
            />
        </DataTable>
    );
};
*/

// TODO: Person column is not grouped correctly. It did work with transactions.

const CancelationsDetails = ({ receipt }) => {
    if (!receipt) return null;

    if (!Array.isArray(receipt.cancelations) || receipt.cancelations.length < 1) return null;

    const headerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column className="table-column-left" header="Cancelaciones" colSpan={2} />
                <Column className="table-column-money" header="Importe" />
            </Row>
        </ColumnGroup>
    );

    const headerTemplate = (data) => {
        return (
            <div className="flex align-items-center gap-2">
                <span className="font-bold">{data.transaction.person.formalName}</span>
            </div>
        );
    };

    return (
        <DataTable
            value={receipt.cancelations}
            // TableHeader
            className="hide-table-header"
            headerColumnGroup={headerColumnGroup}
            // RowGroups
            rowGroupMode="subheader"
            groupRowsBy="transaction.person.formalName"
            sortMode="single"
            sortField="transaction.person.formalName"
            sortOrder={1}
            rowGroupHeaderTemplate={headerTemplate}
            emptyMessage="No hay cancelaciones."
        >
            {/* Indentation column, to make group items look inside the column header */}
            <Column style={{ width: '12px', padding: '0px' }} />
            <Column className="table-column-left" body={cancelationLink} />
            <Column
                className="table-column-money"
                body={(cancelation) => revocableMoneyTemplate(cancelation, 'siteCancelation')}
            />
        </DataTable>
    );
};

export const ReceiptItem = () => {
    const auth = useAuth();
    const params = useParams();
    const [action, setAction] = useState();
    const [service] = useState(new AccountingService());
    const [receipt, setReceipt] = useState(null);
    const [requestErrors, setRequestErrors] = useState([]);

    // Callbacks --------------------------------------------------------------

    const read = useCallback(
        (receiptId) => {
            setRequestErrors(null);
            service
                .receipt(receiptId)
                .then((response) => setReceipt(response.data))
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        },
        [service],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        read(params.id);
    }, [read, params.id]);

    // Events -----------------------------------------------------------------

    const refresh = () => read(params.id);

    const onUpdated = () => {
        setAction(null);
        refresh();
    };

    const onCancelUpdate = () => {
        setAction(null);
    };

    const confirmRevoke = () => {
        const accept = () => {
            service
                .revokeReceipt(params.id)
                .then(() => read())
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        };

        confirmDialog({
            icon: 'pi pi-exclamation-triangle',
            header: '¿Confirma anular recibo?',
            message: 'Las cancelaciones asociadas serán anuladas.',
            defaultFocus: 'accept',
            acceptLabel: 'Confirmo anular recibo',
            accept,
        });
    };

    // Rendering --------------------------------------------------------------

    const menuItems = [
        {
            label: 'Editar comentario',
            icon: 'pi pi-fw pi-pencil',
            disabled: receipt?.isRevoked,
            command: () => setAction(ReceiptAction.CHANGE_COMMENT),
        },
        { separator: true },
        {
            label: 'Anular',
            icon: 'pi pi-fw pi-times',
            disabled: receipt?.isRevoked,
            command: confirmRevoke,
        },
    ];

    const menuAction = <PopupItemsButton menuItems={menuItems} />;

    const downloadAction = (
        <DownloadButton
            onDownload={() => {
                downloadBlob(service.receiptPDF(receipt.id))
                    // .then((response) => alert(`Downloaded=${response.filename}`))
                    .catch((error) => setRequestErrors(genericRequestErrors(error)));
            }}
            disabled={!receipt}
        />
    );

    const shareAction = (
        <ShareButton
            onClick={() => {
                shareDownloadBlob({
                    ...service.receiptPDF(receipt.id),
                    title: `Recibo ${auth.site.name}`,
                    text: `Adjuntamos recibo. Atentamente, ${auth.site.name}`,
                })
                    // This is not working, there might be an error in
                    // the promise we are returning. Ignored anyway.
                    // .then((response) => {
                    //     console.log(response);
                    //     alert('Shared!');
                    // })
                    .catch((error) => setRequestErrors(genericRequestErrors(error)));
            }}
            disabled={!receipt}
        />
    );

    // const taxDocumentAction = (
    //     <Button label="Facturar" disabled={!receipt} style={{ marginLeft: '1rem' }} />
    // );

    const refreshAction = <RefreshButton onRefresh={refresh} />;

    const revokedTitle = receipt && receipt.isRevoked ? ' [ANULADO]' : '';

    const title = (
        <div className="document-action-header">
            <div className="title">
                {menuAction}
                {`Recibo${revokedTitle}`}
            </div>
            <div className="actions">
                {downloadAction}
                {shareAction}
                <span className="spacer" />
                {refreshAction}
            </div>
        </div>
    );

    return (
        <Card title={title} className="receipt-item">
            <ConfirmDialog />
            <RequestMessages messages={requestErrors} />
            <ReceiptHeader receipt={receipt} />
            <br />
            {/* Transaction details not displayed
            <TransactionsDetails receipt={receipt} />
            <br />
            */}
            <CancelationsDetails receipt={receipt} />
            {action === ReceiptAction.CHANGE_COMMENT && (
                <ChangeComment receipt={receipt} onSuccess={onUpdated} onCancel={onCancelUpdate} />
            )}
        </Card>
    );
};
