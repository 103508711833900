function removeFirstOccurrence(str, searchstr) {
    var index = str.indexOf(searchstr);
    if (index === -1) {
        return str;
    }
    return str.slice(0, index) + str.slice(index + searchstr.length);
}

/**
 * Returns the next subpath to navigate a fullPath forwards from currentPath.
 * 
 * For example:
 * 
 *  fullPath     : /accounting/account-due-summaries/overdue
 *  currentPath  : /accounting
 *  nextSubpath  : account-due-summaries

 *  fullPath     : /accounting/account-due-summaries/overdue
 *  currentPath  : /accounting/account-due-summaries
 *  nextSubpath  : overdue

 * @param {*} fullPath to be evaluated, e.g. react-router location.pathname 
 * @param {*} currentPath to be considered, e.g. react-router match.path
 * @returns the next subpath if fullPath is to be navigated forward from currentPath.
 */

export function nextSubpath(fullPath, currentPath) {
    let forward = removeFirstOccurrence(fullPath, currentPath);
    forward = forward.length && forward[0] === '/' ? forward.slice(1) : forward;
    var futureIndex = forward.indexOf('/');
    if (futureIndex === -1) {
        return forward;
    }
    return forward.slice(0, futureIndex);
}

/**
 * Returns the next path to navigate a currentPath backwards one subpath at a time.
 *
 * For example:
 *
 *  currentPath : /accounting/account-due-summaries/overdue
 *  previousPath : /accounting/account-due-summaries
 *
 *  currentPath : /accounting/account-due-summaries
 *  previousPath : /accounting
 *
 * @param {*} currentPath to be considered, e.g. react-router match.path or location.pathname
 * @returns the next path to navigate one subpath backwards.
 */

// export function previousPath(currentPath) {
//     return ''
// }
