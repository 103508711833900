export const ReconciledStatus = Object.freeze({
    PENDING: { value: 'PENDING', label: 'No', description: 'No acreditada', severity: 'danger' },
    ACCEPTED: { value: 'ACCEPTED', label: 'Sí', description: 'Sí acreditada', severity: 'success' },

    values: function () {
        return [this.PENDING, this.ACCEPTED];
    },

    labelFromEntryValue: function (entry) {
        if (entry == null || !entry?.reconciledStatus) return null;
        return ReconciledStatus[entry.reconciledStatus].label;
    },
});
