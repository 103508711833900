import { DateField } from './dateField';

export const MonthField = ({
    fieldName,
    fieldLabel,
    control,
    rules,
    customMessages,
    disabled,
    selectorOptions,
    ...rest
}) => {
    return (
        <DateField
            {...rest} // TODO: Place at the bottom to override props
            //-------------------------------------------------------
            // Captured only so that code IDE shows property names
            fieldName={fieldName}
            fieldLabel={fieldLabel}
            control={control}
            rules={rules}
            customMessages={customMessages}
            selectorOptions={selectorOptions}
            //-------------------------------------------------------
            // Actual overrides
            view="month"
            mask="99/9999"
            dateFormat="mm/yy"
            placeholder="mm/yyyy"
        />
    );
};

MonthField.displayName = 'MonthField';
