import { CommissionCreatePayments } from './commissions/create-payments';
import { CommissionItem } from './commissions/item';
import { CommissionList } from './commissions/list';

import { CommissionPledgeCreate } from './pledges/create';
import { CommissionPledgeItem } from './pledges/item';

import { configuration } from '@app/configuration';

const ROOT_PATH = '/commissions';
// const CONFIG_PATH = '/configuration' + ROOT_PATH;

export const CommissionsPath = Object.freeze({
    Commissions: {
        list: ROOT_PATH,
        item: ROOT_PATH + '/:id',
        createPayments: ROOT_PATH + '/:id/next-payments',
    },

    Pledges: {
        item: ROOT_PATH + '/pledges/:id',
        create: ROOT_PATH + '/pledges/create',
    },
});

export const CommissionsConfigurationPath = Object.freeze({});

const activeRoutesInfo = [
    {
        path: CommissionsPath.Commissions.list,
        title: 'Recaudaciones',
        exact: true,
        component: CommissionList,
    },
    // *** WARNING *** Must be before Commission.item
    {
        path: CommissionsPath.Commissions.createPayments,
        title: 'Generar Rendiciones',
        exact: true,
        component: CommissionCreatePayments,
    },
    {
        path: CommissionsPath.Commissions.item,
        title: 'Recaudación',
        exact: true,
        component: CommissionItem,
    },

    {
        path: CommissionsPath.Pledges.create,
        title: 'Nuevo Compromiso',
        exact: true,
        component: CommissionPledgeCreate,
    },
    {
        path: CommissionsPath.Pledges.item,
        title: 'Compromiso de Pago',
        exact: true,
        component: CommissionPledgeItem,
    },
];

const configRoutesInfo = [];

const makeRoutesInfo = () => {
    const routesInfo = [];
    routesInfo.push(...activeRoutesInfo);
    if (configuration.displayWIP) {
        routesInfo.push(...configRoutesInfo);
    }
    return routesInfo;
};

export const routesInfo = makeRoutesInfo();
