import suma from '@apis/Suma';

import { MIMEType } from '@enums/mime-type';
import { paginationParams, paramsString } from '.';

const APP_PATH = 'tokens';
const TYPES_PATH = 'types';
const TEMPLATES_PATH = 'templates';
const PACKS_PATH = `${APP_PATH}/packs`;
const USES_PATH = `${APP_PATH}/uses`;

export const TokenPackDetail = Object.freeze({
    DEFAULT: 'default',
    PERSON: 'person',
});

const uses_url = ({
    // @prettier:off
    page = null,
    pageSize = null,
    persons = [],
    comment = null,
    typeId = null,
    since = null,
    until = null,
}) => {
    const params = paginationParams(pageSize, page);
    if (Array.isArray(persons) && persons.length) {
        persons.forEach((person) => {
            params.push(`person=${person.id}`);
        });
    }
    if (comment) {
        params.push(`comment=${comment}`);
    }
    if (typeId) {
        params.push(`type=${typeId}`);
    }
    if (since) {
        params.push(`since=${since.toJSON()}`);
    }
    if (until) {
        // until is exclusive: if present, add one day to the request
        const newUntil = new Date(until);
        newUntil.setUTCDate(newUntil.getUTCDate() + 1);
        params.push(`until=${newUntil.toJSON()}`);
    }
    return `${USES_PATH}/${paramsString(params)}`;
};

export class TokenService {
    types({
        // @prettier:off
        page = null,
        pageSize = null,
    }) {
        const params = paginationParams(pageSize, page);
        return suma.get(`${APP_PATH}/${TYPES_PATH}/${paramsString(params)}`);
    }

    templates({
        // @prettier:off
        page = null,
        pageSize = null,
        typeId = null,
    }) {
        const params = paginationParams(pageSize, page);
        if (typeId) {
            params.push(`type=${typeId}`);
        }
        return suma.get(`${APP_PATH}/${TEMPLATES_PATH}/${paramsString(params)}`);
    }

    issue({
        // @prettier:off
        templateId = null,
        personId = null,
        since = null,
    }) {
        const request = { templateId, personId, since };
        return suma.post(`${PACKS_PATH}/`, request);
    }

    pack(packId) {
        return suma.get(`${PACKS_PATH}/${packId}/`);
    }

    revoke(packId) {
        return suma.post(`${PACKS_PATH}/${packId}/revoke/`);
    }

    packs({
        // @prettier:off
        page = null,
        pageSize = null,
        personId = null, // From PersonView!
        detail = null,
        persons = [],
        typeId = null,
        since = null,
        until = null,
    }) {
        const params = paginationParams(pageSize, page);
        if (personId) {
            params.push(`person=${personId}`);
        }
        if (detail) {
            params.push(`detail=${detail}`);
        }
        if (Array.isArray(persons) && persons.length) {
            persons.forEach((person) => {
                params.push(`person=${person.id}`);
            });
        }
        if (typeId) {
            params.push(`type=${typeId}`);
        }
        if (since) {
            params.push(`since=${since.toJSON()}`);
        }
        if (until) {
            // until is exclusive: if present, add one day to the request
            const newUntil = new Date(until);
            newUntil.setUTCDate(newUntil.getUTCDate() + 1);
            params.push(`until=${newUntil.toJSON()}`);
        }
        return suma.get(`${PACKS_PATH}/${paramsString(params)}`);
    }

    uses(
        request,
        // {
        //     // @prettier:off
        //     page = null,
        //     pageSize = null,
        //     persons = [],
        //     comment = null,
        //     since = null,
        //     until = null,
        // }
    ) {
        return suma.get(uses_url(request));
    }

    use_create(data) {
        return suma.post(`${USES_PATH}/`, data);
    }

    use_update(useId, data) {
        return suma.patch(`${USES_PATH}/${useId}/`, data);
    }

    uses_download_request(
        // search params without pagination, should work the same with pagination
        request,
        // {
        //     // @prettier:off
        //     page = null,
        //     pageSize = null,
        //     persons = [],
        //     comment = null,
        //     since = null,
        //     until = null,
        // }
    ) {
        return {
            url: uses_url(request),
            altFilename: 'tokenUseReport.xls',
            configOverride: {
                headers: {
                    Accept: MIMEType.XLSX,
                },
            },
        };
    }
}
