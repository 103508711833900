import { Route, Switch } from 'react-router-dom';

import { allRoutesInfo as routesInfo } from './AppRoutes';

export const AppPageTitles = () => {
    return (
        <Switch>
            {routesInfo.map((routeInfo, index) => (
                <Route key={index} path={routeInfo.path} exact={routeInfo?.exact}>
                    <h1>{routeInfo?.title}</h1>
                </Route>
            ))}
        </Switch>
    );
};
