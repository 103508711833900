import { DEFAULT_PATH_NOT_FOUND } from '@app/configuration';
import { NotFoundView } from './NotFoundView';

export const routesInfo = [
    {
        path: DEFAULT_PATH_NOT_FOUND,
        title: 'Error',
        exact: true,
        component: NotFoundView,
    },
];
