export const MonthFieldSelectorOption = Object.freeze({
    //-------------------------------------------------------------------------
    SEPARATOR: { separator: true },
    //-------------------------------------------------------------------------
    THIS_MONTH: {
        label: 'Este mes',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth(), 1);
        },
    },
    //-------------------------------------------------------------------------
    PREV_MONTH: {
        label: 'Mes anterior',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() - 1, 1);
        },
    },
    TWO_MONTHS_AGO: {
        label: 'Dos meses atrás',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() - 2, 1);
        },
    },
    THREE_MONTHS_AGO: {
        label: 'Tres meses atrás',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() - 3, 1);
        },
    },
    //-------------------------------------------------------------------------
    NEXT_MONTH: {
        label: 'Próximo mes',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() + 1, 1);
        },
    },
    IN_TWO_MONTHS: {
        label: 'Dos meses',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() + 2, 1);
        },
    },
    IN_THREE_MONTHS: {
        label: 'Tres meses',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() + 3, 1);
        },
    },
    //-------------------------------------------------------------------------
    BEGINNING_OF_YEAR: {
        label: 'Inicio de año',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), 0, 1);
        },
    },
    END_OF_YEAR: {
        label: 'Fin de año',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), 12, 0);
        },
    },
    OPEN_ENDED: {
        label: 'Final abierto',
        compute: () => {
            return null;
        },
    },
});

/** Common MonthFieldSelectorOption configurations & menuItems utility */

export const MonthFieldSelectorOptions = Object.freeze({
    ALL: Object.values(MonthFieldSelectorOption).slice(1),
    SOME: [
        MonthFieldSelectorOption.BEGINNING_OF_YEAR,
        MonthFieldSelectorOption.TWO_MONTHS_AGO,
        MonthFieldSelectorOption.PREV_MONTH,
        MonthFieldSelectorOption.SEPARATOR,
        MonthFieldSelectorOption.THIS_MONTH,
        MonthFieldSelectorOption.SEPARATOR,
        MonthFieldSelectorOption.NEXT_MONTH,
        MonthFieldSelectorOption.IN_TWO_MONTHS,
        MonthFieldSelectorOption.END_OF_YEAR,
    ],
    SINCE: [
        MonthFieldSelectorOption.PREV_MONTH,
        MonthFieldSelectorOption.THIS_MONTH,
        MonthFieldSelectorOption.NEXT_MONTH,
    ],
    UNTIL_CLOSED: [
        MonthFieldSelectorOption.PREV_MONTH,
        MonthFieldSelectorOption.THIS_MONTH,
        MonthFieldSelectorOption.NEXT_MONTH,
        MonthFieldSelectorOption.IN_TWO_MONTHS,
        MonthFieldSelectorOption.END_OF_YEAR,
    ],
    UNTIL_CLOSED_FUTURE: [
        MonthFieldSelectorOption.THIS_MONTH,
        MonthFieldSelectorOption.NEXT_MONTH,
        MonthFieldSelectorOption.IN_TWO_MONTHS,
        MonthFieldSelectorOption.IN_THREE_MONTHS,
        MonthFieldSelectorOption.END_OF_YEAR,
    ],
    UNTIL_OPENED_FUTURE: [
        MonthFieldSelectorOption.THIS_MONTH,
        MonthFieldSelectorOption.NEXT_MONTH,
        MonthFieldSelectorOption.IN_TWO_MONTHS,
        MonthFieldSelectorOption.END_OF_YEAR,
        MonthFieldSelectorOption.SEPARATOR,
        MonthFieldSelectorOption.OPEN_ENDED,
    ],

    menuItems: (options, valueSetter) => {
        if (Array.isArray(options) && options.length > 0) {
            return options.map((option) => {
                return option.separator
                    ? option
                    : {
                          label: option.label,
                          command: () => valueSetter(option.compute()),
                      };
            });
        }

        return null;
    },

    slapped: (capDay, back, forth) => {
        const date = new Date();
        return capDay <= date.getDate() ? forth.compute() : back.compute();
    },
});
