import axios from 'axios';

import { MIMEType } from '@enums/mime-type';
import { cookieNotEmpty } from '@utils/cookie-utils';

const BASE_URL = 'api/';

// https://docs.djangoproject.com/en/3.1/ref/settings/#std:setting-CSRF_HEADER_NAME
const CSRF_HEADER_NAME = 'X-CSRFToken';

// https://docs.djangoproject.com/en/3.1/ref/settings/#std:setting-CSRF_COOKIE_NAME
const CSRF_COOKIE_NAME = 'suma_csrftoken';

const COMMON_HEADERS = {
    Accept: MIMEType.JSON,
};

export const SUMA_SITE_COOKIE_NAME = 'suma_site';
export const SUMA_SESSION_COOKIE_NAME = 'suma_session';
export const SUMA_LANGUAGE_COOKIE_NAME = 'suma_language';

export const GLOBAL_ERRORS_KEY = 'nonFieldErrors';

// https://docs.djangoproject.com/en/3.1/ref/csrf/#setting-the-token-on-the-ajax-request

const configuration = {
    baseURL: BASE_URL,
    xsrfHeaderName: CSRF_HEADER_NAME,
    xsrfCookieName: CSRF_COOKIE_NAME,
    headers: {
        common: COMMON_HEADERS,
    },
    mode: 'same-origin', // Do not send CSRF token to another domain.
};

const suma = axios.create(configuration);

export default suma;

/**
 * If a non-empty cookie named CSRF_COOKIE_NAME does not exists, acquire a new one.
 */
export const verifyCSRFToken = () => {
    if (!cookieNotEmpty(CSRF_COOKIE_NAME)) {
        renewCSRFToken();
    }
};

/**
 * Acquire a new CSRFToken cookie, regardless if there was one available.
 */
export const renewCSRFToken = () => {
    suma.get('auth/csrf-token/').catch((error) => {
        console.log(error);
    });
};
