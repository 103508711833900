import { FieldValueGroup } from '../../components/field-value';

export const FieldValueDemo = () => {
    const action = (data) => {
        alert(`action=${data}`);
    };

    const addressMenu = [
        {
            label: 'Modificar',
            icon: 'pi pi-fw pi-pencil',
            command: () => action('edit_address'),
        },
        {
            label: 'Eliminar',
            icon: 'pi pi-fw pi-times',
            command: () => action('remove_address'),
        },
    ];

    // FieldValueCard
    //  field: Optional[str] Could be used by action, e.g. using self.field
    //  label: str
    //  value: str
    //  icon: str >> icon name for action, defaults to 'pi pi-chevron-right'
    //        For menuItems 'pi pi-ellipsis-v' is always uses, ccan´t be changed
    //  [either]
    //      action: callable
    //      menuItems: MenuItems

    const cards = [
        {
            field: 'name',
            label: 'Nombre y Apellido',
            value: 'José Marcos',
            action: () => action('edit_name'),
            icon: 'pi pi-pencil',
        },
        {
            field: 'documento',
            label: 'Documento',
            value: 'DNI 23278938',
            action: () => action('edit_document'),
        },
        {
            field: 'phone',
            label: 'Teléfono',
            value: '3436215631',
            action: () => action('edit_phone'),
        },
        { field: 'address', label: 'Dirección', value: 'Mitre 96', menuItems: addressMenu },
    ];

    return (
        <>
            <FieldValueGroup cards={cards} />
        </>
    );
};
