import { Relations } from './index';

import { configuration } from '@app/configuration';

const ACTIVE_PATH = '/relations';
const CONFIG_PATH = '/configuration';

export const RelationsConfigurationPath = Object.freeze({
    Relation: {
        dashboard: CONFIG_PATH + ACTIVE_PATH,
    },
});

const activeRoutesInfo = [];

const configRoutesInfo = [
    {
        path: RelationsConfigurationPath.Relation.dashboard,
        title: 'Relaciones',
        exact: true,
        component: Relations,
    },
];

const makeRoutesInfo = () => {
    const routesInfo = [];
    routesInfo.push(...activeRoutesInfo);
    if (configuration.displayWIP) {
        routesInfo.push(...configRoutesInfo);
    }
    return routesInfo;
};

export const routesInfo = makeRoutesInfo();
