import { DataBackup } from './backup';
import { ServiceDashboard } from './index';

import { configuration } from '@app/configuration';

const ACTIVE_PATH = '/service';
// const CONFIG_PATH = '/configuration';

export const ServicePath = Object.freeze({
    dashboard: ACTIVE_PATH,
    backup: ACTIVE_PATH + '/backup',
});

const activeRoutesInfo = [
    {
        path: ServicePath.dashboard,
        title: 'Servicio',
        exact: true,
        component: ServiceDashboard,
    },
    {
        path: ServicePath.backup,
        title: 'Backup de Datos',
        exact: true,
        component: DataBackup,
    },
];

const configRoutesInfo = [];

const makeRoutesInfo = () => {
    const routesInfo = [];
    routesInfo.push(activeRoutesInfo[0]);
    if (configuration.displayWIP) {
        routesInfo.push(activeRoutesInfo[1]);
    }
    routesInfo.push(...configRoutesInfo);
    return routesInfo;
};

export const routesInfo = makeRoutesInfo();
