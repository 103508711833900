import { useEffect } from 'react';

/**
 * Based on: https://stackoverflow.com/a/63074976
 *
 * Sample use with PrimeReact OverlayPanel to close on 'Escape' key down:
 *
 *   useOnKeyDown('Escape', () => reference.current.hide());
 *
 * @param {string | number} key
 * @param {function} onKeyDown
 */

// TODO: Consider implement a proper shortcut hook
// https://www.fullstacklabs.co/blog/keyboard-shortcuts-with-react-hooks

const VALID_KEY_EVENTS = new Set(['keydown', 'keyup']);

export const useOnKey = (key, keyEvent, onKeyEvent) => {
    if (!VALID_KEY_EVENTS.has(keyEvent)) {
        throw new Error(`Invalid keyEvent: '${keyEvent}'`);
    }

    useEffect(() => {
        const doOnKeyEvent = (event) => {
            if (event.key === key && onKeyEvent) {
                onKeyEvent(event);
            }
        };
        window.addEventListener(keyEvent, doOnKeyEvent);
        return () => window.removeEventListener(keyEvent, doOnKeyEvent);
    }, [key, keyEvent, onKeyEvent]);
};

export const useOnKeyDown = (key, onKeyDown) => {
    return useOnKey(key, 'keydown', onKeyDown);
};

export const useOnKeyUp = (key, onKeyUp) => {
    return useOnKey(key, 'keyup', onKeyUp);
};
