import { useHistory } from 'react-router';

import { Avatar } from 'primereact/avatar';

import { useAuth } from '@hooks/use-auth.js';

export const AppProfile = ({ onProfileButtonClick }) => {
    const auth = useAuth();
    const history = useHistory();

    const myAccountClick = (event) => {
        onProfileButtonClick(event);
        history.push('/account');
        event.preventDefault();
    };

    const mySitesClick = (event) => {
        onProfileButtonClick(event);
        history.push('/sites');
        event.preventDefault();
    };

    return (
        <div className="layout-profile">
            {/* <ul ref={menuRef} className="layout-profile-menu"> */}
            <ul className="layout-profile-menu">
                <li role="menuitem">
                    <button type="button" className="p-link" onClick={myAccountClick}>
                        <Avatar icon="pi pi-user" />
                        <span>{auth.user.fullName}</span>
                    </button>
                </li>
                <li role="menuitem">
                    <button type="button" className="p-link" onClick={mySitesClick}>
                        <Avatar icon="pi pi-building" />
                        <span>{auth.site ? auth.site.name : 'Mis sitios'}</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};
