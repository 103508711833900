import { LocalPaymentsConfiguration } from './local-payments/configuration';
import { LocalPaymentsDashboard } from './local-payments/dashboard';

import { CashRegister } from './cash/registers/item';
import { CashSession } from './cash/sessions/item';
import { CashSessionList } from './cash/sessions/list';

import { FundAccountConfiguration } from './funds/accounts/FundAccountConfiguration';
import { FundEntrySearch } from './funds/entries/FundEntrySearch';
import { FundEntryCredit, FundEntryDebit } from './funds/entries/create';
import { FundEntryView } from './funds/entries/view';

import { CashReport } from './reports/cash';

import { configuration } from '@app/configuration';

const ROOT_PATH = '';
const CONFIG_PATH = '/configuration';
const TREASURY_PATH = '/treasury';

export const TreasuryPaths = Object.freeze({
    LocalPayments: {
        dashboard: ROOT_PATH + '/local-payments',
    },

    CashRegister: {
        item: ROOT_PATH + '/cash-registers/:id',
        item_sessions: ROOT_PATH + '/cash-registers/:id/sessions',
    },

    CashSession: {
        item: ROOT_PATH + '/cash-sessions/:id',
    },

    FundEntry: {
        list: ROOT_PATH + '/funds-entries',
        view: ROOT_PATH + '/funds-entries/:id',
        debit: ROOT_PATH + '/funds-entries/debit',
        credit: ROOT_PATH + '/funds-entries/credit',
    },

    Reports: {
        cash: TREASURY_PATH + '/cash-report',
    },
});

export const TreasuryConfigurationPaths = Object.freeze({
    LocalPayments: {
        dashboard: CONFIG_PATH + '/local-payments',
    },

    FundAccount: {
        list: CONFIG_PATH + '/funds-accounts',
    },
});

const activeRoutesInfo = [
    {
        path: TreasuryPaths.LocalPayments.dashboard,
        title: 'Cajas y Terminales',
        exact: true,
        component: LocalPaymentsDashboard,
    },
    {
        path: TreasuryPaths.CashRegister.item,
        title: 'Caja Registradora',
        exact: true,
        component: CashRegister,
    },
    {
        path: TreasuryPaths.CashRegister.item_sessions,
        title: 'Últimos Cierres',
        exact: true,
        component: CashSessionList,
    },
    {
        path: TreasuryPaths.CashSession.item,
        title: 'Movimientos de Caja',
        exact: true,
        component: CashSession,
    },

    // ------------------------------------------------------------------------

    {
        path: TreasuryPaths.FundEntry.list,
        title: 'Movimientos de Cuentas',
        exact: true,
        component: FundEntrySearch,
    },
    {
        path: TreasuryPaths.FundEntry.debit,
        title: 'Movimiento de Cuenta',
        exact: true,
        component: FundEntryDebit,
    },
    {
        path: TreasuryPaths.FundEntry.credit,
        title: 'Movimiento de Cuenta',
        exact: true,
        component: FundEntryCredit,
    },
    {
        path: TreasuryPaths.FundEntry.view,
        title: 'Movimiento de Cuenta',
        exact: true,
        component: FundEntryView,
    },

    // ------------------------------------------------------------------------

    {
        path: TreasuryPaths.Reports.cash,
        title: 'Disponibilidades',
        exact: true,
        component: CashReport,
    },
];

const configRoutesInfo = [
    {
        path: TreasuryConfigurationPaths.LocalPayments.dashboard,
        title: 'Cajas y Terminales',
        exact: true,
        component: LocalPaymentsConfiguration,
    },
    {
        path: TreasuryConfigurationPaths.FundAccount.list,
        title: 'Cuentas y Billeteras',
        exact: true,
        component: FundAccountConfiguration,
    },
];

const makeRoutesInfo = () => {
    const routesInfo = [];
    routesInfo.push(...activeRoutesInfo);
    if (configuration.displayWIP) {
        routesInfo.push(...configRoutesInfo);
    }
    return routesInfo;
};

export const routesInfo = makeRoutesInfo();
