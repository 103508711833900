import { useRef, useState } from 'react';

import { Messages } from 'primereact/messages';
import { useForm } from 'react-hook-form';

import { MonthField, MonthFieldSelectorOptions } from '@components/fields';
import { ModalForm } from '@components/forms';

import { hookFormRequestError } from '@custom/hook-form';
import { RegistrationService } from '@services/registrations';
import { lastDayOfMonth, toISOLocalDateString } from '@utils/date-utils';

const DETAIL_FIELDS = ['accrueSince'];

export const AccrueRelationFees = ({
    personId = null,
    registration = null,
    onCancel,
    onSuccess,
}) => {
    // State -------------------------------------------------------------------

    const messages = useRef();
    const [service] = useState(new RegistrationService());
    const [requestErrors, setRequestErrors] = useState([]);

    // Hooks -------------------------------------------------------------------

    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        defaultValues: {
            accrueSince: null,
        },
    });

    // Execute -----------------------------------------------------------------

    const onExecute = (data) => {
        setRequestErrors(null);
        messages.current.clear();
        const request = {
            person: personId,
            registration: registration ? registration.id : null,
            accrueSince: toISOLocalDateString(lastDayOfMonth(data.accrueSince)),
        };
        service
            .accrueFees(request)
            .then(handleRequestSuccess)
            .catch((error) =>
                hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors),
            );
    };

    const handleRequestSuccess = (response) => {
        const { accruedFeesCount } = response.data;
        if (accruedFeesCount > 0) {
            onSuccess(accruedFeesCount);
        } else {
            messages.current.show([
                {
                    sticky: true,
                    severity: 'warn',
                    summary: 'No se generaron nuevas cuotas',
                    closable: true,
                },
            ]);
        }
    };

    // Render ------------------------------------------------------------------

    return (
        <ModalForm
            title="Generar cuotas"
            buttons={{ executeLabel: 'Generar' }}
            requestErrors={requestErrors}
            onSubmit={handleSubmit(onExecute)}
            onCancel={onCancel}
            style={{ width: '350px' }}
        >
            <div className="p-fluid grid formgrid">
                {registration && <h5>{registration.relation.name}</h5>}
                <div style={{ width: '180px', marginTop: '2em' }}>
                    <MonthField
                        control={control}
                        rules={{ required: true }}
                        fieldName="accrueSince"
                        fieldLabel="¿Hasta cuándo?"
                        selectorOptions={MonthFieldSelectorOptions.UNTIL_CLOSED_FUTURE}
                        autoFocus
                    />
                </div>
                <Messages ref={messages} />
            </div>
        </ModalForm>
    );
};
