import { Button } from 'primereact/button';
import { useHistory } from 'react-router';

export const NotFoundView = (props) => {
    // TODO: Remove 404 pages from navigation history.
    // console.log('NotFoundView');

    const history = useHistory();

    return (
        <div>
            <h1>Sistema SUMA</h1>
            <hr />
            <h3>La página solicitada no existe.</h3>
            <p>Use el menú para encontrar lo que necesita.</p>
            <div className="grid">
                <div>
                    <Button className="p-button-link" onClick={() => history.goBack()}>
                        Ir a página anterior
                    </Button>
                </div>
                <div>
                    <Button className="p-button-link" onClick={() => history.push('/')}>
                        Ir al inicio
                    </Button>
                </div>
            </div>
        </div>
    );
};
