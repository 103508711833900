import { useController } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';

import { FieldErrorMessages, invalidClassName } from '@components/field-error-messages';

const CounterpartType = Object.freeze({
    UNKNOWN: { value: 'unknown', label: 'Desconocida' },
    PERSON: { value: 'person', label: 'Persona' },
    USER: { value: 'user', label: 'Usuario' },
});

export const counterpartTypeLabeled = ({
    unknown = CounterpartType.UNKNOWN.label,
    person = CounterpartType.PERSON.label,
    user = CounterpartType.USER.label,
}) => {
    const { UNKNOWN, PERSON, USER } = CounterpartType;
    UNKNOWN.label = unknown;
    PERSON.label = person;
    USER.label = user;
    return Object.freeze({
        UNKNOWN,
        PERSON,
        USER,
    });
};

export const ALL_COUNTERTYPE_TYPE = [
    CounterpartType.UNKNOWN,
    CounterpartType.PERSON,
    CounterpartType.USER,
];

export const CounterpartTypeField = ({
    // disable prettier
    control,
    errors,
    fieldName = 'counterPartType',
    fieldLabel = 'Contraparte',
    optionLabel = 'label',
    options = ALL_COUNTERTYPE_TYPE,
    required = true,
    ...rest
}) => {
    const { field, fieldState } = useController({
        control: control,
        name: fieldName,
        rules: {
            required: required,
        },
    });

    return (
        <div className="field">
            <label htmlFor={field.name} className={invalidClassName(fieldState.error)}>
                {fieldLabel}
                {required ? ' *' : ''}
            </label>

            <Dropdown
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                options={options}
                optionValue="value"
                optionLabel={optionLabel}
                placeholder="Seleccione"
                className={invalidClassName(fieldState.error)}
                {...rest}
            />

            <FieldErrorMessages name={field.name} errors={errors} />
        </div>
    );
};

CounterpartTypeField.displayName = 'CounterpartTypeField';

// DRAFT to use RadioButtons
// export const CounterpartType = Object.freeze({
//     UNKNOWN: { label: 'Desconocida' },
//     PERSON: { label: 'Persona (Clientes y Proveedores)' },
//     USER: { label: 'Staff (Movimiento Interno)' },
// });

// const CounterpartField = ({ control, personField, userField, setValue }) => {
//     const [counterpartType, setCounterpartType] = useState(CounterpartType.UNKNOWN);
//     const unknownCounterpart = counterpartType === CounterpartType.UNKNOWN;
//     const personCounterpart = counterpartType === CounterpartType.PERSON;
//     const userCounterpart = counterpartType === CounterpartType.USER;

//     const changeCounterpartType = (value) => {
//         if (value === CounterpartType.UNKNOWN) {
//             setValue('person', null);
//             setValue('user', null);
//         }
//         if (value === CounterpartType.PERSON) {
//             setValue('user', null);
//         }
//         if (value === CounterpartType.USER) {
//             setValue('person', null);
//         }
//         setCounterpartType(value);
//     };

//     return (
//         <>
//             <div className="grid">
//                 <div className="col-fixed" style={{ width: '42px' }}>
//                     <RadioButton
//                         inputId="unknown"
//                         value={CounterpartType.UNKNOWN}
//                         checked={unknownCounterpart}
//                         onChange={(e) => changeCounterpartType(e.value)}
//                     />
//                 </div>
//                 <div className="col">
//                     <label htmlFor="unknown">{CounterpartType.UNKNOWN.label}</label>
//                 </div>
//             </div>
//             <div className="grid">
//                 <div className="col-fixed" style={{ width: '42px' }}>
//                     <RadioButton
//                         inputId="person"
//                         value={CounterpartType.PERSON}
//                         checked={personCounterpart}
//                         onChange={(e) => changeCounterpartType(e.value)}
//                     />
//                 </div>
//                 <div className="col">{personField}</div>
//             </div>
//             <div className="grid">
//                 <div className="col-fixed" style={{ width: '42px' }}>
//                     <RadioButton
//                         inputId="user"
//                         value={CounterpartType.USER}
//                         checked={userCounterpart}
//                         onChange={(e) => changeCounterpartType(e.value)}
//                     />
//                 </div>
//                 <div className="col">{userField}</div>
//             </div>
//         </>
//     );
// };
