import { createContext, useContext, useMemo, useState } from 'react';

import suma from '@apis/Suma';
import { LogLevel, createLogger } from '@custom/logger';
import { useMountEffect } from '@hooks/use-mount-effect';

/**
 * Based on: https://usehooks.com/useAuth/, implemented for firebase/auth.
 */

const AuthContext = createContext();
AuthContext.displayName = 'AuthContext';

// Provider component that wraps the app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
// This will be executed on each page refresh (F5), causing
// the application to hit the API to retrieve the user-info.

function useProvideAuth() {
    const logger = useMemo(() => createLogger('useProvideAuth', LogLevel.DEBUG), []);

    //--------------------------------------------------------------------------
    // State
    //--------------------------------------------------------------------------

    const [user, setUser] = useState(false);
    const [site, setSite] = useState(false);
    const [isSiteOwner, setIsSiteOwner] = useState(false);

    //--------------------------------------------------------------------------
    // Logging indirections
    //--------------------------------------------------------------------------

    const setSessionData = (data) => {
        logger.trace('setSessionData');
        setUser(data?.user ? data.user : false);
        setSite(data?.site ? data.site : false);
        setIsSiteOwner(data?.isSiteOwner ? data.isSiteOwner : false);
    };

    const clearSessionData = () => {
        logger.trace('clearSessionData');
        setUser(false);
        setSite(false);
        setIsSiteOwner(false);
    };

    //--------------------------------------------------------------------------
    // Effects
    //--------------------------------------------------------------------------

    useMountEffect(() => {
        logger.trace('mountEffect [] >> GET(auth/session/)');

        // Retrieve user-info from API. This will only succeed if
        // we have a valid suma_session cookie, otherwise it will
        // fail with 401 - Unauthorized. We ignore that error.
        // TODO: Implement subscriptions to onAuthStateChanged.

        clearSessionData();

        suma.get('auth/session/')
            .then((response) => setSessionData(response.data))
            .catch((error) => {
                logger.debug(error.response ? error.response : error);
            });
    });

    //--------------------------------------------------------------------------
    // Actions
    //--------------------------------------------------------------------------

    const signIn = (credentials, onSuccess, onError) => {
        logger.trace('signIn');

        suma.post('auth/login/', credentials)
            .then((response) => {
                setSessionData(response.data);
                onSuccess && onSuccess();
            })
            .catch((error) => {
                logger.debug(error.response ? error.response : error);
                clearSessionData();
                onError && onError(error);
            });
    };

    //   const signup = (email, password) => {
    //     return firebase
    //       .auth()
    //       .createUserWithEmailAndPassword(email, password)
    //       .then(response => {
    //         setUser(response.user);
    //         return response.user;
    //       });
    //   };

    const signOut = (onSuccess, onError) => {
        logger.trace('signOut');

        // Executes regardless of wether logout POST succeeds or not
        clearSessionData();

        suma.post('auth/logout/')
            .then(onSuccess && onSuccess())
            .catch((error) => {
                // TODO: Solve the offline logout problem #160
                // https://github.com/ivanbrizuela/sumate/issues/160

                logger.debug(error.response ? error.response : error);

                // Since we call clearUserInfo regardless of errors, there is
                // no need to handle the logout error message. It would just
                // display an error message in the home page, even after the
                // user request action seems to be actually completed.
                // The problem of not being logged out still exists, but if
                // the error is displayed it will confuse the user and leave
                // the application subject to another person's simple use.

                // NOTE: We've reenabled this because no actual error is
                // displayed to user onError, it is just logged to console.
                onError && onError(error);
            });
    };

    const putSite = (site, onSuccess, onError) => {
        logger.trace('putSite');

        const request = { siteId: site ? site.id : null };
        suma.patch('auth/session/', request)
            .then((response) => {
                setSessionData(response.data);
                onSuccess && onSuccess();
            })
            .catch((error) => {
                logger.debug(error.response ? error.response : error);
                onError && onError(error);
            });
    };

    //   const sendPasswordResetEmail = email => {
    //     return firebase
    //       .auth()
    //       .sendPasswordResetEmail(email)
    //       .then(() => {
    //         return true;
    //       });
    //   };

    //   const confirmPasswordReset = (code, password) => {
    //     return firebase
    //       .auth()
    //       .confirmPasswordReset(code, password)
    //       .then(() => {
    //         return true;
    //       });
    //   };

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    //   useEffect(() => {
    //     const unsubscribe = firebase.auth().onAuthStateChanged(user => {
    //       if (user) {
    //         setUser(user);
    //       } else {
    //         setUser(false);
    //       }
    //     });

    //     // Cleanup subscription on unmount
    //     return () => unsubscribe();
    //   }, []);

    return {
        // Expose data
        user,
        site,
        isSiteOwner,

        // Expose methods
        signIn,
        // signUp,
        signOut,
        putSite,
        // sendPasswordResetEmail,
        // confirmPasswordReset
    };
}
