import suma from '@apis/Suma';
import { PresentStatus } from '@enums';
import { firstDayOfMonth, fromISOLocalDateString, localDateNow } from '@utils/date-utils';
import { paramsString } from '.';

export class RegistrationService {
    /** Get all Relations options
     *
     * @returns nested options:
     * relations/major-relations
     * relations/minor-relations
     * relations/payment-options
     */

    getAllOptions = () => {
        return suma.get('relations/options/');
        // return Promise.resolve({ data: SAMPLE_OPTIONS })
    };

    /** Get one Relation options
     *
     * @param {*} relationId
     * @returns nested options
     * relations/<relationId>/minor-relations/
     * relations/<relationId>/payment-options/
     */

    getOptions = (relationId) => {
        return suma.get(`relations/${relationId}/options/`);
    };

    getPersonNestedRegistrations = (personId, showExpired = false) => {
        // TODO: Consider an alternative endpoint in relations application:
        // api/relations/nested-registrations/?person=personId&status=status&details=True
        const status = showExpired ? PresentStatus.ANY : PresentStatus.UNEXPIRED;
        return suma.get(`persons/${personId}/nested-registrations/?status=${status}`);
    };

    registerPerson = (request) => {
        return suma.post('relations/registrations/', request);
    };

    changeRegistration = (registrationId, request) => {
        return suma.patch(`relations/registrations/${registrationId}/`, request);
    };

    deleteRegistration = (registrationId) => {
        return suma.delete(`relations/registrations/${registrationId}/`);
    };

    accrueFees = (request) => {
        return suma.post('relations/accrue-fees/', request);
    };

    // Reports -----------------------------------------------------------------

    /** WARNING: NOT-PAGINATED-REPORT */

    getRegistrationsReport = ({
        reportDate = null,
        // majorRelation = null,
        // minorRelation = null,
    }) => {
        const params = [];
        if (reportDate != null) {
            params.push(`report_date=${reportDate}`);
        }
        // if (minorRelation != null) {
        //     params.push(`minor_relation=${minorRelation}`);
        // } else if (majorRelation != null) {
        //     params.push(`major_relation=${majorRelation}`);
        // }
        return suma.get(`relations/registrations/report/${paramsString(params)}`);
    };

    // Non-web service requests -----------------------------------------------

    getCurrentRelationOption = (relationOptions, registration) => {
        return Array.isArray(relationOptions) && relationOptions.length > 0
            ? relationOptions.find((option) => option.id === registration.relation.id)
            : null;
    };

    getNextRelationOption = (relationOptions, registration) => {
        if (Array.isArray(relationOptions) && relationOptions.length > 0) {
            // First relation that is different from registration.relation (ascending order)
            // return relationOptions.find(option => option.id !== registration.relation.id);

            // First relation listed after the registration.relation
            // Assumes that the server might intelligently order options
            // If already in the last relation, cycle back to the first one
            const prevIndex = relationOptions.findIndex(
                (option) => option.id === registration.relation.id,
            );
            if (prevIndex != null) {
                if (prevIndex + 1 < relationOptions.length) {
                    return relationOptions[prevIndex + 1];
                }
                if (prevIndex !== 0) {
                    // Cycle from the beginning, if different
                    return relationOptions[0];
                }
                // else: There is a single option and equals current
            }
        }

        return null;
    };

    getNextPaymentOption = (relationOption) => {
        if (relationOption != null) {
            const paymentOptions = relationOption?.paymentOptions;
            if (Array.isArray(paymentOptions) && paymentOptions.length > 0) {
                return paymentOptions[0];
            }
        }
        return null;
    };

    getNextMinorRelationOption = (relationOption) => {
        if (relationOption != null) {
            const minorRelations = relationOption?.minorRelations;
            if (Array.isArray(minorRelations) && minorRelations.length > 0) {
                return minorRelations[0];
            }
        }
        return null;
    };

    getNextRegistrationSince = (nextRelation, prevRegistration) => {
        // If we have no information, return no information
        if (nextRelation == null && prevRegistration == null) {
            return null;
        }

        // If prevRegistration exists and starts in the future,
        // then default to that since for any kind of change.
        if (prevRegistration != null) {
            const today = localDateNow();
            if (today <= prevRegistration.since) {
                return fromISOLocalDateString(prevRegistration.since);
            }
        }

        // Otherwise, the registration started in the past or never
        // If nextRelation.requiresPayment, choose next billing period
        if (nextRelation != null && nextRelation.requiresPayment) {
            // TODO: Use relation and currentRegistration and  compute
            // the correct next billing period to propose as default.
            return firstDayOfMonth(new Date());
        }

        // If no payment is required, then it can probably change anytime.
        return new Date();
    };
}
