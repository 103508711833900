import { useState } from 'react';

import { ModalForm } from '@components/forms';
import { genericRequestErrors } from '@services/index';

import { ResponsibilityView } from './ResponsibilityView';
import { ResponsibilityService } from '@services/responsibilityService';

export const ResponsibilityDelete = ({ responsibility, onSuccess, onCancel }) => {
    const [service] = useState(new ResponsibilityService());
    const [requestErrors, setRequestErrors] = useState([]);

    const onExecute = () => {
        setRequestErrors(null);
        service
            .delete(responsibility.id)
            .then((response) => onSuccess && onSuccess(response.data))
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    };

    return (
        <ModalForm
            onSubmit={onExecute}
            title={`Remover ${responsibility.isResponsible ? 'Beneficiario' : 'Responsable'}`}
            buttons={{ executeLabel: 'Confirmar' }}
            onCancel={onCancel}
            // style={{ width: '400px' }}
            requestErrors={requestErrors}
        >
            <ResponsibilityView responsibility={responsibility} />
        </ModalForm>
    );
};
