import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { Button } from 'primereact/button';

import { DateTimeField } from '@components/fields';
import { DEFAULT_SELECTOR_OPTION, SinceUntilBlock } from '@components/fields/sinceUntilBlock';
import { zeroMinutesNow } from '@utils/date-utils';

export const SinceUntilBlockDemo = () => {
    const {
        control,
        watch,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            period: DEFAULT_SELECTOR_OPTION,
            ...DEFAULT_SELECTOR_OPTION.compute(),
            usedAt: [zeroMinutesNow()],
        },
    });

    // WARNING: Watch triggers re-renders. Consider using useWatch hook
    // https://react-hook-form.com/api/useform/watch/
    const [period, since, until, usedAt] = watch(['period', 'since', 'until', 'usedAt']);

    const onExecute = (data) => {
        alert(`since=${data.since}\n until=${data.until}\n`);
    };

    useEffect(() => {
        if (Object.prototype.hasOwnProperty.call(period, 'compute')) {
            const newRange = period.compute();
            setValue('since', newRange.since);
            setValue('until', newRange.until);
        }
    }, [setValue, period]);

    return (
        <form>
            <div className="card p-fluid">
                <SinceUntilBlock control={control} errors={errors} />
                <hr />
                <DateTimeField
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                    fieldLabel="Día y Hora"
                    fieldName="usedAt"
                    selectionMode="multiple"
                    stepMinute={10}
                    readOnlyInput={true}
                />
                <Button label="Ok" style={{ width: '150px' }} onClick={handleSubmit(onExecute)} />
            </div>
            <hr />
            <br />
            <div>
                {`usedAt.length=${usedAt.length}`}
                <ul>
                    {usedAt.map((item, index) => (
                        <li key={index}>{item.toString()}</li>
                    ))}
                </ul>
                <hr />
                {`typeof(since)=${typeof since}`}
                <br />
                {`typeof(until)=${typeof until}`}
                <br />
                <br />
                {`since=${since}`}
                <br />
                {`until=${until}`}
            </div>
        </form>
    );
};
