import { routesInfo as accountRoutes } from '@account/routes';
import { routesInfo as errorsRoutes } from '@errors/routes';
import { routesInfo as sitesRoutes } from '@sites/routes';

import { routesInfo as accountingRoutes } from '@accounting/routes';
import { routesInfo as commissionsRoutes } from '@commissions/routes';
import { routesInfo as personsRoutes } from '@persons/routes';
import { routesInfo as relationsRoutes } from '@relations/routes';
import { routesInfo as reportsRoutes } from '@reports/routes';
import { routesInfo as serviceRoutes } from '@service/routes';
import { routesInfo as taxRoutes } from '@tax/routes';
import { routesInfo as tokensRoutes } from '@tokens/routes';
import { routesInfo as treasuryRoutes } from '@treasury/routes';
import { routesInfo as usersRoutes } from '@users/routes';

export const userRoutesInfo = [...accountRoutes, ...sitesRoutes, ...errorsRoutes];

export const siteRoutesInfo = [
    ...accountingRoutes,
    ...commissionsRoutes,
    ...personsRoutes,
    ...relationsRoutes,
    ...reportsRoutes,
    ...serviceRoutes,
    ...taxRoutes,
    ...tokensRoutes,
    ...treasuryRoutes,
    ...usersRoutes,
];
