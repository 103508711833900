import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { StringUtils } from '@utils/string-utils';

export const CommissionPledgeTemplateEntries = ({ entries }) => {
    const descriptionTemplate = (entry) => {
        return StringUtils.joinNotEmpty([entry.description, entry.timePeriod]);
    };

    const dueDateTemplate = (entry) => {
        return entry.dueDate == null ? 'Hoy' : entry.dueDate;
    };

    return (
        <DataTable value={entries}>
            <Column header="Vencimiento" className="table-column-date" body={dueDateTemplate} />
            <Column header="Descripción" body={descriptionTemplate} />
            <Column header="Importe" className="table-column-money" field="amount" />
        </DataTable>
    );
};
