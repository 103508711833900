import { AppPageTitles } from '@app/AppPageTitles';

export const AppTopbar = ({ onMenuButtonClick }) => {
    return (
        <div className="layout-topbar">
            <button className="layout-menu-button p-link" onClick={onMenuButtonClick}>
                <i className="pi pi-bars"></i>
            </button>
            <div className="layout-topbar-title">
                <AppPageTitles />
            </div>
        </div>
    );
};
