import { DateUtilsTest } from '../../utils/__tests__/date-utils';
import { PathUtilsTest } from '../../utils/__tests__/path-utils';

export function UtilsDemo() {
    return (
        <>
            <DateUtilsTest />
            <br />
            <PathUtilsTest />
        </>
    );
}
