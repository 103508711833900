import { Button } from 'primereact/button';

export const ExecuteCancelButtons = (props) => {
    const executeButton = (
        <>
            {props.showExecute && (
                <Button
                    className="execute-button"
                    type="submit"
                    label={props.executeLabel}
                    onClick={props.onExecute}
                    disabled={props.executeDisabled}
                    {...props?.executeProps}
                />
            )}
        </>
    );

    const cancelButton = (
        <>
            {props.showCancel && (
                <Button
                    className="p-button-text cancel-button"
                    type="reset"
                    label={props.cancelLabel}
                    onClick={props.onCancel}
                    disabled={props.cancelDisabled}
                    {...props?.cancelProps}
                />
            )}
        </>
    );

    return (
        <div className="execute-cancel-buttons">
            {/* Written in the reverse order that they are displayed
            with css flex-direction: row-reverse; This affords showing
            the executeButton to the right, but making it the first
            tabbed-in button. Note that this is NOT recommended:
            https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex */}
            {executeButton} {cancelButton}
        </div>
    );
};

ExecuteCancelButtons.defaultProps = {
    onExecute: null,
    onCancel: null,
    showExecute: true,
    showCancel: true,
    executeLabel: 'Ok',
    cancelLabel: 'Volver',
    executeDisabled: false,
    cancelDisabled: false,
    executeProps: null,
    cancelProps: null,
};

ExecuteCancelButtons.newProps = (props) => {
    return {
        ...ExecuteCancelButtons.defaultProps,
        ...props,
    };
};
