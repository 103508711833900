import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { RequestMessages } from '@components/RequestMessages';
import { Section } from '@components/Section';
import { FieldValueGroup } from '@components/field-value';
import { formattedCUIT } from '@components/fields';
import { Addresses } from '@components/fields/address';
import { computePersonFullName } from '@persons/templates';

import { PersonAction } from './actions';
import { ChangeAddress } from './change-address';
import { ChangeCUIT } from './change-cuit';
import { ChangeDNI } from './change-dni';
import { ChangeDOB } from './change-dob';
import { ChangeEmail } from './change-email';
import { ChangeName } from './change-name';
import { ChangePhoneNumber } from './change-phoneNumber';

import { genericRequestErrors } from '@services/index';
import { PersonService } from '@services/personService';

export const PersonDetails = ({ onChanged }) => {
    const params = useParams();
    const [person, setPerson] = useState();
    const [action, setAction] = useState();
    const [service] = useState(new PersonService());
    const [requestErrors, setRequestErrors] = useState();

    // -------------------------------------------------------------------------
    // Effects
    // -------------------------------------------------------------------------

    const loadPerson = useCallback(() => {
        service
            .get(params.id)
            .then((response) => setPerson(response.data))
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    }, [params.id, service]);

    useEffect(() => {
        loadPerson();
    }, [loadPerson]);

    // -------------------------------------------------------------------------
    // Events
    // -------------------------------------------------------------------------

    const cancelAction = () => {
        setAction(null);
    };

    const completedAction = (updatedPerson) => {
        setPerson(updatedPerson);
        setAction(null);
        if (onChanged) {
            onChanged('details', updatedPerson);
        }
    };

    // -------------------------------------------------------------------------
    // Rendering
    // -------------------------------------------------------------------------

    const personCards = person
        ? [
              {
                  label: 'Nombre y Apellido',
                  value: computePersonFullName(person),
                  action: () => setAction(PersonAction.CHANGE_NAME),
              },
              {
                  label: 'DNI',
                  value: person.dni,
                  action: () => setAction(PersonAction.CHANGE_DNI),
              },
              {
                  label: 'CUIT/CUIL',
                  value: formattedCUIT(person.cuit),
                  action: () => setAction(PersonAction.CHANGE_CUIT),
              },
              {
                  label: 'Teléfono',
                  value: person.phoneNumber,
                  action: () => setAction(PersonAction.CHANGE_PHONE_NUMBER),
              },
              {
                  label: 'E-mail',
                  value: person.email,
                  action: () => setAction(PersonAction.CHANGE_EMAIL),
              },
              {
                  label: 'Fecha de Nacimiento',
                  value: person.dob,
                  action: () => setAction(PersonAction.CHANGE_DOB),
              },
              {
                  label: 'Dirección',
                  value: <Addresses addresses={person?.addresses} />,
                  action: () => setAction(PersonAction.CHANGE_ADDRESS),
              },
          ]
        : null;

    return (
        <Section>
            <RequestMessages messages={requestErrors} />

            <FieldValueGroup cards={personCards} />

            {action === PersonAction.CHANGE_NAME && (
                <ChangeName person={person} onCancel={cancelAction} onSuccess={completedAction} />
            )}

            {action === PersonAction.CHANGE_DNI && (
                <ChangeDNI person={person} onCancel={cancelAction} onSuccess={completedAction} />
            )}

            {action === PersonAction.CHANGE_CUIT && (
                <ChangeCUIT person={person} onCancel={cancelAction} onSuccess={completedAction} />
            )}

            {action === PersonAction.CHANGE_PHONE_NUMBER && (
                <ChangePhoneNumber
                    person={person}
                    onCancel={cancelAction}
                    onSuccess={completedAction}
                />
            )}

            {action === PersonAction.CHANGE_EMAIL && (
                <ChangeEmail person={person} onCancel={cancelAction} onSuccess={completedAction} />
            )}

            {action === PersonAction.CHANGE_DOB && (
                <ChangeDOB person={person} onCancel={cancelAction} onSuccess={completedAction} />
            )}

            {action === PersonAction.CHANGE_ADDRESS && (
                <ChangeAddress
                    person={person}
                    onCancel={cancelAction}
                    onSuccess={completedAction}
                />
            )}
        </Section>
    );
};
