import { Button } from 'primereact/button';

export const ClearFilterButton = ({ className = '', ...props }) => (
    <Button
        type="button"
        icon="pi pi-filter-slash"
        className={`p-button-rounded p-button-text ${className}`}
        {...props}
    />
);
