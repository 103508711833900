import { Button } from 'primereact/button';

// import { PanelButton } from './PanelButton';
import { PopupItemsButton } from './buttons';

const actionTemplate = (card) => {
    if (card.action) {
        // return <i className="pi pi-chevron-right" onClick={card.action} />
        const icon = card.icon ? card.icon : 'pi pi-chevron-right';
        return (
            <Button icon={icon} className="p-button-rounded p-button-text" onClick={card.action} />
        );
    }
    if (card.menuItems) {
        return <PopupItemsButton menuItems={card.menuItems} />;
    }
    return null;
};

const dataTemplate = (card) => (
    <div className="grid data">
        <div className="col-5 label">{card.label}</div>
        <div className="col-6 value">{card.value}</div>
        <div className="col-1 action" style={{ padding: 0 }}>
            {actionTemplate(card)}
        </div>
    </div>
);

const cardTemplate = (card) => {
    const data = dataTemplate(card);
    // if (card.action && card.clicker) {
    //     return <PanelButton onClick={card.action} >
    //         {data}
    //     </PanelButton>
    // }
    return data;
};

export const FieldValueGroup = ({ cards }) => {
    return (
        <div className="field-value-group">
            {cards && cards.length && (
                <ul>
                    {cards.map((card, index) => {
                        return (
                            <li key={index} id={card.field}>
                                {cardTemplate(card)}
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};
