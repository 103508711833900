import { useRef, useState } from 'react';

import { useController } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Menu } from 'primereact/menu';

import { FieldError, errorClassName, invalidClassName } from '@components/field-error-messages';
import { DateFieldSelectorOptions } from './dateFieldSelectorOption';

const defaultYearRange = () => {
    const today = new Date();
    const thisYear = today.getFullYear();
    return `${thisYear - 2}:${thisYear + 2}`;
};

export const DateField = ({
    fieldName,
    fieldLabel,
    control,
    rules = {},
    customMessages = {},
    disabled,
    inputStyle,
    selectorOptions = null,
    yearRange = defaultYearRange(),
    ...rest
}) => {
    const { field, fieldState } = useController({ name: fieldName, control, rules });
    const menu = useRef(null);
    const [menuItems] = useState(
        DateFieldSelectorOptions.menuItems(selectorOptions, (value) => field.onChange(value)),
    );

    return (
        <div className="field">
            {fieldLabel && (
                <label htmlFor={fieldName} className={errorClassName(fieldState.error)}>
                    {fieldLabel}
                    {rules?.required ? ' *' : ''}
                </label>
            )}
            <div className="p-inputgroup" style={inputStyle}>
                {selectorOptions && (
                    <>
                        <Menu model={menuItems} popup ref={menu} />
                        <Button
                            icon="pi pi-bars"
                            disabled={disabled}
                            onClick={(event) => {
                                event.preventDefault();
                                menu.current.toggle(event);
                            }}
                        />
                    </>
                )}
                <Calendar
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    mask="99/99/9999"
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yy"
                    showIcon
                    showOnFocus={false} // Datepicker will not be visible with input focus.
                    monthNavigator
                    yearNavigator
                    yearRange={yearRange}
                    className={invalidClassName(fieldState.error)}
                    {...rest}
                />
            </div>
            <FieldError fieldError={fieldState.error} customMessages={customMessages} />
        </div>
    );
};

DateField.displayName = 'DateField';
