import { Link } from 'react-router-dom';

import { SelectSite } from './SelectSite';

export const SitesDashBoard = () => {
    return (
        <>
            <SelectSite />
            <br />
            <Link to="/account">Mi Cuenta</Link>
        </>
    );
};
