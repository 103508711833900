import { useCallback, useEffect, useState } from 'react';

import { generatePath, useHistory, useParams } from 'react-router-dom';

import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { RequestMessages } from '@components/RequestMessages';
import { genericRequestErrors } from '@services/index';
import { isoLocalDateTemplate } from '@utils/money';

import { CommissionsPath } from '@commissions/routes';
import { CommissionService } from '@services/commissionsService';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

export const PersonCommissionPledgeList = () => {
    const params = useParams();
    const history = useHistory();
    const [service] = useState(new CommissionService());

    // Params: Pagination
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);

    // Search results
    const [items, setItems] = useState([]);
    const [requestMessages, setRequestMessages] = useState();

    // Callbacks --------------------------------------------------------------

    const search = useCallback(
        (first, page, rows) => {
            // if (forceUpdate == null) return;
            const searchParams = {
                pageSize: rows,
                page: page + 1,
                personId: params.id,
            };
            setRequestMessages(null);
            service
                .pledges(searchParams)
                .then((response) => {
                    const data = response.data;
                    setFirst(first);
                    setPage(page);
                    setRows(rows);
                    setItems(data.results);
                    setTotalRecords(data.count ? data.count : 0);
                })
                .catch((error) => setRequestMessages(genericRequestErrors(error)));
        },
        [service, params.id],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        search(0, 0, rows);
    }, [search, rows]);

    // Events -----------------------------------------------------------------

    const onPage = (event) => {
        search(event.first, event.page, event.rows);
    };

    const rowClick = ({ data }) => {
        // TODO: Implement an event-less navigation option so that right-click works
        history.push(generatePath(CommissionsPath.Pledges.item, { id: data.id }));
    };

    // Rendering --------------------------------------------------------------

    const rowClassName = (pledge) => (pledge.isRevoked ? 'revoked' : '');

    const descriptionTemplate = (pledge) => (
        <>
            <div>
                {isoLocalDateTemplate(pledge, 'pledgedOn')}
                {pledge.isRevoked && <span> | ANULADO</span>}
            </div>
            <div>{pledge.commission.name}</div>
            <div>{pledge.template.name}</div>
            <div>{pledge.comments}</div>
        </>
    );

    return (
        <Card className="person-commission-pledge-list">
            <RequestMessages messages={requestMessages} />

            <DataTable
                value={items}
                dataKey="id"
                emptyMessage="No hay compromisos"
                rowClassName={rowClassName}
                // --------------------------------------------------------------------------
                // TODO: Implement an event-less navigation option so that right-click works
                selectionMode="single"
                onRowClick={rowClick}
                // --------------------------------------------------------------------------
                lazy
                paginator={true}
                rows={rows}
                first={first}
                page={page}
                onPage={onPage}
                totalRecords={totalRecords}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            >
                <Column header="Compromiso" body={descriptionTemplate} />
                <Column header="Importe" className="table-column-money" field="incomingTotal" />
                <Column header="Cobrado" className="table-column-money" field="incomingCanceled" />
                <Column header="Saldo" className="table-column-money" field="incomingBalance" />
            </DataTable>
        </Card>
    );
};
