import suma from '@apis/Suma';

import { paginationParams, paramsString } from '.';

const APP_PATH = 'commissions';
const COMMISSIONS_PATH = APP_PATH;
const PLEDGES_PATH = APP_PATH + '/pledges';

export class CommissionService {
    search({
        // @prettier:off
        page = null,
        pageSize = null,
    }) {
        const params = paginationParams(pageSize, page);
        return suma.get(`${COMMISSIONS_PATH}/${paramsString(params)}`);
    }

    get(commissionId) {
        return suma.get(`${COMMISSIONS_PATH}/${commissionId}/`);
    }

    templates({
        // @prettier:off
        page = null,
        pageSize = null,
        commissionId = null,
    }) {
        const params = paginationParams(pageSize, page);
        if (commissionId) {
            params.push(`commission=${commissionId}`);
        }
        return suma.get(`${COMMISSIONS_PATH}/pledge-templates/${paramsString(params)}`);
    }

    pledges({
        // @prettier:off
        page = null,
        pageSize = null,
        personId = null,
        commissionId = null,
    }) {
        const params = paginationParams(pageSize, page);
        if (personId) {
            params.push(`person=${personId}`);
        }
        if (commissionId) {
            params.push(`commission=${commissionId}`);
        }
        return suma.get(`${COMMISSIONS_PATH}/pledges/${paramsString(params)}`);
    }

    entries({
        // @prettier:off
        page = null,
        pageSize = null,
        commissionId = null,
        typeId = null,
    }) {
        const params = paginationParams(pageSize, page);
        if (commissionId) {
            params.push(`commission=${commissionId}`);
        }
        if (typeId) {
            params.push(`type=${typeId}`);
        }
        return suma.get(`${COMMISSIONS_PATH}/entries/${paramsString(params)}`);
    }

    nextPayments({
        // @prettier:off
        commissionId = null,
        collectedOnly = true,
        createEntries = false,
    }) {
        return suma.post(`${COMMISSIONS_PATH}/${commissionId}/next-payments/`, {
            collectedOnly,
            createEntries,
        });
    }
}

export class CommissionPledgeService {
    get(pledgeId) {
        return suma.get(`${PLEDGES_PATH}/${pledgeId}/`);
    }

    create(request) {
        return suma.post(`${PLEDGES_PATH}/`, request);
    }

    update(pledgeId, request) {
        return suma.patch(`${PLEDGES_PATH}/${pledgeId}/`, request);
    }

    templates(commissionId) {
        return suma.get(`${COMMISSIONS_PATH}/${commissionId}/templates/`);
    }

    revoke(pledgeId) {
        return suma.post(`${PLEDGES_PATH}/${pledgeId}/revoke/`);
    }
}
