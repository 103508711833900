export const CanceledStatus = Object.freeze({
    PENDING: {
        value: 'PENDING',
        label: 'Pendiente',
        description: 'Sin imputaciones',
        severity: 'warning',
    },
    PARTIAL: {
        value: 'PARTIAL',
        label: 'Parcial',
        description: 'Imputación parcial',
        severity: 'warning',
    },
    COMPLETED: {
        value: 'COMPLETED',
        label: 'Completa',
        description: 'Imputada',
        severity: 'success',
    },

    values: function () {
        return [this.PENDING, this.PARTIAL, this.COMPLETED];
    },

    labelFromEntryValue: function (entry) {
        if (entry == null || !entry?.canceledStatus) return null;
        return CanceledStatus[entry.canceledStatus].label;
    },

    anyCancelations: function (entry) {
        return [this.PARTIAL.value, this.COMPLETED.value].includes(entry?.canceledStatus);
    },
});
