import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Badge } from 'primereact/badge';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { RefreshButton } from '@components/buttons';
import { RequestMessages } from '@components/RequestMessages';
import { genericRequestErrors } from '@services/index';
import { revocableMoneyTemplate } from '@utils/money';

import { CommercialTransactionCreateButton } from '@accounting/commercial/create';
import { NewPaymentButton } from '@accounting/receipts/newPayment';
import { transactionLink } from '@accounting/templates';
import { accountingService as service } from '@services/accounting';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

export const SiteTransactionsBalance = () => {
    const params = useParams();
    const [items, setItems] = useState([]);
    const [searchTimestamp, setSearchTimestamp] = useState();
    const [requestMessages, setRequestMessages] = useState([]);

    // Pagination & Lazy loading
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);
    // This causes screen flicker by rendering a spinner
    // Might be usable if query takes too long to return
    // const [loading, setLoading] = useState(false);

    // Effects ----------------------------------------------------------------

    const search = useCallback(() => {
        setRequestMessages(null);
        // TODO: Afford filtering uncanceled transactions only
        service
            .siteTransactionsBalance({ personId: params.id, page: page + 1, pageSize: rows })
            .then((response) => {
                const data = response.data;
                setItems(data.results);
                setTotalRecords(data.count ? data.count : 0);
                // Used in NewPaymentButton.forceUpdate to sync data
                setSearchTimestamp(Date.now());
                // TODO: Globally report if there are uncanceled documents. When
                // pagination is used, it is helpful to filter for uncanceled only.
                // setHasUncanceled(data.hasUncanceled);
            })
            .catch((error) => setRequestMessages(genericRequestErrors(error)));
    }, [params.id, page, rows]);

    useEffect(() => {
        search();
    }, [search]);

    // Events -----------------------------------------------------------------

    const onPage = (event) => {
        setFirst(event.first);
        setPage(event.page);
        setRows(event.rows);
    };

    // Rendering --------------------------------------------------------------

    const header = (
        <div className="split-table-header">
            <div className="table-actions-header">
                <CommercialTransactionCreateButton personId={params.id} comeBack={true} />
                {/* WARNING: Refreshing dues does not trigger update on
                         NewPaymentButton (PersonSiteBalance and Responsibility)
                         records because props don't change. Force syncing using
                         something like a time-stamp on the button*/}
                <NewPaymentButton
                    beneficiaryId={params.id}
                    forceUpdate={searchTimestamp}
                    showBalance={false}
                />
            </div>
            <RefreshButton onRefresh={search} className="hide-constrained-400" />
        </div>
    );

    const rowClassName = (transaction) => (transaction.isRevoked ? 'revoked' : '');

    const descriptionTemplate = (transaction) => {
        return (
            <>
                {transaction?.valueDate} <br />
                {transactionLink(transaction)}
            </>
        );
    };

    const badgeTemplate = (transaction) => {
        if (!transaction.isRevoked && (transaction.isOverdue || !transaction.isCanceled)) {
            return (
                <Badge
                    className="x-small"
                    severity={transaction.isOverdue ? 'danger' : 'warning'}
                />
            );
        }
        return null;
    };

    return (
        <Card className="site-transaction-balance">
            <RequestMessages messages={requestMessages} />

            <DataTable
                value={items}
                dataKey="id"
                header={header}
                rowClassName={rowClassName}
                lazy
                paginator={Boolean(totalRecords && totalRecords > MIN_PAGINATION_COUNT)}
                rows={rows}
                totalRecords={totalRecords}
                first={first}
                onPage={onPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            >
                <Column
                    className="table-column-left"
                    header="Descripción"
                    body={descriptionTemplate}
                />
                <Column
                    className="table-column-money"
                    header="Importe"
                    body={(row) => revocableMoneyTemplate(row, 'siteAmount')}
                />
                <Column header={null} className="table-column-badge" body={badgeTemplate} />
                <Column className="table-column-money" header="Saldo" field="siteBalance" />
            </DataTable>
        </Card>
    );
};
