import { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { TabMenu } from 'primereact/tabmenu';

import { nextSubpath } from '../utils/path-utils';

export const SubpathTabMenu = ({ menuModel, rootPath, defaultSubpath, children }) => {
    const location = useLocation();
    const subpath = nextSubpath(location.pathname, rootPath);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if (subpath) {
            const newIndex = menuModel.findIndex((item) => item.subpath === subpath);
            if (newIndex) {
                setActiveIndex(newIndex);
            }
        }
    }, [subpath, menuModel]);

    if (!subpath) {
        return <Redirect push={false} to={`${rootPath}/${defaultSubpath}`} />;
    }

    return (
        <>
            {/* TODO: Considerar reemplazar TabMenu por MenuBar */}
            {/* import { Menubar } from 'primereact/menubar'; */}
            {/* Esto permitiría eventualmente colgar submenus de los principales, e.g.:
            
                Datos Personales
                    Nuevo teléfono
                    Nueva dirección

                Membresías
                    Nueva membresía

                Cuenta Corriente
                    Nuevo pago

                => No tener que navegar a una sección para varias acciones
            */}

            <TabMenu
                model={menuModel}
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
            />
            <div className="mt-3 mb-3">{children}</div>
        </>
    );
};
