import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { generatePath } from 'react-router';

import { Badge } from 'primereact/badge';
import { Checkbox } from 'primereact/checkbox';
import { DataView } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';

import { RequestMessages } from '@components/RequestMessages';
import { ClearFilterButton, PopupItemsButton, RefreshButton } from '@components/buttons';
import { PersonInput } from '@components/fields';

import { AccountingPaths } from '@accounting/routes';
import { PersonFormalNameLink } from '@persons/templates';
import { RevocableMoney } from '@utils/money';

import { CanceledStatus } from '@enums/index';
import { FundEntryAction } from './FundEntryAction';
import { FundEntryReconcile } from './FundEntryReconcile';
import { FundEntryRevoke } from './FundEntryRevoke';
import { FundEntryUpdate } from './FundEntryUpdate';
import { FundEntryCreditButton, FundEntryDebitButton } from './create';

import { genericRequestErrors } from '@services/index';

import { userDescription } from '@account/templates';
import { EMPTY_FUND_ACCOUNT_ID, FundEntryService } from '@services/fundsService';
import { DEFAULT_STATUS_FILTER, useFundEntryStatusFilter } from '@treasury/funds/filters/status';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

const DEFAULT_SEARCH_PARAMS = {
    person: null,
    emptyPerson: false,
    fundAccount: null,
    statusFilter: DEFAULT_STATUS_FILTER,
};

// TODO: Implement FundAccount filter like FundEntryStatusFilter
// TODO: Implement FundTransactionType filter like FundEntryStatusFilter

export const FundEntrySearch = () => {
    // State & Hooks
    const [items, setItems] = useState([]);
    const [action, setAction] = useState(null);
    const [target, setTarget] = useState(null);
    const [service] = useState(new FundEntryService());
    const { control, watch, setValue } = useForm();
    const [requestErrors, setRequestErrors] = useState();

    // SearchParams
    const [searchParams, setSearchParams] = useState({ ...DEFAULT_SEARCH_PARAMS });
    const personWatch = watch('person', DEFAULT_SEARCH_PARAMS.person);
    const [emptyPerson, setEmptyPerson] = useState(DEFAULT_SEARCH_PARAMS.emptyPerson);
    const [fundAccount, setFundAccount] = useState(DEFAULT_SEARCH_PARAMS.fundAccount);
    const [statusFilterButton, statusFilter, resetStatusFilter] = useFundEntryStatusFilter();

    // SearchParams options
    const [fundAccountOptions, setFundAccountOptions] = useState([]);

    // Pagination
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);

    // Callbacks --------------------------------------------------------------

    const searchFundAccounts = useCallback(() => {
        setRequestErrors(null);
        service
            .getAccountOptions()
            .then((response) => {
                const options = response.data.results;
                options.unshift({
                    id: EMPTY_FUND_ACCOUNT_ID,
                    name: 'Sin cuenta',
                });
                setFundAccountOptions(options);
            })
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    }, [service]);

    const searchFundsEntries = useCallback(
        (searchParams, first, page, rows) => {
            setAction(null);
            setRequestErrors(null);
            const request = {
                ...searchParams,
                pageSize: rows,
                page: page + 1,
            };
            service
                .search(request)
                .then((response) => {
                    const data = response.data;
                    setFirst(first);
                    setPage(page);
                    setRows(rows);
                    setItems(data.results);
                    setTotalRecords(data.count ? data.count : 0);
                })
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        },
        [service],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        searchFundAccounts();
    }, [searchFundAccounts]);

    useEffect(() => {
        const newSearchParams = {
            person: personWatch != null ? personWatch?.[0] : null,
            emptyPerson: emptyPerson,
            fundAccount: fundAccount,
            statusFilter: statusFilter,
        };
        setSearchParams(newSearchParams);
        // Prevent pagination error resetting page and first when search params change
        searchFundsEntries(newSearchParams, 0, 0, rows);
    }, [searchFundsEntries, personWatch, emptyPerson, fundAccount, statusFilter, rows]);

    // Events -----------------------------------------------------------------

    const onPage = (event) => {
        searchFundsEntries(searchParams, event.first, event.page, event.rows);
    };

    const refresh = () => {
        searchFundsEntries(searchParams, first, page, rows);
    };

    const resetFilters = () => {
        setValue('person', DEFAULT_SEARCH_PARAMS.person);
        setEmptyPerson(DEFAULT_SEARCH_PARAMS.emptyPerson);
        setFundAccount(DEFAULT_SEARCH_PARAMS.fundAccount);
        resetStatusFilter();
    };

    const cancelTargetAction = () => {
        setTarget(null);
        setAction(null);
    };

    const completedAction = () => {
        setTarget(null);
        setAction(null);
    };

    const updateActionSuccess = (updated) => {
        if (updated.id !== target.id) {
            // A new item was created on update:
            // * Insert new item to the beginning
            // * Update target item where it was
            // We need to create a newItems array here including the new item.
            // If we called setItems here and then call refreshItem directly,
            // the new item would be overwritten, never reaching state.
            const newItems = [updated, ...items];
            refreshItem(target, newItems);
        } else {
            refreshItems(updated, items);
        }
        completedAction();
    };

    const revokeActionSuccess = (revocation) => {
        refreshItem(target);
        completedAction();
    };

    const refreshItem = (outdated, outdatedItems = null) => {
        if (outdated) {
            service
                .get(outdated.id)
                .then((response) => refreshItems(response.data, outdatedItems))
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        }
    };

    const refreshItems = (refreshedItem, outdatedItems = null) => {
        const targetItems = outdatedItems || items;
        const index = targetItems.findIndex((item) => item.id === refreshedItem.id);
        if (index === -1) {
            // refreshedItem was currently not on display. Should rarely happen.
            searchFundsEntries();
        } else {
            const newItems = [...targetItems];
            newItems[index] = refreshedItem;
            setItems(newItems);
        }
    };

    const changeEmptyPerson = (value) => {
        if (value) {
            // When setting emptyPerson, clear any currently selected value
            setValue('person', null);
        }
        setEmptyPerson(value);
    };

    // Filters ----------------------------------------------------------------

    // TODO: Implement filters using advanced pop-up templates (PrimeReact 9.2)
    // https://primereact.org/datatable/#advanced_filter

    // TODO: valueDateFilter
    // TODO: amountFilter

    const personFilter = (
        <div className="p-inputgroup" style={{ maxWidth: '350px' }}>
            <PersonInput
                required={false}
                control={control}
                disabled={emptyPerson}
                placeholder={
                    emptyPerson ? 'Sin persona' : personWatch == null ? 'Buscar persona' : null
                }
            />
            <span className="p-inputgroup-addon">
                <Checkbox checked={emptyPerson} onChange={(e) => changeEmptyPerson(e.checked)} />
                &nbsp;Sin
            </span>
        </div>
    );

    const fundAccountFilter = (
        <Dropdown
            placeholder="Cuenta"
            value={fundAccount}
            options={fundAccountOptions}
            onChange={(e) => setFundAccount(e.value)}
            optionLabel="name"
            style={{ display: 'flex' }}
            showClear
        />
    );

    // Rendering --------------------------------------------------------------

    const header = (
        <div className="flex flex-column gap-2 mb-3">
            <div className="flex flex-wrap gap-2">
                <FundEntryCreditButton />
                <FundEntryDebitButton />
                <div className="flex flex-1 justify-content-end">
                    <RefreshButton onRefresh={refresh} />
                </div>
            </div>
            <div>{personFilter}</div>
            <div className="flex flex-wrap gap-2">
                {fundAccountFilter}
                {statusFilterButton}
                <ClearFilterButton onClick={resetFilters} label="Borrar filtros" />
            </div>
        </div>
    );

    const fundEntryActions = (entry) => {
        const setTargetAction = (action) => {
            setTarget(entry);
            setAction(action);
        };

        const menuItems = [
            {
                label: 'Conciliar',
                disabled: entry.isRevoked,
                command: () => setTargetAction(FundEntryAction.RECONCILE),
            },
            {
                label: `Cancelar${entry.person == null ? ': Falta persona' : ''}`,
                icon: 'pi pi-fw pi-bolt',
                // FundEntryAction.CANCEL
                disabled: entry.person == null || entry.isRevoked,
                url: `#${AccountingPaths.Receipt.create}?person=${entry?.person?.id}`,
            },
            { separator: true },
            // ================================================================
            // We don't offer to "Share" or "Download" a `Transaction` without
            // the user seeing what information is actually going to be shared
            // or downloaded. Many times, he won´t want to send a `Transaction`
            // but a `Receipt`, which is indirectly linked to a `Transaction`.
            // ================================================================
            // TODO: View action could be an icon of its own outside this menu
            {
                label: 'Comprobante',
                icon: 'pi pi-fw pi-eye',
                // FundEntryAction.VIEW
                url: `#${generatePath(AccountingPaths.Transaction.item, { id: entry.id })}`,
            },
            {
                label: 'Editar',
                icon: 'pi pi-fw pi-pencil',
                disabled: entry.isRevoked,
                command: () => setTargetAction(FundEntryAction.UPDATE),
            },
            {
                label: 'Anular',
                icon: 'pi pi-fw pi-times',
                disabled: entry.isRevoked,
                command: () => setTargetAction(FundEntryAction.REVOKE),
            },
            // Nice work, but that's too many options for users
            // {
            //     label: 'Refrescar',
            //     icon: 'pi pi-fw pi-refresh',
            //     disabled: entry.isRevoked,
            //     // FundEntryAction.REFRESH
            //     command: () => refreshItem(entry),
            // },
        ];

        return (
            <PopupItemsButton
                menuItems={menuItems}
                menuProps={{ style: { width: '200px' } }}
                // There seems to be a bug in TieredMenu. Might work with newer versions
                // Collision detection for TieredMenu Submenus to keep them within viewport
                // https://github.com/primefaces/primeng/issues/4717
                // tiered={true}
            />
        );
    };

    const fundEntryCard = (entry) => {
        const entryIcon = (entry) => {
            const reconciledBadge = !entry.isRevoked && !entry.reconciled;
            const iconName = entry.incomingFund ? 'pi pi-download' : 'pi pi-upload';
            return (
                <i className={classNames(iconName, { 'p-overlay-badge': reconciledBadge })}>
                    {reconciledBadge && <Badge severity="danger" />}
                </i>
            );
        };

        const statusTag = (entry) => {
            if (entry.isRevoked) {
                return <Tag value="Anulada" style={{ backgroundColor: 'grey' }} />;
            }

            const canceledStatus = CanceledStatus[entry.canceledStatus];
            if (canceledStatus !== CanceledStatus.COMPLETED) {
                return <Tag value={canceledStatus.label} severity="warning" />;
            }

            return null;
        };

        return (
            <div className={classNames('fund-entry-card', { revoked: entry.isRevoked })}>
                <div className="colgroup">
                    <div className="col1">{entryIcon(entry)}</div>
                    <div className="col2">
                        <div className={classNames('type', { revoked: entry.isRevoked })}>
                            {entry.type.label}
                        </div>
                        <div className="account">
                            Cuenta {entry?.fundAccount ? entry.fundAccount.name : 'sin asignar'}
                        </div>
                    </div>
                    <div className="col3">
                        <div className="amount">
                            <RevocableMoney amount={entry.flowAmount} isRevoked={entry.isRevoked} />
                        </div>
                        <div className="date">{entry.valueDate}</div>
                    </div>
                </div>
                <hr />
                <div className="colgroup">
                    <div className="col1">{fundEntryActions(entry)}</div>
                    <div className="col2">
                        <div className="counterpart">
                            {entry?.person && <PersonFormalNameLink person={entry.person} />}
                            {entry?.user && `Staff: ${userDescription(entry.user)}`}
                            {!(entry?.user || entry?.person) && 'Contraparte sin asignar'}
                        </div>
                        {entry.description && (
                            <div className="description">{entry.description}</div>
                        )}
                    </div>
                    <div className="col3">
                        <div className="status">{statusTag(entry)}</div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="fund-entry-search">
            {header}
            <RequestMessages messages={requestErrors} />
            <DataView
                value={items}
                dataKey="id"
                emptyMessage="No hay movimientos."
                itemTemplate={fundEntryCard}
                lazy
                // --------------------------------------------------------------------------
                // Always displayed for this view, regardless of displayed records count
                // paginator={Boolean(totalRecords && totalRecords > MIN_PAGINATION_COUNT)}
                paginator
                rows={rows}
                first={first}
                onPage={onPage}
                totalRecords={totalRecords}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            />

            {target && action === FundEntryAction.UPDATE && (
                <FundEntryUpdate
                    entry={target}
                    onSuccess={updateActionSuccess}
                    onCancel={cancelTargetAction}
                />
            )}

            {target && action === FundEntryAction.RECONCILE && (
                <FundEntryReconcile
                    entry={target}
                    onUpdated={updateActionSuccess}
                    onCancel={cancelTargetAction}
                />
            )}

            {target && action === FundEntryAction.REVOKE && (
                <FundEntryRevoke
                    entry={target}
                    onSuccess={revokeActionSuccess}
                    onCancel={cancelTargetAction}
                />
            )}
        </div>
    );
};
