import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';

import { renewCSRFToken, verifyCSRFToken } from '@apis/Suma';
import { hookFormRequestError } from '@custom/hook-form';
import { useAuth } from '@hooks/use-auth.js';
import { useMountEffect } from '@hooks/use-mount-effect';

import { RequestMessages } from '@components/RequestMessages';
import { PasswordField, UsernameField } from '@components/fields';

import { defaultPathAuthenticated } from '@app/configuration';

const DETAIL_FIELDS = ['username', 'password'];

export const Login = () => {
    // If CSRFToken exists, use it. Otherwise, request a new one.
    // Guarantees that on mount, a CSRFToken will be available.
    useMountEffect(verifyCSRFToken);

    const auth = useAuth();
    const history = useHistory();
    const location = useLocation();
    const [requestErrors, setRequestErrors] = useState();

    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        defaultValues: {
            username: '',
            password: '',
        },
    });

    const login = (data) => {
        auth.signIn(data, handleSuccess, handleError);
    };

    const handleSuccess = (site) => {
        // On login success redirect user depending on whether a site is already selected.
        // If a site is selected, redirect to the original request location if it existed
        // (will fail if it belongs to another site) or to DEFAULT_PATH_MANAGING_SITE.
        // Otherwise if no site is selected, redirect to DEFAULT_PATH_PERSONAL_SITE.
        const { from } = location.state || {
            from: {
                pathname: defaultPathAuthenticated(site),
            },
        };
        history.replace(from);
    };

    const handleError = (error) => {
        if (error?.response && error.response?.status === 403) {
            // TODO : This should be done only once, because there might be other
            // types of errors. Maybe it should read if CSRF error is reported and
            // on then issue a renewCSRFToken.
            // The second time, we should warn the user that he needs cookies enabled.

            renewCSRFToken();

            // The following is not true when using SessionBasedAuthentication,
            // since when invalid credentials are provided a 403 error is raised.
            // See these references:
            // * https://www.django-rest-framework.org/api-guide/authentication/#unauthorized-and-forbidden-responses
            // * https://stackoverflow.com/a/25880970

            // So we can't use 401 and 403 to distinguish anything...

            // [INVALID...]
            // The HTTP 403 Forbidden client error status response code indicates
            // that the server understood the request but refuses to authorize it.
            // This status is similar to 401, but in this case, re-authenticating
            // will make no difference. The access is permanently forbidden and tied
            // to the application logic, such as insufficient rights to a resource.

            //
            if (error.response?.data?.detail === 'Invalid credentials') {
                setRequestErrors([
                    {
                        severity: 'error',
                        summary: 'Credenciales inválidas',
                        sticky: true,
                        closable: true,
                    },
                ]);
                return null;
            }
        }
        hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors);
    };

    return (
        <form onSubmit={handleSubmit(login)} autoComplete="on">
            <div className="p-fluid">
                <UsernameField
                    control={control}
                    errors={errors}
                    autoFocus
                    autoComplete="on"
                    fieldLabel="Usuario"
                />
                <PasswordField
                    control={control}
                    errors={errors}
                    fieldLabel="Contraseña"
                    size="large"
                />
                <RequestMessages messages={requestErrors} />
                <Button label="Ingresar" size="large" />
            </div>
        </form>
    );
};
