import { settings } from '@localization/settings';

// Returns the first defined, non-null argument.
// https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/coalesce.md
// TODO: Create a nonblank so that empty strings of any lenght are passed over too.

export const coalesce = (...args) => args.find((v) => ![undefined, null].includes(v));

/**
 * WARNING: Prefer to configure server serializers to clean_empty_fields
 *
 */
export const strippedOrNull = (value) => {
    const trimmed = typeof value === 'string' ? value.trim() : '';
    return trimmed.length > 0 ? trimmed : null;
};

export const valueOrEmpty = (value, empty = settings.defaults.emptyValue) => {
    return value ? value : empty;
};

// Source: https://stackoverflow.com/a/57476978
export const isEmpty = (text) => {
    return text == null || text.match(/^\s*$/) !== null;
};

export const isNotEmpty = (text) => {
    return !isEmpty(text);
};

export const joinNotEmpty = (elements, separator = ' - ') => {
    if (Array.isArray(elements)) {
        const cleaned = elements.filter((element) => !isEmpty(element));
        return cleaned.join(separator);
    }
    return null;
};

export const StringUtils = {
    strippedOrNull,
    valueOrEmpty,
    isEmpty,
    joinNotEmpty,
};
