import suma from '@apis/Suma';
import { paginationParams, paramsString } from '.';

const REGISTERS_PATH = 'treasury/cash-registers/';
const SESSIONS_PATH = 'treasury/cash-sessions/';
const ENTRIES_PATH = 'treasury/cash-entries/';

export class CashRegisterService {
    list({ pageSize, page }) {
        const queryParams = paginationParams(pageSize, page);
        const queryString = paramsString(queryParams);
        return suma.get(REGISTERS_PATH + queryString);
    }

    get(id) {
        return suma.get(REGISTERS_PATH + `${id}/`);
    }

    open(id) {
        return suma.post(REGISTERS_PATH + `${id}/open/`);
    }

    close({ id, closeAmount }) {
        return suma.post(REGISTERS_PATH + `${id}/close/`, { closeAmount });
    }

    cashDeposit({ id, ...rest }) {
        return suma.post(REGISTERS_PATH + `${id}/cash-deposit/`, { ...rest });
    }

    cashWithdrawal({ id, ...rest }) {
        return suma.post(REGISTERS_PATH + `${id}/cash-withdrawal/`, { ...rest });
    }

    // TODO: Move as 'search' to CashSessionService
    sessions({ registerId, pageSize, page }) {
        const register = `register=${registerId}`;
        const pagination = paginationParams(pageSize, page);
        const queryParams = [register].concat(pagination);
        const queryString = paramsString(queryParams);
        return suma.get(SESSIONS_PATH + queryString);
    }

    lastSession(id) {
        return suma.get(REGISTERS_PATH + `${id}/last-session/`);
    }

    currentSession(id) {
        return suma.get(REGISTERS_PATH + `${id}/current-session/`);
    }
}

export class CashSessionService {
    get(id) {
        return suma.get(SESSIONS_PATH + `${id}/`);
    }
}

export class CashEntryService {
    get(id) {
        return suma.get(`${ENTRIES_PATH}${id}/`);
    }

    search({ sessionId, pageSize, page }) {
        const queryParams = paginationParams(pageSize, page);

        if (sessionId != null) {
            queryParams.push(`session=${sessionId}`);
        }

        return suma.get(`${ENTRIES_PATH}${paramsString(queryParams)}`);
    }

    revoke(id) {
        return suma.post(`${ENTRIES_PATH}${id}/revoke/`);
    }
}
