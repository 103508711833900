import { Button } from 'primereact/button';

export const RefreshButton = ({ onRefresh, className = '', ...props }) => (
    <Button
        type="button"
        icon="pi pi-refresh"
        className={`p-button-rounded p-button-text ${className}`}
        onClick={onRefresh}
        {...props}
    />
);
