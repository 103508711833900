import { InputText } from 'primereact/inputtext';

import { FieldErrorMessages, errorClassName, invalidClassName } from '../field-error-messages';

export const PhoneNumberField = ({ register, errors, required = false, ...rest }) => {
    return (
        <div className="field">
            <label htmlFor="phoneNumber" className={errorClassName(errors.phoneNumber)}>
                Teléfono
            </label>
            <InputText
                id="phoneNumber"
                name="phoneNumber"
                {...register('phoneNumber', {
                    required: required,
                    // minLength: { value: 2, message: 'Al menos dos letras' }
                })}
                autoComplete="off"
                // TODO: #98 Add regex to ignore all characters but digits and
                //           separators on dni and phone_number fields
                // Using keyfilter property is not working, read the issue.
                // keyfilter={[0 - 9]}
                className={invalidClassName(errors.name)}
                {...rest}
            />
            <FieldErrorMessages name="phoneNumber" errors={errors} />
            <small id="phoneNumber-help-1" className="block">
                Código de área + número. Ejemplo: 343 431-3014.
            </small>
            {errors.phoneNumber && (
                <>
                    <br />
                    <small id="phoneNumber-help-2" className="block">
                        Si es fuera de Argentina, indique el código de país.
                    </small>
                    <small id="phoneNumber-help-3" className="block">
                        Ejemplo: +1 913 667-7523
                    </small>
                </>
            )}
        </div>
    );
};

PhoneNumberField.displayName = 'PhoneNumberField';
