import { useController } from 'react-hook-form';

import { Calendar } from 'primereact/calendar';

import {
    FieldErrorMessages,
    errorClassName,
    invalidClassName,
} from '@components/field-error-messages';

const defaultYearRange = () => {
    const today = new Date();
    const thisYear = today.getFullYear();
    return `${thisYear - 2}:${thisYear + 2}`;
};

export const DateTimeField = ({
    fieldName,
    fieldLabel,
    control,
    errors,
    rules = {},
    customMessages = {},
    disabled,
    inputStyle,
    selectorOptions = null,
    yearRange = defaultYearRange(),
    children,
    ...rest
}) => {
    const { field, fieldState } = useController({ name: fieldName, control, rules });

    return (
        <div className="field">
            {fieldLabel && (
                <label htmlFor={field.name} className={errorClassName(fieldState.error)}>
                    {fieldLabel}
                    {rules?.required ? ' *' : ''}
                </label>
            )}
            <Calendar
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                dateFormat="d/m/yy"
                hourFormat="24"
                showTime
                showIcon
                hideOnDateTimeSelect={true}
                className={invalidClassName(fieldState.error)}
                {...rest}
            />
            <FieldErrorMessages name={fieldName} errors={errors} customMessages={customMessages} />
            {children}
        </div>
    );
};

DateTimeField.displayName = 'DateTimeField';
