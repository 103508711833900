import { useController } from 'react-hook-form';

import { InputMask } from 'primereact/inputmask';

import { FieldErrorMessages, invalidClassName } from '@components/field-error-messages';

export const formattedCUIT = (cuit) => {
    return cuit ? `${cuit.slice(0, 2)}-${cuit.slice(2, 10)}-${cuit.slice(10, 11)}` : null;
};

export const unmaskedCUIT = (value) => {
    if (value != null) {
        const cleaned = value.replace(/_|-/g, '');
        if (cleaned.length > 0) {
            return cleaned;
        }
    }
    return null;
};

const DEFAULT_REQUIRED = false;

/** Different types of CUIT that are known */
const VALID_CUIT_TYPES = [
    '20',
    '23',
    '24',
    '27', // individuals
    '30',
    '33',
    '34', // companies
    '50',
    '51',
    '55', // international purposes
];

/** Returns true if cuit is a valid CUIT, false otherwise
 *
 * @param {string} cuit
 * @returns {bool} True if cuit is a valid CUIT
 *
 * Based on:
 * https://github.com/arthurdejong/python-stdnum/blob/master/stdnum/ar/cuit.py
 * https://gist.github.com/neiker/874c197cd0cbb06efb328f3cbc6753b3
 */
const validateCUIT = (cuit) => {
    if (cuit == null || cuit.length !== 11) {
        return false;
    }

    const cuitType = cuit.slice(0, 2);
    if (!VALID_CUIT_TYPES.includes(cuitType)) {
        return false;
    }

    const [checkDigit, ...rest] = cuit.split('').map(Number).reverse();

    const total = rest.reduce((acc, cur, index) => acc + cur * (2 + (index % 6)), 0);

    const mod11 = 11 - (total % 11);

    if (mod11 === 11) {
        return checkDigit === 0;
    }

    if (mod11 === 10) {
        return false;
    }

    return checkDigit === mod11;
};

const VALIDATE = {
    validCUIT: (value) => {
        const cleaned = unmaskedCUIT(value);
        if (cleaned == null) return true;
        if (cleaned.length < 11) return 'Debe tener 11 dígitos';
        return validateCUIT(cleaned) || 'CUIT inválido';
    },
};

export const CUITField = ({
    fieldName = 'cuit',
    fieldLabel = 'CUIT/CUIL',
    control,
    errors,
    autoComplete = 'off',
    required = DEFAULT_REQUIRED,
    ...rest
}) => {
    const { field, fieldState } = useController({
        name: fieldName,
        control,
        rules: { required: required, validate: VALIDATE },
    });

    return (
        <div className="field">
            {fieldLabel && (
                <label htmlFor={fieldName} className={invalidClassName(fieldState.error)}>
                    {fieldLabel}
                    {required ? ' *' : null}
                </label>
            )}
            <InputMask
                id={field.name}
                value={field.value}
                name={field.name}
                mask="99-99999999-9"
                autoClear={false}
                // IMPORTANT: This does not work at component level
                // <form autoComplete="off"> must be set to be used
                // TODO: Pass it along to the underlying input ctrl.
                // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
                autoComplete={autoComplete}
                className={invalidClassName(fieldState.error)}
                onChange={(e) => field.onChange(e.target.value)}
                {...rest}
            />
            <FieldErrorMessages name={fieldName} errors={errors} />
        </div>
    );
};

CUITField.displayName = 'CUITField';
