import { useController } from 'react-hook-form';
import { InputNumber } from 'primereact/inputnumber';

import { ES_AR_PATCHED_LOCALE } from '@utils/money';
import {
    FieldErrorMessages,
    errorClassName,
    invalidClassName,
} from '@components/field-error-messages';

const DEFAULT_RULES = {
    required: true,
    validate: (value) => value > 0 || 'Debe ser un número positivo (mayor que cero)',
};

export const AmountField = ({
    fieldLabel = 'Importe',
    fieldName = 'amount',
    control,
    errors,
    rules = DEFAULT_RULES,
    ...rest
}) => {
    const { field, fieldState } = useController({
        name: fieldName,
        rules: rules,
        control: control,
    });

    return (
        <div className="field">
            {fieldLabel && (
                <label htmlFor={fieldName} className={errorClassName(fieldState.error)}>
                    {fieldLabel}
                    {rules?.required ? ' *' : null}
                </label>
            )}

            <InputNumber
                id={field.name}
                name={field.name}
                value={field.value}
                // This configuration is in PrimeReact docs, but frizzes
                // the dialog when value is invalid (e.g. negative) due
                // to an error when focusing the error field. But all
                // seems to work fine if both properties are commented.
                // ref={field.ref}
                // onBlur={field.onBlur}
                onValueChange={(e) => field.onChange(e)}
                locale={ES_AR_PATCHED_LOCALE}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2}
                inputClassName={invalidClassName(fieldState.error)}
                {...rest}
            />

            <FieldErrorMessages name={fieldName} errors={errors} />
        </div>
    );
};

AmountField.displayName = 'AmountField';
