import { CashRegisterList } from '../cash/registers/list';
// import { PaymentTerminalList } from '../swipe/terminals/PaymentTerminalList';

export const LocalPaymentsDashboard = () => {
    return (
        <div className="local-payments-dashboard">
            <CashRegisterList />
            {/* <br />
            <PaymentTerminalList /> */}
        </div>
    );
};
