export const ResponsibilityAction = Object.freeze({
    CREATE_RESPONSIBLE_OF: 'create_responsible_of',
    CREATE_BENEFICIARY_OF: 'create_beneficiary_of',
    UPDATE: 'update',
    DELETE: 'delete',

    isCreateAction(action) {
        const actions = [this.CREATE_RESPONSIBLE_OF, this.CREATE_BENEFICIARY_OF];
        return actions.includes(action);
    },
});
