import { ToggleButton } from 'primereact/togglebutton';
import { classNames } from 'primereact/utils';

import { FieldErrorMessages, errorClassName } from '@components/field-error-messages';
import { EMPTY_FUND_ACCOUNT_ID } from '@services/fundsService';
import { Controller } from 'react-hook-form';

export const ReconciledField = ({
    control,
    errors,
    fieldName = 'reconciled',
    fieldLabel = 'Acreditada',
    fundAccount = null,
    required = false,
    ...rest
}) => {
    return (
        <div className="field">
            <label htmlFor={fieldName} className={errorClassName(errors[fieldName])}>
                {fieldLabel}
                {required ? ' *' : null}
            </label>

            <Controller
                name={fieldName}
                control={control}
                rules={{
                    required: required,
                    validate: (value) =>
                        !value || (fundAccount && fundAccount !== EMPTY_FUND_ACCOUNT_ID)
                            ? true
                            : 'Para marcar acreditada, debe indicar cuenta destino.',
                }}
                render={({ field, fieldState }) => (
                    <ToggleButton
                        id={fieldName}
                        checked={field.value}
                        onChange={field.onChange}
                        className={classNames({ 'p-invalid': fieldState.error })}
                        onLabel="Sí acreditada"
                        offLabel="No acreditada"
                        onIcon="pi pi-check"
                        offIcon="pi pi-times"
                        {...rest}
                    />
                )}
            />
            <FieldErrorMessages name={fieldName} errors={errors} />
        </div>
    );
};

ReconciledField.displayName = 'ReconciledField';
