import { Button } from 'primereact/button';

export const BackButton = ({ className, ...props }) => {
    return (
        <Button
            icon="pi pi-arrow-left"
            className={`p-button-rounded p-button-text ${className}`}
            {...props}
        />
    );
};
