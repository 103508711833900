import { useState } from 'react';

import { hookFormRequestError } from '@custom/hook-form';
import { useForm } from 'react-hook-form';

import { ModalForm } from '@components/forms';

import { AddressField } from '@components/fields/address';
import { AddressService } from '@services/addressService';
import { StringUtils } from '@utils/string-utils';

export const ChangeAddress = ({ person, onSuccess, onCancel }) => {
    const [service] = useState(new AddressService());
    const [requestErrors, setRequestErrors] = useState([]);
    const address = person?.addresses?.[0];
    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: {
            value: address?.value || '',
        },
    });

    const onExecute = (data) => {
        clearErrors();
        setRequestErrors(null);

        const handleRequestError = (error) => {
            hookFormRequestError(error, ['value'], setError, setRequestErrors);
        };

        const handleSuccess = (updatedAddress) => {
            if (onSuccess) {
                updatedAddress != null
                    ? (person.addresses = [updatedAddress])
                    : (person.addresses = []);
                onSuccess(person);
            }
        };

        if (StringUtils.isEmpty(data.value)) {
            if (address?.id) {
                service
                    .delete(address.id)
                    .then(() => handleSuccess(null))
                    .catch(handleRequestError);
            } else {
                handleSuccess(null);
            }
        } else {
            if (address?.id) {
                service
                    .update(address.id, data)
                    .then((response) => handleSuccess(response.data))
                    .catch(handleRequestError);
            } else {
                service
                    .create({ ...data, personId: person.id })
                    .then((response) => handleSuccess(response.data))
                    .catch(handleRequestError);
            }
        }
    };

    return (
        <ModalForm
            onSubmit={handleSubmit(onExecute)}
            title="Modificar Dirección"
            buttons={{ executeLabel: 'Modificar' }}
            onCancel={onCancel}
            style={{ width: '400px' }}
            requestErrors={requestErrors}
        >
            <div className="p-fluid">
                <AddressField control={control} errors={errors} autoFocus />
            </div>
        </ModalForm>
    );
};
