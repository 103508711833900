import { useController } from 'react-hook-form';

import { Password } from 'primereact/password';

import { FieldErrorMessages, invalidClassName } from '@components/field-error-messages';

const DEFAULT_RULES = {
    required: true,
    minLength: { value: 2, message: 'Al menos dos letras' },
};

export const PasswordField = ({
    fieldName = 'password',
    fieldLabel = 'Clave de acceso',
    control,
    errors,
    rules = DEFAULT_RULES,
    customMessages = {},
    ...rest
}) => {
    const { field, fieldState } = useController({ name: fieldName, control, rules });

    return (
        <div className="field">
            {fieldLabel && (
                <label htmlFor={fieldName} className={invalidClassName(fieldState.error)}>
                    {fieldLabel}
                    {rules && rules?.required ? ' *' : ''}
                </label>
            )}
            <Password
                id={field.name}
                value={field.value}
                name={field.name}
                className={invalidClassName(fieldState.error)}
                onChange={(e) => field.onChange(e.target.value)}
                feedback={false}
                toggleMask={true}
                {...rest}
            />
            <FieldErrorMessages name={fieldName} errors={errors} customMessages={customMessages} />
        </div>
    );
};

PasswordField.displayName = 'PasswordField';
