import { configuration } from '@app/configuration';
import { whatsAppChatURL } from '@components/contacts';

export const whatsapp = (
    <div className="whatsapp">
        <i className="pi pi-whatsapp mr-3"></i>
        <a
            href={whatsAppChatURL(configuration.whatsAppPhone)}
            className="p-link"
            target="_blank"
            rel="noopener noreferrer"
        >
            {configuration.whatsAppPhone}
        </a>
    </div>
);

export const website = (
    <div className="website">
        <i className="pi pi-globe mr-3"></i>
        <a
            href={configuration.serviceWebsiteURL}
            className="p-link"
            target="_blank"
            rel="noopener noreferrer"
        >
            {configuration.serviceWebsite}
        </a>
    </div>
);

export const email = (
    <div className="email">
        <i className="pi pi-envelope mr-3"></i>
        <a href={`mailto:${configuration.contactEmail}`} className="p-link">
            {configuration.contactEmail}
        </a>
    </div>
);

export const copyright = <div className="copyright">{configuration.copyrightInfo}</div>;
