import { useEffect, useState } from 'react';

import { MultiSelect } from 'primereact/multiselect';

import { fieldErrorMessages } from '@components/field-error-messages';
import { genericRequestErrors } from '@services/index';

import { RegistrationService } from '@services/registrations';

// TODO: IMPORTANT : Improve handling network error on this component.
// TODO: IMPORTANT : Whe should be able to refresh relations on demand.

export const ReportMultiRelation = ({
    onRelationsSelected,
    iniMajorRelations = null,
    iniMinorRelations = null,
}) => {
    const [options, setOptions] = useState([]);
    const [service] = useState(new RegistrationService());
    const [majorRelations, setMajorRelations] = useState(iniMajorRelations);
    const [minorRelations, setMinorRelations] = useState(iniMinorRelations);
    const [requestMessages, setRequestMessages] = useState();

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        service
            .getAllOptions()
            .then((response) => {
                const newOptions = response.data;
                let newMajorRelations = null;
                let newMinorRelations = null;
                if (
                    Array.isArray(newOptions) &&
                    newOptions.length > 0 &&
                    iniMajorRelations != null
                ) {
                    const iniMajorIds = new Set(iniMajorRelations.map((major) => major.id));
                    newMajorRelations = newOptions.filter((major) => iniMajorIds.has(major.id));
                    if (iniMinorRelations != null) {
                        const iniMinorIds = new Set(iniMinorRelations.map((minor) => minor.id));
                        newMinorRelations = newMajorRelations.flatMap((major) =>
                            major?.minorRelations.filter((minor) => iniMinorIds.has(minor.id)),
                        );
                    }
                }
                setOptions(newOptions);
                setMajorRelations(newMajorRelations?.length > 0 ? newMajorRelations : null);
                setMinorRelations(newMinorRelations?.length > 0 ? newMinorRelations : null);
            })
            .catch((error) => setRequestMessages(genericRequestErrors(error)));
    }, [service, iniMajorRelations, iniMinorRelations]);

    // NOTE: We can´t use events directly (e.g. changeMajor, changeMinor)
    // because there is no clear path to attach to the "clear" event.

    useEffect(() => {
        if (onRelationsSelected) {
            onRelationsSelected(majorRelations, minorRelations);
        }
    }, [majorRelations, minorRelations, onRelationsSelected]);

    // Events -----------------------------------------------------------------

    const changeMajorRelations = (value) => {
        if (value == null || majorRelations == null) {
            // Nothing is to be selected or
            // Nothing was selected before
            setMajorRelations(value);
            setMinorRelations(null);
        } else {
            // Something was selected before AND
            // Something is to be selected now
            if (minorRelations != null) {
                // If some minor relations where selected
                // Remove those related to any removed major
                const oldMajorIds = new Set(majorRelations.map((relation) => relation.id));
                const newMajorIds = new Set(value.map((relation) => relation.id));
                const delMajorIds = oldMajorIds.difference(newMajorIds);
                if (delMajorIds.size > 0) {
                    const oldMinorIds = new Set(minorRelations.map((relation) => relation.id));
                    const newMinorIds = new Set(
                        value.flatMap((major) => major.minorRelations.map((minor) => minor.id)),
                    );
                    const delMinorIds = oldMinorIds.difference(newMinorIds);
                    if (delMinorIds.size > 0) {
                        const newMinorRelations = minorRelations.filter(
                            (relation) => !delMinorIds.has(relation.id),
                        );
                        setMinorRelations(newMinorRelations.length > 0 ? newMinorRelations : null);
                    }
                }
            }
            setMajorRelations(value);
        }
    };

    // Render -----------------------------------------------------------------

    const groupedItemTemplate = (option) => {
        if (option?.minorRelations?.length > 0)
            return (
                <div className="flex align-items-center">
                    <div>{option.name}</div>
                </div>
            );
        return <span className="empty-minor-selector" />;
    };

    const minorSelectorEnabled = () =>
        majorRelations != null && majorRelations.some((major) => major?.minorRelations?.length > 0);

    return (
        <>
            <div className="field col-fixed" style={{ width: '300px' }}>
                <label htmlFor="majorRelation">Relación:</label>
                <MultiSelect
                    id="majorRelations"
                    value={majorRelations}
                    options={options}
                    onChange={(e) => changeMajorRelations(e.value)}
                    optionLabel="name"
                    placeholder="Todas"
                    showClear={true}
                    // display="chip"
                    resetFilterOnHide
                    filter
                    // Requires primefaces 10+
                    // onRemove={majorRemoved}
                />
                {fieldErrorMessages(requestMessages)}
            </div>

            {minorSelectorEnabled() && (
                <div className="field col-fixed" style={{ width: '300px' }}>
                    <label htmlFor="minorRelation">Detalle:</label>
                    <MultiSelect
                        id="minorRelations"
                        value={minorRelations}
                        options={majorRelations}
                        optionLabel="name"
                        optionGroupLabel="name"
                        optionGroupChildren="minorRelations"
                        optionGroupTemplate={groupedItemTemplate}
                        onChange={(e) => setMinorRelations(e.value)}
                        placeholder="Todos"
                        showClear={true}
                        // display="chip"
                        resetFilterOnHide
                        filter
                    />
                </div>
            )}
        </>
    );
};

ReportMultiRelation.displayName = 'ReportMultiRelation';
