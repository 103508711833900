import { useState, useCallback, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';

import { hookFormRequestError } from '@custom/hook-form';
import { ModalForm } from '@components/forms';
import { PersonInput } from '@components/fields';
import {
    FieldErrorMessages,
    invalidClassName,
    errorClassName,
} from '@components/field-error-messages';
import { genericRequestErrors } from '@services/index';

import { PersonDetail, PersonService } from '@services/personService';
import { ResponsibilityService } from '@services/responsibilityService';

const COLUMN_STYLE = { width: '8em' };

export const ResponsibilityEditor = ({
    personId,
    responsibility,
    isCreate,
    isResponsible,
    onSuccess,
    onCancel,
}) => {
    const [service] = useState(new ResponsibilityService());
    const [personService] = useState(new PersonService());
    const [person, setPerson] = useState({ id: personId, formalName: 'Loading...' });

    const [requestErrors, setRequestErrors] = useState([]);
    const [editResponsible] = useState(isCreate & !isResponsible);
    const [editBeneficiary] = useState(isCreate & isResponsible);

    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: isCreate
            ? {
                  responsible: isResponsible ? person : null,
                  beneficiary: isResponsible ? null : person,
                  responsibleRole: '',
                  beneficiaryRole: '',
              }
            : {
                  responsible: responsibility.responsible,
                  beneficiary: responsibility.beneficiary,
                  responsibleRole: responsibility.responsibleRole,
                  beneficiaryRole: responsibility.beneficiaryRole,
              },
    });

    const loadPerson = useCallback(() => {
        personService
            .get(personId, PersonDetail.IDENTITY)
            .then((response) => setPerson(response.data))
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    }, [personId, personService]);

    useEffect(() => {
        if (isCreate) {
            loadPerson();
        }
    }, [loadPerson, isCreate]);

    const onExecute = (data) => {
        clearErrors();
        setRequestErrors(null);

        const executeCreate = () => {
            // WARNING: Because person is edited in a list like component, extract input
            const request = {
                responsibleId: isResponsible ? personId : data.responsible[0].id,
                beneficiaryId: isResponsible ? data.beneficiary[0].id : personId,
                responsibleRole: data.responsibleRole,
                beneficiaryRole: data.beneficiaryRole,
            };
            service
                .create(request)
                .then((response) => onSuccess && onSuccess(response.data))
                .catch(handleRequestError);
        };
        const executeUpdate = () => {
            const request = {
                responsibleRole: data.responsibleRole,
                beneficiaryRole: data.beneficiaryRole,
            };
            service
                .update(responsibility.id, request)
                .then((response) => onSuccess && onSuccess(response.data))
                .catch(handleRequestError);
        };

        isCreate ? executeCreate() : executeUpdate();
    };

    const handleRequestError = (error) => {
        hookFormRequestError(
            error,
            ['responsible', 'beneficiary', 'responsibleRole', 'beneficiaryRole'],
            setError,
            setRequestErrors,
        );
    };

    const editorTitle = isCreate
        ? `Agregar ${isResponsible ? 'Beneficiario' : 'Responsable'}`
        : 'Modificar relación';

    const executeLabel = isCreate ? 'Agregar' : 'Modificar';

    return (
        <ModalForm
            onSubmit={handleSubmit(onExecute)}
            title={editorTitle}
            buttons={{ executeLabel: executeLabel }}
            onCancel={onCancel}
            style={{ width: '400px' }}
            requestErrors={requestErrors}
        >
            <br />
            <div className="field grid">
                <label
                    htmlFor="responsible"
                    style={COLUMN_STYLE}
                    className={errorClassName(errors['responsible'], 'col-fixed')}
                >
                    Responsable{editResponsible ? ' *' : ':'}
                </label>
                <div className="col">
                    {editResponsible ? (
                        <PersonInput
                            fieldName="responsible"
                            fieldLabel="Seleccionar responsable"
                            control={control}
                            errors={errors}
                            onRequestError={handleRequestError}
                            required={true}
                            autoFocus={true}
                        />
                    ) : (
                        <label>
                            {isCreate ? person.formalName : responsibility.responsible.formalName}
                        </label>
                    )}
                    <br />
                    <FieldErrorMessages
                        name={'responsible'}
                        errors={errors}
                        customMessages={{
                            personRequired: 'Requerido',
                        }}
                    />
                </div>
            </div>
            <div className="field grid">
                <label
                    htmlFor="responsibleRole"
                    style={COLUMN_STYLE}
                    className={errorClassName(errors['responsibleRole'], 'col-fixed')}
                >
                    Rol *
                </label>
                <div className="col">
                    <Controller
                        name="responsibleRole"
                        control={control}
                        rules={{ required: 'Requerido' }}
                        render={({ field }) => (
                            <InputText
                                style={{ width: '100%' }}
                                id={field.name}
                                name={field.name}
                                value={field.value}
                                autoComplete="off"
                                autoFocus={!editResponsible}
                                onChange={(e) => field.onChange(e.target.value)}
                                className={invalidClassName(errors.responsibleRole)}
                            />
                        )}
                    />
                    <br />
                    <small id="beneficiaryRole-help" className="block">
                        e.g. Mamá, Papá, Abuela, Abuelo.
                    </small>
                    <br />
                    <FieldErrorMessages name="responsibleRole" errors={errors} />
                </div>
            </div>
            <hr />
            <div className="field grid">
                <label
                    htmlFor="beneficiary"
                    style={COLUMN_STYLE}
                    className={errorClassName(errors['responsible'], 'col-fixed')}
                >
                    Beneficiario{editBeneficiary ? ' *' : ':'}
                </label>
                <div className="col">
                    {editBeneficiary ? (
                        <PersonInput
                            fieldName="beneficiary"
                            fieldLabel="Seleccionar beneficiario"
                            control={control}
                            errors={errors}
                            onRequestError={handleRequestError}
                            required={true}
                        />
                    ) : (
                        <label>
                            {isCreate ? person.formalName : responsibility.beneficiary.formalName}
                        </label>
                    )}
                    <br />
                    <FieldErrorMessages
                        name={'beneficiary'}
                        errors={errors}
                        customMessages={{
                            personRequired: 'Requerido',
                        }}
                    />
                </div>
            </div>
            <div className="field grid">
                <label
                    htmlFor="beneficiaryRole"
                    style={COLUMN_STYLE}
                    className={errorClassName(errors['beneficiaryRole'], 'col-fixed')}
                >
                    Rol *
                </label>
                <div className="col">
                    <Controller
                        name="beneficiaryRole"
                        control={control}
                        rules={{ required: 'Requerido' }}
                        render={({ field }) => (
                            <InputText
                                style={{ width: '100%' }}
                                id={field.name}
                                name={field.name}
                                value={field.value}
                                autoComplete="off"
                                onChange={(e) => field.onChange(e.target.value)}
                                className={invalidClassName(errors.beneficiaryRole)}
                            />
                        )}
                    />
                    <br />
                    <small id="beneficiaryRole-help" className="block">
                        e.g. Hija, Hijo, Nieta, Nieto.
                    </small>
                    <br />
                    <FieldErrorMessages name="beneficiaryRole" errors={errors} />
                </div>
            </div>
        </ModalForm>
    );
};
