import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { hookFormRequestError } from '@custom/hook-form';
import { useQuery } from '@custom/react-router';

import {
    MonthFieldSelectorOption,
    MonthFieldSelectorOptions,
    PersonField,
} from '@components/fields';
import { CardPageForm } from '@components/forms';
import { NETWORK_ERROR, isNetworkError } from '@services/index';
import { toISOLocalDateString } from '@utils/money';

import { PersonPath } from '@persons/routes';
import { PersonDetail, PersonService } from '@services/personService';

import { TokenTemplateField } from '@components/fields/tokenTemplate';
import { TokenTypeField } from '@components/fields/tokenType';
import { TokenService } from '@services/tokenService';

const DETAIL_FIELDS = ['typeId', 'packId', 'personId', 'since'];

const NEXT_MONTH_CAP_DAY = 20;

export const TokenPackCreate = () => {
    const from = useLocation().state?.from;
    const query = useQuery();
    const history = useHistory();
    const [service] = useState(new TokenService());
    const [requestErrors, setRequestErrors] = useState([]);
    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
        clearErrors,
        watch,
    } = useForm({
        defaultValues: {
            typeId: query.get('typeId'),
            template: null,
            person: null,
            since: null,
        },
    });
    const [requestPersonId] = useState(query.get('personId'));

    const [typeId, template] = watch(['typeId', 'template']);

    // Callbacks --------------------------------------------------------------

    const handleRequestError = useCallback(
        (error) => {
            hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors);
        },
        [setError],
    );

    const fieldRequestError = useCallback(
        (fieldName, error) => {
            isNetworkError(error)
                ? setError(fieldName, { message: NETWORK_ERROR.summary })
                : hookFormRequestError(error, [fieldName], setError, setRequestErrors);
        },
        [setError],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        if (requestPersonId != null) {
            const service = new PersonService();
            service
                .get(requestPersonId, PersonDetail.IDENTITY)
                .then((response) => setValue('person', [response.data]))
                .catch((error) => {
                    if (isNetworkError(error)) {
                        setError('person', [NETWORK_ERROR]);
                    } else if (error?.response?.status === 404) {
                        setError('person', {
                            type: 'error',
                            message:
                                'No encontrada para el parámetro de persona (personId) solicitado',
                        });
                    } else {
                        fieldRequestError('person', error);
                    }
                });
        }
    }, [requestPersonId, setValue, setError, fieldRequestError]);

    useEffect(() => {
        // The template selector options are automatically updated, but the
        // previous values for template and since remain in the forms.data.
        // Therefore, they must be reset to the default values.
        setValue('template', null);
        setValue('since', null);
    }, [typeId, setValue]);

    useEffect(() => {
        if (template != null && template?.duration) {
            setValue(
                'since',
                MonthFieldSelectorOptions.slapped(
                    NEXT_MONTH_CAP_DAY,
                    MonthFieldSelectorOption.THIS_MONTH,
                    MonthFieldSelectorOption.NEXT_MONTH,
                ),
            );
        }
    }, [template, setValue]);

    // Execute ----------------------------------------------------------------

    const onExecute = (data) => {
        clearErrors();
        setRequestErrors(null);
        const templateId = data?.template?.id || null;
        const personId = data?.person?.[0]?.id || null;
        const since = toISOLocalDateString(data.since);
        service
            .issue({ templateId, personId, since })
            .then((response) => {
                // const nextStep = from || generatePath(TokensPath.TokenType.list, {});
                const nextStep = from || generatePath(PersonPath.read.tokens, { id: personId });
                history.replace(nextStep);
            })
            .catch(handleRequestError);
    };

    return (
        <CardPageForm
            className="token-pack-create"
            title="Generar Tokens"
            onSubmit={handleSubmit(onExecute)}
            requestErrors={requestErrors}
            executeButtonsProps={{
                executeLabel: 'Generar',
            }}
        >
            <div className="p-fluid">
                <TokenTypeField
                    autoFocus
                    control={control}
                    onFieldRequestError={fieldRequestError}
                />
                <TokenTemplateField
                    control={control}
                    errors={errors}
                    typeId={typeId}
                    onFieldRequestError={fieldRequestError}
                />
                {/* TODO: Display pack.amount = f(pack, since) */}
                <PersonField
                    control={control}
                    errors={errors}
                    required={true}
                    onRequestError={handleRequestError}
                />
            </div>
        </CardPageForm>
    );
};
