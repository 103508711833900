import { useRef, useState } from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { TreasuryPaths } from '@treasury/routes';
import { ALL_ACTIONS, CashRegisterAction } from './action';
import { CashRegisterClose, CashRegisterOpen } from './switch';
import { closedBody, openedBody } from './templates';
import { IncomingCashTransaction, OutgoingCashTransaction } from './transaction';

export const CashSessionsLink = ({ registerId, className = null }) => {
    return registerId == null ? null : (
        <Link
            className={className}
            to={generatePath(TreasuryPaths.CashRegister.item_sessions, { id: registerId })}
        >
            Últimos cierres
        </Link>
    );
};

export const CashRegisterControl = ({
    registerId,
    session,
    actionSet = ALL_ACTIONS,
    isAuthorizedUser = false,
    onChanged = null,
}) => {
    const toast = useRef(null);
    const [action, setAction] = useState();

    // Events -----------------------------------------------------------------

    const onActionSuccess = ({ message = null }) => {
        if (message) {
            toast.current.show(message);
        }
        onChanged && onChanged();
        setAction(null);
    };

    const onCancelAction = () => {
        setAction(null);
    };

    // Rendering --------------------------------------------------------------

    if (registerId == null && session == null) {
        return null;
    }

    const isOpened = session && session?.isOpen;

    const statusControls = () => {
        const openedByData = () => (
            <div className="text-as-button">
                <div className="font-semibold">Abierta:</div>
                {openedBody(session)}
            </div>
        );

        const closedByData = () => (
            <div className="text-as-button">
                {session?.closedBy ? (
                    <>
                        <div className="font-semibold">Cerrada:</div>
                        {closedBody(session)}
                    </>
                ) : (
                    <div className="font-semibold">Cerrada</div>
                )}
            </div>
        );

        if (actionSet.has(CashRegisterAction.LAST_STATUS)) {
            return isOpened ? openedByData() : closedByData();
        }

        if (actionSet.has(CashRegisterAction.FULL_STATUS)) {
            return (
                <>
                    {openedByData()}
                    {!isOpened && closedByData()}
                </>
            );
        }
    };

    const switchControls = () => {
        const openedControls = () => (
            <>
                {actionSet.has(CashRegisterAction.VIEW_OPENED) && (
                    <Link
                        to={generatePath(TreasuryPaths.CashRegister.item, {
                            id: registerId,
                        })}
                    >
                        <Button
                            className="p-button-lg p-button-outlined long-text-label"
                            icon="pi pi-search"
                            label="Abierta"
                        />
                    </Link>
                )}

                {actionSet.has(CashRegisterAction.CLOSE) && (
                    <Button
                        className="p-button-lg"
                        label="Cerrar"
                        icon="pi pi-lock"
                        disabled={!isAuthorizedUser}
                        onClick={() => setAction(CashRegisterAction.CLOSE)}
                    />
                )}

                {actionSet.has(CashRegisterAction.INCOMING_CASH) && (
                    <Button
                        className="p-button-lg p-button-outlined"
                        label="Ingresar Efectivo"
                        icon="pi pi-download"
                        disabled={!isAuthorizedUser}
                        onClick={() => setAction(CashRegisterAction.INCOMING_CASH)}
                    />
                )}

                {actionSet.has(CashRegisterAction.OUTGOING_CASH) && (
                    <Button
                        className="p-button-lg p-button-outlined"
                        label="Retirar Efectivo"
                        icon="pi pi-upload"
                        disabled={!isAuthorizedUser}
                        onClick={() => setAction(CashRegisterAction.OUTGOING_CASH)}
                    />
                )}
            </>
        );

        const closedControls = () => (
            <>
                {actionSet.has(CashRegisterAction.VIEW_CLOSED) && (
                    <Link
                        to={generatePath(TreasuryPaths.CashRegister.item, {
                            id: registerId,
                        })}
                    >
                        <Button
                            className="p-button-lg p-button-outlined long-text-label"
                            icon="pi pi-search"
                            label="Cerrada"
                            // label={
                            //     session?.closedBy ? (
                            //         <>
                            //             Cerrada:
                            //             <br />
                            //             {userDescription(session.closedBy)}
                            //         </>
                            //     ) : (
                            //         'Cerrada'
                            //     )
                            // }
                        />
                    </Link>
                )}

                {actionSet.has(CashRegisterAction.OPEN) && (
                    <Button
                        className="p-button-lg"
                        label="Abrir"
                        icon="pi pi-lock-open"
                        disabled={!isAuthorizedUser}
                        onClick={() => setAction(CashRegisterAction.OPEN)}
                    />
                )}
            </>
        );

        return isOpened ? openedControls() : closedControls();
    };

    return (
        <div className="cash-register-control">
            <Toast ref={toast} />

            {statusControls()}
            {switchControls()}

            {action === CashRegisterAction.OPEN && (
                <CashRegisterOpen
                    registerId={registerId}
                    onSuccess={onActionSuccess}
                    onCancel={onCancelAction}
                />
            )}

            {action === CashRegisterAction.CLOSE && (
                <CashRegisterClose
                    registerId={registerId}
                    onSuccess={onActionSuccess}
                    onCancel={onCancelAction}
                />
            )}

            {action === CashRegisterAction.INCOMING_CASH && (
                <IncomingCashTransaction
                    // registerId={registerId}
                    sessionId={session.id}
                    onSuccess={onActionSuccess}
                    onCancel={onCancelAction}
                />
            )}

            {action === CashRegisterAction.OUTGOING_CASH && (
                <OutgoingCashTransaction
                    // registerId={registerId}
                    sessionId={session.id}
                    onSuccess={onActionSuccess}
                    onCancel={onCancelAction}
                />
            )}
        </div>
    );
};
