import { useController } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';

import { FieldErrorMessages, invalidClassName } from '@components/field-error-messages';
import { TimeUnit } from '@enums/time';

export const TimeUnitField = ({
    // disable prettier
    control,
    errors,
    fieldName = 'timeUnit',
    fieldLabel = 'Unidad de Tiempo',
    optionLabel = 'label',
    options = TimeUnit.ALL,
    required = true,
    ...rest
}) => {
    const { field, fieldState } = useController({
        control: control,
        name: fieldName,
        rules: {
            required: required,
        },
    });

    return (
        <div className="field">
            <label htmlFor={field.name} className={invalidClassName(fieldState.error)}>
                {fieldLabel}
                {required ? ' *' : ''}
            </label>

            <Dropdown
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                options={options}
                optionValue="value"
                optionLabel={optionLabel}
                placeholder="Seleccione"
                className={invalidClassName(fieldState.error)}
                {...rest}
            />

            <FieldErrorMessages name={field.name} errors={errors} />
        </div>
    );
};
