import { useCallback, useState } from 'react';

import { ExecuteCancelButtons } from '@components/ExecuteCancelButtons';
import { RequestMessages } from '@components/RequestMessages';

import { PaymentModeSelector } from '@accounting/payments/PaymentModeSelector';
import { DisplayTotal } from './confirmation';
import { ReceiptStep } from './create';

export const ReceiptPayments = ({ data, onContinue }) => {
    const [paymentMode, setPaymentMode] = useState(data?.paymentMode);
    const [validationErrors, setValidationErrors] = useState();

    // Callbacks --------------------------------------------------------------

    const onPaymentModeSelected = useCallback((paymentMode) => {
        // console.log('onPaymentModeSelected');
        // updateValidation(paymentMode);
        setPaymentMode(paymentMode);
    }, []);

    // Execution --------------------------------------------------------------

    const updateValidation = (paymentMode) => {
        const errors = [];
        if (!paymentMode) {
            errors.push({
                severity: 'error',
                summary: 'Debe seleccionar un modo de pago.',
            });
        }
        setValidationErrors(errors);
        return errors;
    };

    const onSubmit = () => {
        const errors = updateValidation(paymentMode);
        if (!errors.length) {
            const newPart = { paymentMode: paymentMode };
            onContinue(newPart);
        }
    };

    return (
        <>
            <DisplayTotal
                amount={-data?.summary?.newPayment}
                onEdit={() => onContinue(null, ReceiptStep.CANCELATIONS)}
            />
            <br />
            <PaymentModeSelector
                personId={data.personId}
                // Don't use paymentMode, because that would cause a loop
                iniPaymentMode={data?.paymentMode}
                onSelected={onPaymentModeSelected}
            />
            <br />
            <RequestMessages messages={validationErrors} />

            <ExecuteCancelButtons
                executeLabel="Continuar"
                onExecute={onSubmit}
                showCancel={false}
            />
        </>
    );
};
