/**
 * Returns an object with cookie names as property keys {cookieName:cookieValue}
 *
 * {cookieName1: cookieValue1,
 *  cookieName2: cookieValue2}
 *
 * IMPORTANT: HttpOnly cookies like session_id are not accesible to JavaScript.
 */

export const getCookies = () => {
    const result = {};
    const cookies = document.cookie.split(';');
    for (var cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name && name.trim() !== '') {
            result[name.trim()] = value && value.trim();
        }
    }
    return result;
};

/**
 * Returns cookie as {name:value} if it exists.
 *
 * @param {string} name of cookie to find.
 */
export const getCookie = (name) => {
    const cookies = getCookies();
    if (Object.prototype.hasOwnProperty.call(cookies, name)) {
        return { [name]: cookies[name] };
    }
    return null;
};

/**
 * Returns true if cookie with 'name' exists.
 *
 * @param {string} name of cookie to test.
 */
export const cookieExists = (name) => {
    const cookie = getCookie(name);
    return Boolean(cookie);
};

/**
 * Returns true if cookie with 'name' exists and has a value.
 *
 * @param {string} name of cookie to test.
 */
export const cookieNotEmpty = (name) => {
    const cookie = getCookie(name);
    return Boolean(cookie) && Boolean(cookie[name]);
};

/**
 * Returns value of cookie named 'name' if exists, 'defaultValue' otherwise.
 *
 * @param {string} name of cookie to test.
 */

export const getCookieValue = (name, defaultValue = null) => {
    const cookie = getCookie(name);
    return cookie !== null ? cookie[name] : defaultValue;
};
