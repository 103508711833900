/*
Shallow equality tests for two sets
https://stackoverflow.com/a/74226286
*/
export const equalSets = (a, b) => {
    if (a === b) return true;
    if (a.size !== b.size) return false;
    for (const value of a) if (!b.has(value)) return false;
    return true;
};

/**
 * Compare two lists of objects that have an `id` property.
 * Neither order nor repetition are accounted for, i.e. tests
 * both iterable / maps have the same objects at least once.
 */
export const sameModels = (models1, models2) => {
    const ids1 = new Set(models1.map((model) => model.id));
    const ids2 = new Set(models2.map((model) => model.id));
    return equalSets(ids1, ids2);
};
