import suma from '@apis/Suma';
import { toISODateString } from '@utils/date-utils';

import { paginationParams, paramsString } from '.';

const APP_PATH = 'persons';

export const PersonDetail = Object.freeze({
    DEFAULT: 'default',
    IDENTITY: 'identity', // id, formalName, dni
    DASHBOARD: 'dashboard', // IDENTITY + relations_registrations (no payment_options)
});

const DISPLAY_ALL = true;
const EMPTY_DATA = {
    count: 0,
    data: {
        results: [],
    },
};

export class PersonService {
    get(personId, detail = null) {
        let path = `${APP_PATH}/${personId}/`;
        if (detail) {
            path = `${path}?detail=${detail}`;
        }
        return suma.get(path);
    }

    create(request) {
        return suma.post(`${APP_PATH}/`, request);
    }

    update(personId, request) {
        return suma.patch(`${APP_PATH}/${personId}/`, request);
    }

    search({
        query = null,
        detail = null,
        reportDate = null,
        overdueOnly = null,
        majorRelations = null,
        minorRelations = null,
        page = null,
        pageSize = null,
    }) {
        if (!query & ((detail === PersonDetail.IDENTITY) | !DISPLAY_ALL)) {
            return Promise.resolve(EMPTY_DATA);
        }

        const params = paginationParams(pageSize, page);
        if (query) {
            params.push(`search=${query}`);
        }
        if (detail) {
            params.push(`detail=${detail}`);
        }
        if (reportDate) {
            params.push(`report_date=${toISODateString(reportDate)}`);
        }
        if (overdueOnly) {
            params.push('is_overdue=true');
        }
        if (majorRelations?.length > 0) {
            params.push(...majorRelations.map((relation) => `major_relation=${relation.id}`));
        }
        if (minorRelations?.length > 0) {
            params.push(...minorRelations.map((relation) => `minor_relation=${relation.id}`));
        }

        return suma.get(`${APP_PATH}/${paramsString(params)}`);
    }
}
