import { useCallback, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';

import { RequestMessages } from '@components/RequestMessages';
import { ExpandButtons, RefreshButton } from '@components/buttons';
import { TransparentOverlay } from '@components/overlay';
import { genericRequestErrors, paramsString } from '@services/index';
import { toISOLocalDateString } from '@utils/date-utils';
import { orEmptyCount } from '@utils/number-utils';

import { RegistrationService } from '@services/registrations';

export const RegistrationsReport = () => {
    const [service] = useState(new RegistrationService());
    const [requestErrors, setRequestErrors] = useState([]);

    const [majorRows, setMajorRows] = useState([]);
    const [total, setTotal] = useState();
    // dataSynced is no longer used as we are updating report as soon as
    // any of the report parameters change. Revert it causes problems.
    const [dataSynced, setDataSynced] = useState(false);
    const [reportDate, setReportDate] = useState(new Date());
    // const [majorRelation, setMajorRelation] = useState(null);
    // const [minorRelation, setMinorRelation] = useState(null);
    const [expandedRows, setExpandedRows] = useState();
    const [anyExpandable, setAnyExpandable] = useState(false);

    // Callbacks --------------------------------------------------------------

    // const relationSelected = useCallback((majorRelation, minorRelation) => {
    //     // If not implemented as a callback, ReportRelation component which
    //     // uses this method re-renders on each main component re-render.
    //     setMajorRelation(majorRelation);
    //     setMinorRelation(minorRelation);
    // }, []);

    const doExpandAll = useCallback((rows) => {
        const newExpandedRows = rows
            .filter((row) => allowExpansion(row))
            .reduce((prev, row) => ({ ...prev, [row.id]: true }), {});
        setExpandedRows(newExpandedRows);
    }, []);

    const search = useCallback(
        (reportDate) => {
            // (reportDate, majorRelation, minorRelation) => {
            const handleReportLines = (lines) => {
                const newMajorRows = lines.filter((row) => row.majorRelationId == null);
                const total = newMajorRows.reduce((n, { count }) => n + Number(count), 0);
                newMajorRows.forEach((majorRow) => {
                    majorRow.minorRelations = lines.filter(
                        (line) => line.majorRelationId === majorRow.id,
                    );
                });
                setMajorRows(newMajorRows);
                setTotal(total);
                setDataSynced(true);
                setAnyExpandable(newMajorRows.some((row) => allowExpansion(row)));
                // Expand data by default after search?
                // No: It is a pain hide what a person does not want
                //     Might be easier to work to see what you want
                // Yes: Otherwise it is hard to notice data has changed
                // Maybe: If there was a minorRelation in search params
                // Better: Keep an "expandData" boolean value. After search
                // is performed, adjust data accordingly to that parameter.
                // doExpandAll(newMajorRows);
            };

            const searchParams = {
                reportDate: toISOLocalDateString(reportDate),
                // majorRelation: majorRelation ? majorRelation.id : null,
                // minorRelation: minorRelation ? minorRelation.id : null,
            };

            service
                .getRegistrationsReport(searchParams)
                .then((response) => handleReportLines(response.data))
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        },
        // [service, doExpandAll], // If we expand data after search
        [service],
    );

    // Effects ----------------------------------------------------------------

    // useEffect(() => {
    //     // setDataSynced(false);
    //     search(reportDate, majorRelation, minorRelation);
    // }, [search, reportDate, majorRelation, minorRelation]);

    useEffect(() => {
        // setDataSynced(false);
        search(reportDate);
    }, [search, reportDate]);

    // Events -----------------------------------------------------------------

    const expandAll = () => {
        doExpandAll(majorRows);
    };

    const collapseAll = () => {
        setExpandedRows(null);
    };

    // Render -----------------------------------------------------------------

    // This is not working, expect a boolean value instead of a function
    // Maybe the ability to evaluate rowData was added in later version of PFReact
    // const allowExpansion = rowData => {
    //     return Array.isArray(rowData?.minorRelations) && rowData.minorRelations.length > 0;
    // };

    const detailTemplate = (majorRow, minorRow) => {
        const searchParams = () => {
            const params = [];
            if (reportDate != null) {
                params.push(`report-date=${toISOLocalDateString(reportDate)}`);
            }
            if (majorRow != null) {
                params.push(`major=${majorRow.id}&major-name=${majorRow.name}`);
            }
            if (minorRow != null) {
                params.push(`minor=${minorRow.id}&minor-name=${minorRow.name}`);
            }
            return params;
        };

        return (
            <Link
                to={{
                    pathname: '/persons',
                    search: paramsString(searchParams()),
                }}
            >
                Detalles
            </Link>
        );
    };

    const majorReportTemplate = (majorRow) => detailTemplate(majorRow, null);

    const minorReportTemplate = (majorRow) => {
        return (
            <DataTable value={majorRow.minorRelations} dataKey="id">
                <Column className="table-column-4" />
                <Column field="name" header="Relación" />
                <Column
                    field="count"
                    header="Cantidad"
                    className="table-column-6 table-column-center"
                />
                <Column
                    header="Detalles"
                    className="table-column-6"
                    body={(minorRow) => detailTemplate(majorRow, minorRow)}
                />
            </DataTable>
        );
    };

    const footerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column />
                <Column footer="TOTAL REGISTRACIONES *" />
                <Column footer={orEmptyCount(total)} className="table-column-center" />
                <Column />
            </Row>
            <Row>
                <Column />
                <Column
                    colSpan={3}
                    footer="* Una misma persona puede tener varias registraciones"
                    style={{ fontWeight: 'normal' }}
                />
            </Row>
        </ColumnGroup>
    );

    const allowExpansion = (majorRow) => {
        return majorRow?.minorRelations && majorRow.minorRelations.length;
    };

    const MonthSelector = () => {
        return (
            <Calendar
                value={reportDate}
                view="month"
                mask="99/9999"
                dateFormat="mm/yy"
                // Hidden to afford more drop-down width
                // showIcon={true}
                onChange={(e) => setReportDate(e.value)}
            />
        );
    };

    const header = (
        <div className="split-table-header">
            <div className="table-actions-header">
                <MonthSelector />
                {anyExpandable ? (
                    <ExpandButtons onExpand={expandAll} onCollapse={collapseAll} />
                ) : null}
            </div>
            {/* <ReportRelation onRelationSelected={relationSelected} /> */}
            {/* Reports are always updated when parameters change*/}
            <RefreshButton onRefresh={() => search(reportDate)} className="hide-constrained-700" />
            {/* disabled={dataSynced} */}
            {/* Always enabled, since there might be other reasons to refetch
                the report data other than the report parameter changing, e.g.
                because a new registration has been logged to the system. The
                overlay mask is used to denote that report params and report
                data have diverged: What you see is not what you would get. */}
        </div>
    );

    return (
        <Card className="registrations-report-page">
            <RequestMessages messages={requestErrors} />
            <TransparentOverlay isOn={!dataSynced}>
                <DataTable
                    header={header}
                    value={majorRows}
                    dataKey="id"
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    className="table-expandable-details"
                    scrollable={true}
                    rowExpansionTemplate={minorReportTemplate}
                    footerColumnGroup={footerColumnGroup}
                >
                    <Column className="table-column-4" expander={allowExpansion} />
                    <Column field="name" header="Relación" />
                    <Column
                        field="count"
                        header="Cantidad"
                        className="table-column-6 table-column-center"
                    />
                    <Column
                        header="Detalles"
                        className="table-column-6"
                        body={majorReportTemplate}
                    />
                </DataTable>
            </TransparentOverlay>
        </Card>
    );
};
