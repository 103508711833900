/* https://faq.whatsapp.com/5913398998672934 */

export const whatsAppChatURL = (phoneNumber) => {
    const cleanedNumber = phoneNumber ? phoneNumber.replace(/\D/g, '') : '';
    return cleanedNumber ? `https://wa.me/${cleanedNumber}` : null;
};

export const mailToURL = (email) => {
    const cleanedEmail = email ? email.trim() : '';
    return cleanedEmail ? `mailto:${cleanedEmail}` : null;
};
