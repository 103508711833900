import { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { OverlayPanel } from 'primereact/overlaypanel';

import { useOnKeyDown } from '@hooks/use-on-key';
import { genericRequestErrors } from '@services/index';
import { moneyOrEmpty } from '@utils/money';

import { PersonDetail, PersonService } from '@services/personService';

const SelectPaymentResponsible = ({ beneficiary, beneficiaryOf, onSelected, onCancel }) => {
    // TODO: Consider rendering Beneficiary/Responsible persons with a different
    // control where the one that has the focus is more obvious than a Button.
    // Maybe a radio button selector which made more obvious the selected choice?

    const responsibleHeader =
        Array.isArray(beneficiaryOf) && beneficiaryOf.length > 1 ? 'Responsables' : 'Responsable';

    return (
        <Dialog
            header="¿Quién realiza el pago?"
            visible={true}
            onHide={() => onCancel()}
            style={{ width: '25em' }}
        >
            <h4>Beneficiario</h4>
            <div className="grid">
                <div className="col-12">
                    <Button
                        label={`${beneficiary.formalName}`}
                        onClick={() => onSelected(beneficiary)}
                        style={{ width: '100%' }}
                        autoFocus
                    />
                </div>
            </div>
            <h4>{responsibleHeader}</h4>
            <div className="grid">
                {beneficiaryOf.map((record, index) => (
                    <div key={index} className="col-12">
                        <Button
                            label={`${record.responsible.formalName} (${record.responsibleRole})`}
                            onClick={() => onSelected(record.responsible)}
                            style={{ width: '100%' }}
                        />
                    </div>
                ))}
            </div>
        </Dialog>
    );
};

const PersonBalanceHeader = ({ siteBalance }) => {
    // TODO: USABILITY - Alternative implementation
    // Instead of displaying beneficiaryOfTotal & responsibleOfTotal, display
    // a single value siteBalance.total with an icon that on-hover or on-click
    // displays the extra information. That icon could be different or red if
    // there were over-due transactions to be canceled.

    // TODO: USABILITY - Alternative implementation
    // Display siteBalance.total and in parenthesis all the details, e.g.:
    // Saldo Neto: 5.000 (Propio: -5.000, Responsable: 10.000)
    // Si la persona no tiene nadie a cargo, solo mostrar:
    // Saldo Propio: -5.000

    if (!siteBalance) return null;

    const { beneficiaryOfTotal, responsibleOfTotal } = siteBalance;

    return (
        <>
            {beneficiaryOfTotal && (
                <span className="flex-now-wrap">
                    <span className="font-bold">Saldo Propio:</span>
                    <span className="font-normal">{` ${moneyOrEmpty(beneficiaryOfTotal)}`}</span>
                </span>
            )}
            {/* If some kind of divider is required */}
            {/* {beneficiaryOfTotal && responsibleOfTotal && ' | '} */}
            {responsibleOfTotal && (
                <span className="flex-now-wrap">
                    <span className="font-bold">Responsable:</span>
                    <span className="font-normal">{` ${moneyOrEmpty(responsibleOfTotal)}`}</span>
                </span>
            )}
        </>
    );
};

// IMPORTANT: Set forceUpdate to a new value (e.g. a timestamp like Date.now())
// each time that the button should refresh due to changes outside its purview.

export const NewPaymentButton = ({
    beneficiaryId,
    isOverdue = null,
    showBalance = true,
    forceUpdate = null,
    buttonProps = {},
}) => {
    const history = useHistory();
    const location = useLocation();
    const [service] = useState(new PersonService());
    const [loading, setLoading] = useState(true);
    const [requestErrors, setRequestErrors] = useState([]);
    const [beneficiary, setBeneficiary] = useState(null);
    const [siteBalance, setSiteBalance] = useState(null);
    const [beneficiaryOf, setBeneficiaryOf] = useState(null);
    const [hasUncanceled, setHasUncanceled] = useState(false);
    const [selectResponsible, setSelectResponsible] = useState(false);

    useEffect(() => {
        if (beneficiaryId && forceUpdate) {
            setLoading(true);
            setRequestErrors(null);
            service
                .get(beneficiaryId, PersonDetail.DASHBOARD)
                .then((response) => {
                    const { siteBalance, beneficiaryOf, ...rest } = response.data;
                    setBeneficiary(rest);
                    setSiteBalance(siteBalance);
                    setBeneficiaryOf(beneficiaryOf);
                    setHasUncanceled(Boolean(siteBalance.total));
                })
                .catch((error) => setRequestErrors(genericRequestErrors(error)))
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
            setBeneficiary(null);
            setSiteBalance(null);
            setBeneficiaryOf(null);
            setHasUncanceled(false);
            setRequestErrors(null);
        }
    }, [service, beneficiaryId, forceUpdate]);

    const executeNewPayment = (personId) => {
        history.push({
            pathname: '/accounting/receipts/create',
            search: `?person=${personId}`,
            state: { from: location },
        });
    };

    const onNewPayment = () => {
        const hasResponsible = Array.isArray(beneficiaryOf) && beneficiaryOf.length;
        hasResponsible ? setSelectResponsible(true) : executeNewPayment(beneficiaryId);
    };

    const overdueSeverity = isOverdue == null ? beneficiary?.isOverdue : isOverdue;

    return (
        <>
            <Button
                label="Cancelar"
                iconPos="left"
                icon="pi pi-bolt"
                loading={loading}
                onClick={onNewPayment}
                disabled={!hasUncanceled}
                severity={overdueSeverity ? 'danger' : 'info'}
                badge={
                    Array.isArray(requestErrors) && requestErrors.length
                        ? 'Error Responsables'
                        : null
                }
                badgeClassName="p-badge-danger"
                {...buttonProps}
            />

            {showBalance && <PersonBalanceHeader siteBalance={siteBalance} />}

            {selectResponsible && (
                <SelectPaymentResponsible
                    beneficiary={beneficiary}
                    beneficiaryOf={beneficiaryOf}
                    onCancel={() => setSelectResponsible(false)}
                    onSelected={(responsible) => executeNewPayment(responsible.id)}
                />
            )}
        </>
    );
};

const PersonBalanceOverlay = ({ reference, siteBalance, onNewPayment, toShowDetails }) => {
    useOnKeyDown('Escape', () => reference.current.hide());
    const LABEL_COLUMN_STYLE = { width: '8em' };
    const VALUE_COLUMN_STYLE = { textAlign: 'right', width: '8em' };

    return (
        <OverlayPanel showCloseIcon={true} dismissable={true} ref={reference}>
            <div>
                <div className="grid">
                    <div className="col-fixed" style={LABEL_COLUMN_STYLE}>
                        Saldo Propio:
                    </div>
                    <div className="col" style={VALUE_COLUMN_STYLE}>
                        {moneyOrEmpty(siteBalance.beneficiaryOfTotal)}
                    </div>
                </div>
                <div className="grid">
                    <div className="col-fixed" style={LABEL_COLUMN_STYLE}>
                        Responsable:
                    </div>
                    <div className="col" style={VALUE_COLUMN_STYLE}>
                        {moneyOrEmpty(siteBalance.responsibleOfTotal)}
                    </div>
                </div>
                <div className="grid" style={{ fontWeight: '600' }}>
                    <div className="col-fixed" style={LABEL_COLUMN_STYLE}>
                        Saldo Total:
                    </div>
                    <div className="col" style={VALUE_COLUMN_STYLE}>
                        {moneyOrEmpty(siteBalance.total)}
                    </div>
                </div>
            </div>
            <hr />
            <div style={{ width: '100%', height: '30px', textAlign: 'center' }}>
                <Link to={toShowDetails}>Detalle de saldos</Link>
            </div>
            <Button label="Nuevo Pago" style={{ width: '100%' }} onClick={onNewPayment} />
        </OverlayPanel>
    );
};

export const FlashPaymentButtons = ({
    isOverdue,
    beneficiary,
    beneficiaryOf,
    siteBalance,
    hideNoDues = true,
}) => {
    const history = useHistory();
    const location = useLocation();
    const reference = useRef(null);
    const [hasUncanceled] = useState(Boolean(siteBalance.total));
    const [selectResponsible, setSelectResponsible] = useState(false);

    if (!hasUncanceled && hideNoDues) return null;

    const isBeneficiaryOf = Boolean(Array.isArray(beneficiaryOf) && beneficiaryOf.length);
    const responsibleDues = Boolean(siteBalance.responsibleOfTotal);

    const executeNewPayment = (personId) => {
        history.push({
            pathname: '/accounting/receipts/create',
            search: `?person=${personId}`,
            state: { from: location },
        });
    };

    const onNewPayment = () => {
        isBeneficiaryOf ? setSelectResponsible(true) : executeNewPayment(beneficiary.id);
    };

    return (
        <div className="flex align-items-center">
            <Button
                icon="pi pi-bolt"
                className="p-button-rounded mr-2"
                severity={isOverdue ? 'danger' : 'info'}
                aria-label="Nuevo Pago"
                onClick={onNewPayment}
                disabled={!hasUncanceled}
            />
            {responsibleDues && (
                <>
                    <PersonBalanceOverlay
                        reference={reference}
                        siteBalance={siteBalance}
                        onNewPayment={onNewPayment}
                        toShowDetails={{
                            pathname: `persons/${beneficiary.id}/dues`,
                            state: { from: location },
                        }}
                    />
                    <Button
                        icon="pi pi-plus-circle"
                        className="p-button-rounded"
                        aria-label="Detalle de saldos"
                        onClick={(e) => reference.current.toggle(e)}
                        aria-haspopup
                        aria-controls="overlay_panel"
                    />
                </>
            )}
            {selectResponsible && (
                <SelectPaymentResponsible
                    beneficiary={beneficiary}
                    beneficiaryOf={beneficiaryOf}
                    onCancel={() => setSelectResponsible(false)}
                    onSelected={(responsible) => executeNewPayment(responsible.id)}
                />
            )}
        </div>
    );
};
