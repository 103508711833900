import { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@custom/react-router';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { hookFormRequestError } from '@custom/hook-form';
import { useForm } from 'react-hook-form';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { RadioButton } from 'primereact/radiobutton';

import { FieldErrorMessages, errorClassName } from '@components/field-error-messages';
import { DescriptionField, PersonField } from '@components/fields';
import { ModalPageForm } from '@components/forms/ModalPageForm';
import { genericRequestErrors } from '@services/index';

import { CommissionHeader } from '@commissions/commissions/header';
import { CommissionsPath } from '@commissions/routes';
import { CommissionPledgeService } from '@services/commissionsService';
import { CommissionPledgeTemplateEntries } from './templates/entries';

const DETAIL_FIELDS = ['person'];

export const CommissionPledgeCreate = () => {
    const query = useQuery();
    // const personId = query.get('person');
    const commissionId = query.get('commission');
    const [from] = useState(useLocation().state?.from);

    const history = useHistory();
    const [service] = useState(new CommissionPledgeService());

    const [templates, setTemplates] = useState();
    const [commission, setCommission] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [requestErrors, setRequestErrors] = useState();

    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: {
            personId: null,
            templateId: null,
            comments: '',
        },
    });

    // Callbacks --------------------------------------------------------------

    const searchTemplates = useCallback(
        (commissionId) => {
            setRequestErrors(null);
            service
                .templates(commissionId)
                .then((response) => {
                    const { templates, ...commission } = response.data;
                    setTemplates(templates);
                    setCommission(commission);
                })
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        },
        [service],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        searchTemplates(commissionId);
    }, [searchTemplates, commissionId]);

    // Events ----------------------------------------------------------------

    const changeSelectedTemplate = (value) => {
        if (value == null) {
            setError('templateId', { message: 'Debe seleccionar una opción' });
        } else {
            clearErrors('templateId');
        }
        setSelectedTemplate(value);
    };

    // Execute ----------------------------------------------------------------

    const onExecute = (data) => {
        clearErrors();
        setRequestErrors(null);

        if (selectedTemplate == null) {
            setError('templateId', { message: 'Debe seleccionar una opción' });
            return;
        }

        const request = {
            personId: data?.person?.[0]?.id,
            comments: data.comments,
            templateId: selectedTemplate.id,
        };
        service
            .create(request)
            .then((response) => {
                const nextStep =
                    from || generatePath(CommissionsPath.Commissions.item, { id: commissionId });
                history.replace(nextStep);
                // OR: Got to pledge or go to person to collect it
            })
            .catch((error) =>
                hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors),
            );
    };

    const handleRequestError = (error) => {
        setRequestErrors(genericRequestErrors(error));
    };

    // Rendering --------------------------------------------------------------

    const templateEntries = (template) => {
        return <CommissionPledgeTemplateEntries entries={template?.entries} />;
    };

    return (
        <ModalPageForm
            className="commission-pledge-create"
            onSubmit={handleSubmit(onExecute)}
            requestErrors={requestErrors}
            executeButtonsProps={{
                executeLabel: 'Crear compromiso',
                cancelLabel: 'Volver sin crear',
            }}
        >
            <CommissionHeader commission={commission}>
                <div className="p-fluid">
                    <PersonField
                        control={control}
                        errors={errors}
                        autoFocus
                        onRequestError={handleRequestError}
                    />
                    <DescriptionField
                        fieldLabel="Comentario / Especificaciones"
                        fieldName="comments"
                        control={control}
                        errors={errors}
                        rules={{ required: false }}
                    />
                </div>
                <label htmlFor="templateId" className={errorClassName(errors['templateId'])}>
                    Opción *
                </label>
                <FieldErrorMessages name="templateId" errors={errors} />
                <br />
                <br />
                <Accordion>
                    {templates?.map((template) => {
                        const header = (
                            <>
                                <RadioButton
                                    name="templateId"
                                    value={template}
                                    onChange={(e) => changeSelectedTemplate(e.value)}
                                    checked={selectedTemplate?.id === template.id}
                                />
                                <label htmlFor={template.id} className="ml-2">
                                    {template.name}
                                </label>
                            </>
                        );
                        return (
                            <AccordionTab key={template.id} header={header}>
                                {templateEntries(template)}
                            </AccordionTab>
                        );
                    })}
                </Accordion>
            </CommissionHeader>
        </ModalPageForm>
    );
};
