import { useController } from 'react-hook-form';

import { Calendar } from 'primereact/calendar';

import { FieldErrorMessages, invalidClassName } from '@components/field-error-messages';
import { TimeUnit } from '@enums/time';

const getViewDateFormat = (unit) => {
    switch (unit) {
        case TimeUnit.YEAR.value:
            return { view: 'year', dateFormat: 'yy' };
        case TimeUnit.MONTH.value:
            return { view: 'month', dateFormat: 'mm/yy' };
        // TODO: Restrict WEEK date selector to Monday's. It doesn't
        // really matter, because the server will select Monday's only.
        case TimeUnit.WEEK.value:
        case TimeUnit.DAY.value:
        default:
            return { view: 'date', dateFormat: 'dd/mm/yy' };
    }
};

export const TimeUnitDateField = ({
    // disable prettier
    control,
    errors,
    fieldName = 'timeUnitDate',
    fieldLabel = 'Fecha',
    required = true,
    unit = TimeUnit.DAY.value,
    ...rest
}) => {
    const { field, fieldState } = useController({
        control: control,
        name: fieldName,
        rules: {
            required: required,
        },
    });

    const { view, dateFormat } = getViewDateFormat(unit);

    return (
        <div className="field">
            <label htmlFor={field.name} className={invalidClassName(fieldState.error)}>
                {fieldLabel}
                {required ? ' *' : ''}
            </label>

            <Calendar
                inputId={field.name}
                value={field.value}
                onChange={field.onChange}
                view={view}
                dateFormat={dateFormat}
                className={invalidClassName(fieldState.error)}
                {...rest}
            />

            <FieldErrorMessages name={field.name} errors={errors} />
        </div>
    );
};
