import { useController } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';

import { FieldErrorMessages, invalidClassName } from '@components/field-error-messages';

const DEFAULT_RULES = {
    required: true,
    minLength: { value: 2, message: 'Al menos dos letras' },
};

export const UsernameField = ({
    fieldName = 'username',
    fieldLabel = 'Nombre de usuario',
    control,
    errors,
    rules = DEFAULT_RULES,
    customMessages = {},
    autoComplete = 'off', // Off by default, but can be overridden
    ...rest
}) => {
    const { field, fieldState } = useController({ name: fieldName, control, rules });

    return (
        <div className="field">
            {fieldLabel && (
                <label htmlFor={fieldName} className={invalidClassName(fieldState.error)}>
                    {fieldLabel}
                    {rules && rules?.required ? ' *' : ''}
                </label>
            )}
            <InputText
                id={field.name}
                value={field.value}
                name={field.name}
                // This setting is being honored in Chrome,
                // regardless of value set at form level.
                autoComplete={autoComplete}
                className={invalidClassName(fieldState.error)}
                onChange={(e) => field.onChange(e.target.value)}
                {...rest}
            />
            <FieldErrorMessages name={fieldName} errors={errors} customMessages={customMessages} />
        </div>
    );
};

UsernameField.displayName = 'UsernameField';
