/**
 * Shorter reference to frequently used MIME types
 * https://en.wikipedia.org/wiki/Media_type
 */

export const MIMEType = Object.freeze({
    PDF: 'application/pdf',
    JSON: 'application/json',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
});
