export const PresentStatus = Object.freeze({
    ANY: 'any',
    INACTIVE: 'inactive',
    EXPIRED: 'expired',
    ACTIVE: 'active',
    FUTURE: 'future',
    UNEXPIRED: 'unexpired',
});

// export const TimeUnit = Object.freeze({
//     // https://www.typescriptlang.org/docs/handbook/enums.html
//     YEAR: 'year',
//     MONTH: 'month',
//     WEEK: 'week',
//     DAY: 'day',
//     HOUR: 'hour',
//     MINUTE: 'minute',
//     SECOND: 'second',
// });

export class TimeUnit {
    // https://www.sohamkamani.com/javascript/enums/
    static YEAR = new TimeUnit('year', 'Año', 'Anual');
    static MONTH = new TimeUnit('month', 'Mes', 'Mensual');
    static WEEK = new TimeUnit('week', 'Semana', 'Semanal');
    static DAY = new TimeUnit('day', 'Día', 'Diario');
    static HOUR = new TimeUnit('hour', 'Hora');
    static MINUTE = new TimeUnit('minute', 'Minuto');
    static SECOND = new TimeUnit('second', 'Segundo');

    constructor(value, label, period) {
        this.value = value;
        this.label = label;
        this.period = period ? period : label;
    }

    static ALL = [
        // prettier-ignore
        this.YEAR,
        this.MONTH,
        this.WEEK,
        this.DAY,
        this.HOUR,
        this.MINUTE,
        this.SECOND,
    ];
}
