import { Controller } from 'react-hook-form';

import { Calendar } from 'primereact/calendar';

import {
    FieldErrorMessages,
    errorClassName,
    fieldErrorMessage,
    invalidClassName,
} from '@components/field-error-messages';
import { isFutureDate, midnightStartTomorrow } from '@utils/date-utils';

const MAX_PAST_YEARS = 2;

const getYearRange = () => {
    const today = new Date();
    const endYear = today.getFullYear();
    const startYear = endYear - MAX_PAST_YEARS;
    return `${startYear}:${endYear}`;
};

const NOT_IN_FUTURE_CHECK = {
    notInFuture: (v) => {
        return v < midnightStartTomorrow();
    },
};

const NOT_IN_FUTURE_ERROR = {
    notInFuture: 'No puede ser una fecha futura',
};

export const ValueDateField = ({
    fieldName = 'valueDate',
    fieldLabel = 'Fecha Valor',
    control,
    errors,
    required = true,
    allowFuture = true, // If true, allow setting valueDate in the future
    warnOnFuture = true, // If true and allowFuture, warn on future valueDate
    ...rest
}) => {
    // TODO: Change widget to input dob (or any dates actually) #94
    // Refs: https://designnotes.blog.gov.uk/2013/12/05/asking-for-a-date-of-birth/

    const validations = allowFuture ? {} : NOT_IN_FUTURE_CHECK;
    const errorMessages = allowFuture ? {} : NOT_IN_FUTURE_ERROR;

    return (
        <div className="field">
            <label htmlFor={fieldName} className={errorClassName(fieldName in errors)}>
                {fieldLabel}
                {required ? ' *' : null}
            </label>

            <Controller
                name={fieldName}
                control={control}
                rules={{
                    required: required,
                    validate: { ...validations },
                }}
                render={({ field, fieldState }) => (
                    <>
                        <Calendar
                            id={field.name}
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                            dateFormat="dd/mm/yy"
                            mask="99/99/9999"
                            showIcon
                            showOnFocus={false} // Datepicker will not be visible with input focus.
                            monthNavigator
                            yearNavigator
                            yearRange={getYearRange()}
                            className={invalidClassName(fieldState.error)}
                            {...rest}
                        />
                        <FieldErrorMessages
                            name={fieldName}
                            errors={errors}
                            customMessages={errorMessages}
                        />
                        {allowFuture &&
                            warnOnFuture &&
                            isFutureDate(field.value) &&
                            fieldErrorMessage(0, 'ADVERTENCIA: Es una fecha futura', 'p-warn')}
                    </>
                )}
            />
        </div>
    );
};

ValueDateField.displayName = 'ValueDateField';
