import { configuration } from './configuration';

const applicationItems = (auth) => {
    // GroupOne ---------------------------------------------------------------

    const group_one = [
        {
            separator: true,
            items: [
                { label: 'Personas', icon: 'pi pi-fw pi-id-card', to: '/persons' },

                { label: 'Cajas', icon: 'pi pi-fw pi-money-bill', to: '/local-payments' },
                // { label: 'Cajas y Terminales', icon: 'pi pi-fw pi-money-bill', to: '/local-payments' },

                {
                    label: 'Transferencias',
                    icon: 'pi pi-fw pi-arrow-right-arrow-left',
                    to: '/funds-entries',
                },
            ],
        },
        {
            separator: true,
            items: [
                {
                    label: 'Tokens',
                    // icon: 'pi pi-barcode',
                    icon: 'pi pi-circle',
                    to: '/tokens',
                },
                {
                    label: 'Recaudaciones',
                    icon: 'pi pi-shopping-bag',
                    to: '/commissions',
                },
            ],
        },
    ];

    // GroupTwo ---------------------------------------------------------------

    const group_two_items = [];

    if (configuration.displayWIP) {
        group_two_items.push({
            label: 'Comprobantes',
            icon: 'pi pi-book',
            items: [
                {
                    label: 'Transacciones',
                    icon: 'pi pi-file-excel',
                    to: '/accounting/transactions',
                },
                {
                    label: 'Recibos',
                    icon: 'pi pi-bookmark',
                    to: '/accounting/receipts',
                },
                {
                    label: 'Fiscales',
                    icon: 'pi pi-file',
                    to: '/accounting/documents',
                },
            ],
        });
    } else {
        group_two_items.push({
            label: 'Comprobantes',
            icon: 'pi pi-book',
            items: [
                {
                    label: 'Recibos',
                    icon: 'pi pi-bookmark',
                    to: '/accounting/receipts',
                },
            ],
        });
    }

    group_two_items.push({
        label: 'Reportes',
        icon: 'pi pi-chart-line',
        items: [
            {
                label: 'Registraciones',
                // icon: 'pi pi-id-card',
                to: '/reports/registrations',
            },
            {
                label: 'Disponibilidades',
                // icon: 'pi pi-dollar',
                to: '/treasury/cash-report',
                disabled: !auth.isSiteOwner,
            },
            {
                label: 'Contabilidad',
                // icon: 'pi pi-dollar',
                to: '/reports/accounting',
                disabled: !auth.isSiteOwner,
            },
            {
                label: 'Movimientos Staff',
                // icon: 'pi pi-dollar',
                to: '/users/transactions-balance',
                disabled: !auth.isSiteOwner,
            },
        ],
    });

    const group_two = [
        {
            separator: true,
            items: group_two_items,
        },
    ];

    // GroupThree -------------------------------------------------------------

    const group_three_items = [];

    if (configuration.displayWIP) {
        group_three_items.push({
            label: 'Configuración',
            icon: 'pi pi-fw pi-cog',
            items: [
                {
                    label: 'Usuarios',
                    icon: 'pi pi-fw pi-users',
                    to: '/configuration/users',
                },
                {
                    label: 'Relaciones',
                    icon: 'pi pi-fw pi-sitemap',
                    to: '/configuration/relations',
                },
                {
                    label: 'Cajas y Terminales',
                    icon: 'pi pi-fw pi-money-bill',
                    to: '/configuration/local-payments',
                },
                {
                    label: 'Cuentas y Billeteras',
                    icon: 'pi pi-fw pi-wallet',
                    to: '/configuration/funds-accounts',
                },
                {
                    label: 'Perfiles Fiscales',
                    icon: 'pi pi-fw pi-flag',
                    to: '/configuration/tax-profiles',
                },
            ],
        });
    }

    group_three_items.push({
        label: 'Servicio Suma',
        icon: 'pi pi-fw pi-server',
        to: '/service',
    });

    const group_three = [
        {
            separator: true,
            items: group_three_items,
        },
    ];

    return [...group_one, ...group_two, ...group_three];
};

const playgroundItems = [
    {
        label: 'Playground',
        icon: 'pi pi-fw pi-bookmark',
        items: [
            { label: 'Playground 1', icon: 'pi pi-fw pi-sitemap', to: '/playground/one' },
            { label: 'Playground 2', icon: 'pi pi-fw pi-sitemap', to: '/playground/two' },
        ],
    },
];

const demoItems = [
    {
        label: 'Demos',
        icon: 'pi pi-fw pi-bookmark',
        items: [
            { label: 'ModalSubPath', to: '/demos/components/modal-sub-path' },
            { label: 'FieldValueComponents', to: '/demos/components/field-value' },
            { label: 'MonthField', to: '/demos/components/month-field' },
            { label: 'SinceUntilBlock', to: '/demos/components/since-until-block' },
            { label: 'DNIField & CUITField', to: '/demos/components/dni-cuit' },
            { label: 'MoneyFormatDemo', to: '/demos/accounting/money-format' },
            { label: 'Utils Demos', to: '/demos/utils' },
        ],
    },
];

export const menuItems = (auth) => {
    const items = [];
    items.push(...applicationItems(auth));
    if (configuration.developMode) {
        items.push(...playgroundItems);
        items.push(...demoItems);
    }
    return items;
};
