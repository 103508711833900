import { useCallback, useEffect, useState } from 'react';

import { generatePath, Link } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { RequestMessages } from '@components/RequestMessages';
import { LinkButton, RefreshButton } from '@components/buttons';
import { genericRequestErrors } from '@services/index';

import { TokenService } from '@services/tokenService';
import { TokensPath } from '@tokens/routes';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

export const TokenTypeSearch = () => {
    const [service] = useState(new TokenService());

    // Params: Pagination
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);

    // Search results
    const [items, setItems] = useState([]);
    const [requestMessages, setRequestMessages] = useState();

    // Callbacks --------------------------------------------------------------

    // search can be called:
    // - On demand: repeatSearch
    // - On change: searchConfig
    // - On change: pagination (rows/page)
    // When searchConfig changes, pagination hast to change too: Page == 0

    const search = useCallback(
        (first, page, rows) => {
            const searchParams = {
                pageSize: rows,
                page: page + 1,
            };
            setRequestMessages(null);
            service
                .types(searchParams)
                .then((response) => {
                    const data = response.data;
                    setFirst(first);
                    setPage(page);
                    setRows(rows);
                    setItems(data.results);
                    setTotalRecords(data.count ? data.count : 0);
                })
                .catch((error) => setRequestMessages(genericRequestErrors(error)));
        },
        [service],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        search(0, 0, rows);
    }, [search, rows]);

    // Events -----------------------------------------------------------------

    const onPage = (event) => {
        search(event.first, event.page, event.rows);
    };

    const repeatSearch = () => {
        search(first, page, rows);
    };

    // const rowClick = ({ data }) => {
    //     // TODO: Implement an event-less navigation option so that right-click works
    //     history.push(generatePath(TokensPath.TokenType.item, { id: data.id }));
    // };

    // Rendering --------------------------------------------------------------

    const descriptionTemplate = (tokenType) => (
        <div className="split-table-header">
            <div>
                <div className="font-bold">{tokenType.name}</div>
                <div className="text-color-secondary">{tokenType.description}</div>
            </div>
            <LinkButton
                to={{
                    pathname: generatePath(TokensPath.TokenPack.create),
                    search: '?' + new URLSearchParams({ typeId: tokenType.id }).toString(),
                }}
            >
                <Button label="Generar" />
            </LinkButton>
        </div>
    );

    const header = (
        <div>
            <div className="flex gap-4">
                <Link to={TokensPath.TokenPack.search}>Generados</Link>
                <Link to={TokensPath.TokenUse.search}>Consumidos</Link>
            </div>
            <hr />
            <div className="split-table-header">
                <h2>Tipos de Tokens</h2>
                <RefreshButton onRefresh={repeatSearch} />
            </div>
        </div>
    );

    return (
        <div className="token-type-search">
            <RequestMessages messages={requestMessages} />

            <DataTable
                value={items}
                dataKey="id"
                header={header}
                className="hide-columns-header"
                // --------------------------------------------------------------------------
                // TODO: Implement an event-less navigation option so that right-click works
                // selectionMode="single"
                // onRowClick={rowClick}
                // --------------------------------------------------------------------------
                emptyMessage="No hay tipos de tokens"
                lazy
                // Use this to show paginator only when required
                paginator={Boolean(totalRecords && totalRecords > MIN_PAGINATION_COUNT)}
                rows={rows}
                first={first}
                onPage={onPage}
                totalRecords={totalRecords}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            >
                <Column body={descriptionTemplate} />
            </DataTable>
        </div>
    );
};
