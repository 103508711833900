export const RegistrationAction = Object.freeze({
    CREATE: 'create',
    CHANGE_MAJOR_RELATION: 'change-major-relation',
    CHANGE_MINOR_RELATION: 'change-minor-relation',
    CHANGE_MAJOR_PAYMENT_OPTION: 'change-major-payment-option',
    CHANGE_MINOR_PAYMENT_OPTION: 'change-minor-payment-option',

    CHANGE_MAJOR_DURATION: 'change-major-duration',
    CHANGE_MINOR_DURATION: 'change-minor-duration',

    ACCRUE_FEES_PERSON: 'accrue-fees-person',
    ACCRUE_FEES_REGISTRATION: 'accrue-fees-registration',

    SHOW_PAYMENT_CHOICES: 'show-payment-choices',
    CHANGE_PAYMENT_CHOICE: 'change-payment-choice',

    DELETE_REGISTRATION: 'delete-registration',
});
