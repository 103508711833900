import { Button } from 'primereact/button';

import { webShareSupported } from '@services/sharing';

export const ShareButton = ({ onDownload, className = '', disabled = false, ...props }) => {
    return (
        <Button
            type="button"
            icon="pi pi-share-alt"
            className={`p-button-rounded p-button-text ${className}`}
            disabled={disabled || !webShareSupported}
            {...props}
        />
    );
};
