import { TabPanel, TabView } from 'primereact/tabview';

import { CashEntryList } from './entries';
import { CashSessionSummary } from './summary';

export const CashSessionDetail = ({ session }) => {
    if (session == null) {
        return null;
    }

    // TODO: When used in a CashRegister view and the register is closed,
    // consider styling this as: "Último Resumen | Últimos Movimientos"

    // IMPORTANT: Using renderActiveOnly=false will:
    // - Render both tabs when the view is created
    // - BUT will not re-render them when switching (which is highly likely)
    return (
        <TabView className="cash-session-detail" renderActiveOnly={false}>
            <TabPanel header="Resumen">
                <CashSessionSummary session={session} />
            </TabPanel>
            <TabPanel header="Movimientos">
                <CashEntryList session={session} />
            </TabPanel>
        </TabView>
    );
};
