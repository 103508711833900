import { useState } from 'react';

import { useForm } from 'react-hook-form';

import { RadioButton } from 'primereact/radiobutton';

import { MonthField, MonthFieldSelectorOptions } from '@components/fields';
import { ModalForm } from '@components/forms';
import { hookFormRequestError } from '@custom/hook-form';
import {
    fromISOLocalDateString,
    lastDayOfMonth,
    toISOLocalDateString,
    toMonthYearFromISOString,
} from '@utils/date-utils';

import { RegistrationService } from '@services/registrations';

const DETAIL_FIELDS = ['until'];

// TODO: Generic improvements
// - Preselect a default option: only preselect if there is only one?
// - Disable selector (make it readonly) if there is a single option.

export const ChangeMajorDuration = ({ registration, onCancel, onSuccess }) => {
    // State -------------------------------------------------------------------

    const [service] = useState(new RegistrationService());
    const [opened, setOpened] = useState(!registration?.until);
    const [requestErrors, setRequestErrors] = useState([]);

    // Hooks -------------------------------------------------------------------

    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        defaultValues: {
            until: fromISOLocalDateString(registration?.until),
        },
    });

    // Execute -----------------------------------------------------------------

    const onExecute = (data) => {
        setRequestErrors(null);
        const apiRequest = {
            until: opened ? null : toISOLocalDateString(lastDayOfMonth(data.until)),
        };
        service
            .changeRegistration(registration.id, apiRequest)
            .then(onSuccess)
            .catch((error) =>
                hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors),
            );
    };

    // Render ------------------------------------------------------------------

    return (
        <ModalForm
            title="Cambiar duración"
            buttons={{ executeLabel: 'Cambiar' }}
            requestErrors={requestErrors}
            onSubmit={handleSubmit(onExecute)}
            onCancel={onCancel}
            style={{ width: '400px' }}
        >
            <div>
                <p>Duración actual:</p>
                <ul>
                    <li>Desde: {toMonthYearFromISOString(registration.since)}</li>
                    <li>
                        Hasta:{' '}
                        {toMonthYearFromISOString(registration.until, 'Abierto (sin fecha hasta)')}
                    </li>
                </ul>
                <hr />
                <div className="field-radiobutton">
                    <RadioButton
                        value={true}
                        inputId="opened-choice"
                        checked={opened}
                        autoFocus={opened}
                        onChange={(e) => setOpened(e.value)}
                    />
                    <label htmlFor="opened-choice">Abierto (sin fecha hasta)</label>
                </div>
                <div className="field-radiobutton">
                    <RadioButton
                        value={false}
                        inputId="closed-choice"
                        checked={!opened}
                        autoFocus={!opened}
                        onChange={(e) => setOpened(e.value)}
                    />
                    <label htmlFor="closed-choice">Nueva fecha hasta</label>
                </div>
                <div>
                    <MonthField
                        fieldName="until"
                        control={control}
                        selectorOptions={MonthFieldSelectorOptions.UNTIL_CLOSED}
                        inputStyle={{ width: '180px' }}
                        rules={
                            opened
                                ? {}
                                : {
                                      required: true,
                                      validate: {
                                          beforeSince: (until) => {
                                              const sinceValue = fromISOLocalDateString(
                                                  registration.since,
                                              );
                                              return sinceValue == null || sinceValue <= until;
                                          },
                                      },
                                  }
                        }
                        customMessages={{
                            beforeSince: `No puede ser anterior al inicio del registro: ${toMonthYearFromISOString(
                                registration.since,
                            )}`,
                        }}
                    />
                </div>
            </div>
        </ModalForm>
    );
};
