import { useState } from 'react';

import { useParams } from 'react-router-dom';

import { Card } from 'primereact/card';

import { RequestMessages } from '@components/RequestMessages';

import { ResponsibilityList } from './ResponsibilityList';

export const PersonResponsibility = () => {
    const params = useParams();
    const [requestErrors, setRequestErrors] = useState();

    return (
        <Card>
            <RequestMessages messages={requestErrors} />
            <ResponsibilityList personId={params.id} onRequestError={setRequestErrors} />
        </Card>
    );
};
