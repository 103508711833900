// -------------------------------
// Build constants: Change bundle
// -------------------------------
// RELEASE_BUILD >> Exclude all debug features
// DEVELOP_BUILD >> Include all debug features

// TODO: Include / exclude files from bundle
// https://stackoverflow.com/questions/28572380/conditional-build-based-on-environment-using-webpack

// -------------------------------
// Runtime settings: DEVELOP_BUILD
// -------------------------------
// DISPLAY_WIP >> If true, show WIP features
// RELEASE_MODE >> Hide all debug features
// DEVELOP_MODE >> Show all debug features

const DISPLAY_WIP = false;
const DEVELOP_MODE = false;
const RELEASE_MODE = !DEVELOP_MODE;
const ALWAYS_DISABLED = false;

export const configuration = {
    appConfig: ALWAYS_DISABLED,
    displayWIP: DISPLAY_WIP,
    releaseMode: RELEASE_MODE,
    developMode: DEVELOP_MODE,
    whatsAppPhone: '+54-343 467-8891',
    contactEmail: 'hola@suma.ac',
    serviceWebsite: 'www.suma.ac',
    serviceWebsiteURL: 'https://suma.ac',
    copyrightInfo: '© 2024 Kernstein LLC | Brisa de Argentina S.A.',
};

export const DEFAULT_PATH_TO_LOGIN = '/login';
export const DEFAULT_PATH_NOT_FOUND = '/not-found';

export const DEFAULT_PATH_MANAGING_SITE = '/persons';
export const DEFAULT_PATH_PERSONAL_SITE = '/sites';

export const defaultPathAuthenticated = (site) => {
    return site ? DEFAULT_PATH_MANAGING_SITE : DEFAULT_PATH_PERSONAL_SITE;
};
