import { paginationParams, paramsString, pseudoNoPaginationParams } from '.';
import suma from '../apis/Suma';

const ENTRIES_PATH = 'treasury/funds-entries/';
const ACCOUNTS_PATH = 'treasury/funds-accounts/';

export const EMPTY_FUND_ACCOUNT_ID = 'emptyFundAccount';

export class FundEntryService {
    create(request) {
        return suma.post(ENTRIES_PATH, request);
    }

    update(id, request) {
        return suma.patch(`${ENTRIES_PATH}${id}/`, request);
    }

    get(id) {
        return suma.get(`${ENTRIES_PATH}${id}/`);
    }

    search({
        // @prettier:off
        person,
        emptyPerson,
        fundAccount,
        statusFilter,
        pageSize,
        page,
    }) {
        const queryParams = paginationParams(pageSize, page);

        if (person != null) {
            queryParams.push(`person=${person.id}`);
        }

        if (emptyPerson) {
            queryParams.push('empty_person=true');
        }

        if (fundAccount != null) {
            queryParams.push(
                fundAccount.id === EMPTY_FUND_ACCOUNT_ID
                    ? 'empty_fund_account=true'
                    : `fund_account=${fundAccount.id}`,
            );
        }

        if (!statusFilter.allStatus) {
            if (statusFilter.onlyRevoked) {
                queryParams.push('reconciled_status=REVOKED');
            } else {
                if (statusFilter.reconciledStatus != null) {
                    queryParams.push(
                        `reconciled_status=${
                            statusFilter.reconciledStatus ? 'ACCEPTED' : 'PENDING'
                        }`,
                    );
                }

                if (statusFilter.canceledStatus.size > 0) {
                    statusFilter.canceledStatus.forEach((cancelStatus) => {
                        queryParams.push(`canceled_status=${cancelStatus}`);
                    });
                }
            }
        }

        return suma.get(`${ENTRIES_PATH}${paramsString(queryParams)}`);
    }

    getAccountOptions() {
        return suma.get(ACCOUNTS_PATH + `?${pseudoNoPaginationParams}`);
    }

    issueReceipt(id) {
        return suma.post(`${ENTRIES_PATH}${id}/receipt/`);
    }

    revoke(id) {
        return suma.post(`${ENTRIES_PATH}${id}/revoke/`);
    }
}

export class FundAccountService {
    search({ pageSize, page }) {
        const pagination = paginationParams(pageSize, page);
        // TODO: Add searchParams for filtering
        const queryParams = pagination;
        const queryString = paramsString(queryParams);
        return suma.get(ACCOUNTS_PATH + queryString);
    }

    get(id) {
        return suma.get(ACCOUNTS_PATH + `${id}/`);
    }

    // TODO: PUT Actions
}
