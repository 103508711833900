import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';

import { DNIField, unmaskedDNI } from '@components/fields';
import { CUITField, unmaskedCUIT } from '@components/fields';

export const DniCuitDemo = () => {
    const {
        control,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            dni: null,
            dniAlt: null,
            cuit: null,
            cuitAlt: null,
        },
    });

    // WARNING: Watch triggers re-renders. Consider using useWatch hook
    // https://react-hook-form.com/api/useform/watch/
    const [dni, dniAlt, cuit, cuitAlt] = watch(['dni', 'dniAlt', 'cuit', 'cuitAlt']);

    const onExecute = (data) => {
        const request = {
            dni: unmaskedDNI(data['dni']),
            dniAlt: unmaskedDNI(data['dniAlt']),
            cuit: unmaskedCUIT(data['cuit']),
            cuitAlt: unmaskedCUIT(data['cuitAlt']),
        };
        console.log('DATA ===================');
        console.log(data);
        console.log('REQUEST ================');
        console.log(request);
    };

    return (
        // Prevent autoComplete of dni & cuit fields. We can't get it to work
        // at field level (should pass setting to input), but works at form.
        // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
        // https://stackoverflow.com/questions/53601587/how-to-disable-browser-autocomplete-on-p-inputmask-field

        <form autoComplete="off">
            <div className="card p-fluid">
                <DNIField required={true} control={control} errors={errors} autoFocus={true} />

                <DNIField
                    required={false}
                    fieldName="dniAlt"
                    fieldLabel="DNI Alternativo"
                    control={control}
                    errors={errors}
                />

                <CUITField required={true} control={control} errors={errors} autoFocus={true} />

                <CUITField
                    required={false}
                    fieldName="cuitAlt"
                    fieldLabel="CUIT Alternativo"
                    control={control}
                    errors={errors}
                />

                <Button label="Ok" style={{ width: '150px' }} onClick={handleSubmit(onExecute)} />
            </div>
            <hr />
            <br />
            <div>
                {`typeof(dni)=${typeof dni}`}
                <br />
                {`typeof(dniAlt)=${typeof dniAlt}`}
                <br />
                {`typeof(cuit)=${typeof cuit}`}
                <br />
                {`typeof(cuitAlt)=${typeof cuitAlt}`}
                <br />
                <br />
                <h5>Raw Values</h5>
                {`dni="${dni}"`}
                <br />
                {`dniAlt="${dniAlt}"`}
                <br />
                {`cuit="${cuit}"`}
                <br />
                {`cuitAlt="${cuitAlt}"`}
                <br />
                <br />
                <h5>Unmasked Values: Use in API requests</h5>
                {`dni=${unmaskedDNI(dni)}`}
                <br />
                {`dniAlt=${unmaskedDNI(dniAlt)}`}
                <br />
                {`cuit=${unmaskedCUIT(cuit)}`}
                <br />
                {`cuitAlt=${unmaskedCUIT(cuitAlt)}`}
                <br />
            </div>
        </form>
    );
};
