import { SitesDashBoard } from './';

const BASE_PATH = '/sites';

export const routesInfo = [
    {
        path: BASE_PATH,
        title: 'Mis Sitios',
        exact: true,
        component: SitesDashBoard,
    },
];
