import { Controller } from 'react-hook-form';

import { Calendar } from 'primereact/calendar';

import {
    FieldErrorMessages,
    errorClassName,
    invalidClassName,
} from '@components/field-error-messages';

const MAX_PAST_YEARS = 2;

const getYearRange = () => {
    const today = new Date();
    const endYear = today.getFullYear();
    const startYear = endYear - MAX_PAST_YEARS;
    return `${startYear}:${endYear}`;
};

const isBeforeValueDate = (dueDate, valueDate) => {
    if (dueDate == null || valueDate == null) return false;
    dueDate.setHours(0, 0, 0, 0);
    valueDate.setHours(0, 0, 0, 0);
    return dueDate.getTime() < valueDate.getTime();
};
export const DueDateField = ({
    fieldName = 'dueDate',
    fieldLabel = 'Fecha Vencimiento',
    control,
    errors,
    required = true,
    valueDate,
    allowBeforeValue = false, // If true, allow setting dueDate before valueDate
    ...rest
}) => {
    const validations = allowBeforeValue
        ? {}
        : {
              notBeforeValueDate: (v) => !isBeforeValueDate(v, valueDate),
          };
    const errorMessages = allowBeforeValue
        ? {}
        : {
              notBeforeValueDate: 'No puede ser anterior a la fecha valor',
          };

    return (
        <div className="field">
            <label htmlFor={fieldName} className={errorClassName(fieldName in errors)}>
                {fieldLabel}
                {required ? ' *' : null}
            </label>

            <Controller
                name={fieldName}
                control={control}
                rules={{
                    required: required,
                    validate: { ...validations },
                }}
                render={({ field, fieldState }) => (
                    <>
                        <Calendar
                            id={field.name}
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                            dateFormat="dd/mm/yy"
                            mask="99/99/9999"
                            showIcon
                            showOnFocus={false} // Datepicker will not be visible with input focus.
                            monthNavigator
                            yearNavigator
                            yearRange={getYearRange()}
                            className={invalidClassName(fieldState.error)}
                            {...rest}
                        />
                        <FieldErrorMessages
                            name={fieldName}
                            errors={errors}
                            customMessages={errorMessages}
                        />
                    </>
                )}
            />
        </div>
    );
};

DueDateField.displayName = 'DueDateField';
