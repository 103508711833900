import { useCallback, useEffect, useState } from 'react';

import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { RequestMessages } from '@components/RequestMessages';
import { Section } from '@components/Section';
import { RefreshButton } from '@components/buttons';
import { genericRequestErrors } from '@services/index';

import { CashRegisterService } from '@services/cashService';
import { CashRegisterAction } from './action';
import { CashRegisterControl, CashSessionsLink } from './control';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

const REGISTER_CONTROL_ACTIONS = new Set([
    // prettier:off
    CashRegisterAction.VIEW_OPENED,
    CashRegisterAction.VIEW_CLOSED,
    CashRegisterAction.OPEN,
    CashRegisterAction.CLOSE,
    CashRegisterAction.INCOMING_CASH,
    CashRegisterAction.OUTGOING_CASH,
]);

export const CashRegisterList = () => {
    const [items, setItems] = useState([]);
    const [service] = useState(new CashRegisterService());
    const [requestErrors, setRequestErrors] = useState();
    // const [searchParams, setSearchParams] = useState(defaultSearchParams);

    // Pagination & Lazy loading
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);

    // Effects ----------------------------------------------------------------

    const search = useCallback(() => {
        const handleError = (error) => {
            setRequestErrors(genericRequestErrors(error));
        };

        const params = {
            // ...searchParams,
            pageSize: rows,
            page: page + 1,
        };

        setRequestErrors(null);

        service
            .list(params)
            .then((response) => {
                const data = response.data;
                setItems(data.results);
                setTotalRecords(data.count ? data.count : 0);
            })
            .catch(handleError);
    }, [
        service,
        rows,
        page,
        // searchParams
    ]);

    useEffect(
        () => {
            setFirst(0);
            setPage(0);
        },
        [
            // searchParams
        ],
    );

    useEffect(() => {
        search();
    }, [search]);

    // Events ------------------------------------------------------------------

    const onPage = (event) => {
        setFirst(event.first);
        setPage(event.page);
        setRows(event.rows);
    };

    // Rendering --------------------------------------------------------------

    const cashRegisterTemplate = (register) => {
        return (
            <Card title={register.name} subTitle={<CashSessionsLink registerId={register.id} />}>
                <CashRegisterControl
                    registerId={register.id}
                    session={register?.lastSession}
                    isAuthorizedUser={register.isAuthorizedUser}
                    actionSet={REGISTER_CONTROL_ACTIONS}
                    onChanged={search}
                />
            </Card>
        );
    };

    const refreshAction = <RefreshButton onRefresh={search} style={{ float: 'right' }} />;

    // TODO: Replace DataTable with DataView
    // https://primereact.org/dataview/

    return (
        <Section title="Cajas Registradoras" actions={refreshAction}>
            <RequestMessages messages={requestErrors} />

            <DataTable
                value={items}
                dataKey="id"
                className="hide-columns-header cash-register-list"
                emptyMessage="No tiene cajas registradoras asignadas."
                lazy
                paginator={Boolean(totalRecords && totalRecords > MIN_PAGINATION_COUNT)}
                rows={rows}
                totalRecords={totalRecords}
                first={first}
                onPage={onPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            >
                <Column body={cashRegisterTemplate} />
            </DataTable>
        </Section>
    );
};
