import { Button } from 'primereact/button';

export const ExpandButtons = ({ onExpand, onCollapse }) => {
    return (
        <>
            <Button
                type="button"
                icon="pi pi-angle-double-down"
                className="p-button-rounded p-button-text"
                onClick={onExpand}
            />
            <Button
                type="button"
                icon="pi pi-angle-double-up"
                className="p-button-rounded p-button-text"
                onClick={onCollapse}
            />
        </>
    );
};
