import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Card } from 'primereact/card';

import { RefreshButton } from '@components/buttons';
import { RequestMessages } from '@components/RequestMessages';

import { mailToURL, whatsAppChatURL } from '@components/contacts';
import { computePersonFullName, personDocumentsDescription } from '@persons/templates';

import { genericRequestErrors } from '@services/index';
import { PersonService } from '@services/personService';

export const PersonSummary = ({ summaryRef }) => {
    const params = useParams();
    const [person, setPerson] = useState();
    const [service] = useState(new PersonService());
    const [requestErrors, setRequestErrors] = useState();

    // -------------------------------------------------------------------------
    // Effects
    // -------------------------------------------------------------------------

    const refresh = useCallback(() => {
        service
            .get(params.id)
            .then((response) => setPerson(response.data))
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    }, [params.id, service]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const onAspectChanged = (aspectName, newData) => {
        if (aspectName === 'details') {
            setPerson(newData);
        }
    };

    summaryRef.current.onAspectChanged = onAspectChanged;

    // -------------------------------------------------------------------------
    // Rendering
    // -------------------------------------------------------------------------

    const refreshAction = <RefreshButton onRefresh={refresh} style={{ float: 'right' }} />;
    const summaryTitle = (
        <>
            {computePersonFullName(person)} {refreshAction}
        </>
    );

    const personPhoneNumber = () => {
        const phoneNumber = person?.phoneNumber;
        const chatURL = whatsAppChatURL(phoneNumber);
        if (chatURL) {
            return (
                <div className="mb-3">
                    <a href={chatURL}>
                        <i className="pi pi-whatsapp mr-2" />
                        {phoneNumber}
                    </a>
                </div>
            );
        }
        return null;
    };

    const personEmail = () => {
        const email = person?.email;
        const mailURL = mailToURL(email);
        if (mailURL) {
            return (
                <div className="mb-3">
                    <a href={mailURL}>
                        <i className="pi pi-envelope mr-2" />
                        {email}
                    </a>
                </div>
            );
        }
        return null;
    };

    const documentDescription = () => {
        const description = personDocumentsDescription(person, true, false);
        return description != null ? <div className="mb-3">{description}</div> : null;
    };

    return (
        <div>
            <RequestMessages messages={requestErrors} />

            <Card title={summaryTitle}>
                {person && (
                    <>
                        {documentDescription()}
                        {personPhoneNumber()}
                        {personEmail()}
                    </>
                )}
            </Card>
        </div>
    );
};
