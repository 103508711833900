import { Calendar } from 'primereact/calendar';
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { FieldErrorMessages, errorClassName } from '../field-error-messages';
import { midnightStartTomorrow } from '../../utils/date-utils';

const MAX_PERSON_YEARS = 120;

const getYearRange = () => {
    const today = new Date();
    const endYear = today.getFullYear();
    const startyear = endYear - MAX_PERSON_YEARS;
    return `${startyear}:${endYear}`;
};

export const DOBField = ({ control, errors, required = false, ...rest }) => {
    // TODO: Change widget to input dob (or any dates actually) #94
    // Refs: https://designnotes.blog.gov.uk/2013/12/05/asking-for-a-date-of-birth/

    return (
        <div className="field">
            <label htmlFor="dob" className={errorClassName(errors.dob)}>
                Fecha de Nacimiento
            </label>

            <Controller
                name="dob"
                control={control}
                rules={{
                    required: required,
                    validate: {
                        notInFuture: (v) => {
                            return v < midnightStartTomorrow();
                        },
                    },
                }}
                render={({ field, fieldState }) => (
                    <>
                        <Calendar
                            id={field.name}
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                            dateFormat="dd/mm/yy"
                            mask="99/99/9999"
                            showIcon
                            showOnFocus={false} // Datepicker will not be visible with input focus.
                            monthNavigator
                            yearNavigator
                            yearRange={getYearRange()}
                            className={classNames({ 'p-invalid': fieldState.invalid })}
                            {...rest}
                        />
                        <FieldErrorMessages
                            name="dob"
                            errors={errors}
                            customMessages={{
                                notInFuture: 'No puede ser una fecha futura',
                            }}
                        />
                    </>
                )}
            />
        </div>
    );
};

DOBField.displayName = 'DOBField';
