import { useCallback, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { RequestMessages } from '@components/RequestMessages';
import { Section } from '@components/Section';
import { ButtonLink, RefreshButton } from '@components/buttons';
import { FieldValueGroup } from '@components/field-value';
import { genericRequestErrors } from '@services/index';

import { SelfUserService } from '@services/userService';
import { AccountAction } from './action';

export const AccountDashboard = ({ onAction }) => {
    const [user, setUser] = useState();
    const [service] = useState(new SelfUserService());
    const [requestMessages, setRequestMessages] = useState();

    // Callbacks --------------------------------------------------------------

    const refresh = useCallback(() => {
        setRequestMessages(null);
        service
            .getSelf()
            .then((response) => setUser(response.data))
            .catch((error) => setRequestMessages(genericRequestErrors(error)));
    }, [service]);

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        refresh();
    }, [refresh]);

    // Events -----------------------------------------------------------------

    const executeAction = (action) => {
        onAction && onAction(action);
    };

    // Rendering --------------------------------------------------------------

    const accountCards = user
        ? [
              {
                  label: 'Nombre y Apellido',
                  value: user.fullName,
                  // action: () => executeAction(AccountAction.CHANGE_NAME)
              },
              {
                  label: 'Nombre de Usuario',
                  value: user.username,
                  // action: () => executeAction(AccountAction.CHANGE_USERNAME)
              },
              // {
              //     label: 'E-mail',
              //     value: user.email,
              //     // action: () => executeAction(AccountAction.CHANGE_EMAIL)
              // },
              {
                  label: '',
                  value: (
                      <ButtonLink
                          label="Cambiar password"
                          onClick={() => executeAction(AccountAction.CHANGE_PASSWORD)}
                      />
                  ),
              },
              // {
              //     label: 'Sitio por defecto',
              //     value: user.default_site.name,
              //     // action: () => executeAction(AccountAction.CHANGE__DEFAULT_SITE)
              // },
          ]
        : null;

    const refreshAction = (
        <div>
            <RefreshButton onRefresh={refresh} style={{ float: 'right' }} />
            <br />
        </div>
    );

    return (
        <Section actions={refreshAction}>
            <RequestMessages messages={requestMessages} />
            <FieldValueGroup cards={accountCards} />
            <br />
            <br />
            <Link to="/sites">Mis sitios</Link>
        </Section>
    );
};
