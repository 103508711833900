import { MonthField, MonthFieldSelectorOptions } from '@components/fields';
import { toMonthYearString } from '@utils/date-utils';

export const SinceField = ({ control, required = true, ...rest }) => {
    const rules = {
        required: required,
    };

    return (
        <MonthField
            fieldName="since"
            fieldLabel="Desde"
            control={control}
            rules={rules}
            required={required}
            selectorOptions={MonthFieldSelectorOptions.SINCE}
            {...rest}
        />
    );
};
SinceField.displayName = 'SinceField';

export const UntilField = ({ control, required = false, since = null, ...rest }) => {
    const rules = {
        required: required,
        validate: {
            beforeSince: (until) => {
                if (since == null || until == null) return true;
                const sinceValue = new Date(since);
                return sinceValue <= until;
            },
        },
    };

    const customMessages = {
        beforeSince: `No puede ser anterior al mes desde: ${toMonthYearString(since)}`,
    };

    return (
        <MonthField
            fieldName="until"
            fieldLabel="Hasta"
            control={control}
            rules={rules}
            customMessages={customMessages}
            selectorOptions={MonthFieldSelectorOptions.UNTIL_OPENED_FUTURE}
            {...rest}
        />
    );
};
UntilField.displayName = 'UntilField';
