import { FundAccountList } from './FundAccountList';

export const FundAccountConfiguration = () => {
    return (
        <>
            FundAccountConfiguration
            <br />
            <FundAccountList />
        </>
    );
};
