import { useCallback, useEffect, useState } from 'react';

import { Link, generatePath, useParams } from 'react-router-dom';

import { Card } from 'primereact/card';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Fieldset } from 'primereact/fieldset';

import { RequestMessages } from '@components/RequestMessages';
import { PopupItemsButton, RefreshButton } from '@components/buttons';
import { PersonFormalNameLink } from '@persons/templates';
import { genericRequestErrors } from '@services/index';
import { isoLocalDateTimeTemplate, moneyOrEmpty } from '@utils/money';
import { StringUtils } from '@utils/string-utils';

import { CommissionsPath } from '@commissions/routes';
import { CommissionPledgeService } from '@services/commissionsService';
import { CommissionPledgeAction } from './actions';
import { ChangeComments } from './change-comments';

const PledgeHeader = ({ pledge }) => {
    if (!pledge) return null;

    const labelStyle = { width: '120px', fontWeight: 600 };

    const row = (label, value) => (
        <div className="grid">
            <div className="col-fixed" style={labelStyle}>
                {label}:
            </div>
            <div className="col flex align-items-center">{value}</div>
        </div>
    );

    const description = (
        <div>
            <Link
                to={generatePath(CommissionsPath.Commissions.item, {
                    id: pledge.commission.id,
                })}
            >
                {pledge.commission.name}
            </Link>
            <br />
            {pledge.commission.description}
            <br />
            <PersonFormalNameLink person={pledge.commission.person} />
        </div>
    );

    return (
        <Fieldset>
            {row('Persona', <PersonFormalNameLink person={pledge.person} />)}
            {row('Fecha', isoLocalDateTimeTemplate(pledge, 'pledgedOn'))}
            {row('Total', `$ ${moneyOrEmpty(pledge?.incomingTotal)}`)}
            {row(
                'Opción',
                StringUtils.joinNotEmpty([pledge.template.name, pledge.template.description]),
            )}
            {row('Descripción', description)}
            {row('Comentarios', pledge.comments)}
        </Fieldset>
    );
};

export const CommissionPledgeItem = () => {
    const params = useParams();
    const [action, setAction] = useState();
    const [service] = useState(new CommissionPledgeService());
    const [pledge, setPledge] = useState();
    const [requestErrors, setRequestErrors] = useState();

    // Callbacks --------------------------------------------------------------

    const read = useCallback(
        (pledgeId) => {
            setRequestErrors(null);
            service
                .get(pledgeId)
                .then((response) => setPledge(response.data))
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        },
        [service],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        read(params.id);
    }, [read, params.id]);

    // Events -----------------------------------------------------------------

    const refresh = () => read(params.id);

    const onUpdated = () => {
        setAction(null);
        refresh();
    };

    const onCancelUpdate = () => {
        setAction(null);
    };

    const confirmRevoke = () => {
        const accept = () => {
            service
                .revoke(params.id)
                .then(() => refresh())
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        };

        confirmDialog({
            icon: 'pi pi-exclamation-triangle',
            header: '¿Confirma anular compromiso?',
            message: 'Todas las cancelaciones asociadas serán anuladas.',
            defaultFocus: 'accept',
            acceptLabel: 'Confirmo anular compromiso',
            accept,
        });
    };

    // Rendering --------------------------------------------------------------

    const menuItems = [
        {
            label: 'Editar comentario',
            icon: 'pi pi-fw pi-pencil',
            disabled: pledge?.isRevoked,
            command: () => setAction(CommissionPledgeAction.CHANGE_COMMENTS),
        },
        { separator: true },
        {
            label: 'Anular',
            icon: 'pi pi-fw pi-times',
            disabled: pledge?.isRevoked,
            command: confirmRevoke,
        },
    ];

    const menuAction = <PopupItemsButton menuItems={menuItems} />;

    const refreshAction = <RefreshButton onRefresh={refresh} />;

    const revokedTitle = pledge && pledge.isRevoked ? ' [ANULADO]' : '';

    const title = (
        <div className="document-action-header">
            <div className="title">
                {menuAction}
                {`Compromiso de Pago${revokedTitle}: ${pledge?.commission?.name}`}
            </div>
            <div className="actions">
                <span className="spacer" />
                {refreshAction}
            </div>
        </div>
    );

    return (
        <Card title={title} className="commission-pledge-item">
            <ConfirmDialog />
            <RequestMessages messages={requestErrors} />
            <PledgeHeader pledge={pledge} />
            <ul>
                <li>Cobrado: $ {moneyOrEmpty(pledge?.incomingCanceled)}</li>
                <li>Saldo: $ {moneyOrEmpty(pledge?.incomingBalance)}</li>
            </ul>
            {/* TODO */}
            {/* <ul>
                    <li>Cobros (CommissionEntry)</li>
                    <li>Pagos (CommissionEntry)</li>
                </ul> */}
            {action === CommissionPledgeAction.CHANGE_COMMENTS && (
                <ChangeComments pledge={pledge} onSuccess={onUpdated} onCancel={onCancelUpdate} />
            )}
        </Card>
    );
};
