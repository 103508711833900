import { StringUtils } from '@utils/string-utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { useController } from 'react-hook-form';
import { FieldErrorMessages, invalidClassName } from '../field-error-messages';

export const Address = ({ address }) => {
    if (address == null || StringUtils.isEmpty(address?.value)) return null;
    return <div className="address-value">{address.value}</div>;
};

export const Addresses = ({ addresses }) => {
    if (addresses == null || addresses.length < 1) return null;
    return (
        <div className="addresses">
            {addresses.map((address, index) => (
                <Address key={index} address={address} />
            ))}
        </div>
    );
};

export const AddressField = ({
    // @prettier-off
    fieldName = 'value',
    fieldLabel = 'Dirección',
    control,
    errors,
    required = false,
    ...rest
}) => {
    const { field, fieldState } = useController({
        control: control,
        name: fieldName,
        rules: {
            required: required,
        },
    });

    return (
        <div className="field">
            {fieldLabel && (
                <label htmlFor={fieldName} className={invalidClassName(fieldState.error)}>
                    {fieldLabel}
                    {required ? ' *' : ''}
                </label>
            )}

            <InputTextarea
                id={field.name}
                value={field.value}
                name={field.name}
                autoComplete="off"
                onChange={(e) => field.onChange(e.target.value)}
                className={invalidClassName(fieldState.error)}
                rows={5}
                cols={30}
                {...rest}
            />
            <FieldErrorMessages name={fieldName} errors={errors} />
        </div>
    );
};

AddressField.displayName = 'AddressField';
