import { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { Link, generatePath, useHistory, useLocation } from 'react-router-dom';

import { Button } from 'primereact/button';

import {
    AmountField,
    DescriptionField,
    DueDateField,
    TransactionTypeField,
    ValueDateField,
} from '@components/fields';
import { ModalPathForm } from '@components/forms';
import { hookFormRequestError } from '@custom/hook-form';
import { useQuery } from '@custom/react-router';
import { TransactionType } from '@enums/accounting';
import { genericRequestErrors } from '@services/index';
import { toISOLocalDateString } from '@utils/date-utils';

import { AccountingPaths } from '@accounting/routes';
import { AccountingService } from '@services/accounting';
import { PersonDetail, PersonService } from '@services/personService';

export const CommercialTransactionCreateButton = ({
    // @prettier-off
    personId,
    comeBack = true, // If true, onSuccess or onCancel return to the calling view
}) => {
    // https://stackoverflow.com/a/70624565
    const location = useLocation();
    return (
        <Link
            to={{
                pathname: AccountingPaths.Commercial.create,
                search: `?person=${personId}`,
                state: { from: comeBack ? location : null },
            }}
        >
            <Button label="Nuevo" aria-label="Nuevo movimiento" icon="pi pi-plus" />
        </Link>
    );
};

const CLIENT_OPTIONS = [
    // prettier:off
    TransactionType.REVENUE,
    TransactionType.REVENUE_DISCOUNT,
];

const VENDOR_OPTIONS = [
    // prettier:off
    TransactionType.EXPENSE,
    TransactionType.EXPENSE_DISCOUNT,
];

const TYPE_OPTIONS = [
    {
        label: 'CLIENTE',
        code: 'CLIENT',
        items: CLIENT_OPTIONS,
    },
    {
        label: 'PROVEEDOR',
        code: 'VENDOR',
        items: VENDOR_OPTIONS,
    },
];

// const ITEM_TYPE_BULL = '- ';
const ITEM_TYPE_BULL = '';
const GROUP_TYPE_SEP = ' >> ';

const typeOptionTemplate = (option) => {
    return `${ITEM_TYPE_BULL}${option.label}`;
};

const selectedTypeOptionTemplate = (option, props) => {
    if (option == null) return <div>{props.placeholder}</div>;

    if (CLIENT_OPTIONS.includes(option)) {
        return (
            <div>
                <span className="font-bold">CLIENTE</span>
                {GROUP_TYPE_SEP}
                {option.label}
            </div>
        );
    }
    if (VENDOR_OPTIONS.includes(option)) {
        return (
            <div>
                <span className="font-bold">PROVEEDOR</span>
                {GROUP_TYPE_SEP}
                {option.label}
            </div>
        );
    }
    return <div>{option.label}</div>;
};

const initData = {
    typeId: null,
    personId: null,
    valueDate: null,
    description: '',
    amount: '',
};

const DETAIL_FIELDS = ['typeId', 'valueDate', 'dueDate', 'description', 'amount'];

export const CommercialTransactionCreate = () => {
    const from = useLocation().state?.from;
    const query = useQuery();
    const history = useHistory();
    const personId = query.get('person');
    const [person, setPerson] = useState();
    const [service] = useState(new AccountingService());
    const [personService] = useState(new PersonService());
    const [requestErrors, setRequestErrors] = useState([]);
    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
        watch,
    } = useForm({
        defaultValues: {
            ...initData,
            personId: personId,
            valueDate: new Date(),
            dueDate: new Date(),
        },
    });

    const valueDate = watch('valueDate');

    useEffect(() => {
        setPerson(null);
        setRequestErrors(null);
        personService
            .get(personId, PersonDetail.IDENTITY)
            .then((response) => setPerson(response.data))
            .catch((error) => {
                console.log(error.response);
                if (error?.response?.status === 404) {
                    setRequestErrors([
                        {
                            severity: 'error',
                            sticky: true,
                            closable: false, // User can check connection and retry
                            content: (
                                <div>
                                    <h5>Persona no encontrada</h5>
                                    <p>
                                        Utilice los menús del sistema para generar un nuevo
                                        movimiento en la cuenta corriente de una persona.
                                    </p>
                                    <Link to="/persons">Buscar personas</Link>
                                </div>
                            ),
                        },
                    ]);
                } else {
                    setRequestErrors(genericRequestErrors(error));
                }
            });
    }, [personService, personId]);

    const onExecute = (data) => {
        clearErrors();
        setRequestErrors(null);
        const request = {
            typeId: data.typeId,
            personId: data.personId,
            valueDate: toISOLocalDateString(data.valueDate),
            dueDate: toISOLocalDateString(data.dueDate),
            description: data.description,
            amount: data.amount,
        };
        service
            .createCommercialTransaction(request)
            .then((response) => {
                const nextStep =
                    from ||
                    generatePath(AccountingPaths.Transaction.item, { id: response.data.id });
                history.replace(nextStep);
            })
            .catch((error) =>
                hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors),
            );
    };

    return (
        <ModalPathForm
            // TODO: Add link to profile as subtitle
            // TODO: Add Person best id's (DNI, CUIT, etc)
            title={person ? person.formalName : 'Nuevo movimiento'}
            onSubmit={handleSubmit(onExecute)}
            requestErrors={requestErrors}
            executeButtonsProps={{
                executeLabel: 'Crear Movimiento',
                cancelLabel: 'Volver sin crear',
            }}
            style={{ maxWidth: '400px', position: 'relative', margin: '0 auto' }}
        >
            <div className="p-fluid">
                <TransactionTypeField
                    fieldLabel="Tipo"
                    control={control}
                    errors={errors}
                    options={TYPE_OPTIONS}
                    autoFocus
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    itemTemplate={typeOptionTemplate}
                    valueTemplate={selectedTypeOptionTemplate}
                />

                <ValueDateField control={control} errors={errors} />

                <DueDateField control={control} errors={errors} valueDate={valueDate} />

                <DescriptionField control={control} errors={errors} />

                <AmountField control={control} errors={errors} />
            </div>
        </ModalPathForm>
    );
};
