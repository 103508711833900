import { useState } from 'react';

import { useForm } from 'react-hook-form';

import { FirstNameField, LastNameField } from '@components/fields';
import { ModalForm } from '@components/forms';

import { genericRequestErrors } from '@services/index';
import { PersonService } from '@services/personService';

export const ChangeName = ({ person, onSuccess, onCancel }) => {
    const [service] = useState(new PersonService());
    const [requestErrors, setRequestErrors] = useState([]);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstName: person.firstName,
            lastName: person.lastName,
        },
    });

    const onExecute = (data) => {
        setRequestErrors(null);
        service
            .update(person.id, data)
            .then((response) => onSuccess && onSuccess(response.data))
            .catch(handleRequestError);
    };

    const handleRequestError = (error) => {
        setRequestErrors(genericRequestErrors(error));
    };

    return (
        <ModalForm
            onSubmit={handleSubmit(onExecute)}
            title="Modificar nombre y apellido"
            buttons={{ executeLabel: 'Modificar' }}
            onCancel={onCancel}
            style={{ width: '400px' }}
            requestErrors={requestErrors}
        >
            <div className="p-fluid">
                <FirstNameField register={register} errors={errors} autoFocus />
                <LastNameField register={register} errors={errors} />
            </div>
        </ModalForm>
    );
};
