import { useCallback, useEffect, useState } from 'react';

import { generatePath, useHistory } from 'react-router';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { userDescription } from '@account/templates';
import { revokedClassName } from '@accounting/templates';
import { RequestMessages } from '@components/RequestMessages';
import { PersonFormalNameLink } from '@persons/templates';
import { genericRequestErrors } from '@services/index';
import { isoLocalDateTimeTemplate, revocableMoneyTemplate } from '@utils/money';
import { appClickOpenPush } from '@utils/nav-utils';

import { AccountingPaths } from '@accounting/routes';
import { CashEntryService } from '@services/cashService';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

export const CashEntryList = ({ session }) => {
    // State
    const history = useHistory();
    const [items, setItems] = useState();
    const [service] = useState(new CashEntryService());
    const [requestErrors, setRequestErrors] = useState([]);
    // const [searchParams, setSearchParams] = useState(defaultSearchParams);

    // Pagination & Lazy loading
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);

    // Effects & Callbacks -----------------------------------------------------

    const search = useCallback(
        ({ rows, page }) => {
            if (session == null) return;

            const request = {
                sessionId: session.id,
                // ...searchParams,
                pageSize: rows,
                page: page + 1,
            };

            setRequestErrors(null);

            service
                .search(request)
                .then((response) => {
                    const data = response.data;
                    setItems(data.results);
                    setTotalRecords(data.count ? data.count : 0);
                })
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        },
        [service, session],
    );

    useEffect(() => {
        search({ rows, page });
    }, [
        search,
        rows,
        page,
        // searchParams
    ]);

    // Events & Actions -------------------------------------------------------

    const rowClick = ({ data, originalEvent }) => {
        const url = generatePath(AccountingPaths.Transaction.item, { id: data.id });
        appClickOpenPush(url, originalEvent, history);
    };

    const onPage = (event) => {
        setFirst(event.first);
        setPage(event.page);
        setRows(event.rows);
    };

    // Rendering --------------------------------------------------------------

    const descriptionTemplate = (entry) => {
        return (
            <>
                <div className="font-bold">
                    {entry.type.label}
                    {entry.isRevoked ? ' | ANULADO' : ''}
                </div>
                {entry?.person && (
                    <div>
                        <PersonFormalNameLink person={entry.person} />
                    </div>
                )}
                {entry?.user && <div>{userDescription(entry.user)}</div>}
                {entry?.description && <div>{entry.description}</div>}
                <div>{isoLocalDateTimeTemplate(entry, 'issuedTime', false)}</div>
            </>
        );
    };

    return (
        <>
            <RequestMessages messages={requestErrors} />

            <DataTable
                value={items}
                dataKey="id"
                emptyMessage="Sesión de caja sin movimientos"
                lazy
                // --------------------------------------------------------------------------
                selectionMode="single"
                onRowClick={rowClick}
                rowClassName={revokedClassName}
                // --------------------------------------------------------------------------
                paginator={Boolean(totalRecords && totalRecords > MIN_PAGINATION_COUNT)}
                rows={rows}
                first={first}
                onPage={onPage}
                totalRecords={totalRecords}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            >
                <Column
                    className="table-column-left"
                    header="Transacción"
                    body={descriptionTemplate}
                />

                <Column
                    className="table-column-money"
                    header="Importe"
                    body={(entry) => revocableMoneyTemplate(entry, 'flowAmount')}
                />
            </DataTable>
        </>
    );
};
