import { Link } from 'react-router-dom';

import { formattedCUIT } from '@components/fields';

// TODO: Create an enum with PersonAspect

export const computePersonFullName = (person) =>
    person ? `${person.firstName ? `${person.firstName} ` : ''}${person.lastName} ` : null;

export const computePersonFormalName = (person) =>
    person ? `${person.lastName}${person.firstName ? `, ${person.firstName}` : ''}` : null;

export const personAccountName = (person) => `${computePersonFormalName(person)} [${person.id}]`;

export const PersonFormalNameLink = ({ person, emptyValue = null, aspect = null }) => {
    if (person == null) return emptyValue;
    const url = `/persons/${person.id}${aspect != null ? `/${aspect}` : ''}`;
    return <Link to={url}>{person?.formalName}</Link>;
};
export const PersonAccountLink = ({ person }) => (
    <Link to={`/persons/${person.id}`}>{personAccountName(person)}</Link>
);

export const personFormalNameLink = (person, emptyValue = null) => (
    <PersonFormalNameLink person={person} emptyValue={emptyValue} />
);

export const personDocumentsDescription = (person, stacked = true, bestDocumentOnly = false) => {
    const dniDescription = () => `DNI: ${person.dni}`;
    const cuitDescription = () => `CUIT: ${formattedCUIT(person.cuit)}`;

    const bestDocumentDescription = () => {
        if (person?.cuit) return <>{cuitDescription()}</>;
        if (person?.dni) return <>{dniDescription()}</>;
        return null;
    };

    const allDocumentsDescription = () => {
        if (person?.dni && person?.cuit) {
            return stacked ? (
                <>
                    <div>{dniDescription()}</div>
                    <div>{cuitDescription()}</div>
                </>
            ) : (
                <>{`${dniDescription()} - ${cuitDescription()}`}</>
            );
        }
        return bestDocumentDescription(person);
    };

    if (!person) return null;

    return bestDocumentOnly ? bestDocumentDescription() : allDocumentsDescription();
};

export const personIdentityDescription = ({
    person,
    emptyValue = null,
    stacked = true,
    bestDocumentOnly = false,
}) => {
    if (!person) return emptyValue;

    const nameData = person.formalName;
    const documents = personDocumentsDescription(person, stacked, bestDocumentOnly);

    return (
        <>
            {nameData}
            {documents && stacked ? (
                <div>{documents}</div>
            ) : (
                <span className="ml-2">{documents}</span>
            )}
        </>
    );
};

export const PersonIdentityLink = ({
    person,
    emptyValue = null,
    stacked = true,
    bestDocumentOnly = false,
}) => {
    if (!person) return emptyValue;

    const nameLink = personFormalNameLink(person, emptyValue);
    const documents = personDocumentsDescription(person, stacked, bestDocumentOnly);

    return (
        <>
            {nameLink}
            {documents && stacked ? (
                <div>{documents}</div>
            ) : (
                <span className="ml-2">{documents}</span>
            )}
        </>
    );
};
