import { useEffect, useState } from 'react';

import { useController } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';

import { errorClassName, FieldError, invalidClassName } from '@components/field-error-messages';

import { TokenService } from '@services/tokenService';

export const TokenTypeField = ({
    control,
    fieldName = 'typeId',
    fieldLabel = 'Tipo',
    required = true,
    onFieldRequestError,
    ...rest
}) => {
    const [options, setOptions] = useState([]);
    const [service] = useState(new TokenService());

    const { field, fieldState } = useController({
        control: control,
        name: fieldName,
        rules: {
            required: required,
        },
    });

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        service
            .types({})
            .then((response) => {
                setOptions(response?.data?.results);
            })
            .catch((error) => onFieldRequestError && onFieldRequestError(fieldName, error));
    }, [service, fieldName, onFieldRequestError]);

    // Render -----------------------------------------------------------------

    return (
        <div className="field">
            <label htmlFor={field.name} className={errorClassName(fieldState.error)}>
                {fieldLabel}
                {required ? ' *' : ''}
            </label>

            <Dropdown
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                options={options}
                optionValue="id"
                optionLabel="name"
                placeholder="Seleccione"
                className={invalidClassName(fieldState.error)}
                {...rest}
            />
            <FieldError fieldError={fieldState.error} />
        </div>
    );
};

TokenTypeField.displayName = 'TokenTypeField';
