import { processRequestError } from '../../service';

/** Utility to process API request errors using react-hook-form
 *
 * @param {*} error : Caught error on a failed API request
 * @param {*} detailFields : Field names whose errors are to be displayed by the registered input component
 * @param {*} setError : React-hook-form callback obtained from useForm hook
 * @param {*} setGlobalErrors : Setter of global generic error messages (those not matched as detail fields)
 */

export const setDetailErrors = (detailErrors = [], setError) => {
    for (const [key, value] of Object.entries(detailErrors)) {
        // console.log(`key=${key} >> value`);
        // console.log(value);
        setError(key, { messages: value }, { shouldFocus: true });
    }
};

export const hookFormRequestError = (error, detailFields = null, setError, setGlobalErrors) => {
    // Add custom fields for testing purposes:
    // error.response.data.otherError = 'Other global error';
    // error.response.data.[$KNOWN_DETAIL_FIELD'].push('Another detail error');

    // console.log('hookFormRequestError: error?.response?.data');
    // console.log(error?.response?.data);
    // console.log(detailFields);

    const { detailErrors, globalErrorMessages } = processRequestError(error, detailFields);

    // console.log('hookFormRequestError: detailErrors');
    // console.log(detailErrors);

    setDetailErrors(detailErrors, setError);
    setGlobalErrors(globalErrorMessages);
};
