import suma from '@apis/Suma';

import { StringUtils } from '@utils/string-utils';
import { paginationParams, paramsString } from '.';

const APP_PATH = 'accounting';

// TODO: Consider implementing DRF-FlexFields
// const TRANSACTION = {
//     expand: [
//         'type.id',
//         'type.label',
//         'person.id',
//         'person.fullName',
//         'person.dni',
//         'receipt.id',
//     ],
//     omit: [
//         'site',
//     ]
// }

export class AccountingService {
    transaction(transactionId) {
        return suma.get(`${APP_PATH}/transactions/${transactionId}/`);
    }

    revokeTransaction(transactionId) {
        return suma.post(`${APP_PATH}/transactions/${transactionId}/revoke/`);
    }

    transactionPDF(transactionId) {
        return {
            url: `${APP_PATH}/transactions/${transactionId}/pdf/`,
            altFilename: `Transacción ${transactionId}.pdf`,
        };
    }

    userTransactionsBalance({ userId, page, pageSize }) {
        const queryParams = paginationParams(pageSize, page);
        return suma.get(
            `${APP_PATH}/user-transactions-balance/${userId}/${paramsString(queryParams)}`,
        );
    }

    siteTransactionsBalance({ personId, page, pageSize }) {
        const queryParams = paginationParams(pageSize, page);
        return suma.get(
            `${APP_PATH}/site-transactions-balance/${personId}/${paramsString(queryParams)}`,
        );
    }

    siteTransactionsDueSummary(personId) {
        return suma.get(`${APP_PATH}/site-transactions/due-summaries/${personId}/`);
    }

    siteTransactionsDueSummaryPDF(personId) {
        return {
            url: `${APP_PATH}/site-transactions/due-summaries/${personId}/pdf/`,
            altFilename: `Estado Deuda ${personId}.pdf`,
        };
    }

    issueReceipt(request) {
        return suma.post(`/${APP_PATH}/receipts/`, request);
    }

    receipt(receiptId) {
        return suma.get(`${APP_PATH}/receipts/${receiptId}/`);
    }

    revokeReceipt(receiptId) {
        return suma.post(`${APP_PATH}/receipts/${receiptId}/revoke/`);
    }

    receipts({
        // prettier:off
        persons,
        comment,
        pageSize,
        page,
    }) {
        const queryParams = paginationParams(pageSize, page);

        if (Array.isArray(persons)) {
            queryParams.push(...persons.map((person) => `person=${person.id}`));
        }

        if (!StringUtils.isEmpty(comment)) {
            queryParams.push(`comment=${comment.trim()}`);
        }

        return suma.get(`${APP_PATH}/receipts/${paramsString(queryParams)}`);
    }

    receiptUpdate(receiptId, data) {
        return suma.patch(`${APP_PATH}/receipts/${receiptId}/`, data);
    }

    receiptPDF(receiptId) {
        return {
            url: `${APP_PATH}/receipts/${receiptId}/pdf/`,
            altFilename: `Recibo ${receiptId}.pdf`,
        };
    }

    createCommercialTransaction(request) {
        return suma.post(`/${APP_PATH}/commercial-transactions/`, request);
    }

    paymentModes(personId) {
        if (personId == null) {
            return Promise.resolve(null);
        }
        return suma.get(`${APP_PATH}/payment-modes/?person=${personId}/`);
    }

    // Reports -----------------------------------------------------------------

    getAccountingReport = (request) => {
        return suma.post(`${APP_PATH}/reports/`, request);
    };
}

export const accountingService = new AccountingService();
