import { useCallback, useEffect, useState } from 'react';

import { RefreshButton } from '@components/buttons';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { genericRequestErrors } from '@services/index';

import { PersonFormalNameLink } from '@persons/templates';
import { ResponsibilityService } from '@services/responsibilityService';
import { ResponsibilityAction } from './ResponsibilityAction';
import { ResponsibilityDelete } from './ResponsibilityDelete';
import { ResponsibilityEditor } from './ResponsibilityEditor';

// TODO: Data table requires data initially sorted. See if this is needed.
// const sortResponsibilities = (first, second) => {
//     if (first.isResponsible === second.isResponsible) {
//         return first.since > second.since;
//     }
//     if (first.isResponsible) {
//         return 1;
//     }
//     return -1;
// };

export const ResponsibilityList = ({ personId, onRequestError }) => {
    const [service] = useState(new ResponsibilityService());
    const [action, setAction] = useState();
    const [responsibility, setResponsibility] = useState();
    const [responsibilities, setResponsibilities] = useState();

    // Callbacks & Effects ----------------------------------------------------

    const loadResponsibilities = useCallback(() => {
        const setData = (data) => {
            setResponsibilities(
                data.map((item) => ({
                    ...item,
                    isResponsible: item.responsible.id === personId,
                })),
            );
        };

        service
            .search({ personId: personId, active: true })
            .then((response) => setData(response.data['results']))
            .catch((error) => onRequestError && onRequestError(genericRequestErrors(error)));
    }, [personId, onRequestError, service]);

    useEffect(() => {
        loadResponsibilities();
    }, [loadResponsibilities]);

    // Events -----------------------------------------------------------------

    const onCreatedResponsibility = (responsibility) => {
        const newList = [...responsibilities];
        const newItem = {
            ...responsibility,
            isResponsible: responsibility.responsible.id === personId,
        };
        newList.push(newItem);
        setResponsibilities(newList);
        onCanceledAction();
    };

    const onUpdatedResponsibility = (responsibility) => {
        const newList = [...responsibilities];
        const itemIndex = newList.findIndex((item) => item.id === responsibility.id);
        responsibility.isResponsible = responsibility.responsible.id === personId;
        newList[itemIndex] = responsibility;
        setResponsibilities(newList);
        onCanceledAction();
    };

    const onDeletedResponsibility = (responsibility) => {
        const newList = responsibilities.filter((item) => item.id !== responsibility.id);
        setResponsibilities(newList);
        onCanceledAction();
    };

    const onCanceledAction = () => {
        setAction(null);
        setResponsibility(null);
    };

    // Render -----------------------------------------------------------------

    const addResponsibleOf = (
        <Button
            icon="pi pi-plus"
            label="Beneficiario"
            aria-label="Agregar beneficiario"
            className="p-button-rounded p-button-success"
            onClick={() => setAction(ResponsibilityAction.CREATE_RESPONSIBLE_OF)}
        />
    );

    const addBeneficiaryOf = (
        <Button
            icon="pi pi-plus"
            label="Responsable"
            aria-label="Agregar responsable"
            className="p-button-rounded p-button-success"
            onClick={() => setAction(ResponsibilityAction.CREATE_BENEFICIARY_OF)}
        />
    );

    const header = (
        <div className="split-table-header">
            <div>
                {addBeneficiaryOf} {addResponsibleOf}
            </div>

            <RefreshButton onRefresh={loadResponsibilities} className="hide-constrained-400" />
        </div>
    );

    const renderAction = () => {
        if (!action) return null;

        if (action === ResponsibilityAction.DELETE) {
            return (
                <ResponsibilityDelete
                    responsibility={responsibility}
                    onCancel={onCanceledAction}
                    onSuccess={() => onDeletedResponsibility(responsibility)}
                />
            );
        }

        if (action === ResponsibilityAction.UPDATE) {
            return (
                <ResponsibilityEditor
                    personId={personId}
                    isCreate={false}
                    responsibility={responsibility}
                    isResponsible={responsibility.isResponsible}
                    onCancel={onCanceledAction}
                    onSuccess={onUpdatedResponsibility}
                />
            );
        }

        return (
            <ResponsibilityEditor
                personId={personId}
                isCreate={true}
                isResponsible={action === ResponsibilityAction.CREATE_RESPONSIBLE_OF}
                onCancel={onCanceledAction}
                onSuccess={onCreatedResponsibility}
            />
        );
    };

    const relationBodyTemplate = (responsibility) => (
        <h4>{responsibility.isResponsible ? 'Beneficiarios' : 'Responsables'}</h4>
    );

    const personBodyTemplate = (responsibility) => {
        const person = responsibility.isResponsible
            ? responsibility.beneficiary
            : responsibility.responsible;
        const roles = `${responsibility.responsibleRole} >> ${responsibility.beneficiaryRole}`;
        return (
            <>
                <PersonFormalNameLink person={person} />
                <br />
                {`(${roles})`}
            </>
        );
    };

    const actionBodyTemplate = (responsibility) => (
        <>
            <Button
                icon="pi pi-pencil"
                className="p-button-rounded p-button-primary p-button-text"
                aria-label="Editar relación"
                onClick={() => {
                    setResponsibility(responsibility);
                    setAction(ResponsibilityAction.UPDATE);
                }}
            />
            <Button
                icon="pi pi-times"
                className="p-button-rounded p-button-danger p-button-text"
                aria-label="Eliminar relación"
                onClick={() => {
                    setResponsibility(responsibility);
                    setAction(ResponsibilityAction.DELETE);
                }}
            />
        </>
    );

    return (
        <div>
            <DataTable
                header={header}
                value={responsibilities}
                rowGroupMode="rowspan"
                sortField="isResponsible"
                groupRowsBy="isResponsible"
                sortMode="single"
                sortOrder={1}
                responsiveLayout="scroll"
            >
                <Column field="isResponsible" header="Relación" body={relationBodyTemplate} />
                <Column header="Persona" body={personBodyTemplate} />
                <Column body={actionBodyTemplate} style={{ width: '100px' }} />
            </DataTable>

            {renderAction()}
        </div>
    );
};
