import { GLOBAL_ERRORS_KEY } from '@apis/Suma';
import {
    formatMessageContent,
    formatMessageHeaderDetailDict,
    formatMessageHeaderDetailList,
} from '@custom/primereact/utils';
import { MIMEType } from '@enums/mime-type';

// -----------------------------------------------------------------------------
// Error handling routines
// -----------------------------------------------------------------------------

// ***** DEPRECATED! **** Use genericRequestErrors (plural) instead

export const genericRequestError = (error) => {
    // console.log('genericRequestError')
    // console.log(error)
    if (error.response) {
        return formatMessageHeaderDetailDict(
            {
                severity: 'error',
                summary: `${error.response.status} - ${error.response.statusText}`,
                sticky: true,
                closable: false, // User must resubmit for errors to change
            },
            error.response.status === 400 ? error.response.data : [],
        );
    }
    return {
        severity: 'warn',
        summary: 'Verifique conexión a internet',
        sticky: true,
        closable: true, // User can check connection and retry
    };
};

// Consider deprecating in favour or processRequestError

export const genericRequestErrors = (error) => {
    const { globalErrorMessages } = processRequestError(error);
    return globalErrorMessages;
};

export const NETWORK_ERROR = {
    severity: 'warn',
    summary: 'Verifique conexión a internet',
    sticky: true,
    closable: false, // User can check connection and retry
};

const RESPONSE_ERROR = {
    severity: 'error',
    sticky: true,
    closable: true, // User can check connection and retry
};

/**
    Errors might come in three formats:

    [1] List of error messages (an Array) -----------------

    Input:
        errors = ['error_1', ..., 'error_n']
    
    Output:
        * Summary: error_1
        * ...
        * Summary: error_n

    
    [2] Dict (object) of error messages -------------------

    Input: 
        errors = {
            field_1: ['error_1.1', 'error_1.n'],
            ...
            field_n: ['error_n.1', 'error_n.n'],    
        }

    Output:
        * Summary: field_1
            * error_1.1
            * ...
            * error_1.n
        * ...
        * Summary: field_n
            * error_n.1
            * ...
            * error_n.n


    [3] Dict (object) with List of dict (object) of error messages (List)

    {
        "transactionsRequests": [
            {
                "description": [
                    "Este campo no puede ser nulo."
                ]
            }
        ]
    }

    Input:
        errors = {
            field_1: [
                {field_1_1: ['error_1_1.1', 'error_1_1.n']},
                ...
                {field_1_n: ['error_1_n.1', 'error_1_n.n']},
            ]
            ...
            field_n: [
                {field_n_1: ['error_n_1.1', 'error_n_1.n']},
                ...
                {field_n_n: ['error_n_n.1', 'error_n_n.n']},
            ]
        }

    Output:
        * Summary: field_1: field_1_1
            * error_1.1.1
            * ...
            * error_1.1.n
        * Summary: field_1: field_1_n
            * error_1.n.1
            * ...
            * error_1.n.n
*/

export const genericErrorMessages = (errors) => {
    const messages = [];

    const processList = (list, fieldSummary = null) => {
        const stringList = list.every((item) => typeof item === 'string');

        if (stringList) {
            fieldSummary == null
                ? list.forEach((item, i) => {
                      messages.push(
                          formatMessageContent({
                              severity: 'error',
                              summary: item,
                              sticky: true,
                              closable: true, // User might close some errors
                          }),
                      );
                  })
                : messages.push(
                      formatMessageHeaderDetailList(
                          {
                              severity: 'error',
                              summary: fieldSummary,
                              sticky: true,
                              closable: true, // User might close some errors
                          },
                          list,
                      ),
                  );
        } else {
            list.forEach((item) => {
                processDictionary(item, fieldSummary);
            });
        }
    };

    const processDictionary = (dictionary, fieldSummary = null) => {
        Object.entries(dictionary).forEach(([field, fieldDetail]) => {
            const fieldName = field === GLOBAL_ERRORS_KEY ? 'Error Global' : field;
            const thisFieldSummary = `${fieldSummary ? `${fieldSummary}: ` : ''}${fieldName}`;

            if (Array.isArray(fieldDetail)) {
                processList(fieldDetail, thisFieldSummary);
            } else {
                messages.push(
                    formatMessageHeaderDetailList(
                        {
                            severity: 'error',
                            summary: thisFieldSummary,
                            sticky: true,
                            closable: true, // User might close some errors
                        },
                        fieldDetail,
                    ),
                );
            }
        });
    };

    Array.isArray(errors) ? processList(errors, null) : processDictionary(errors, null);

    return messages;
};

const genericErrorMessage = (status, statusText) => {
    return {
        severity: 'error',
        summary: `${status} - ${statusText}`,
        sticky: true,
        closable: true, // Errors cleared before request and recreated after (if persisting)
        // closable: false, // User must resubmit for errors to change
    };
};

export const splitDetailGlobalErrors = (data = {}, detailFields = null) => {
    let globalErrors = {};
    const detailErrors = {};

    if (detailFields && detailFields.length) {
        for (const [key, value] of Object.entries(data)) {
            detailFields.includes(key) ? (detailErrors[key] = value) : (globalErrors[key] = value);
        }
    } else {
        globalErrors = data;
    }

    return { detailErrors, globalErrors };
};

export const isNetworkError = (error) => error?.message === 'Network Error';

export const processRequestError = (error, detailFields = null) => {
    // TODO: This method will produce errors in renderers when
    // not flattened in the form of key:value pairs, e.g.:
    // {"paymentTransactions":[{"amount":["Este campo no puede ser nulo."]}]}

    const detailErrors = {};

    // console.log(error.toJSON());

    if (error.message === 'Network Error') {
        return { detailErrors, globalErrorMessages: [NETWORK_ERROR] };
    }

    const { response } = error;

    // NOTE: That if response is null here, it does not actually mean that the
    // server returned nothing. The error might have been raised by functions
    // processing the response, i.e. a webapp client programming error, like
    // expecting some data key's to be present when it is not.

    if (response == null) {
        console.log(error);
        return {
            detailErrors,
            globalErrorMessages: [
                {
                    ...RESPONSE_ERROR,
                    summary: error.message,
                },
            ],
        };
    }

    const { data, status, statusText, headers } = response;

    // TODO: Handle 404 errors differently? E.g. navigate to "not-found"

    const contentType = headers && headers['content-type'];

    if (data && contentType === MIMEType.JSON) {
        const { detailErrors, globalErrors } = splitDetailGlobalErrors(data, detailFields);

        const globalErrorMessages = genericErrorMessages(globalErrors);

        return { detailErrors, globalErrorMessages };
    }

    // TODO: Handle 502 and 505 errors differently?
    //       User should wait and we should notify
    //       some service to restart our servers.

    return { detailErrors, globalErrorMessages: [genericErrorMessage(status, statusText)] };
};

// -----------------------------------------------------------------------------
// Params utils
// -----------------------------------------------------------------------------

export function paginationParams(pageSize, page) {
    const params = [];
    if (pageSize) {
        params.push(`page-size=${pageSize}`);
    }
    if (page) {
        params.push(`page=${page}`);
    }
    return params;
}

export function paramsString(params = []) {
    return params.length ? '?'.concat(params.join('&')) : '';
}

// Return all items: First page with 999.999.999 items per page
export const pseudoNoPaginationParams = 'page-size=999999999&page=1';
