import { Card } from 'primereact/card';

import { Link, generatePath } from 'react-router-dom';

import { RefreshButton } from '@components/buttons';
import { PersonFormalNameLink } from '@persons/templates';

import { CommissionsPath } from '@commissions/routes';

export const CommissionHeader = ({ commission, onRefresh, children, selfLink = false }) => {
    const title = (
        <>
            {commission?.name}
            {onRefresh && <RefreshButton onRefresh={onRefresh} style={{ float: 'right' }} />}
        </>
    );

    const subTitle = (
        <>
            <div>
                {commission?.description}
                {selfLink && commission?.id && (
                    <>
                        {' | '}
                        <Link
                            to={generatePath(CommissionsPath.Commissions.item, {
                                id: commission?.id,
                            })}
                        >
                            Detalles
                        </Link>
                    </>
                )}
            </div>
            <PersonFormalNameLink person={commission?.person} />
        </>
    );
    return (
        <Card title={title} subTitle={subTitle}>
            {children}
        </Card>
    );
};
