import { useEffect, useRef, useState } from 'react';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';

import { ExecuteCancelButtons } from '@components/ExecuteCancelButtons';
import { equalSets } from '@utils/iterable-utils';

import { CanceledStatus } from '@enums/CanceledStatus';

export const DEFAULT_STATUS_FILTER = {
    allStatus: true,
    onlyRevoked: false,
    reconciledStatus: null,
    canceledStatus: new Set(),
};

const FundEntryStatusFilterInput = ({ filter, onApply }) => {
    const [isChanged, setIsChanged] = useState(false);
    const [allStatus, setAllStatus] = useState(filter.allStatus);
    const [onlyRevoked, setOnlyRevoked] = useState(filter.onlyRevoked);
    const [reconciledStatus, setReconciledStatus] = useState(filter.reconciledStatus);
    const [canceledStatus, setCanceledStatus] = useState(filter.canceledStatus);

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        const newAllStatus =
            onlyRevoked === false && reconciledStatus == null && canceledStatus.size === 0;
        setAllStatus(newAllStatus);
    }, [onlyRevoked, reconciledStatus, canceledStatus]);

    useEffect(() => {
        const newIsChanged =
            onlyRevoked !== filter.onlyRevoked ||
            reconciledStatus !== filter.reconciledStatus ||
            !equalSets(canceledStatus, filter.canceledStatus);
        setIsChanged(newIsChanged);
    }, [filter, allStatus, onlyRevoked, reconciledStatus, canceledStatus]);

    // Setters ----------------------------------------------------------------

    const changeAllStatus = (value) => {
        // if value, clear all other options
        if (value) {
            setOnlyRevoked(false);
            setReconciledStatus(null);
            setCanceledStatus(new Set());
        }
        setAllStatus(value);
    };

    const changeOnlyRevoked = (value) => {
        if (value) {
            // if value, clear all other options
            setAllStatus(false);
            setReconciledStatus(null);
            setCanceledStatus(new Set());
        }
        setOnlyRevoked(value);
    };

    const changeReconciledStatus = (value) => {
        if (value) {
            setAllStatus(false);
        }
        setReconciledStatus(value);
    };

    const changeCanceledStatus = (value, add) => {
        if (value == null) {
            setCanceledStatus(new Set());
        } else {
            setAllStatus(false);
            const newCanceledStatus = new Set(canceledStatus);
            add ? newCanceledStatus.add(value) : newCanceledStatus.delete(value);
            setCanceledStatus(newCanceledStatus);
        }
    };

    // Events -----------------------------------------------------------------

    const applyFilter = () => {
        onApply({
            allStatus: allStatus,
            onlyRevoked: onlyRevoked,
            reconciledStatus: reconciledStatus,
            canceledStatus: canceledStatus,
        });
    };

    const eraseFilter = () => {
        setAllStatus(DEFAULT_STATUS_FILTER.allStatus);
    };

    // Render -----------------------------------------------------------------

    const filterHeadClass = classNames('filter-head', { disabled: onlyRevoked });
    const filterItemClass = classNames('filter-item', { disabled: onlyRevoked });

    return (
        <div className="fund-entry-status-filter input-panel">
            <div>
                <Checkbox
                    inputId="allOptions"
                    onChange={(e) => changeAllStatus(e.checked)}
                    checked={allStatus}
                />
                <label htmlFor="allOptions" className={classNames('ml-2', { checked: allStatus })}>
                    Todos los estados
                </label>
            </div>
            <hr />
            <div>
                <Checkbox
                    inputId="onlyRevoked"
                    onChange={(e) => changeOnlyRevoked(e.checked)}
                    checked={onlyRevoked}
                />
                <label
                    htmlFor="onlyRevoked"
                    className={classNames('ml-2', { checked: onlyRevoked })}
                >
                    Solo anuladas
                </label>
            </div>
            <hr />
            <div className={filterHeadClass}>
                <label className={classNames({ checked: reconciledStatus != null })}>
                    Acreditación
                </label>
                <Button
                    label="Todas"
                    onClick={() => changeReconciledStatus(null)}
                    disabled={onlyRevoked}
                />
            </div>
            <div className={filterItemClass}>
                <RadioButton
                    inputId="reconciledStatusTrue"
                    value={true}
                    disabled={onlyRevoked}
                    checked={reconciledStatus === true}
                    onChange={(e) => changeReconciledStatus(e.value)}
                />
                <label
                    htmlFor="reconciledStatusTrue"
                    className={classNames('ml-2', { checked: reconciledStatus === true })}
                >
                    Sí acreditadas
                </label>
            </div>
            <div className={filterItemClass}>
                <RadioButton
                    inputId="reconciledStatusFalse"
                    value={false}
                    disabled={onlyRevoked}
                    checked={reconciledStatus === false}
                    onChange={(e) => changeReconciledStatus(e.value)}
                />
                <label
                    htmlFor="reconciledStatusFalse"
                    className={classNames('ml-2', { checked: reconciledStatus === false })}
                >
                    No acreditadas
                </label>
            </div>
            <hr />
            <div className={filterHeadClass}>
                <label className={classNames({ checked: canceledStatus.size > 0 })}>
                    Imputación
                </label>
                <Button
                    label="Todas"
                    disabled={onlyRevoked}
                    onClick={() => changeCanceledStatus(null)}
                />
            </div>
            <div className={filterItemClass}>
                <Checkbox
                    inputId="canceledStatusPending"
                    disabled={onlyRevoked}
                    onChange={(e) => changeCanceledStatus(CanceledStatus.PENDING.value, e.checked)}
                    checked={canceledStatus.has(CanceledStatus.PENDING.value)}
                />
                <label
                    htmlFor="canceledStatusPending"
                    className={classNames('ml-2', {
                        checked: canceledStatus.has(CanceledStatus.PENDING.value),
                    })}
                >
                    {CanceledStatus.PENDING.label}
                </label>
            </div>
            <div className={filterItemClass}>
                <Checkbox
                    inputId="canceledStatusPartial"
                    disabled={onlyRevoked}
                    onChange={(e) => changeCanceledStatus(CanceledStatus.PARTIAL.value, e.checked)}
                    checked={canceledStatus.has(CanceledStatus.PARTIAL.value)}
                />
                <label
                    htmlFor="canceledStatusPartial"
                    className={classNames('ml-2', {
                        checked: canceledStatus.has(CanceledStatus.PARTIAL.value),
                    })}
                >
                    {CanceledStatus.PARTIAL.label}
                </label>
            </div>
            <div className={filterItemClass}>
                <Checkbox
                    inputId="canceledStatusCompleted"
                    disabled={onlyRevoked}
                    onChange={(e) =>
                        changeCanceledStatus(CanceledStatus.COMPLETED.value, e.checked)
                    }
                    checked={canceledStatus.has(CanceledStatus.COMPLETED.value)}
                />
                <label
                    htmlFor="canceledStatusCompleted"
                    className={classNames('ml-2', {
                        checked: canceledStatus.has(CanceledStatus.COMPLETED.value),
                    })}
                >
                    {CanceledStatus.COMPLETED.label}
                </label>
            </div>
            <hr />
            <ExecuteCancelButtons
                executeLabel="Aplicar"
                onExecute={applyFilter}
                executeDisabled={!isChanged}
                cancelLabel="Borrar"
                onCancel={eraseFilter}
                cancelDisabled={!isChanged}
            />
        </div>
    );
};

const FundEntryStatusFilterButton = ({ filter, onChange }) => {
    const op = useRef(null);
    const [onDisplay, setOnDisplay] = useState(false);
    const icon = onDisplay ? 'pi pi-angle-up' : 'pi pi-angle-down';

    return (
        <>
            <Button
                icon={icon}
                label="Estados"
                iconPos="right"
                className="p-button-rounded p-button-text"
                onClick={(e) => op.current.toggle(e)}
            />
            <OverlayPanel
                ref={op}
                onHide={() => setOnDisplay(false)}
                onShow={() => setOnDisplay(true)}
                dismissable
                // TODO: This raises an error, update PrimeReact!
                // https://github.com/primefaces/primereact/issues/4750
                // closeOnEscape
            >
                <FundEntryStatusFilterInput
                    filter={filter}
                    onApply={(newFilter) => {
                        onChange(newFilter);
                        op.current.hide();
                    }}
                />
            </OverlayPanel>
        </>
    );
};

export const useFundEntryStatusFilter = () => {
    const [filter, setFilter] = useState(DEFAULT_STATUS_FILTER);
    const button = <FundEntryStatusFilterButton filter={filter} onChange={setFilter} />;
    const reset = () => {
        setFilter(DEFAULT_STATUS_FILTER);
    };
    return [button, filter, reset];
};
