export const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener noreferrer');
};

/**
 * Opens `url` in new application tab
 *
 * `url` must be an application url, i.e. within the hash router.
 *
 * @param {string} url as returned by react-router.generatePath
 */
export const appOpenInNewTab = (url) => {
    const appURL = url.startsWith('#') ? url : `#${url}`;
    openInNewTab(appURL);
};

/**
 * If the target was clicked with modifier keys (ctrlKey or altKey)
 * - then open the app url in a new tab,
 * - else replace current with that url.
 *
 * `url` must be an application url, i.e. within the hash router.
 *
 * @param {*} url as returned by react-router.generatePath
 * @param {*} clickEvent as returned by the target onclick event
 * @param {*} history a valid react-router history object
 */
export const appClickOpenPush = (url, clickEvent, history) => {
    clickEvent?.ctrlKey || clickEvent?.altKey ? appOpenInNewTab(url) : history.push(url);
};
