import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { hookFormRequestError } from '@custom/hook-form';

import { Button } from 'primereact/button';

import { DateTimeField, DescriptionField } from '@components/fields';
import { ModalForm } from '@components/forms';
import { PersonPath } from '@persons/routes';
import { zeroMinutesNow } from '@utils/date-utils';
import { strippedOrNull } from '@utils/string-utils';

import { TokenService } from '@services/tokenService';
import { packDescription } from '@tokens/templates';

const DETAIL_FIELDS = ['usedAt', 'description'];

export const TokenUseCreate = ({ personId, pack, onSuccess, ...rest }) => {
    const from = useLocation().state?.from;
    const history = useHistory();
    const [service] = useState(new TokenService());
    const [requestErrors, setRequestErrors] = useState([]);
    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues: {
            packId: pack.id,
            usedAt: [zeroMinutesNow()],
            comment: '',
        },
    });

    // Execute ----------------------------------------------------------------

    const onExecute = (data) => {
        clearErrors();
        setRequestErrors(null);
        const request = data.usedAt.map((value) => {
            return { packId: pack.id, usedAt: value };
        });
        const commentValue = strippedOrNull(data.comment);
        if (commentValue) {
            request.forEach((item) => (item['comment'] = commentValue));
        }
        service
            .use_create(request)
            .then((response) => {
                // const nextStep = from || generatePath(TokensPath.TokenType.list, {});
                if (onSuccess != null) {
                    onSuccess(response.data);
                } else {
                    const nextStep = from || generatePath(PersonPath.read.tokens, { id: personId });
                    history.replace(nextStep);
                }
            })
            .catch((error) =>
                hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors),
            );
    };

    return (
        <ModalForm
            className="token-use-create"
            title="Consumir Token"
            onSubmit={handleSubmit(onExecute)}
            requestErrors={requestErrors}
            buttons={{
                executeLabel: 'Consumir',
            }}
            {...rest}
        >
            <div className="p-fluid">
                <p>
                    <span className="font-bold">Paquete:</span> {packDescription(pack)}
                </p>
                <p>
                    <span className="font-bold">Período:</span> {pack.timePeriod}
                </p>
                <p>
                    <span className="font-bold">Disponibles:</span> {pack.balance}
                </p>
                <hr />
                {/* TODO: Use the pack (since, until) range in the date selector */}
                <DateTimeField
                    control={control}
                    errors={errors}
                    rules={{
                        required: true,
                        validate: {
                            maxCount: (values) => {
                                return values.length <= pack.balance;
                            },
                            minCount: (values) => {
                                return values.length > 0;
                            },
                        },
                    }}
                    customMessages={{
                        maxCount: `Excede cantidad disponible de tokens: ${pack.balance}`,
                        minCount: 'Debe seleccionar al menos una fecha',
                    }}
                    fieldLabel="Día y Hora (pueden ser múltiples días)"
                    fieldName="usedAt"
                    stepMinute={10}
                    selectionMode="multiple"
                    readOnlyInput={true}
                    // This can´t work as expected with selectionMode="multiple"
                    hideOnDateTimeSelect={false}
                />
                {/* TODO: Consider making this `required` here, because we don´t
                have context of why the token is consumed. That would not happen
                if the server consumes a token creating an EventAttendance. */}
                <DescriptionField
                    control={control}
                    errors={errors}
                    fieldName="comment"
                    fieldLabel="Comentario (opcional)"
                />
            </div>
        </ModalForm>
    );
};

export const TokenPackUsed = ({ pack }) => `${pack.used === 0 ? '-.-' : pack.used} / ${pack.count}`;

export const TokenPackBalance = ({ pack, onChanged, style = null, balanceLabel = null }) => {
    const [usePack, setUsePack] = useState(false);

    const doChanged = (data) => {
        setUsePack(false);
        if (onChanged != null) {
            onChanged(data);
        }
    };

    const doCancel = () => {
        setUsePack(false);
    };

    const label = balanceLabel ? `${balanceLabel}: ` : '';

    return (
        <>
            <div className="token-pack-balance" style={style}>
                {label}
                {pack.balance === 0 ? '-.-' : pack.balance}
                {pack.balance > 0 && !pack.isRevoked && (
                    <Button
                        className="p-button-rounded"
                        icon="pi pi-bolt"
                        onClick={(event) => {
                            event.preventDefault();
                            setUsePack(true);
                        }}
                    />
                )}
            </div>
            {usePack && (
                <TokenUseCreate
                    pack={pack}
                    onSuccess={doChanged}
                    onCancel={doCancel}
                    // ----------------------------------------------
                    // Both properties are required to prevent that any click
                    // on either the dialog or the dialog modal mask triggers
                    // this button's parent onClick. When this button is on a
                    // PersonTokenPacks row, navigation is invoked on click.
                    dismissableMask={true}
                    onClick={(event) => event.stopPropagation()}
                    // We are getting closed too often on regular use....
                    closeOnEscape={false}
                    // ----------------------------------------------
                />
            )}
        </>
    );
};
