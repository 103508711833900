import { useState } from 'react';

import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { AccountAction } from './action';
import { ChangePassword } from './change-password';
import { AccountDashboard } from './dashboard';

export const AccountView = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const [action, setAction] = useState();

    const restartView = action == null && match.path !== location.pathname;
    // Seems not to be needed - See modal-sub-path demos, were it was used.
    // const clearAction = action != null && match.path === location.pathname;

    // Events -----------------------------------------------------------------

    const onAction = (action) => {
        setAction(action);
        // This pushes to locations that afterwards are replaced by itself.
        // Then we have many entries in history to this location. The sub-path
        // location is not accessible again through paths, but still there are
        // many entries to this location.
        history.push(`${match.path}/${action}`);
    };

    // const onCancel = (action) => {
    //     setAction(null);
    //     history.replace(match.path);
    // };

    // const onSuccess = (action) => {
    //     setAction(null);
    //     history.replace(match.path);
    // };

    // Rendering --------------------------------------------------------------

    const dashboard = AccountDashboard({
        onAction: onAction,
    });

    return (
        <>
            <Switch>
                {restartView && <Redirect to={match.path} />}
                <Route path={`${match.path}/${AccountAction.CHANGE_PASSWORD}`}>
                    <ChangePassword
                        // onCancel={onCancel}
                        // onSuccess={onSuccess}
                        from={match.path}
                    />
                </Route>
                <Route>{dashboard}</Route>
                {/* This makes AccountDashboard re-render always */}
                {/* We would want to do if data was actually edited */}
                {/* <Route>
                    <AccountDashboard />
                </Route> */}
            </Switch>
        </>
    );
};
