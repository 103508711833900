import { useState } from 'react';
import { Controller } from 'react-hook-form';

import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';

import { userDescription } from '@account/templates';
import { FieldErrorMessages, errorClassName } from '@components/field-error-messages';
import { SiteUserService, UserDetail } from '@services/userService';

export const UserInput = ({
    fieldName = 'user',
    fieldLabel = 'Usuario',
    className,
    control,
    errors,
    required = true,
    onRequestError,
    ...rest
}) => {
    const [service] = useState(new SiteUserService());
    const [suggestions, setSuggestions] = useState(null);

    const searchUser = (event) => {
        const query = event.query.trim();
        if (query.length) {
            service
                .search({
                    query: query,
                    detail: UserDetail.DEFAULT,
                })
                .then((response) => setSuggestions(response.data['results']))
                .catch((error) => onRequestError && onRequestError(error));
        } else {
            setSuggestions(null);
        }
    };

    const itemTemplate = (item) => {
        return (
            <div className="flex align-items-center">
                <div>{userDescription(item)}</div>
            </div>
        );
    };

    return (
        <>
            <Controller
                name={fieldName}
                control={control}
                rules={{
                    required: required,
                    validate: {
                        userRequired: (values) => {
                            return !required || (Array.isArray(values) && values.length === 1);
                        },
                    },
                }}
                render={({ field, fieldState }) => (
                    <>
                        <AutoComplete
                            id={field.name}
                            value={field.value}
                            onChange={(e) => field.onChange(e.value.slice(-1))}
                            // We use 'multiple' to inherit the Chip's output.
                            // It is easier to know when a person is selected
                            // and when not with chips. It is also more intuitive
                            // to remove a person by clicking on the chip icon.
                            multiple
                            suggestions={suggestions}
                            completeMethod={searchUser}
                            itemTemplate={itemTemplate}
                            field="formalName"
                            aria-label={fieldLabel}
                            className={classNames(className, 'user-input', {
                                'p-invalid': fieldState.error,
                            })}
                            {...rest}
                        />
                    </>
                )}
            />
        </>
    );
};

export const UserField = ({
    fieldName = 'user',
    fieldLabel = 'Usuario',
    errors,
    required = true,
    ...rest
}) => {
    return (
        <div className="field">
            {fieldLabel && (
                <label htmlFor={fieldName} className={errorClassName(errors[fieldName])}>
                    {fieldLabel}
                    {required ? ' *' : ''}
                </label>
            )}

            <UserInput
                fieldName={fieldName}
                fieldLabel={fieldLabel}
                errors={errors}
                required={required}
                {...rest}
            />

            <FieldErrorMessages
                name={fieldName}
                errors={errors}
                customMessages={{
                    personRequired: 'Requerido',
                }}
            />
        </div>
    );
};

UserField.displayName = 'UserField';
