export const Section = ({ title, actions, children, ...rest }) => {
    return (
        <div className="section" {...rest}>
            <h2>
                {title} {actions}
            </h2>
            {children}
        </div>
    );
};
