import { useController, useWatch } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';

import { errorClassName, FieldError, invalidClassName } from '@components/field-error-messages';
import { DateFieldSelectorOption } from '@components/fields/dateFieldSelectorOption';
import { toISODateString } from '@utils/date-utils';
import { DateField } from './dateField';

export const SinceUntilSelectorOption = Object.freeze({
    //-------------------------------------------------------------------------
    SEPARATOR: { separator: true },
    //-------------------------------------------------------------------------
    TODAY: {
        label: 'Hoy',
        compute: () => {
            const value = DateFieldSelectorOption.TODAY.compute();
            return {
                since: value,
                until: value,
            };
        },
    },
    LAST_7_DAYS: {
        label: 'Últimos 7 días',
        compute: () => {
            const until = DateFieldSelectorOption.TODAY.compute();
            const since = new Date(until);
            since.setUTCDate(since.getUTCDate() - 7);
            return {
                since: since,
                until: until,
            };
        },
    },
    LAST_14_DAYS: {
        label: 'Últimos 14 días',
        compute: () => {
            const until = DateFieldSelectorOption.TODAY.compute();
            const since = new Date(until);
            since.setUTCDate(since.getUTCDate() - 14);
            return {
                since: since,
                until: until,
            };
        },
    },
    //-------------------------------------------------------------------------
    THIS_MONTH: {
        label: 'Este mes',
        compute: () => {
            return {
                since: DateFieldSelectorOption.BEGINNING_THIS_MONTH.compute(),
                until: DateFieldSelectorOption.END_THIS_MONTH.compute(),
            };
        },
    },
    //-------------------------------------------------------------------------
    PREV_MONTH: {
        label: 'Mes anterior',
        compute: () => {
            return {
                since: DateFieldSelectorOption.BEGINNING_PREVIOUS_MONTH.compute(),
                until: DateFieldSelectorOption.END_PREVIOUS_MONTH.compute(),
            };
        },
    },
    DATE_RANGE: {
        label: 'Rango de fechas',
    },
});

const SinceUntilOptionField = ({
    fieldName = 'period',
    fieldLabel = 'Periodo',
    control,
    required = true,
    options = [],
    ...rest
}) => {
    const { field, fieldState } = useController({
        control: control,
        name: fieldName,
        rules: {
            required: required,
        },
    });

    return (
        <div className="field">
            <label htmlFor={field.name} className={errorClassName(fieldState.error)}>
                {fieldLabel}
                {required ? ' *' : ''}
            </label>

            <Dropdown
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                options={options}
                optionLabel="label"
                className={invalidClassName(fieldState.error)}
                {...rest}
            />
            <FieldError fieldError={fieldState.error} />
        </div>
    );
};

const DEFAULT_SELECTOR_OPTIONS = [
    SinceUntilSelectorOption.TODAY,
    SinceUntilSelectorOption.LAST_7_DAYS,
    SinceUntilSelectorOption.LAST_14_DAYS,
    SinceUntilSelectorOption.THIS_MONTH,
    SinceUntilSelectorOption.PREV_MONTH,
    SinceUntilSelectorOption.DATE_RANGE,
];

export const DEFAULT_SELECTOR_OPTION = SinceUntilSelectorOption.THIS_MONTH;

export const SinceUntilBlock = ({
    sinceField = 'since',
    untilField = 'until',
    periodField = 'period',
    periodLabel = 'Período',
    control,
    selectorOptions = DEFAULT_SELECTOR_OPTIONS,
}) => {
    const [period, since] = useWatch({ control: control, name: [periodField, sinceField] });

    const canEdit = period && !Object.prototype.hasOwnProperty.call(period, 'compute');

    const sinceRules = { required: false };

    // WARNING: The beforeSince rule is never executed without
    // a useForm hook (like in other places of the application)
    const untilRules = {
        required: false,
        validate: {
            beforeSince: (until) => {
                // console.log('executes.beforeSince');
                // console.log(`since:${since}, until=${until}`);
                if (since == null || until == null) return true;
                const sinceValue = new Date(since);
                return sinceValue <= until;
            },
        },
    };

    const untilCustomMessages = {
        beforeSince: `No puede ser anterior a desde: ${toISODateString(since)}`,
    };

    return (
        <div className="since-until-block">
            <SinceUntilOptionField
                control={control}
                required={false}
                fieldName={periodField}
                fieldLabel={periodLabel}
                options={selectorOptions}
            />
            <span className="date-part">
                <DateField
                    fieldLabel="Desde"
                    fieldName={sinceField}
                    control={control}
                    rules={sinceRules}
                    showIcon={canEdit}
                    readOnlyInput={!canEdit}
                />
            </span>
            <span className="date-part">
                <DateField
                    fieldName={untilField}
                    fieldLabel="Hasta"
                    control={control}
                    rules={untilRules}
                    // This corrects the field value min date,
                    // but does not update the component view.
                    minDate={since}
                    showIcon={canEdit}
                    readOnlyInput={!canEdit}
                    customMessages={untilCustomMessages}
                />
            </span>
        </div>
    );
};

SinceUntilBlock.displayName = 'SinceUntilBlock';
