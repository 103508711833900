import suma from '@apis/Suma';

import { paginationParams, paramsString } from '.';

const SELF_USER_PATH = 'user/';
const SITE_USER_PATH = 'users/';

export const UserDetail = Object.freeze({
    DEFAULT: 'default', // id, formalName
    IDENTITY: 'identity', // id, formalName, email
});

const DISPLAY_ALL = true;
const EMPTY_DATA = {
    count: 0,
    data: {
        results: [],
    },
};

export class SelfUserService {
    getSelf() {
        return suma.get(SELF_USER_PATH);
    }

    changePassword(request) {
        return suma.post(SELF_USER_PATH + 'change-password/', request);
    }
}

export class SiteUserService {
    search({
        // prettier-off
        query = null,
        detail = null,
        page = null,
        pageSize = null,
    }) {
        if (!query && (detail === UserDetail.IDENTITY) | !DISPLAY_ALL) {
            return Promise.resolve(EMPTY_DATA);
        }

        const params = paginationParams(pageSize, page);
        if (query) {
            params.push(`search=${query}`);
        }
        if (detail) {
            params.push(`detail=${detail}`);
        }

        return suma.get(`${SITE_USER_PATH}${paramsString(params)}`);
    }

    get(userId) {
        return suma.get(`${SITE_USER_PATH}${userId}/`);
    }

    list() {
        return suma.get(SITE_USER_PATH);
    }
}
