import { useCallback, useEffect, useState } from 'react';

import { Link, generatePath, useHistory, useParams } from 'react-router-dom';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';

import { genericRequestErrors } from '@services/index';

import { CommissionsPath } from '@commissions/routes';
import { ModalPageForm } from '@components/forms/ModalPageForm';
import { CommissionService } from '@services/commissionsService';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { CommissionHeader } from './header';

const DEFAULT_COLLECTED_ONLY = true;

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];

const NO_PAYMENTS_WERE_CREATED = {
    severity: 'warn',
    summary: 'Ningún pago fue generado',
    sticky: true,
    closable: true,
};

const NEW_PAYMENT_OPTIONS = [
    'Solo por compromisos ya cobrados',
    'Por todos los compromisos (cobrados o no)',
];

export const CommissionCreatePayments = () => {
    const params = useParams();
    const history = useHistory();
    const [service] = useState(new CommissionService());

    // Search params
    const [collectedOnly, setCollectedOnly] = useState(DEFAULT_COLLECTED_ONLY);

    // Search results
    const [commission, setCommission] = useState();
    const [nextPayments, setNextPayments] = useState();
    const [totalRecords, setTotalRecords] = useState(0);
    const [requestMessages, setRequestMessages] = useState();

    // Callbacks --------------------------------------------------------------

    const search = useCallback(
        (commissionId, collectedOnly) => {
            setRequestMessages(null);
            const request = {
                commissionId,
                collectedOnly,
                createEntries: false,
            };
            service
                .nextPayments(request)
                .then((response) => {
                    const { nextPayments, ...commission } = response.data;
                    setCommission(commission);
                    setNextPayments(nextPayments);
                    setTotalRecords(nextPayments.entries.length);
                })
                .catch((error) => setRequestMessages(genericRequestErrors(error)));
        },
        [service],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        search(params.id, collectedOnly);
    }, [search, params.id, collectedOnly]);

    // Events -----------------------------------------------------------------

    const gotoCommission = () => {
        history.replace(generatePath(CommissionsPath.Commissions.item, { id: params.id }));
    };

    // Execute ----------------------------------------------------------------

    const onExecute = (event) => {
        event.preventDefault();
        setRequestMessages(null);
        const request = {
            commissionId: params.id,
            collectedOnly,
            createEntries: true,
        };
        service
            .nextPayments(request)
            .then((response) => {
                if (response.data.nextPayments.entries.length > 0) {
                    gotoCommission();
                } else {
                    setRequestMessages([NO_PAYMENTS_WERE_CREATED]);
                }
            })
            .catch((error) => setRequestMessages(genericRequestErrors(error)));
    };

    // Rendering --------------------------------------------------------------

    const header = (
        <div className="flex flex-column gap-3">
            Generar rendiciones
            <div className="flex align-items-center">
                <RadioButton
                    inputId="collectedOnlyTrue"
                    name="collectedOnly"
                    value={true}
                    onChange={(e) => setCollectedOnly(e.value)}
                    checked={collectedOnly}
                />
                <label htmlFor="collectedOnlyTrue" className="ml-2">
                    {NEW_PAYMENT_OPTIONS[0]}
                </label>
            </div>
            <div className="flex align-items-center">
                <RadioButton
                    inputId="collectedOnlyFalse"
                    name="collectedOnly"
                    value={false}
                    onChange={(e) => setCollectedOnly(e.value)}
                    checked={!collectedOnly}
                />
                <label htmlFor="collectedOnlyFalse" className="ml-2">
                    {NEW_PAYMENT_OPTIONS[1]}
                </label>
            </div>
        </div>
    );

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column
                    footer={`TOTAL (${totalRecords} ${
                        totalRecords > 1 ? 'rendiciones' : 'rendición'
                    })`}
                />
                <Column footer={nextPayments?.total} className="table-column-money" />
            </Row>
        </ColumnGroup>
    );

    const descriptionTemplate = (payment) => {
        return (
            <>
                <div>{payment.description}</div>
                <div>{payment.timePeriod}</div>
                <Link to={generatePath(CommissionsPath.Pledges.item, { id: payment.pledgeId })}>
                    Compromiso
                </Link>
            </>
        );
    };

    return (
        <ModalPageForm
            className="commission-next-payments"
            onSubmit={onExecute}
            requestErrors={requestMessages}
            executeButtonsProps={{
                executeLabel: 'Generar rendiciones',
                executeDisabled: totalRecords < 1,
                cancelLabel: 'Volver',
                onCancel: gotoCommission,
            }}
        >
            <CommissionHeader
                commission={commission}
                onRefresh={() => search(params.id, collectedOnly)}
            >
                <DataTable
                    header={header}
                    value={nextPayments?.entries}
                    dataKey="id"
                    emptyMessage="No hay compromisos a rendir"
                    className={classNames({ 'hide-columns-header': totalRecords < 1 })}
                    lazy
                    paginator={totalRecords > 0}
                    rows={ROWS_PER_PAGE_OPTIONS[0]}
                    totalRecords={totalRecords}
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="{first} a {last} de {totalRecords}"
                    footerColumnGroup={totalRecords > 0 && footerGroup}
                >
                    <Column header="Detalle" body={descriptionTemplate} />
                    <Column header="Importe" className="table-column-money" field="amount" />
                </DataTable>
            </CommissionHeader>
        </ModalPageForm>
    );
};
