import {
    toISOLocalDateString,
    localDateNow,
    firstDayOfMonth,
    lastDayOfMonth,
} from '@utils/date-utils';

export const DateUtilsTest = () => {
    const now = new Date();

    const endConfig = {
        year: now.getFullYear(),
        month: now.getMonth(),
        day: now.getDay(),
        hour: 23,
        minute: 59,
        seconds: 59,
    };

    const end = new Date(
        endConfig.year,
        endConfig.month,
        endConfig.day,
        endConfig.hour,
        endConfig.minute,
        endConfig.seconds,
    );

    const nowStr = JSON.stringify(now, null, 4);
    const endConfigStr = JSON.stringify(endConfig, null, 4);
    const endStr = JSON.stringify(end, null, 4);

    return (
        <div>
            <h2>date-utils</h2>
            <h3>Now</h3>
            <pre>{nowStr}</pre>
            <h3>EndConfig</h3>
            <pre>{endConfigStr}</pre>
            <h2>End</h2>
            <pre>{endStr}</pre>
            <hr />
            <p>{`now.toISOString() = ${now.toISOString()} // UTC-Time`}</p>
            <p>{`end.toISOString() = ${end.toISOString()} // UTC-Time`}</p>
            <hr />
            <p>{`toISOLocalDateString(now) = ${toISOLocalDateString(now)}`}</p>
            <p>{`toISOLocalDateString(end) = ${toISOLocalDateString(end)}`}</p>
            <hr />
            <p>{`localDateNow() = ${localDateNow()}`}</p>
            <h3>First/Last</h3>
            <p>{`firstDayOfMonth(new Date('1973-12-24')) = ${firstDayOfMonth(
                new Date('1973-12-24'),
            )}`}</p>
            <p>{`lastDayOfMonth(new Date('1973-12-24')) = ${lastDayOfMonth(
                new Date('1973-12-24'),
            )}`}</p>
        </div>
    );
};
