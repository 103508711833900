import { addLocale, locale } from 'primereact/api';
import locale_file from './primereact/es.json';

const DEFAULT_LOCALE = 'es';
const DEFAULT_EMPTY_VALUE = '-.-';
const DEFAULT_EMPTY_MONEY = '-.-';
const DEFAULT_EMPTY_LOCAL_DATE = '-.-';
const DEFAULT_EMPTY_LOCAL_DATE_TIME = '-.-';
const DEFAULT_LOCAL_DATE_TIME_OPTIONS = { hour: '2-digit', minute: '2-digit' };

addLocale(DEFAULT_LOCALE, locale_file[DEFAULT_LOCALE]);
locale(DEFAULT_LOCALE);

const defaults = {
    locale: DEFAULT_LOCALE,
    emptyValue: DEFAULT_EMPTY_VALUE,
    emptyMoney: DEFAULT_EMPTY_MONEY,
    emptyLocalDate: DEFAULT_EMPTY_LOCAL_DATE,
    emptyLocalDateTime: DEFAULT_EMPTY_LOCAL_DATE_TIME,
    localDateTimeOptions: DEFAULT_LOCAL_DATE_TIME_OPTIONS,
};

export const settings = {
    defaults,
};
