/**
 * Simple transparent overlay above rendered content.
 *
 * @param {boolean} isOn: Toggle overlay on-off
 * @param {React.ReactNode} children: Content rendered below the transparent overlay.
 * @param {boolean} preventClicks: When true (default), underlying components are not clickable.
 * @returns {React.ReactNode} Children rendered with (isOn) / without (!isOn) the transparent overlay.
 *
 * References:
 * https://stackoverflow.com/a/42512720
 * https://stackoverflow.com/a/45967143
 */

export const TransparentOverlay = ({ isOn, children, opacity = '0.4', preventClicks = true }) => {
    const renderOn = () => {
        const style = { opacity: opacity };
        if (preventClicks) {
            style.pointerEvents = 'none';
        }
        return <div style={style}>{children}</div>;
    };

    const renderOff = () => {
        return <>{children}</>;
    };

    return isOn ? renderOn() : renderOff();
};
