import { useCallback, useEffect, useState } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';

import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Fieldset } from 'primereact/fieldset';

import { PopupItemsButton, RefreshButton } from '@components/buttons';
import { RequestMessages } from '@components/RequestMessages';
import { genericRequestErrors } from '@services/index';
import { isoLocalDateTimeTemplate } from '@utils/money';
import { StringUtils } from '@utils/string-utils';

import { AccountingPaths } from '@accounting/routes';
import { PersonFormalNameLink } from '@persons/templates';

import { TokenService } from '@services/tokenService';
import { packDescription } from '@tokens/templates';
import { CommentDisplayEditor } from '@tokens/uses/comment';
import { TokenPackBalance, TokenPackUsed } from '@tokens/uses/create';

const TokenPackHeader = ({ pack, onRefresh }) => {
    if (pack == null) return null;

    const labelStyle = { width: '120px', fontWeight: 600 };

    const row = (label, value) => (
        <div className="grid align-items-center">
            <div className="col-fixed" style={labelStyle}>
                {`${StringUtils.isEmpty(label) ? '' : `${label}:`}`}
            </div>
            <div className="col">{value}</div>
        </div>
    );

    const transactionTemplate = () => {
        return pack.transaction?.id == null
            ? null
            : row(
                  '',
                  <Link
                      to={generatePath(AccountingPaths.Transaction.item, {
                          id: pack.transaction.id,
                      })}
                  >
                      Transacción contable
                  </Link>,
              );
    };

    return (
        <>
            <Fieldset>
                {row('Persona', <PersonFormalNameLink person={pack.person} aspect="tokens" />)}
                {row('Período', pack.timePeriod)}
                {row('Emitido', isoLocalDateTimeTemplate(pack, 'issuedAt'))}
                {transactionTemplate()}
                {row('Consumidos', <TokenPackUsed pack={pack} />)}
                {row(
                    pack.isRevoked ? 'Anulados' : 'Disponibles',
                    <TokenPackBalance
                        pack={pack}
                        style={{ justifyContent: 'flex-start' }}
                        onChanged={onRefresh}
                    />,
                )}
            </Fieldset>
        </>
    );
};

const TokenPackUses = ({ pack, forceUpdate = null }) => {
    if (!pack) return null;

    const usedAtTemplate = (use) => isoLocalDateTimeTemplate(use, 'usedAt');
    const commentTemplate = (use) => <CommentDisplayEditor use={use} />;

    return (
        <DataTable
            value={pack?.uses}
            // footerColumnGroup={footerColumnGroup}
            className="hide-table-header"
            emptyMessage="No tiene consumos"
        >
            <Column
                className="table-column-datetime-left"
                header="Consumos"
                body={usedAtTemplate}
            />
            <Column header="Comentarios" body={commentTemplate} />
        </DataTable>
    );
};

export const TokenPackItem = () => {
    const params = useParams();
    const [service] = useState(new TokenService());
    const [loading, setLoading] = useState(true);
    const [pack, setPack] = useState(null);
    const [readTimestamp, setReadTimestamp] = useState();
    const [requestErrors, setRequestErrors] = useState([]);

    // Callbacks --------------------------------------------------------------

    const read = useCallback(() => {
        setLoading(true);
        setRequestErrors(null);
        service
            .pack(params.id)
            .then((response) => {
                setPack(response.data);
                setReadTimestamp(new Date());
            })
            .catch((error) => setRequestErrors(genericRequestErrors(error)))
            .finally(() => setLoading(false));
    }, [params.id, service]);

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        read();
    }, [read]);

    // Events -----------------------------------------------------------------

    const confirmRevoke = () => {
        const accept = () => {
            service
                .revoke(params.id)
                .then(() => read())
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        };

        confirmDialog({
            icon: 'pi pi-exclamation-triangle',
            header: '¿Confirma anular pack de tokens?',
            message: (
                <>
                    <p>La transacción asociada y sus cancelaciones serán anuladas.</p>
                </>
            ),
            defaultFocus: 'accept',
            acceptLabel: 'Confirmo anular pack',
            accept,
        });
    };

    // Rendering --------------------------------------------------------------

    const menuItems = [
        {
            label: 'Anular',
            icon: 'pi pi-fw pi-times',
            disabled: pack?.isRevoked,
            command: confirmRevoke,
        },
    ];

    const menuAction = <PopupItemsButton menuItems={menuItems} />;

    const refreshAction = <RefreshButton onRefresh={read} />;

    const itemTitle = packDescription(pack);
    const revokedTitle = pack && pack.isRevoked ? ' [ANULADO]' : '';

    const title = (
        <div className="document-action-header">
            <div className="title">
                {menuAction}
                {`${itemTitle}${revokedTitle}`}
            </div>
            <div className="actions">
                <span className="spacer" />
                {refreshAction}
            </div>
        </div>
    );

    return (
        <Card title={title} className="token-pack-item">
            <ConfirmDialog />
            <RequestMessages messages={requestErrors} />
            {loading && (
                <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: '2em', marginBottom: '15px' }}
                />
            )}
            <TokenPackHeader pack={pack} onRefresh={read} />
            <br />
            <TokenPackUses pack={pack} forceUpdate={readTimestamp} />
        </Card>
    );
};
