import { Controller } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';

import {
    FieldErrorMessages,
    errorClassName,
    invalidClassName,
} from '@components/field-error-messages';

const PaymentOptionField = ({
    fieldName,
    fieldLabel,
    control,
    errors,
    options,
    required = true,
    hideEmpty = true,
    ...rest
}) => {
    if (hideEmpty & (!options || options.length < 1)) {
        return null;
    }

    return (
        <div className="field">
            <label htmlFor={fieldName} className={errorClassName(errors?.[fieldName])}>
                {fieldLabel}
                {required ? ' *' : ''}
            </label>
            <Controller
                name={fieldName}
                control={control}
                rules={{ required: required }}
                render={({ field }) => (
                    <Dropdown
                        id={field.name}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        options={options}
                        optionLabel="name"
                        optionDisabled={(option) => !option.enabled}
                        placeholder={`Seleccione ${fieldLabel}`}
                        className={invalidClassName(errors?.[fieldName])}
                        disabled={!options || options.length < 1}
                        {...rest}
                    />
                )}
            />
            <FieldErrorMessages name={fieldName} errors={errors} />
        </div>
    );
};

export const MajorPaymentOptionField = ({ control, errors, options, required = true, ...rest }) => (
    <PaymentOptionField
        fieldName="paymentOption"
        fieldLabel="Opción de pago"
        control={control}
        errors={errors}
        options={options}
        required={required}
        {...rest}
    />
);
MajorPaymentOptionField.displayName = 'MajorPaymentOptionField';

export const MinorPaymentOptionField = ({ control, errors, options, required = true, ...rest }) => (
    <PaymentOptionField
        fieldName="minorPaymentOption"
        fieldLabel="Opción de pago"
        control={control}
        errors={errors}
        options={options}
        required={required}
        {...rest}
    />
);
MinorPaymentOptionField.displayName = 'MinorPaymentOptionField';
