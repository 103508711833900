import { useState } from 'react';

import { useForm } from 'react-hook-form';

import { Button } from 'primereact/button';

import { DescriptionField } from '@components/fields';
import { ModalForm } from '@components/forms';
import { genericRequestErrors } from '@services/index';
import { StringUtils } from '@utils/string-utils';

import { TokenService } from '@services/tokenService';

const CommentEditor = ({ use, onSuccess, onCancel }) => {
    const [service] = useState(new TokenService());
    const [requestErrors, setRequestErrors] = useState([]);
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: { comment: StringUtils.valueOrEmpty(use?.comment, '') },
    });

    const onExecute = (data) => {
        setRequestErrors(null);
        data.comment = StringUtils.strippedOrNull(data.comment);
        service
            .use_update(use.id, data)
            .then((response) => onSuccess && onSuccess(response.data))
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    };

    return (
        <ModalForm
            onSubmit={handleSubmit(onExecute)}
            title="Editar comentario"
            buttons={{ executeLabel: 'Guardar', showCancel: false }}
            onCancel={onCancel}
            style={{ width: '400px' }}
            requestErrors={requestErrors}
        >
            <div className="p-fluid">
                <DescriptionField
                    fieldName="comment"
                    fieldLabel={null}
                    // When label is null, the upper border is partially hidden
                    style={{ marginTop: '10px' }}
                    control={control}
                    errors={errors}
                    autoFocus
                    rules={{ required: false }}
                />
            </div>
        </ModalForm>
    );
};

export const CommentDisplayEditor = ({ use }) => {
    const [showDialog, setShowDialog] = useState(false);
    const isEmpty = StringUtils.isEmpty(use?.comment);
    const style = isEmpty ? { color: 'grey' } : null;
    return (
        <>
            <div className="flex align-items-center">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-text"
                    onClick={() => setShowDialog(true)}
                />
                <span style={style}>{isEmpty ? 'Agregar comentario...' : use.comment}</span>
            </div>
            {showDialog && (
                <CommentEditor
                    use={use}
                    onSuccess={(data) => {
                        use.comment = data.comment;
                        setShowDialog(false);
                    }}
                    onCancel={() => setShowDialog(false)}
                />
            )}
        </>
    );
};
