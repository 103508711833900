import { useCallback, useEffect, useState } from 'react';

import { useController } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';

import {
    errorClassName,
    FieldErrorMessages,
    invalidClassName,
} from '@components/field-error-messages';
import { MonthField } from './monthField';
import { MonthFieldSelectorOptions } from './monthFieldSelectorOption';

import { TokenService } from '@services/tokenService';

export const TokenTemplateField = ({
    control,
    errors,
    typeId = null,
    fieldName = 'template',
    fieldLabel = 'Cantidad',
    required = true,
    onFieldRequestError,
    ...rest
}) => {
    const [options, setOptions] = useState([]);
    const [service] = useState(new TokenService());

    const { field, fieldState } = useController({
        control: control,
        name: fieldName,
        rules: {
            required: required,
        },
    });

    // Effects ----------------------------------------------------------------

    const search = useCallback(
        (typeId) => {
            if (typeId == null) {
                setOptions(null);
            } else {
                service
                    .templates({ typeId: typeId })
                    .then((response) => {
                        setOptions(response?.data?.results);
                    })
                    .catch((error) => onFieldRequestError && onFieldRequestError(fieldName, error));
            }
        },
        [service, fieldName, onFieldRequestError],
    );

    useEffect(() => {
        search(typeId);
    }, [search, typeId]);

    // Render -----------------------------------------------------------------

    const templateOption = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option.description}</div>
            </div>
        );
    };

    const selectedTemplate = (option, props) => {
        return option ? templateOption(option) : <span>{props.placeholder}</span>;
    };

    return (
        <span>
            <div className="field">
                <label htmlFor={field.name} className={errorClassName(fieldState.error)}>
                    {fieldLabel}
                    {required ? ' *' : ''}
                </label>

                <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={options}
                    // optionValue="id" => Set the object as value
                    optionLabel="name"
                    itemTemplate={templateOption}
                    valueTemplate={selectedTemplate}
                    placeholder="Seleccione"
                    disabled={typeId == null}
                    className={invalidClassName(fieldState.error)}
                    {...rest}
                />
                <FieldErrorMessages name={field.name} errors={errors} />
            </div>
            {field?.value?.duration != null && (
                <MonthField
                    fieldName="since"
                    fieldLabel="Período"
                    control={control}
                    rules={{ required: true }}
                    selectorOptions={MonthFieldSelectorOptions.SINCE}
                />
            )}
        </span>
    );
};

TokenTemplateField.displayName = 'TokenTemplateField';
