import suma from '@apis/Suma';

import { paginationParams, paramsString } from '.';

const SITE_PATH = 'sites';

export class SiteService {
    staffSites({
        // @prettier:off
        page = null,
        pageSize = null,
    }) {
        const params = paginationParams(pageSize, page);
        return suma.get(`${SITE_PATH}/staff/${paramsString(params)}`);
    }
}
