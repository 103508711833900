import { useState } from 'react';
import { generatePath, useHistory } from 'react-router';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';

import { ExecuteCancelButtons } from '@components/ExecuteCancelButtons';
import { RequestMessages } from '@components/RequestMessages';
import { genericRequestErrors } from '@services/index';
import { formatMoney } from '@utils/money';

import { AccountingPaths } from '@accounting/routes';
import { paymentModeDescription, transactionLink } from '@accounting/templates';
import { TransactionType } from '@enums/accounting';

import { ExpandButtons } from '@components/buttons';
import { personFormalNameLink } from '@persons/templates';
import { accountingService as service } from '@services/accounting';
import { ReceiptStep } from './create';

/** DEPRECATED SCREEN
 *
 * This confirmation step is no longer used, because it seemed not to add value
 * to users. Once the removal is welcomed by users, delete and remove the code.
 * i.e. check your hypothesis that removing it is the right way to go. On demos
 * I kept saying "Now you skip this screen pressing continue" (costly no value)
 */

const DisplayCancelations = ({ data, onEdit }) => {
    const [details] = useState(data.details);
    const [summary] = useState(data.summary);
    const [expandedRows, setExpandedRows] = useState(null);

    const expandAll = (event) => {
        event.preventDefault();
        const _expandedRows = {};
        details.forEach((detail) => (_expandedRows[`${detail.person.id}`] = true));
        setExpandedRows(_expandedRows);
    };

    const collapseAll = (event) => {
        event.preventDefault();
        setExpandedRows(null);
    };

    const expanderHeader = <ExpandButtons onExpand={expandAll} onCollapse={collapseAll} />;

    const headerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column className="table-column-left" header={expanderHeader} colSpan={2} />
                <Column className="table-column-money width-constrained" header="Saldo Actual" />
                <Column className="table-column-money" header="Cancelaciones" />
                <Column className="table-column-money width-constrained" header="Nuevo Saldo" />
            </Row>
        </ColumnGroup>
    );

    const footerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column className="table-column-right" footer="Totales" colSpan={2} />
                <Column
                    className="table-column-money width-constrained"
                    footer={summary?.siteDueAmount}
                />
                <Column className="table-column-money" footer={formatMoney(summary?.newPayment)} />
                <Column
                    className="table-column-money width-constrained"
                    footer={formatMoney(summary?.newBalance)}
                />
            </Row>
        </ColumnGroup>
    );

    const personTemplate = (data) => personFormalNameLink(data.person);

    const detailData = (detail) => {
        const descriptionTemplate = (transaction) => {
            return (
                <>
                    {transaction?.valueDate} <br />
                    {transactionLink(transaction)}
                </>
            );
        };

        return (
            <DataTable value={detail.transactions} emptyMessage="No hay saldos pendientes">
                <Column className="table-column-left" body={descriptionTemplate} />
                <Column className="table-column-money width-constrained" field="siteDueAmount" />
                <Column
                    className="table-column-money"
                    body={(rowData) => formatMoney(rowData?.newPayment)}
                />
                <Column
                    className="table-column-money width-constrained"
                    body={(rowData) => formatMoney(rowData?.newBalance)}
                />
            </DataTable>
        );
    };

    const title = (
        <div className="flex flex-wrap align-items-baseline">
            <span>Transacciones</span>
            <Button type="button" label="Modificar" className="p-button-link" onClick={onEdit} />
        </div>
    );

    const allowExpansion = (detail) => {
        return true;
        // TODO: Disable if there are no cancelations?
        // TODO: Hide person altogether if no cancelations?
        // TODO: Only display transactions that are canceled?
        // - Good: Less information to "confirm"
        // - Bad: Looses a chance to correct an error
        // The siteAmountDue total makes no sense (it's a subset)
    };

    return (
        <Card title={title}>
            <DataTable
                value={details}
                dataKey="person.id"
                className="table-expandable-details issue-receipt-cancelations hide-table-header"
                headerColumnGroup={headerColumnGroup}
                footerColumnGroup={footerColumnGroup}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={detailData}
            >
                <Column className="table-column-button-expander" expander={allowExpansion} />
                <Column className="table-column-left" body={personTemplate} />
                <Column className="table-column-money width-constrained" field="siteDueAmount" />
                <Column
                    className="table-column-money"
                    body={(rowData) => formatMoney(rowData?.newPayment)}
                />
                <Column
                    className="table-column-money width-constrained"
                    body={(rowData) => formatMoney(rowData?.newBalance)}
                />
            </DataTable>
        </Card>
    );
};

const DisplayPayments = ({ data, onEdit }) => {
    const title = (
        <div className="flex flex-wrap align-items-baseline">
            <span>Forma de Pago</span>
            <Button type="button" label="Modificar" className="p-button-link" onClick={onEdit} />
        </div>
    );

    return (
        <Card title={title} style={{ height: '100%' }}>
            {paymentModeDescription(data?.paymentMode)}
        </Card>
    );
};

export const DisplayTotal = ({ amount, onEdit }) => {
    // Avoid displaying -0,00 when value is set to negative zero.
    const displayAmount = !amount || amount === 0 ? 0 : amount;
    const displayAction = amount === 0 ? '' : amount > 0 ? 'a Cobrar' : 'a Pagar';

    const title = (
        <div className="flex flex-wrap align-items-baseline">
            <span>{`Total ${displayAction}`}</span>
            {onEdit && (
                <Button
                    type="button"
                    label="Modificar"
                    className="p-button-link"
                    onClick={onEdit}
                />
            )}
        </div>
    );

    return (
        <Card title={title} style={{ height: '100%' }}>
            <div style={{ fontSize: '2rem', fontWeight: 700 }}>$ {formatMoney(displayAmount)}</div>
        </Card>
    );
};

export const ReceiptConfirmation = ({ data, onReview }) => {
    const history = useHistory();
    const [requestErrors, setRequestErrors] = useState();

    //-------------------------------------------------------------------------
    // Events
    //-------------------------------------------------------------------------

    const editCancelations = (event) => {
        event.preventDefault();
        onReview(ReceiptStep.CANCELATIONS);
    };

    const editPayments = (event) => {
        event.preventDefault();
        onReview(ReceiptStep.PAYMENTS);
    };

    const getCancelationsRequests = () => {
        const requests = [];
        data.details.forEach((detail) => {
            detail.transactions.forEach((transaction) => {
                if (transaction.hasCancelation()) {
                    requests.push({
                        transactionId: transaction.id,
                        amount: Math.abs(transaction.newPayment),
                    });
                }
            });
        });
        return requests;
    };

    const getTransactionsRequests = () => {
        const paymentMode = data?.paymentMode;
        const transactionType =
            data.summary.newPayment > 0
                ? TransactionType.CASH_OUTGOING_PAYMENT
                : TransactionType.CASH_INCOMING_PAYMENT;
        if (paymentMode && paymentMode?.type === 'treasury.cashsession') {
            return [
                {
                    typeId: transactionType.id,
                    sessionId: paymentMode.id,
                    amount: Math.abs(data?.summary?.newPayment),
                },
            ];
        }
        // TODO: Implement other paymentMode's, e.g. FUNDS_OUTGOING_TRANSFER
        return null;
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const request = {
            personId: data.personId,
            cancelationsRequests: getCancelationsRequests(),
            transactionsRequests: getTransactionsRequests(),
        };
        setRequestErrors(null);
        service
            .issueReceipt(request)
            .then((response) => onCompleted(response.data))
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    };

    const onCompleted = (data) => {
        // There are always cancelation through this IssueReceipt view.
        // Therefore, we can directly redirect to the generated receipt.
        const redirectPath = generatePath(AccountingPaths.Receipt.item, { id: data.receipt.id });
        history.replace(redirectPath);
    };

    //-------------------------------------------------------------------------
    // Rendering
    //-------------------------------------------------------------------------

    // const errors = [
    //     'Test hasCancelations',
    //     'Test hasPaymentMode'
    // ];
    // const canExecute = !errors;

    return (
        <>
            <DisplayCancelations data={data} onEdit={editCancelations} />

            {Boolean(data.requiresPayment()) && (
                <>
                    <br />
                    <div className="flex flex-wrap gap-3">
                        <div
                            className="flex-grow-1"
                            style={{ minWidth: '200px', minHeight: '100px' }}
                        >
                            <DisplayPayments data={data} onEdit={editPayments} />
                        </div>
                        <div
                            className="flex-grow-1"
                            style={{ minWidth: '200px', minHeight: '100px' }}
                        >
                            <DisplayTotal
                                amount={-data?.summary?.newPayment}
                                onEdit={editCancelations}
                            />
                        </div>
                    </div>
                </>
            )}

            <br />

            <RequestMessages messages={requestErrors} />

            <form onSubmit={onSubmit}>
                <ExecuteCancelButtons executeLabel="Confirmar" showCancel={false} />
            </form>

            {/* <br /><br />
            <ul>
                {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                ))}
            </ul> */}
        </>
    );
};
