import { parseMoney } from '@utils/money';

const accounting = require('accounting');

export const MoneyFormatDemo = () => {
    const value = '1.000.000,25';
    const noValue = null;
    const correctSeparator = ',';
    const invalidSeparator = '.';

    return (
        <pre>
            Value = &apos;{value}&apos;
            <br />
            <br />
            NoValue = &apos;null&apos;
            <br />
            <br />
            Correct Separator = &apos;{correctSeparator}&apos;
            <br />
            <br />
            Invalid Separator = &apos;{invalidSeparator}&apos;
            <br />
            <br />
            <hr />
            <br />
            <br />
            {`accounting.unformat('${value}', '${correctSeparator}') = ${accounting.unformat(
                value,
                correctSeparator,
            )}`}
            <br />
            <br />
            {`accounting.unformat('${value}') = ${accounting.unformat(value)}`} FAILS!
            <br />
            <br />
            {`accounting.unformat('${value}', '${invalidSeparator}') = ${accounting.unformat(
                value,
                invalidSeparator,
            )}`}{' '}
            FAILS! Default separator is &apos;{invalidSeparator}&apos;
            <br />
            <br />
            {`accounting.unformat(null, '${correctSeparator}') = ${accounting.unformat(
                noValue,
                correctSeparator,
            )}`}{' '}
            FAILS! Expected null
            <br />
            <br />
            <hr />
            <br />
            <br />
            {`parseMoney('${value}') = ${parseMoney(value)}`}
            <br />
            <br />
            {`parseMoney('${value}', '${correctSeparator}') = ${parseMoney(
                value,
                correctSeparator,
            )}`}
            <br />
            <br />
            {`parseMoney('${value}', '${invalidSeparator}') = ${parseMoney(
                value,
                invalidSeparator,
            )}`}{' '}
            FAILS!
            <br />
            <br />
            {`parseMoney(null, '${correctSeparator}') = ${parseMoney(
                noValue,
                invalidSeparator,
            )}`}{' '}
            WORKS!
        </pre>
    );
};
