import { useCallback, useEffect, useRef, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Chip } from 'primereact/chip';
import { InputText } from 'primereact/inputtext';
import { OverlayPanel } from 'primereact/overlaypanel';
import { RadioButton } from 'primereact/radiobutton';

import { ExecuteCancelButtons } from '@components/ExecuteCancelButtons';
import { ReportMultiRelation } from '@components/fields/reportMultiRelation';
import { useOnKeyDown } from '@hooks/use-on-key';
import { fromISOLocalDateString, toISOLocalDateString, toMonthYearString } from '@utils/date-utils';
import { StringUtils } from '@utils/string-utils';

const SearchConfigurationPanel = ({ reference, searchConfig, onSearchConfigChange }) => {
    // No need to initialize state here, an effect or event will handle it
    const [searchCase, setSearchCase] = useState();
    const [reportDate, setReportDate] = useState();
    const [overdueOnly, setOverdueOnly] = useState();
    const [majorRelations, setMajorRelations] = useState();
    const [minorRelations, setMinorRelations] = useState();

    // Hooks ------------------------------------------------------------------

    useOnKeyDown('Escape', (event) => {
        // Do not close the conf overlay if other 'drop-down' targets are
        // displayed, e.g. MonthSelector or ReportMultiRelation selectors.
        const targetClassName = event?.target?.className || '';
        if (targetClassName === '' || targetClassName.includes('p-button-icon-only')) {
            reference.current.hide();
        }
    });

    // Callbacks --------------------------------------------------------------

    const reloadSearchConfig = useCallback((iniSearchConfig) => {
        setSearchCase(iniSearchConfig?.reportDate ? 'REPORTING' : 'DASHBOARD');
        setReportDate(iniSearchConfig?.reportDate);
        setOverdueOnly(iniSearchConfig?.overdueOnly);
        setMajorRelations(iniSearchConfig?.majorRelations);
        setMinorRelations(iniSearchConfig?.minorRelations);
    }, []);

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        reloadSearchConfig(searchConfig);
    }, [searchConfig, reloadSearchConfig]);

    // Helpers ----------------------------------------------------------------

    const onHide = () => {
        // Overlay is reloaded onHide (and not on show) to prevent the time
        // delay in the update: If done on show, the user can view the control
        // values being updated to either the new searchConfig or the previous
        // one, if the panel was dismissed or canceled with button cancel click.
        reloadSearchConfig(searchConfig);
    };

    const changeSearchCase = (newSearchCase) => {
        setSearchCase(newSearchCase);
        setReportDate(newSearchCase === 'REPORTING' ? new Date() : null);
    };

    const changeRelations = (newMajorRelations, newMinorRelations) => {
        setMajorRelations(newMajorRelations);
        setMinorRelations(newMinorRelations);
    };

    // Execution --------------------------------------------------------------

    const onCancelChange = () => {
        reference.current.hide();
    };

    const onExecuteChange = () => {
        if (onSearchConfigChange) {
            const newSearchConfig = {
                ...searchConfig,
                reportDate: reportDate,
                overdueOnly: overdueOnly,
                majorRelations: majorRelations,
                minorRelations: minorRelations,
            };
            onSearchConfigChange(newSearchConfig);
        }
        reference.current.hide();
    };

    // Rendering --------------------------------------------------------------

    const MonthSelector = () => {
        return (
            <Calendar
                value={reportDate}
                view="month"
                mask="99/9999"
                dateFormat="mm/yy"
                // Hidden to afford more drop-down width
                // showIcon={true}
                disabled={searchCase === 'DASHBOARD'}
                onChange={(e) => setReportDate(e.value)}
            />
        );
    };

    return (
        <OverlayPanel
            ref={reference}
            id="config_panel"
            dismissable={true}
            showCloseIcon={false}
            onHide={onHide}
        >
            <div className="field-radiobutton">
                <RadioButton
                    value="DASHBOARD"
                    inputId="dashboard-choice"
                    checked={searchCase === 'DASHBOARD'}
                    onChange={(e) => changeSearchCase(e.value)}
                />
                <label htmlFor="dashboard-choice">Dashboard (todas las personas)</label>
            </div>
            <div className="field-radiobutton" style={{ alignItems: 'center' }}>
                <RadioButton
                    value="REPORTING"
                    inputId="reporting-choice"
                    checked={searchCase === 'REPORTING'}
                    onChange={(e) => changeSearchCase(e.value)}
                />
                <label htmlFor="reporting-choice">Reporte (solo activos en fecha)</label>
            </div>
            <div style={{ width: '180px', marginLeft: '2em' }}>
                <MonthSelector />
            </div>
            <br />
            <div className="field-checkbox">
                <Checkbox
                    inputId="overdueOnly"
                    onChange={(e) => setOverdueOnly(e.checked)}
                    checked={overdueOnly}
                />
                <label htmlFor="overdueOnly">Solo personas con saldos vencidos</label>
            </div>
            <hr />
            <div className="p-fluid">
                <ReportMultiRelation
                    onRelationsSelected={changeRelations}
                    iniMajorRelations={searchConfig?.majorRelations}
                    iniMinorRelations={searchConfig?.minorRelations}
                />
            </div>
            <hr />
            <div style={{ marginBottom: '1em', float: 'left' }}>
                <ExecuteCancelButtons
                    executeLabel="Buscar"
                    onExecute={onExecuteChange}
                    onCancel={onCancelChange}
                />
            </div>
        </OverlayPanel>
    );
};

const SearchQueryInput = ({ searchConfig, onSearchConfigChange, onRepeatSearch }) => {
    const inputReference = useRef(null);
    const panelReference = useRef(null);

    /** TODO: Consider triggering search only after pressing ENTER on inputQuery.
        
        Reasons to do this:
        - Prevent too many consecutive server hits.
        - Solve update problem when typing too fast (see below)
    
        When a user types very fast, the screen might not get updated:
        1 - search: 'something' >> fetch >> set([8]) >> updates to 8 items
        2 - search: 'something very' >> fetch >> set([]) >> does not reach update to nothing
        3 - search: 'something very odd' >> fetch >> set([]) >> will never update: (previous == updated)
        previousState: [] == updatedState: []
    
        This causes the UI to show the last matching values, 8, even though it continues searching on each key 
     */

    const [inputQuery, setInputQuery] = useState(searchConfig.query);

    // Hooks ------------------------------------------------------------------

    useOnKeyDown('/', (event) => {
        // TODO: Consider implement a proper shortcut hook
        // https://www.fullstacklabs.co/blog/keyboard-shortcuts-with-react-hooks
        if (event?.ctrlKey) {
            event.preventDefault();
            inputReference.current.focus();
            inputReference.current.select();
        }
    });

    useOnKeyDown('7', (event) => {
        // TODO: Consider implement a proper shortcut hook
        // https://www.fullstacklabs.co/blog/keyboard-shortcuts-with-react-hooks
        if (event?.ctrlKey) {
            event.preventDefault();
            inputReference.current.focus();
            inputReference.current.select();
        }
    });

    // Events -----------------------------------------------------------------

    const changeInputQuery = (value) => {
        setInputQuery(value);
        if (onSearchConfigChange) {
            const cleaned = value ? value.trim().toLowerCase() : '';
            if (cleaned !== searchConfig.query) {
                onSearchConfigChange({
                    ...searchConfig,
                    query: cleaned,
                });
            }
        }
    };

    // Render -----------------------------------------------------------------

    return (
        <div className="p-inputgroup">
            <span className="flex-shrink-0">
                <Button
                    icon="pi pi-sliders-h"
                    aria-haspopup
                    aria-controls="config_panel"
                    onClick={(e) => {
                        panelReference.current.toggle(e);
                        // TODO: Set focus to the configuration panel.
                        // This is not working, renders page unusable.
                        // if (panelReference.current.state.visible) {
                        //     panelReference.current.focus();
                        // }
                    }}
                />
            </span>
            <SearchConfigurationPanel
                reference={panelReference}
                searchConfig={searchConfig}
                onSearchConfigChange={onSearchConfigChange}
            />
            <span className="p-input-icon-right" style={{ width: '100%' }}>
                <InputText
                    style={{ width: '100%' }}
                    autoFocus
                    ref={inputReference}
                    value={inputQuery}
                    onChange={(e) => changeInputQuery(e.target.value)}
                />
                {inputQuery && (
                    <i
                        className="pi pi-times mr-1"
                        onClick={() => {
                            changeInputQuery('');
                            inputReference.current.focus();
                        }}
                    />
                )}
            </span>
            <span className="flex-shrink-0">
                <Button icon="pi pi-search" onClick={() => onRepeatSearch & onRepeatSearch()} />
            </span>
        </div>
    );
};

const SearchConfigurationDisplay = ({ searchConfig, onSearchConfigChange }) => {
    const { reportDate, overdueOnly, majorRelations, minorRelations } = searchConfig;

    const changeSearchConfig = (newSearchConfig) => {
        if (onSearchConfigChange) {
            onSearchConfigChange(newSearchConfig);
        }
    };

    const removeReportDate = () => {
        changeSearchConfig({
            ...searchConfig,
            reportDate: null,
        });
    };

    const removeOverdueOnly = () => {
        changeSearchConfig({
            ...searchConfig,
            overdueOnly: false,
        });
    };

    const removeMajorRelation = (removeMajor) => {
        const newMajorRelations = majorRelations.filter(
            (relation) => relation.id !== removeMajor.id,
        );
        let newMinorRelations = minorRelations || [];
        if (minorRelations && minorRelations.length > 0) {
            if (removeMajor?.minorRelations?.length > 0) {
                const removedMinorIds = new Set(
                    removeMajor?.minorRelations.map((relation) => relation.id),
                );
                newMinorRelations = minorRelations.filter(
                    (relation) => !removedMinorIds.has(relation.id),
                );
            }
        }
        changeSearchConfig({
            ...searchConfig,
            majorRelations: newMajorRelations.length > 0 ? newMajorRelations : null,
            minorRelations: newMinorRelations.length > 0 ? newMinorRelations : null,
        });
    };

    const removeMinorRelation = (removeMinor) => {
        const newMinorRelations = minorRelations.filter(
            (relation) => relation.id !== removeMinor.id,
        );
        changeSearchConfig({
            ...searchConfig,
            minorRelations: newMinorRelations.length > 0 ? newMinorRelations : null,
        });
    };

    return (
        <>
            {reportDate && (
                <Chip
                    key="report-date"
                    label={`Reporte: ${toMonthYearString(reportDate)}`}
                    removable
                    onRemove={removeReportDate}
                    className="mr-2 mb-2"
                />
            )}
            {overdueOnly && (
                <Chip
                    key="overdue-only"
                    label="Solo con saldos vencidos"
                    removable
                    onRemove={removeOverdueOnly}
                    className="mr-2 mb-2"
                />
            )}
            {majorRelations &&
                majorRelations.map((relation) => (
                    <Chip
                        key={relation.id}
                        label={`Relación: ${relation.name}`}
                        removable
                        onRemove={() => removeMajorRelation(relation)}
                        className="mr-2 mb-2"
                    />
                ))}

            {minorRelations &&
                minorRelations.map((relation) => (
                    <Chip
                        key={relation.id}
                        label={`Detalle: ${relation.name}`}
                        removable
                        onRemove={() => removeMinorRelation(relation)}
                        className="mr-2 mb-2"
                    />
                ))}
        </>
    );
};

const EMPTY_SEARCH_CONFIG = {
    query: '',
    reportDate: null,
    overdueOnly: false,
    majorRelations: null,
    minorRelations: null,
};

const searchConfigFromQueryParams = (queryParams) => {
    if (queryParams == null || queryParams.size < 1) return EMPTY_SEARCH_CONFIG;

    const overdueOnlyStr = queryParams.get('overdue-only');
    const overdueOnly = overdueOnlyStr && overdueOnlyStr.toLowerCase() === 'true';

    // TODO: Fetch relations at this moment to check params are valid.
    // This could prevent retrieving options on the configuration panel.
    // This would allow to drop major-name and minor-name parameters,
    // preventing "bad" relation names being typed in the search bar.

    const majorIds = queryParams.getAll('major');
    const majorNames = queryParams.getAll('major-name');
    const minorIds = queryParams.getAll('minor');
    const minorNames = queryParams.getAll('minor-name');

    const majorRelations = majorIds.map((majorId, idx) => ({
        id: majorId,
        name: majorNames[idx],
    }));
    const minorRelations = minorIds.map((minorId, idx) => ({
        id: minorId,
        name: minorNames[idx],
    }));

    return {
        query: queryParams.get('search') || '',
        reportDate: fromISOLocalDateString(queryParams.get('report-date')),
        overdueOnly: overdueOnly,
        majorRelations: majorRelations.length > 0 ? majorRelations : null,
        minorRelations: minorRelations.length > 0 ? minorRelations : null,
    };
};

const queryParamsFromSearchConfig = (searchConfig) => {
    const queryParams = new URLSearchParams();

    if (!StringUtils.isEmpty(searchConfig.query)) {
        queryParams.set('search', searchConfig.query);
    }

    if (searchConfig.reportDate != null) {
        queryParams.set('report-date', toISOLocalDateString(searchConfig.reportDate));
    }

    if (searchConfig.overdueOnly) {
        queryParams.set('overdue-only', 'true');
    }

    const { majorRelations, minorRelations } = searchConfig;

    if (Array.isArray(majorRelations) && majorRelations.length > 0) {
        majorRelations.forEach((relation) => {
            queryParams.append('major', relation.id);
            queryParams.append('major-name', relation.name);
        });
    }

    if (Array.isArray(minorRelations) && minorRelations.length > 0) {
        minorRelations.forEach((relation) => {
            queryParams.append('minor', relation.id);
            queryParams.append('minor-name', relation.name);
        });
    }

    return queryParams;
};

export const useSearchConfiguration = (onRepeatSearch) => {
    // Using Link.state only works while not navigating to another tab. That is
    // a very important feature of RegistrationReport, so we can't use it.
    // Right now we implement initializing the searchConfig parameters with

    // This is working as expected, except that:
    // - searchConfigFromQueryParams is called multiple times (without effects)
    //   . Is this an error making the application run slower?
    //   . Is it because onRepeatSearch changes with every render?
    // - Params can't be changed by typing in the URL bar:
    //   . They are ignored and the current in memory searchParm established.
    //   . This is not actually bad: forces user to change queryParams using
    //     the application in controlled mode.
    //   . PROBLEM: This also prevents navigating with the browser back button.
    //     We have to navigate backwards using a link from the history tab.
    // TODO: Try to implement query parameters as it is done with 'search' / 'queryInput'

    const history = useHistory();
    const location = useLocation();
    const [queryParams, setQueryParams] = useState(new URLSearchParams(location.search));
    const [queryString, setQueryString] = useState(queryParams.toString());
    const [searchConfig, setSearchConfig] = useState(searchConfigFromQueryParams(queryParams));

    useEffect(() => {
        const newQueryParams = queryParamsFromSearchConfig(searchConfig);
        const newQueryString = newQueryParams.toString();
        setQueryString(newQueryString);
        setQueryParams(newQueryParams);
    }, [searchConfig]);

    useEffect(() => {
        history.replace(`${location.pathname}?${queryString}`);
    }, [queryString, location.pathname, history]);

    return {
        searchConfig,

        searchInput: (
            <SearchQueryInput
                searchConfig={searchConfig}
                onSearchConfigChange={setSearchConfig}
                onRepeatSearch={onRepeatSearch}
            />
        ),

        searchDisplay: (
            <SearchConfigurationDisplay
                searchConfig={searchConfig}
                onSearchConfigChange={setSearchConfig}
            />
        ),
    };
};
