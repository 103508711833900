import classNames from 'classnames';

const DEFAULT_ERROR_MESSAGES = {
    required: 'Requerido',
    minLength: 'Faltan caracteres',
    maxLength: 'Excede el máximo de caracteres',
};

const NO_INDEX_VALUE = 0;
const UNDEFINED_ERROR_MESSAGE = 'Error no especificado';

export const errorClassName = (error, otherClasses = '') => {
    return classNames(otherClasses, { 'p-error': error });
};

export const invalidClassName = (invalid, otherClasses = '') => {
    return classNames(otherClasses, { 'p-invalid': invalid });
};

export const fieldErrorMessage = (index, message, severity) => {
    const className = severity ? severity : 'p-error';
    return (
        <small key={index} className={`${className} field-error-message`}>
            {message}
        </small>
    );
};

export const fieldErrorMessages = (messages) => {
    if (messages) {
        return messages.map((message, index) => {
            const isLast = messages.length === index + 1;
            // const severity = message?.severity ? message.severity : 'p-error';
            const messageValue = message?.summary ? message.summary : message;
            // const errorMessage = fieldErrorMessage(index, messageValue, severity);
            const errorMessage = fieldErrorMessage(index, messageValue);
            return !isLast ? (
                <>
                    {errorMessage}
                    <br />
                </>
            ) : (
                errorMessage
            );
        });
    }
    return null;
};

export const FieldErrorMessages = ({ name, errors, customMessages }) => {
    return <FieldError fieldError={errors?.[name]} customMessages={customMessages} />;
};

export const FieldError = ({ fieldError, customMessages }) => {
    if (fieldError == null) {
        return null;
    }

    const { message, messages, type } = fieldError;

    if (message) {
        return fieldErrorMessage(NO_INDEX_VALUE, message);
    }

    if (messages) {
        return messages.map((message, index) => {
            const isLast = messages.length === index + 1;
            const errorMessage = fieldErrorMessage(index, message);
            return !isLast ? (
                <>
                    {errorMessage}
                    <br />
                </>
            ) : (
                errorMessage
            );
        });
    }

    if (type) {
        if (customMessages && customMessages[type]) {
            return fieldErrorMessage(NO_INDEX_VALUE, customMessages[type]);
        }

        if (DEFAULT_ERROR_MESSAGES[type]) {
            return fieldErrorMessage(NO_INDEX_VALUE, DEFAULT_ERROR_MESSAGES[type]);
        }
    }

    return fieldErrorMessage(NO_INDEX_VALUE, UNDEFINED_ERROR_MESSAGE);
};
