import { useState } from 'react';

import { useForm } from 'react-hook-form';

import { PhoneNumberField } from '@components/fields';
import { ModalForm } from '@components/forms';
import { hookFormRequestError } from '@custom/hook-form';

import { PersonService } from '@services/personService';

export const ChangePhoneNumber = ({ person, onSuccess, onCancel }) => {
    const [service] = useState(new PersonService());
    const [requestErrors, setRequestErrors] = useState([]);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: {
            phoneNumber: person.phoneNumber,
        },
    });

    const onExecute = (data) => {
        clearErrors();
        setRequestErrors(null);
        service
            .update(person.id, data)
            .then((response) => onSuccess && onSuccess(response.data))
            .catch(handleRequestError);
    };

    const handleRequestError = (error) => {
        hookFormRequestError(error, ['phoneNumber'], setError, setRequestErrors);
    };

    return (
        <ModalForm
            onSubmit={handleSubmit(onExecute)}
            title="Modificar número de teléfono"
            buttons={{ executeLabel: 'Modificar' }}
            onCancel={onCancel}
            style={{ width: '400px' }}
            requestErrors={requestErrors}
        >
            <div className="p-fluid">
                <PhoneNumberField register={register} errors={errors} autoFocus />
            </div>
        </ModalForm>
    );
};
