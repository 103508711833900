import { useEffect } from 'react';

/**
 *  Run 'fun' function only once after the component mounts.
 *
 *  When using useEffect with a second array argument, React will run the callback
 *  after mounting (initial render) and after values in the array have changed.
 *  When an empty array is passed, it will run only once after mounted.
 *
 *  From: https://stackoverflow.com/a/56767883
 *
 *  WARNING && TODO: Check if the same effect could be achieved
 *  by the client code by memoizing 'fun', making it a dependency
 *  that will not change and therefore would still be called once.
 *
 *  For now we disable the warning.
 */

export const useMountEffect = (fun) => useEffect(fun, []); // eslint-disable-line react-hooks/exhaustive-deps
