import { useState } from 'react';

import { hookFormRequestError } from '@custom/hook-form';
import { useForm } from 'react-hook-form';

import { DNIField, unmaskedDNI } from '@components/fields';
import { ModalForm } from '@components/forms';

import { PersonService } from '@services/personService';

export const ChangeDNI = ({ person, onSuccess, onCancel }) => {
    const [service] = useState(new PersonService());
    const [requestErrors, setRequestErrors] = useState([]);
    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: {
            dni: person?.dni ? person.dni : '',
        },
    });

    const onExecute = (data) => {
        clearErrors();
        setRequestErrors(null);
        const request = {
            dni: unmaskedDNI(data.dni),
        };
        service
            .update(person.id, request)
            .then((response) => onSuccess && onSuccess(response.data))
            .catch(handleRequestError);
    };

    const handleRequestError = (error) => {
        hookFormRequestError(error, ['dni'], setError, setRequestErrors);
    };

    return (
        <ModalForm
            onSubmit={handleSubmit(onExecute)}
            title="Modificar DNI"
            buttons={{ executeLabel: 'Modificar' }}
            onCancel={onCancel}
            style={{ width: '400px' }}
            requestErrors={requestErrors}
        >
            <div className="p-fluid">
                <DNIField
                    control={control}
                    errors={errors}
                    autoFocus={process.env.REACT_APP_TARGET === 'production'}
                />
                {/* WARING: When 'autoFocus' is set, the InputMask component
                    raises an error when the control is displayed on a dialog:
                    
                        Uncaught TypeError: Cannot read properties of null
                        (reading 'value') at onFocus (inputmask.esm.js:353:1)
                    
                    This error shows in the console and to user in development
                    environment, but does not seem to pop up to the user on a
                    production build. For this reason, we show it on production
                    only, since development mode is used on DEMO's.
                    
                    autoFocus does work, except that mask is only displayed
                    once the user starts typing, not before.*/}
            </div>
        </ModalForm>
    );
};
