import { useForm } from 'react-hook-form';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import {
    MonthField,
    MonthFieldSelectorOption,
    MonthFieldSelectorOptions,
} from '@components/fields';
import { toMonthYearString } from '@utils/date-utils';

const THIS_NEXT_MONTH_CAP = 20;

export const MonthFieldDemo = () => {
    const {
        control,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            since: MonthFieldSelectorOptions.slapped(
                THIS_NEXT_MONTH_CAP,
                MonthFieldSelectorOption.THIS_MONTH,
                MonthFieldSelectorOption.NEXT_MONTH,
            ),
            until: null,
        },
    });

    // WARNING: Watch triggers re-renders. Consider using useWatch hook
    // https://react-hook-form.com/api/useform/watch/
    const [since, until] = watch(['since', 'until']);

    const onExecute = (data) => {
        alert(`since=${data.since}\n until=${data.until}\n`);
    };

    const sinceRules = { required: true };

    const untilRules = {
        required: false,
        validate: {
            beforeSince: (until) => {
                if (since == null || until == null) return true;
                const sinceValue = new Date(since);
                return sinceValue <= until;
            },
        },
    };

    const untilCustomMessages = {
        beforeSince: `No puede ser anterior al mes desde: ${toMonthYearString(since)}`,
    };

    return (
        <form>
            <div className="card p-fluid">
                <InputText autoFocus /> <br /> <br />
                <MonthField
                    fieldName="since"
                    fieldLabel="Desde"
                    control={control}
                    rules={sinceRules}
                    selectorOptions={MonthFieldSelectorOptions.SINCE}
                />
                <MonthField
                    fieldName="until"
                    fieldLabel="Hasta"
                    control={control}
                    since={until}
                    rules={untilRules}
                    customMessages={untilCustomMessages}
                    selectorOptions={MonthFieldSelectorOptions.UNTIL_OPENED_FUTURE}
                />
                <InputText /> <br /> <br />
                <Button label="Ok" style={{ width: '150px' }} onClick={handleSubmit(onExecute)} />
            </div>
            <hr />
            <br />
            <div>
                {`typeof(since)=${typeof since}`}
                <br />
                {`typeof(until)=${typeof until}`}
                <br />
                <br />
                {`since=${since}`}
                <br />
                {`until=${until}`}
                <br />
                <br />
                {`toMonthYearString(since)=${toMonthYearString(since, '-.-')}`}
                <br />
                {`toMonthYearString(until)=${toMonthYearString(until, 'Abierto')}`}
                <br />
            </div>
        </form>
    );
};
