import { useCallback, useEffect, useState } from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';

import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { RequestMessages } from '@components/RequestMessages';
import { Section } from '@components/Section';
import { RefreshButton } from '@components/buttons';
import { genericRequestErrors } from '@services/index';
import { parseMoney } from '@utils/money';

import { CashRegisterService } from '@services/cashService';
import { fullStatusTemplate } from '@treasury/cash/registers/templates';
import { TreasuryPaths } from '@treasury/routes';

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

export const CashSessionList = () => {
    // State & Hooks ----------------------------------------------------------
    const params = useParams();
    const [service] = useState(new CashRegisterService());
    const [register, setRegister] = useState();
    const [sessions, setSessions] = useState();
    const [requestErrors, setRequestErrors] = useState([]);
    // const [searchParams, setSearchParams] = useState(defaultSearchParams);

    // Pagination & Lazy loading
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);

    // Effects ----------------------------------------------------------------

    const search = useCallback(() => {
        const handleRegisterError = (error) => {
            setRegister(null);
            const newErrors = genericRequestErrors(error);
            setRequestErrors((oldErrors) => [...oldErrors, ...newErrors]);
        };

        const handleSessionsError = (error) => {
            setSessions(null);
            setTotalRecords(0);
            const newErrors = genericRequestErrors(error);
            setRequestErrors((oldErrors) => [...oldErrors, ...newErrors]);
        };

        const request = {
            registerId: params.id,
            // ...searchParams,
            pageSize: rows,
            page: page + 1,
        };

        setRequestErrors([]);

        service
            .get(params.id)
            .then((response) => setRegister(response.data))
            .catch(handleRegisterError);

        service
            .sessions(request)
            .then((response) => {
                const data = response.data;
                setSessions(data.results);
                setTotalRecords(data.count ? data.count : 0);
            })
            .catch(handleSessionsError);
    }, [
        service,
        params.id,
        rows,
        page,
        // searchParams
    ]);

    useEffect(
        () => {
            setFirst(0);
            setPage(0);
        },
        [
            // searchParams
        ],
    );

    useEffect(() => {
        search();
    }, [search]);

    // Events -----------------------------------------------------------------

    const onPage = (event) => {
        setFirst(event.first);
        setPage(event.page);
        setRows(event.rows);
    };

    // DoubleClicking is tricky for the user (and the system)
    // SingleClicking is too much, data can't be copy pasted.
    // const onRowDoubleClick = (event) => {
    //     history.push(generatePath(TreasuryPaths.CashSession.item, { id: event.data.id }));
    // };

    // Rendering --------------------------------------------------------------

    const refreshAction = <RefreshButton onRefresh={search} style={{ float: 'right' }} />;

    const amountsTemplate = (session) => {
        const errorTemplate = () => {
            const positiveError = parseMoney(session.errorAmount) > 0;
            return (
                <span>
                    <Badge
                        className="x-small mr-1"
                        severity={positiveError ? 'warning' : 'danger'}
                    />
                    {session.errorAmount}
                    <br />
                    {positiveError ? 'Sobrante' : 'Faltante'}
                </span>
            );
        };

        const hasError = Boolean(session?.errorAmount);

        return (
            <div className="amounts-template">
                <span>
                    {session.closeAmount}
                    {session.isOpen ? (
                        <>
                            <br />
                            {'Esperado'}
                        </>
                    ) : (
                        ''
                    )}
                </span>
                {hasError && errorTemplate()}
            </div>
        );
    };

    const detailActionBody = (session) => (
        <Link to={generatePath(TreasuryPaths.CashSession.item, { id: session.id })}>
            <Button icon="pi pi-search-plus" className="p-button-rounded p-button-text" />
        </Link>
    );

    return (
        <Section
            className="cash-register-sessions"
            title={`Caja: ${register ? register.name : params.id}`}
            actions={refreshAction}
        >
            <Link to={TreasuryPaths.LocalPayments.dashboard}>Efectivo y Cajas</Link>
            <br />
            <br />

            <RequestMessages messages={requestErrors} />

            <DataTable
                value={sessions}
                dataKey="id"
                className="cash-session-list"
                // onRowDoubleClick={onRowDoubleClick}
                lazy
                paginator={Boolean(totalRecords && totalRecords > MIN_PAGINATION_COUNT)}
                rows={rows}
                first={first}
                onPage={onPage}
                totalRecords={totalRecords}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            >
                <Column
                    className="table-column-left"
                    header="Operadores"
                    body={fullStatusTemplate}
                />

                <Column
                    className="table-column-money"
                    header="Importe Cierre"
                    body={amountsTemplate}
                />
                <Column className="table-column-button" body={detailActionBody} />
            </DataTable>
        </Section>
    );
};
