import { useState } from 'react';

import { ModalForm } from '@components/forms';

import { genericRequestErrors } from '@services/index';
import { RegistrationService } from '@services/registrations';

export const DeleteRegistration = ({ registration, onCancel, onSuccess }) => {
    // State -------------------------------------------------------------------

    const [service] = useState(new RegistrationService());
    const [requestErrors, setRequestErrors] = useState([]);

    // Execute -----------------------------------------------------------------

    const onExecute = () => {
        setRequestErrors(null);
        service
            .deleteRegistration(registration.id)
            .then(onSuccess)
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    };

    // Render ------------------------------------------------------------------

    return (
        <ModalForm
            title="Eliminar registración"
            buttons={{ executeLabel: 'Eliminar' }}
            requestErrors={requestErrors}
            onSubmit={onExecute}
            onCancel={onCancel}
            style={{ width: '400px' }}
        >
            <div className="flex align-items-center gap-3">
                <i className="pi pi-exclamation-triangle" style={{ fontSize: '2rem' }} />
                ¿Confirma eliminar registración?
            </div>
            {/* <br /> */}
            <br />
            <div>
                Si hubiera cuotas pagadas, las mismas serán revertidas acreditando el monto a favor
                de esta persona.
            </div>
        </ModalForm>
    );
};
