import { useState } from 'react';

import { useForm } from 'react-hook-form';

import { ModalForm } from '@components/forms';
import { hookFormRequestError } from '@custom/hook-form';

import {
    AddressField,
    CUITField,
    DNIField,
    DOBField,
    EmailField,
    FirstNameField,
    LastNameField,
    PhoneNumberField,
    unmaskedCUIT,
    unmaskedDNI,
} from '@components/fields';
import { toISOLocalDateString } from '@utils/date-utils';
import { StringUtils } from '@utils/string-utils';

import { PersonService } from '@services/personService';

const initPerson = {
    firstName: '',
    lastName: '',
    dni: '',
    cuit: '',
    // phoneNumber: '343',
    phoneNumber: '',
    email: '',
    dob: null,
    address: '',
};

const DETAIL_FIELDS = Object.getOwnPropertyNames(initPerson);

export const PersonCreate = ({ onSuccess, onCancel }) => {
    const [service] = useState(new PersonService());
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: { ...initPerson },
    });
    const [requestErrors, setRequestErrors] = useState([]);

    const onExecute = (data) => {
        clearErrors();
        setRequestErrors(null);
        const { address, ...personData } = data;
        const request = {
            ...personData,
            dni: unmaskedDNI(data.dni),
            cuit: unmaskedCUIT(data.cuit),
            dob: toISOLocalDateString(data.dob),
            addresses: StringUtils.isEmpty(address) ? null : [{ value: address }],
        };
        service
            .create(request)
            .then((response) => onSuccess && onSuccess(response.data))
            .catch(handleRequestError);
    };

    const handleRequestError = (error) => {
        if (error?.response?.data) {
            const { addresses, ...restData } = error.response.data;
            if (addresses) {
                restData['address'] = addresses[0].value;
                error.response.data = restData;
            }
        }
        hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors);
    };

    return (
        <ModalForm
            onSubmit={handleSubmit(onExecute)}
            title="Nueva persona"
            buttons={{ executeLabel: 'Crear' }}
            onCancel={onCancel}
            requestErrors={requestErrors}
            style={{ width: '400px' }}
        >
            <div className="p-fluid">
                <FirstNameField register={register} errors={errors} autoFocus />
                <LastNameField register={register} errors={errors} />
                <DNIField required={false} control={control} errors={errors} />
                <CUITField required={false} control={control} errors={errors} />
                <PhoneNumberField register={register} errors={errors} />
                <EmailField register={register} errors={errors} />
                <DOBField control={control} errors={errors} />
                <AddressField control={control} errors={errors} fieldName="address" rows={2} />
            </div>
        </ModalForm>
    );
};
