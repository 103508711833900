import { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { DEFAULT_PATH_TO_LOGIN, defaultPathAuthenticated } from '@app/configuration';
import { useAuth } from '@hooks/use-auth';
import { App } from './App';
import { LoginPage } from './public/LoginPage';

export const AppWrapper = () => {
    const auth = useAuth();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const LoginRequired = () => (
        <Redirect
            to={{
                pathname: DEFAULT_PATH_TO_LOGIN,
                state: { from: location },
            }}
        />
    );

    const LoginCompleted = () => (
        <Redirect to={location?.state?.from || defaultPathAuthenticated(auth.site)} />
    );

    return (
        <Switch>
            <Route path={DEFAULT_PATH_TO_LOGIN} exact={true}>
                {auth.user ? <LoginCompleted /> : <LoginPage />}
            </Route>
            <Route>{auth.user ? <App /> : <LoginRequired />}</Route>
        </Switch>
    );
};
