import { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';

import { revokedClassName } from '@accounting/templates';
import { RequestMessages } from '@components/RequestMessages';
import { ClearFilterButton, RefreshButton } from '@components/buttons';
import { DescriptionField, PersonField } from '@components/fields';
import { PersonIdentityLink } from '@persons/templates';
import { genericRequestErrors } from '@services/index';
import { isoLocalDateTimeTemplate } from '@utils/money';
import { appClickOpenPush } from '@utils/nav-utils';

import { AccountingPaths } from '@accounting/routes';
import { AccountingService } from '@services/accounting';
import { ReceiptAction } from './actions';
import { ChangeComment } from './change-comment';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

const DEFAULT_SEARCH_PARAMS = {
    persons: [],
    comment: '',
    pageSize: MIN_PAGINATION_COUNT,
    page: 1,
};

// TODO: Implement ReceiptView as a sub-path-view of this one,
// so returning to this view with back-button from a detail
// ReceiptView will not issue a new request and update view.

// TODO: Implement filtering by multiple persons, the server
// already can handle that type of filter input. PersonInput
// component should have

export const ReceiptsSearch = () => {
    // State & Hooks
    const history = useHistory();
    const [service] = useState(new AccountingService());
    const { control, watch, setValue } = useForm();

    const [items, setItems] = useState([]);
    const [action, setAction] = useState(null);
    const [target, setTarget] = useState(null);
    const [requestErrors, setRequestErrors] = useState();

    // SearchParams
    const [searchParams, setSearchParams] = useState({ ...DEFAULT_SEARCH_PARAMS });
    const [persons, comment] = watch(['persons', 'comment']);

    // Pagination & Lazy loading
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(DEFAULT_SEARCH_PARAMS.page - 1);
    const [rows, setRows] = useState(DEFAULT_SEARCH_PARAMS.pageSize);
    const [totalRecords, setTotalRecords] = useState(0);

    // Callbacks --------------------------------------------------------------

    const search = useCallback(
        (searchParams) => {
            setAction(null);
            setRequestErrors(null);
            service
                .receipts(searchParams)
                .then((response) => {
                    const data = response.data;
                    setItems(data.results);
                    setTotalRecords(data.count ? data.count : 0);
                })
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        },
        [service],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        const newSearchParams = {
            persons: persons,
            comment: comment,
            pageSize: rows,
            page: page + 1,
        };
        setSearchParams(newSearchParams);
        search(newSearchParams);
    }, [search, persons, comment, rows, page]);

    // Events & Actions -------------------------------------------------------

    const refresh = () => {
        search(searchParams);
    };

    const clearFilters = () => {
        setValue('persons', DEFAULT_SEARCH_PARAMS.persons);
        setValue('comment', DEFAULT_SEARCH_PARAMS.comment);
    };

    const rowClick = ({ data, originalEvent }) => {
        const url = generatePath(AccountingPaths.Receipt.item, { id: data.id });
        appClickOpenPush(url, originalEvent, history);
    };

    const onPage = (event) => {
        setFirst(event.first);
        setPage(event.page);
        setRows(event.rows);
    };

    const cancelTargetAction = () => {
        setAction(null);
    };

    const completedAction = () => {
        setAction(null);
    };

    const updateActionSuccess = (updated) => {
        refreshItems(updated, items);
        completedAction();
    };

    // const refreshItem = (outdated, outdatedItems = null) => {
    //     if (outdated) {
    //         service
    //             .receipt(outdated.id)
    //             .then((response) => refreshItems(response.data, outdatedItems))
    //             .catch((error) => setRequestErrors(genericRequestErrors(error)));
    //     }
    // };

    const refreshItems = (refreshedItem, outdatedItems = null) => {
        const targetItems = outdatedItems || items;
        const index = targetItems.findIndex((item) => item.id === refreshedItem.id);
        if (index === -1) {
            // refreshedItem was currently not on display. Should never happen.
            search();
        } else {
            const newItems = [...targetItems];
            newItems[index] = refreshedItem;
            setItems(newItems);
        }
    };

    // Filters ----------------------------------------------------------------

    const searchFilters = (
        <div className="receipts-filter">
            <PersonField
                control={control}
                fieldName="persons"
                fieldLabel="Persona(s)"
                fieldClassName="person"
                required={false}
                single={false}
            />
            <DescriptionField
                control={control}
                fieldName="comment"
                fieldLabel="Comentario"
                fieldClassName="comment"
                rules={{ required: false }}
            />
        </div>
    );

    // Rendering --------------------------------------------------------------

    const header = <>{searchFilters}</>;

    const headerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column
                    header={
                        <>
                            <span>Recibos</span>
                            <ClearFilterButton onClick={clearFilters} />
                            <RefreshButton onRefresh={refresh} />
                        </>
                    }
                    className="table-column-with-button"
                />
                <Column header="Importe" className="table-column-money" />
            </Row>
        </ColumnGroup>
    );

    const descriptionTemplate = (receipt) => (
        <span className="description-template">
            <PersonIdentityLink
                person={receipt?.person}
                stacked={true}
                emptyValue="-.-"
                bestDocumentOnly={true}
            />
            <div>
                {isoLocalDateTimeTemplate(receipt, 'issuedTime')}
                {receipt.isRevoked ? ' [ANULADO]' : ''}
            </div>
            {/* <div className="id">{`ID: ${receipt.id}`}</div> */}
            {/* Consider:
                - Removing editing comment from here, as it is done in item view
                - Or add the ability to revoke from here, as in a full pop-menu */}
            <div className="comment">
                <span className="comment-text">{receipt?.comment}</span>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-text"
                    onClick={() => {
                        setTarget(receipt);
                        setAction(ReceiptAction.CHANGE_COMMENT);
                    }}
                />
            </div>
        </span>
    );

    return (
        <div className="receipts-search">
            <RequestMessages messages={requestErrors} />

            <DataTable
                value={items}
                dataKey="id"
                header={header}
                headerColumnGroup={headerColumnGroup}
                emptyMessage="No hay recibos"
                lazy
                // --------------------------------------------------------------------------
                selectionMode="single"
                onRowClick={rowClick}
                rowClassName={revokedClassName}
                // --------------------------------------------------------------------------
                // Always displayed for this view, regardless of displayed records count
                // paginator={Boolean(totalRecords && totalRecords > MIN_PAGINATION_COUNT)}
                paginator
                rows={rows}
                first={first}
                onPage={onPage}
                totalRecords={totalRecords}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            >
                <Column body={descriptionTemplate} />
                <Column
                    className="table-column-money"
                    //  field="total"
                />
            </DataTable>

            {target && action === ReceiptAction.CHANGE_COMMENT && (
                <ChangeComment
                    receipt={target}
                    onSuccess={updateActionSuccess}
                    onCancel={cancelTargetAction}
                />
            )}
        </div>
    );
};
