import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Custom hook that builds on useLocation to parse the query string.
 *
 * Source: https://reactrouter.com/web/example/query-parameters
 *
 * @returns key-value dictionary with query params: query.get("param-name")
 */

export function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}
