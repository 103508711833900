import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';

import { FlashPaymentButtons } from '@accounting/receipts/newPayment';
import { RequestMessages } from '@components/RequestMessages';
import { PopupItemsButton } from '@components/buttons';
import { mailToURL, whatsAppChatURL } from '@components/contacts';
import { genericRequestErrors } from '@services/index';
import { toISOLocalDateString } from '@utils/date-utils';
import { EMPTY_MONEY_DISPLAY } from '@utils/money';
import { PersonFormalNameLink, personFormalNameLink } from '../templates';

import { PersonDetail, PersonService } from '@services/personService';
import { PersonAction } from '../details/actions';
import { PersonCreate } from '../details/create';
import { useSearchConfiguration } from './config';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

export const PersonSearch = () => {
    const history = useHistory();
    const [service] = useState(new PersonService());

    // Params: SearchConfig
    const { searchConfig, searchInput, searchDisplay } = useSearchConfiguration(() =>
        repeatSearch(),
    );

    // Params: Pagination
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);

    // Request results
    const [items, setItems] = useState([]);
    const [requestMessages, setRequestMessages] = useState();

    // Actions
    const [action, setAction] = useState();

    // Loading indicator
    // This causes screen flicker by rendering a spinner
    // Might be usable if query takes too long to return
    // const [loading, setLoading] = useState(false);

    // Callbacks --------------------------------------------------------------

    // search can be called:
    // - On demand: repeatSearch
    // - On change: searchConfig
    // - On change: pagination (rows/page)
    // When searchConfig changes, pagination hast to change too: Page == 0

    const search = useCallback(
        (searchConfig, first, page, rows) => {
            const searchParams = {
                detail: PersonDetail.DASHBOARD,
                ...searchConfig,
                pageSize: rows,
                page: page + 1,
            };
            setRequestMessages(null);
            service
                .search(searchParams)
                .then((response) => {
                    const data = response.data;
                    setFirst(first);
                    setPage(page);
                    setRows(rows);
                    setItems(data.results);
                    setTotalRecords(data.count ? data.count : 0);
                })
                .catch((error) => {
                    console.log(error);
                    if (
                        (error?.response?.status === 404) &
                        (error.response?.data?.detail === 'Página inválida.')
                    ) {
                        // Explicitly ignore this error: it is a problem with
                        // of using two effect hooks instead of a single one,
                        // and a new API request will be executed right away.
                        // See bellow: PAGINATION_ERROR
                        // This only happens in border cases:
                        // - changing the input query and
                        // - changing the rows per page to display
                        return null;
                    }
                    setRequestMessages(genericRequestErrors(error));
                });
        },
        [service],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        /* PREVENT PAGINATION ERROR
        
            [0] Initial situation:
                query: 'mar'
                total: 104
                rows:   50
                page:    2
            [1] Next request:
                query: 'mara'
                total:  17
                rows:   50
                page:    2
        
            >> This causes InvalidPage (404) to be returned from DRF pagination:
            page 2 does not exists for query 'mara' and pageSize/rows 50.
            
            To prevent this, we reset to first page when searchConfig changes.
        */
        search(searchConfig, 0, 0, rows);
    }, [search, searchConfig, rows]);

    // Events -----------------------------------------------------------------

    const onPage = (event) => {
        search(searchConfig, event.first, event.page, event.rows);
    };

    const repeatSearch = () => {
        search(searchConfig, first, page, rows);
    };

    // Rendering --------------------------------------------------------------

    const header = (
        <div className="flex flex-column gap-3">
            <div className="flex flex-wrap gap-3">
                <div className="flex flex-initial">
                    <Button
                        label="Nueva"
                        icon="pi pi-plus"
                        onClick={() => setAction(PersonAction.CREATE)}
                    />
                </div>
                <div className="flex flex-grow-1">{searchInput}</div>
            </div>
            <div className="flex flex-wrap gap-3">{searchDisplay}</div>
        </div>
    );

    const siteBalanceTemplate = ({ siteBalance }) => (
        <>{siteBalance.total ? siteBalance.total : EMPTY_MONEY_DISPLAY}</>
    );

    const paymentActionsTemplate = (data) => <FlashPaymentButtons beneficiary={data} {...data} />;

    const minorRegistrationsTemplate = (data) => {
        return data.map((registration) => (
            <div key={registration.id}>{registration.relation.name}</div>
        ));
    };

    const nestedRegistrationsTemplate = (data) => {
        return data['nestedRegistrations'].map((registration) => (
            <div key={registration.id}>
                {registration.relation.name}
                <div>{minorRegistrationsTemplate(registration?.minorRegistrations)}</div>
            </div>
        ));
    };

    const responsibilityTemplate = (data) => {
        const beneficiaryOf =
            data?.beneficiaryOf &&
            data.beneficiaryOf.map((responsibility) => (
                <div key={responsibility.id}>
                    <PersonFormalNameLink person={responsibility.responsible} />
                    {` (${responsibility.responsibleRole} - R)`}
                </div>
            ));
        const responsibleOf =
            data?.responsibleOf &&
            data.responsibleOf.map((responsibility) => (
                <div key={responsibility.id}>
                    <PersonFormalNameLink person={responsibility.beneficiary} />
                    {` (${responsibility.beneficiaryRole} - B)`}
                </div>
            ));
        return (
            <>
                {beneficiaryOf}
                {responsibleOf}
            </>
        );
    };

    const globalActionsTemplate = (data) => {
        const { id, siteBalance } = data;
        const baseURL = `#/persons/${id}/`;
        const mailURL = mailToURL(data?.email);
        const chatURL = whatsAppChatURL(data?.phoneNumber);

        const actionMenuItems = [
            { label: 'WhatsApp', url: chatURL, icon: 'pi pi-whatsapp', disabled: !chatURL },
            { label: 'Email', url: mailURL, icon: 'pi pi-envelope', disabled: !mailURL },
            { separator: true },
            { label: 'Detalles', url: baseURL + 'details' },
            { label: 'Registros', url: baseURL + 'registrations' },
            { label: 'Movimientos', url: baseURL + 'balances' },
            { label: 'Saldos', url: baseURL + 'dues', disabled: !siteBalance.total },
            { label: 'Responsables', url: baseURL + 'responsibility' },
        ];
        return <PopupItemsButton menuItems={actionMenuItems} />;
    };

    const headerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Nombre" />
                <Column header="Saldos" className="table-column-money" />
                {/* PaymentActions */}
                <Column className="table-column-button-2" />
                <Column
                    header={
                        searchConfig?.reportDate
                            ? `Registros: ${toISOLocalDateString(searchConfig.reportDate)}`
                            : 'Registros: Actuales'
                    }
                />
                <Column header="Relaciones" />
                {/* GlobalActions */}
                <Column className="table-column-button" />
            </Row>
        </ColumnGroup>
    );

    return (
        <>
            <RequestMessages messages={requestMessages} />

            <Card>
                <DataTable
                    value={items}
                    dataKey="id"
                    header={header}
                    headerColumnGroup={headerColumnGroup}
                    // Recheck this is needed, toggling it in the previous PersonSearch
                    lazy
                    // Always displayed for this view, regardless of displayed records count
                    // paginator={Boolean(totalRecords && totalRecords > MIN_PAGINATION_COUNT)}
                    paginator
                    rows={rows}
                    first={first}
                    onPage={onPage}
                    totalRecords={totalRecords}
                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="{first} a {last} de {totalRecords}"
                >
                    <Column body={personFormalNameLink} />
                    <Column body={siteBalanceTemplate} className="table-column-money" />
                    <Column body={paymentActionsTemplate} />
                    <Column body={nestedRegistrationsTemplate} />
                    <Column body={responsibilityTemplate} />
                    <Column body={globalActionsTemplate} />
                </DataTable>

                {action === PersonAction.CREATE && (
                    <PersonCreate
                        onCancel={() => setAction(null)}
                        onSuccess={(newPerson) =>
                            history.push(`/persons/${newPerson.id}/registrations`)
                        }
                    />
                )}
            </Card>
        </>
    );
};
