import classNames from 'classnames';

export const SEVERITY_ICON = {
    success: 'pi-check',
    info: 'pi-info-circle',
    warn: 'pi-exclamation-triangle',
    error: 'pi-times-circle',
};

// Primereact renders $Summary$Detail without space / formatting.
// For this reason we render them using a custom content method.

// TODO: This method produces errors when
// not flattened in the form of key:value pairs, e.g.:
// {"paymentTransactions":[{"amount":["Este campo no puede ser nulo."]}]}

export const formatMessageContent = (message) => {
    return message?.content
        ? // If a content was defined, use it.
          message
        : // Otherwise, correct render problems or create a message.
          {
              ...message,
              content: (
                  <>
                      <span
                          className={classNames(
                              'p-message-icon pi',
                              SEVERITY_ICON[message.severity],
                          )}
                      />
                      {message.summary && (
                          <div className="ml-2">
                              <b>{message.summary}</b>
                          </div>
                      )}
                      {message.detail && <div className="ml-2">{message.detail}</div>}
                  </>
              ),
          };
};

/*  From a Dict

    [icon] message-summary
            * detail
            * detail

*/

export const formatMessageHeaderDetailDict = (message, data) => {
    const details = [];
    for (var key of Object.keys(data)) {
        // console.log(key + " -> " + data[key])
        details.push(
            <li>
                {key}: {data[key]}
            </li>,
        );
    }
    return {
        ...message,
        content: (
            <>
                <div className="grid nested-grid">
                    <div className="col-fixed" style={{ width: '40px' }}>
                        <span
                            className={classNames(
                                'p-message-icon pi',
                                SEVERITY_ICON[message.severity],
                            )}
                        />
                    </div>
                    <div className="col">
                        <b>{message.summary}</b>
                        <ul style={{ padding: '0px', margin: '0px', listStyle: 'none' }}>
                            {details}
                        </ul>
                    </div>
                </div>
            </>
        ),
    };
};

/*  From a List

    [icon] message-summary
            * detail
            * detail

*/

export const formatMessageHeaderDetailList = (message, data) => {
    const details = () => {
        if (typeof data === 'string') {
            return <li key="unique">{data}</li>;
        }
        return Array.from(data, (item, index) => <li key={index}>{item}</li>);
    };

    return {
        ...message,
        content: (
            <>
                <div className="grid nested-grid">
                    <div className="col-fixed" style={{ width: '40px' }}>
                        <span
                            className={classNames(
                                'p-message-icon pi',
                                SEVERITY_ICON[message.severity],
                            )}
                        />
                    </div>
                    <div className="col">
                        <b>{message.summary}</b>
                        <ul>{details()}</ul>
                    </div>
                </div>
            </>
        ),
    };
};
