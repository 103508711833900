import suma from '@apis/Suma';

const APP_PATH = 'persons/addresses';

export class AddressService {
    get(addressId, detail = null) {
        return suma.get(`${APP_PATH}/${addressId}/`);
    }

    create(request) {
        return suma.post(`${APP_PATH}/`, request);
    }

    update(addressId, request) {
        return suma.patch(`${APP_PATH}/${addressId}/`, request);
    }

    delete(addressId) {
        return suma.delete(`${APP_PATH}/${addressId}/`);
    }
}
