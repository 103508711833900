import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { Message } from 'primereact/message';
import { Row } from 'primereact/row';

import { ExecuteCancelButtons } from '@components/ExecuteCancelButtons';
import { RequestMessages } from '@components/RequestMessages';
import { ExpandButtons, RefreshButton } from '@components/buttons';

import { genericRequestErrors } from '@services/index';
import { ES_AR_PATCHED_LOCALE, formatMoneyTemplate, parseMoney } from '@utils/money';

import { transactionLink } from '@accounting/templates';
import { personFormalNameLink } from '@persons/templates';
import { DisplayTotal } from './confirmation';

import { personNotFoundError } from '@errors/index';
import { AccountingService } from '@services/accounting';

// Issue #58: [webapp.treasury] CreateReceipt improved cancellation amount selection

export const ReceiptCancelations = ({ data, onContinue }) => {
    // State ------------------------------------------------------------------
    const [service] = useState(new AccountingService());
    const [details, setDetails] = useState(data?.details);
    const [summary, setSummary] = useState(data?.summary);
    const [expandedRows, setExpandedRows] = useState(null);
    const hasTransactions = Boolean(Array.isArray(details) && details.length);
    const [requestMessages, setRequestMessages] = useState();
    const [validationErrors, setValidationErrors] = useState();

    // Callbacks & Effects ----------------------------------------------------

    const search = useCallback(() => {
        const resetData = () => {
            setSummary(null);
            setDetails(null);
            setValidationErrors(null);
        };

        if (!data.personId) {
            resetData();
            setRequestMessages([personNotFoundError(data.personId)]);
            return;
        }

        const onSearchError = (error) => {
            resetData();
            error?.response?.status === 404
                ? setRequestMessages([personNotFoundError(data.personId)])
                : setRequestMessages(genericRequestErrors(error));
        };

        function hasCancelation() {
            return this.newPayment !== 0;
        }

        const processData = (data) => {
            const { siteAmount, siteCanceledAmount, siteDueAmount, details } = data;

            // Remove reported persons that don't have any outstanding transactions
            const newDetails = details.filter(
                (detail) => Array.isArray(detail.transactions) && detail.transactions.length,
            );
            newDetails.forEach((newDetail) => {
                newDetail.transactions.forEach((transaction) => {
                    transaction.oldBalance = parseMoney(transaction.siteDueAmount);
                    transaction.newPayment = 0;
                    transaction.newBalance = transaction.oldBalance;
                    transaction.hasCancelation = hasCancelation;
                    transaction.cancelMovement = transaction.siteCancelMovement;
                });
                newDetail.oldBalance = parseMoney(newDetail.siteDueAmount);
                newDetail.newPayment = 0;
                newDetail.newBalance = newDetail.oldBalance;
            });

            const newSummary = { siteAmount, siteCanceledAmount, siteDueAmount };
            newSummary.oldBalance = parseMoney(siteDueAmount);
            newSummary.newPayment = 0;
            newSummary.newBalance = newSummary.oldBalance;

            setDetails(newDetails);
            setSummary(newSummary);
            expandRows(newDetails);
        };

        setRequestMessages(null);
        service
            .siteTransactionsDueSummary(data.personId)
            .then((response) => processData(response.data))
            .catch((error) => onSearchError(error));
    }, [data.personId, service]);

    useEffect(() => {
        const loadedDetails = data?.details;
        if (loadedDetails == null || !loadedDetails?.length) {
            search();
        } else {
            // else: Transactions where loaded before, new payments might exists
            //       We neither validate nor reload the data
            expandRows(loadedDetails);
        }
    }, [search, data]);

    // Helpers ----------------------------------------------------------------

    const validateCancelations = (details, summary) => {
        const newErrors = [];

        // TODO: Check if empty transactions have to be evaluated here
        const hasCancelations =
            Array.isArray(details) &&
            details.some((detail) =>
                detail.transactions.some((transaction) => transaction.hasCancelation()),
            );

        if (!hasCancelations) {
            newErrors.push({
                severity: 'error',
                text: 'Seleccione transacciones e importes a cancelar.',
            });
        }

        setValidationErrors(newErrors);
        return newErrors;
    };

    const updateSummary = (newDetails) => {
        const newSummary = { ...summary };
        newSummary.newPayment = newDetails
            .map((detail) => detail.newPayment)
            .reduce((accumulated, newPayment) => +accumulated + +newPayment, 0);
        newSummary.newBalance = newSummary.oldBalance + newSummary.newPayment;

        setDetails(newDetails);
        setSummary(newSummary);
        validateCancelations(newDetails, newSummary);
    };

    // Events -----------------------------------------------------------------

    const onNewPaymentChange = (oldTransaction, oldDetail, event) => {
        const inputValue = event.value;
        const oldPayment = oldTransaction.newPayment;
        let newPayment = 0;
        if (inputValue) {
            const maxValue = Math.abs(oldTransaction.oldBalance);
            newPayment = Math.abs(inputValue);
            newPayment = newPayment > maxValue ? maxValue : newPayment;
            newPayment = newPayment * oldTransaction.cancelMovement;
        }
        if (newPayment === oldPayment) {
            // Our validation rules take the user input as an absolute value,
            // changing the signs as required. If the user changes the input
            // more than once and all the inputValues after the first one are
            // capped to the same value (i.e. newPayment == oldPayment), then
            // even though the correct value is set in state the old value is
            // displayed in the input component. State does not trigger a re-
            // render of that component because it does not actually change.
            // To prevent this, when the final value will remain unchanged,
            // we manually update the component's displayed value.
            event.originalEvent.target.value = newPayment;
        } else {
            // State is only updated if value changed according to our rules

            const newTransaction = { ...oldTransaction };
            newTransaction.newPayment = newPayment;
            newTransaction.newBalance = newTransaction.oldBalance + newTransaction.newPayment;

            const newTransactions = [...oldDetail.transactions];
            const transactionIndex = oldDetail.transactions.indexOf(oldTransaction);
            newTransactions[transactionIndex] = newTransaction;

            const newDetail = { ...oldDetail };
            newDetail.transactions = newTransactions;
            newDetail.newPayment = newTransactions
                .map((transaction) => transaction.newPayment)
                .reduce((accumulated, newPayment) => +accumulated + +newPayment, 0);
            newDetail.newBalance = newDetail.oldBalance + newDetail.newPayment;

            const newDetails = [...details];
            const detailIndex = details.indexOf(oldDetail);
            newDetails[detailIndex] = newDetail;

            updateSummary(newDetails);
        }
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const errors = validateCancelations(details, summary);
        if (errors.length < 1) {
            const newPart = {
                summary: summary,
                details: details,
            };
            onContinue(newPart);
        }
    };

    const cancelAll = (event) => {
        // TODO: When there are credits that exceed debits, this algorithm would
        // result in an error: Money would be payed out, which is forbidden now.
        // To solve this:
        // - Update all new payments for debits
        // - Then update all payments for credits, up to debits matching amount
        event.preventDefault();
        if (Array.isArray(details) && details.length) {
            const newDetails = details.map((oldDetail) => {
                const newDetail = { ...oldDetail };
                const newTransactions = oldDetail.transactions.map((oldTransaction) => {
                    const newTransaction = { ...oldTransaction };
                    newTransaction.newPayment = -oldTransaction.oldBalance;
                    newTransaction.newBalance = 0;
                    return newTransaction;
                });
                newDetail.transactions = newTransactions;
                newDetail.newPayment = newTransactions
                    .map((newTransaction) => newTransaction.newPayment)
                    .reduce((accumulated, newPayment) => +accumulated + +newPayment, 0);
                newDetail.newBalance = newDetail.oldBalance + newDetail.newPayment;
                return newDetail;
            });
            updateSummary(newDetails);
        }
    };

    const cancelNone = (event) => {
        event.preventDefault();
        if (Array.isArray(details) && details.length) {
            const newDetails = details.map((oldDetail) => {
                const newDetail = { ...oldDetail };
                const newTransactions = oldDetail.transactions.map((oldTransaction) => {
                    const newTransaction = { ...oldTransaction };
                    newTransaction.newPayment = 0;
                    newTransaction.newBalance = oldTransaction.oldBalance;
                    return newTransaction;
                });
                newDetail.transactions = newTransactions;
                newDetail.newPayment = 0;
                newDetail.newBalance = oldDetail.oldBalance;
                return newDetail;
            });
            updateSummary(newDetails);
        }
    };

    const expandRows = (details) => {
        const _expandedRows = {};
        details.forEach((detail) => (_expandedRows[`${detail.person.id}`] = true));
        setExpandedRows(_expandedRows);
    };

    const expandAll = () => {
        expandRows(details);
    };

    const collapseAll = () => {
        setExpandedRows(null);
    };

    // const cancelAllPerson = (oldDetail) => {
    //     const newDetail = { ...oldDetail };
    //     const newTransactions = oldDetail.transactions.map((oldTransaction) => {
    //         const newTransaction = { ...oldTransaction };
    //         newTransaction.newPayment = -oldTransaction.oldBalance;
    //         newTransaction.newBalance = 0;
    //         return newTransaction;
    //     });
    //     newDetail.transactions = newTransactions;
    //     newDetail.newPayment = newTransactions
    //         .map((newTransaction) => newTransaction.newPayment)
    //         .reduce((accumulated, newPayment) => +accumulated + +newPayment, 0);
    //     newDetail.newBalance = newDetail.oldBalance + newDetail.newPayment;

    //     const newDetails = [...details];
    //     const detailIndex = details.indexOf(oldDetail);
    //     newDetails[detailIndex] = newDetail;

    //     updateSummary(newDetails);
    // };

    // const cancelNonePerson = (oldDetail) => {
    //     const newDetail = { ...oldDetail };
    //     const newTransactions = oldDetail.transactions.map((oldTransaction) => {
    //         const newTransaction = { ...oldTransaction };
    //         newTransaction.newPayment = 0;
    //         newTransaction.newBalance = oldTransaction.oldBalance;
    //         return newTransaction;
    //     });
    //     newDetail.transactions = newTransactions;
    //     newDetail.newPayment = newTransactions
    //         .map((newTransaction) => newTransaction.newPayment)
    //         .reduce((accumulated, newPayment) => +accumulated + +newPayment, 0);
    //     newDetail.newBalance = newDetail.oldBalance + newDetail.newPayment;

    //     const newDetails = [...details];
    //     const detailIndex = details.indexOf(oldDetail);
    //     newDetails[detailIndex] = newDetail;

    //     updateSummary(newDetails);
    // };

    // Render : DetailData -----------------------------------------------------

    const detailData = (detail) => {
        const newPaymentTemplate = (transaction) => {
            // if (!canIssueReceipt) {
            //     return emptyColumnValue;
            // }
            // Setting max and min value does not work with our logic, because we
            // are using input values as absolute values, adjusting signs as needed.
            const identification = `input_${transaction.id}`;
            return (
                <InputNumber
                    id={identification}
                    name={identification}
                    value={transaction.newPayment}
                    onValueChange={(event) => onNewPaymentChange(transaction, detail, event)}
                    // min={minValue}
                    // max={maxValue}
                    // mode="currency"
                    // currency="ARS"
                    locale={ES_AR_PATCHED_LOCALE}
                    mode="decimal"
                    minFractionDigits={2}
                />
            );
        };

        const descriptionTemplate = (transaction) => {
            return (
                <>
                    {transaction?.valueDate} <br />
                    {transactionLink(transaction)}
                </>
            );
        };

        return (
            <DataTable value={detail.transactions} emptyMessage="No hay saldos pendientes">
                <Column className="table-column-left" body={descriptionTemplate} />
                <Column className="table-column-money" field="siteDueAmount" />
                <Column className="table-money-input" body={newPaymentTemplate} />
                <Column
                    className="table-column-money"
                    body={(rowData) => formatMoneyTemplate(rowData, 'newBalance')}
                />
            </DataTable>
        );
    };

    // Render : MasterData -----------------------------------------------------

    // Disabled for now: menu button uses up too much room on mobile. See:
    // Issue #58: [webapp.treasury] CreateReceipt improved cancellation amount selection
    // const personAction = (data) => {
    //     const actionMenuItems = [
    //         {
    //             label: 'Todos',
    //             command: () => cancelAllPerson(data),
    //         },
    //         {
    //             label: 'Ninguno',
    //             command: () => cancelNonePerson(data),
    //         },
    //         { separator: true },
    //         {
    //             label: 'Ver movimientos',
    //             url: `#persons/${data.person.id}/balances`,
    //             target: '_blank',
    //             icon: 'pi pi-external-link',
    //         },
    //         {
    //             label: 'Ver de saldos',
    //             url: `#persons/${data.person.id}/dues`,
    //             target: '_blank',
    //             icon: 'pi pi-external-link',
    //         },
    //     ];
    //     return (
    //         <PopupItemsButton
    //             menuItems={actionMenuItems}
    //             // Removes {vertical-align: bottom}, a default result for button.
    //             // The button was still displayed vertically aligned in TD element,
    //             // but the text next to it was aligned to the bottom.
    //             buttonProps={{ style: { verticalAlign: 'middle' } }}
    //         />
    //     );
    // };
    // const personTemplate = (data) => {
    //     return (
    //         <>
    //             {data.person.formalName} {personAction(data)}
    //         </>
    //     );
    // };

    const personTemplate = (data) => personFormalNameLink(data.person);

    const expanderHeader = <ExpandButtons onExpand={expandAll} onCollapse={collapseAll} />;

    const headerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column header={expanderHeader} colSpan={2} />
                <Column className="table-column-money" header="Saldo" />
                <Column className="table-money-input" header="Cancelar" />
                <Column className="table-column-money" header="Resto" />
            </Row>
        </ColumnGroup>
    );

    const footerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column
                    className="table-column-left"
                    footer="Total"
                    colSpan={2}
                    footerStyle={{ textAlign: 'right' }}
                />
                <Column className="table-column-money" footer={summary?.siteDueAmount} />
                <Column
                    className="table-column-money"
                    footer={formatMoneyTemplate(summary, 'newPayment')}
                />
                <Column
                    className="table-column-money"
                    footer={formatMoneyTemplate(summary, 'newBalance')}
                />
            </Row>
        </ColumnGroup>
    );

    const allowExpansion = (detail) => {
        return true;
        // TODO: Disable if there are no cancelations?
        // TODO: Hide person altogether if no cancelations?
    };

    const masterData = () => (
        <DataTable
            value={details}
            dataKey="person.id"
            className="table-expandable-details issue-receipt-cancelations hide-table-header"
            headerColumnGroup={headerColumnGroup}
            footerColumnGroup={footerColumnGroup}
            expandedRows={expandedRows}
            rowExpansionTemplate={detailData}
            onRowToggle={(e) => setExpandedRows(e.data)}
        >
            <Column className="table-column-button-expander" expander={allowExpansion} />
            <Column className="table-column-left" body={personTemplate} />
            <Column className="table-column-money" field="siteDueAmount" />
            <Column
                className="table-money-input"
                body={(data) => formatMoneyTemplate(data, 'newPayment')}
            />
            <Column
                className="table-column-money"
                body={(data) => formatMoneyTemplate(data, 'newBalance')}
            />
        </DataTable>
    );

    const generateOutput = () => {
        if (!summary) {
            return <p>Cargando...</p>;
        }

        // if (inexistentAccount) {
        //     return <p><Link to='/persons'>Buscar otras personas</Link></p>
        // }

        if (!hasTransactions) {
            return (
                <>
                    <p>No hay transacciones pendientes de cancelar</p>
                    <p>
                        <Link to={`/persons/${data.personId}/balances`}>
                            Ver últimos movimientos de cuenta
                        </Link>
                    </p>
                    <p>
                        <Link to="/persons">Buscar otras personas</Link>
                    </p>
                </>
            );
        }

        return masterData();
    };

    const cardTitle = (
        <div className="split-table-header">
            <div className="table-actions-header">
                <span>Transacciones</span>
                {/* Filter overdue */}
                <Button onClick={cancelAll} label="Todas" className="p-button-link" />
                <Button onClick={cancelNone} label="Ninguna" className="p-button-link" />
                {/* Add amount to pay and assign by priority due-date */}
            </div>
            <RefreshButton onRefresh={search} className="hide-constrained-700" />
        </div>
    );

    return (
        <>
            <RequestMessages messages={requestMessages} />

            <Card title={cardTitle}>{generateOutput()}</Card>

            <br />

            {hasTransactions && (
                <>
                    <DisplayTotal amount={-summary?.newPayment} onEdit={null} />

                    <br />

                    {validationErrors && validationErrors.length > 0 && (
                        <div>
                            {validationErrors.map((error, index) => (
                                <div key={index}>
                                    <Message {...error} />
                                </div>
                            ))}
                            <br />
                        </div>
                    )}

                    <ExecuteCancelButtons
                        executeLabel="Continuar"
                        onExecute={onSubmit}
                        showCancel={false}
                    />
                </>
            )}
        </>
    );
};
