import { useController } from 'react-hook-form';

import { InputMask } from 'primereact/inputmask';

import { FieldErrorMessages, invalidClassName } from '@components/field-error-messages';

export const unmaskedDNI = (value) => {
    if (value != null) {
        const cleaned = value.replace(/_/g, '');
        if (cleaned.length > 0) {
            return cleaned;
        }
    }
    return null;
};

const DEFAULT_REQUIRED = false;

const VALIDATE = {
    validDNI: (value) => {
        const cleaned = unmaskedDNI(value);
        return cleaned == null || cleaned.length >= 7 || 'Al menos 7 dígitos';
    },
};

export const DNIField = ({
    fieldName = 'dni',
    fieldLabel = 'DNI',
    control,
    errors,
    autoComplete = 'off',
    required = DEFAULT_REQUIRED,
    ...rest
}) => {
    const { field, fieldState } = useController({
        name: fieldName,
        control,
        rules: { required: required, validate: VALIDATE },
    });

    return (
        <div className="field">
            {fieldLabel && (
                <label htmlFor={fieldName} className={invalidClassName(fieldState.error)}>
                    {fieldLabel}
                    {required ? ' *' : null}
                </label>
            )}
            <InputMask
                id={field.name}
                value={field.value}
                name={field.name}
                mask="9999999?9"
                autoClear={false}
                // IMPORTANT: This does not work at component level
                // <form autoComplete="off"> must be set to be used
                // TODO: Pass it along to the underlying input ctrl.
                // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
                autoComplete={autoComplete}
                className={invalidClassName(fieldState.error)}
                onChange={(e) => field.onChange(e.target.value)}
                {...rest}
            />
            <FieldErrorMessages name={fieldName} errors={errors} />
        </div>
    );
};

DNIField.displayName = 'DNIField';
