import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { DataView } from 'primereact/dataview';

import { DEFAULT_PATH_MANAGING_SITE, configuration } from '@app/configuration';
import { RequestMessages } from '@components/RequestMessages';
import { RefreshButton } from '@components/buttons';
import { useAuth } from '@hooks/use-auth';
import { genericRequestErrors } from '@services/index';

import { SiteService } from '@services/siteService';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

export const SelectSite = () => {
    const auth = useAuth();
    const history = useHistory();
    const [items, setItems] = useState([]);
    const [service] = useState(new SiteService());
    const [requestErrors, setRequestErrors] = useState();

    // Params: Pagination
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);

    // Callbacks --------------------------------------------------------------

    const search = useCallback(
        (first, page, rows) => {
            const searchParams = {
                pageSize: rows,
                page: page + 1,
            };
            setRequestErrors(null);
            service
                .staffSites(searchParams)
                .then((response) => {
                    const data = response.data;
                    setFirst(first);
                    setPage(page);
                    setRows(rows);
                    setItems(data.results);
                    setTotalRecords(data.count ? data.count : 0);
                })
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        },
        [service],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        search(0, 0, rows);
    }, [search, rows]);

    //--------------------------------------------------------------------------
    // Events & Actions
    //--------------------------------------------------------------------------

    const onSiteSelected = (site) => {
        const onSuccess = () => {
            history.push(DEFAULT_PATH_MANAGING_SITE);
        };

        const onError = (error) => {
            setRequestErrors(genericRequestErrors(error));
        };

        auth.putSite(site, onSuccess, onError);
    };

    const onPage = (event) => {
        search(event.first, event.page, event.rows);
    };

    const repeatSearch = () => {
        search(first, page, rows);
    };

    //---------------------------------------------------------------------------
    // Rendering
    //---------------------------------------------------------------------------

    // TOK:
    //  header:
    //      filter sites: [owned | admin | personal]
    //      change layout: [grid | list]
    //  rows:
    //      execute actions: [delete | change_owner | resign_admin]

    const itemTemplate = (site) => (
        <div className="col-12" style={{ padding: '1em' }}>
            <Button
                style={{ width: '100%' }}
                label={`${site.name} ${configuration.developMode ? `(ID: ${site.id})` : ''}`}
                className="p-button-lg"
                onClick={() => onSiteSelected(site)}
            />
        </div>
    );

    const refreshAction = <RefreshButton onRefresh={repeatSearch} style={{ float: 'right' }} />;

    return (
        <Card title={refreshAction}>
            <RequestMessages messages={requestErrors} />
            <DataView
                value={items}
                layout="list"
                itemTemplate={itemTemplate}
                emptyMessage="No se encontraron sitios"
                lazy
                // Use this to show paginator only when required
                paginator={Boolean(totalRecords && totalRecords > MIN_PAGINATION_COUNT)}
                rows={rows}
                first={first}
                onPage={onPage}
                totalRecords={totalRecords}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            />
        </Card>
    );
};
