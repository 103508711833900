import { Button } from 'primereact/button';

export const DownloadButton = ({ onDownload, className = '', ...props }) => (
    <Button
        type="button"
        icon="pi pi-download"
        className={`p-button-rounded p-button-text ${className}`}
        onClick={onDownload}
        {...props}
    />
);
