import { useCallback, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { RequestMessages } from '@components/RequestMessages';
import { RefreshButton } from '@components/buttons';
import { useAuth } from '@hooks/use-auth';
import { genericRequestErrors } from '@services/index';

import { SiteUserService } from '@services/userService';

export const SiteUserTransactionsBalanceMaster = () => {
    const auth = useAuth();
    const [users, setUsers] = useState();
    const [service] = useState(new SiteUserService());
    const [requestMessages, setRequestMessages] = useState();

    // Callbacks --------------------------------------------------------------

    const refresh = useCallback(() => {
        setRequestMessages(null);
        service
            .list()
            .then((response) => {
                setUsers(response.data['results']);
            })
            .catch((error) => setRequestMessages(genericRequestErrors(error)));
    }, [service]);

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        refresh();
    }, [refresh]);

    // Render -----------------------------------------------------------------

    const userTemplate = (data) => {
        return <Link to={`/users/${data.id}/transactions-balance/`}>{data.formalName}</Link>;
    };

    const header = (
        <span className="split-table-header">
            <h2 style={{ marginBottom: 0 }}>{`Staff de ${auth.site.name}`}</h2>
            <RefreshButton onRefresh={refresh} />
        </span>
    );

    return (
        <Card className="user-transactions-balance-master">
            <RequestMessages messages={requestMessages} />
            <DataTable className="hide-columns-header" header={header} value={users}>
                <Column body={userTemplate} />
            </DataTable>
        </Card>
    );
};
