import { settings } from '../localization/settings';

/**
 * Returns true if (min <= value <= max), i.e. inclusive range.
 *
 * Function not exported to namespace it with Integers export.
 *
 * @param {int} value
 * @param {*} min
 * @param {*} max
 */
const insideRange = (value, min, max) => {
    return min <= value && value <= max;
};

export const Integers = {};

Integers.insideRange = insideRange;

// 'count' denotes an integer value
export const orEmptyCount = (count) => {
    return count || settings.defaults.emptyValue;
};
