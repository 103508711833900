import { useEffect, useRef, useState } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import { SubpathTabMenu } from '@components/subpath-tab-menu';
import { SwitchOrRedirect } from '@components/SwitchOrRedirect';

import { PersonDetails } from './details';
import { PersonCommissionPledgeList } from './pledges';
import { PersonRegistrations } from './registrations';
import { PersonResponsibility } from './responsibility';
import { PersonSummary } from './summary';
import { PersonTokenPackList } from './tokens/packs';
import { SiteTransactionsBalance } from './transactions/balances';
import { SiteTransactionsDue } from './transactions/dues';

const aspects = [
    {
        label: 'Detalles',
        path: 'details',
        exact: true,
        component: PersonDetails,
    },
    {
        label: 'Registros',
        path: 'registrations',
        exact: true,
        component: PersonRegistrations,
    },
    {
        label: 'Movimientos',
        path: 'balances',
        exact: true,
        component: SiteTransactionsBalance,
    },
    {
        label: 'Saldos',
        path: 'dues',
        exact: true,
        component: SiteTransactionsDue,
    },
    {
        label: 'Responsables',
        path: 'responsibility',
        exact: true,
        component: PersonResponsibility,
    },
    {
        label: 'Compromisos',
        path: 'pledges',
        exact: true,
        component: PersonCommissionPledgeList,
    },
    {
        label: 'Tokens',
        path: 'tokens',
        exact: true,
        component: PersonTokenPackList,
    },
];

const defaultSubpath = aspects[0].path;

export const PersonRead = () => {
    const match = useRouteMatch();
    const summaryRef = useRef({});
    const [menuModel, setMenuModel] = useState([]);

    useEffect(() => {
        setMenuModel(
            aspects.map((aspect, index) => ({
                key: index,
                label: aspect.label,
                subpath: aspect.path,
                url: `#${match.url}/${aspect.path}`,
            })),
        );
    }, [match.url]);

    // -------------------------------------------------------------------------
    // Rendering
    // -------------------------------------------------------------------------

    const aspectChanged = (aspectName, newData) => {
        summaryRef.current.onAspectChanged(aspectName, newData);
    };

    const renderAspect = (PersonView) => {
        return <PersonView onChanged={aspectChanged} />;
    };

    return (
        <div className="person-page">
            <PersonSummary summaryRef={summaryRef} />

            <br />
            <br />

            <SubpathTabMenu
                menuModel={menuModel}
                rootPath={match.url}
                defaultSubpath={defaultSubpath}
            >
                <SwitchOrRedirect>
                    {aspects.map((aspect, index) => (
                        <Route
                            key={index}
                            exact={aspect.exact}
                            path={`${match.path}/${aspect.path}`}
                        >
                            {renderAspect(aspect.component)}
                        </Route>
                    ))}
                </SwitchOrRedirect>
            </SubpathTabMenu>
        </div>
    );
};
