import suma from '@apis/Suma';

const fileDownload = require('js-file-download');

// Feature detection
export const webShareSupported = 'canShare' in navigator;
// NOTE: We should still actually test if blobs can be shared...
// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/canShare

export const downloadBlob = ({ url, altFilename = 'archivo', configOverride = {} }) => {
    return new Promise(function (resolve, reject) {
        //Force receive data in Blob Format
        //      responseType: 'blob',
        // Using accept headers does not work for PDF:
        //      headers: {Accept: 'application/pdf'}
        // Returns an error: "406 Not Acceptable"
        // This has probably to do with our patched render.
        // XLSX file downloads do require an accept header.

        const config = {
            responseType: 'blob',
            ...configOverride,
        };
        suma.get(url, config)
            .then((response) => {
                // Parse the response file name or set an alternative filename
                const contentDisposition = response.headers['content-disposition'];
                const solvedFilename = contentDisposition.split('=')[1] || altFilename;
                const cleanedFilename = solvedFilename.replace(/["']/g, '');
                // Download the file
                fileDownload(response.data, cleanedFilename);
                // Return the filename
                resolve({ filename: cleanedFilename });
            })
            .catch((error) => reject(error));
    });
};

// When shared to email and WhatsApp, the server returned filename is used.
// When shared to GoogleDrive, then the title is used as suggested filename.

export const shareDownloadBlob = ({
    url,
    title = 'Documento',
    text = '',
    altFilename = 'archivo',
}) => {
    return new Promise(function (resolve, reject) {
        suma.get(url, { responseType: 'blob' })
            .then((response) => {
                const blob = response.data;
                // Parse the response file name or set an alternative filename
                const contentDisposition = response.headers['content-disposition'];
                const solvedFilename = contentDisposition.split('=')[1] || altFilename;
                const cleanedFilename = solvedFilename.replace(/["']/g, '');
                return {
                    title,
                    text,
                    files: [
                        new File([blob], cleanedFilename, {
                            type: blob.type,
                        }),
                    ],
                };
            })
            .then((fileShare) => navigator.share(fileShare))
            .catch((error) => (error.name === 'AbortError' ? resolve() : reject(error)));
    });
};
