export class TransactionType {
    // https://www.sohamkamani.com/javascript/enums/
    // TODO: Consider returning this information from server with search queries.

    // RelationFeeType --------------------------------------------------------

    static FEE_REVENUE = new TransactionType({
        id: '85b56e8faa064c74a0c35e66a27816a1',
        value: 'FEE_INCOME',
        label: 'Cuota',
        plural: 'Cuotas',
    });

    static FEE_DISCOUNT = new TransactionType({
        id: '5de02d24c47b4d6cbdbd4d3c6921bacc',
        value: 'FEE_DISCOUNT',
        label: 'Descuento cuota',
        plural: 'Descuentos de cuota',
    });

    // CommercialTransactionType ----------------------------------------------

    static REVENUE = new TransactionType({
        id: '870893b2f09f425fa61f04aa40b08a2e',
        value: 'REVENUE',
        label: 'Ingreso a Cobrar',
        plural: 'Ingresos a Cobrar',
    });
    static REVENUE_DISCOUNT = new TransactionType({
        id: 'fa8b4436c13841a5adee62d9253ae495',
        value: 'REVENUE_DISCOUNT',
        label: 'Descuento Otorgado',
        plural: 'Descuentos Otorgados',
    });

    static EXPENSE = new TransactionType({
        id: '9d6c850d6b6a4be9b609383f1cfbdc58',
        value: 'EXPENSE',
        label: 'Egreso a Pagar',
        plural: 'Egresos a Pagar',
    });
    static EXPENSE_DISCOUNT = new TransactionType({
        id: '7e4399d801bc4280bad67b45abc61a90',
        value: 'EXPENSE_DISCOUNT',
        label: 'Descuento Recibido',
        plural: 'Descuentos Recibidos',
    });

    static FEE_REVENUE_REVERSE = new TransactionType({
        id: 'caea57ca23f441348e9e4cb9ff48fff2',
        value: 'FEE_REVENUE_REVERSE',
        label: 'Reversión de Cuota',
        plural: 'Reversiones de Cuotas',
    });
    static FEE_DISCOUNT_REVERSE = new TransactionType({
        id: 'ec736b0c5a8d401f8febccd42dcb9fef',
        value: 'FEE_DISCOUNT_REVERSE',
        label: 'Reversión de Descuento Cuota',
        plural: 'Reversiones de Descuentos Cuotas',
    });

    static ACCOUNT_DEBIT = new TransactionType({
        id: '065581b1ea314cb997d94da32708d004',
        value: 'ACCOUNT_DEBIT',
        label: 'Débito',
        plural: 'Débitos',
    });
    static ACCOUNT_CREDIT = new TransactionType({
        id: '7405e25260f84266926bec9d04cdec79',
        value: 'ACCOUNT_CREDIT',
        label: 'Crédito',
        plural: 'Créditos',
    });

    // TokenTransactionType ----------------------------------------------------

    static TOKEN_REVENUE = new TransactionType({
        id: '7405e25260f84266926bec9d04cdec79',
        value: 'TOKEN_REVENUE',
        label: 'Venta de Tokens',
        plural: 'Ventas de Tokens',
    });

    // CashTransactionType ----------------------------------------------------

    static CASH_REGISTER_DEPOSIT = new TransactionType({
        id: 'dd79d1b223004e788747d0b84386d06a',
        value: 'CASH_REGISTER_DEPOSIT',
        label: 'Depósito en Caja',
        plural: 'Depósitos en Caja',
        userRequired: true,
        personRequired: false,
    });
    static CASH_REGISTER_WITHDRAWAL = new TransactionType({
        id: 'ec9f503e1dc8448c972ff26e533c44e8',
        value: 'CASH_REGISTER_WITHDRAWAL',
        label: 'Extracción de Caja',
        plural: 'Extracciones de Caja',
        userRequired: true,
        personRequired: false,
    });
    static CASH_INCOMING_PAYMENT = new TransactionType({
        id: '1317ccd835694a8ab63051d6b9f68afb',
        value: 'CASH_INCOMING_PAYMENT',
        label: 'Cobro en Efectivo',
        plural: 'Cobros en Efectivo',
    });
    static CASH_OUTGOING_PAYMENT = new TransactionType({
        id: '021fbd8fff8f452bbb530431f09cfd01',
        value: 'CASH_OUTGOING_PAYMENT',
        label: 'Pago en Efectivo',
        plural: 'Pagos en Efectivo',
    });

    // FundTransactionType ---------------------------------------------------

    static FUND_ACCOUNT_DEPOSIT = new TransactionType({
        id: '45dd0c4622df4d4e87d0de4ba1b25155',
        value: 'FUND_ACCOUNT_DEPOSIT',
        label: 'Depósito en Cuenta',
        plural: 'Depósitos en Cuenta',
        userRequired: true,
        personRequired: false,
    });
    static FUND_ACCOUNT_WITHDRAWAL = new TransactionType({
        id: '9693155563a9474b8738e57d5f435b99',
        value: 'FUND_ACCOUNT_WITHDRAWAL',
        label: 'Extracción de Cuenta',
        plural: 'Extracciones de Cuenta',
        userRequired: true,
        personRequired: false,
    });

    static FUND_INCOMING_TRANSFER = new TransactionType({
        id: '69d0d7b03cc0427fa8691e86649ac51e',
        value: 'FUND_INCOMING_TRANSFER',
        label: 'Transferencia Recibida',
        plural: 'Transferencias Recibidas',
    });
    static FUND_OUTGOING_TRANSFER = new TransactionType({
        id: '64ffff8b123a4b41820c29a1c4ef7ab6',
        value: 'FUND_OUTGOING_TRANSFER',
        label: 'Transferencia Enviada',
        plural: 'Transferencias Enviadas',
    });

    // CommissionTransactionType ---------------------------------------------

    static COMMISSION_INCOMING = new TransactionType({
        id: '9df22c63397f440e8ffe780acf7a4663',
        value: 'COMMISSION_INCOMING',
        label: 'Recaudación',
        plural: 'Recaudaciones',
    });

    static COMMISSION_OUTGOING = new TransactionType({
        id: 'f0e20e11b21140ab9b179f172f99182b',
        value: 'COMMISSION_OUTGOING',
        label: 'Rendición',
        plural: 'Rendiciones',
    });

    // Constructor -----------------------------------------------------------

    constructor({ id, value, label, plural, userRequired = false, personRequired = true }) {
        this.id = id;
        this.value = value;
        this.label = label;
        this.plural = plural ? plural : `${label}s`;
        this.userRequired = userRequired;
        this.personRequired = personRequired;
    }

    static ALL = [
        // prettier-ignore
        this.FEE_REVENUE,
        this.FEE_DISCOUNT,
        // ----------------------------
        this.REVENUE,
        this.REVENUE_DISCOUNT,
        this.EXPENSE,
        this.EXPENSE_DISCOUNT,
        this.ACCOUNT_DEBIT,
        this.ACCOUNT_CREDIT,
        // ----------------------------
        this.CASH_REGISTER_DEPOSIT,
        this.CASH_REGISTER_WITHDRAWAL,
        this.CASH_INCOMING_PAYMENT,
        this.CASH_OUTGOING_PAYMENT,
        // ----------------------------
        this.FUND_ACCOUNT_DEPOSIT,
        this.FUND_ACCOUNT_WITHDRAWAL,
        this.FUND_INCOMING_TRANSFER,
        this.FUND_OUTGOING_TRANSFER,
        // ----------------------------
        this.COMMISSION_INCOMING,
        this.COMMISSION_OUTGOING,
    ];

    static fromId(id) {
        return this.ALL.find((element) => element?.id === id);
    }
}
