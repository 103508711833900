import { useEffect, useState } from 'react';

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Message } from 'primereact/message';
import { useForm, useWatch } from 'react-hook-form';

import { userDescription } from '@account/templates';
import { AmountField } from '@components/fields';
import { ModalForm } from '@components/forms';
import { genericRequestErrors } from '@services/index';
import { formatMoney, isoLocalDateTimeTemplate, parseMoney } from '@utils/money';

import { CashRegisterService } from '@services/cashService';

const DEFAULT_VALUES = {
    amount: null,
};

const IS_OPENED_MESSAGE = {
    severity: 'success',
    summary: 'La caja ya estaba abierta',
    detail: null,
    closable: true,
    life: 3000,
};

const IS_CLOSED_MESSAGE = {
    severity: 'warn',
    summary: 'La caja ya estaba cerrada',
    detail: null,
    closable: true,
    life: 3000,
};

export const CashRegisterOpen = ({ registerId, onSuccess, onCancel }) => {
    const [service] = useState(new CashRegisterService());
    const [lastSession, setLastSession] = useState(null);
    const [requestErrors, setRequestErrors] = useState();

    useEffect(() => {
        const checkRetrievedSession = (retrievedSession) => {
            if (retrievedSession?.isOpen) {
                onSuccess({ message: IS_OPENED_MESSAGE });
            } else {
                setLastSession(retrievedSession);
            }
        };

        registerId
            ? service
                  .lastSession(registerId)
                  .then((response) => checkRetrievedSession(response.data))
                  .catch((error) => setRequestErrors(genericRequestErrors(error)))
            : setLastSession(null);
    }, [service, registerId, onSuccess]);

    const onExecute = () => {
        setRequestErrors(null);
        service
            .open(registerId)
            .then(onSuccess)
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    };

    const getMessage = () => {
        if (lastSession) {
            return lastSession?.closedBy ? (
                <div>
                    <p>La caja se abrirá con el importe del último cierre:</p>
                    <p className="font-bold text-3xl">$ {lastSession.closeAmount}</p>
                    <Message
                        severity="warn"
                        text="Verifique que este importe se encuentre en la caja"
                    />
                    <br />
                    <br />
                    <p>Último cierre</p>
                    <ul>
                        <li>Usuario: {userDescription(lastSession.closedBy)}</li>
                        <li>{isoLocalDateTimeTemplate(lastSession, 'closedOn')}</li>
                    </ul>
                </div>
            ) : (
                <div className="p-fluid">
                    <p>Esta es la primera vez que se abre esta caja.</p>
                    <p>
                        La caja se abrirá con un importe de
                        <span className="font-bold"> $ 0,00</span>
                    </p>
                </div>
            );
        }
        return <p>Cargando información de cierre anterior...</p>;
    };

    return (
        <ModalForm
            onSubmit={onExecute}
            title="Abrir Caja"
            buttons={{
                executeLabel: 'Abrir Caja',
                executeDisabled: lastSession == null || requestErrors != null,
            }}
            onCancel={onCancel}
            requestErrors={requestErrors}
        >
            {requestErrors == null ? getMessage() : null}
        </ModalForm>
    );
};

const computeExpectedError = (expectedAmount, currentAmount) => {
    if ((expectedAmount || expectedAmount === 0) && (currentAmount || currentAmount === 0)) {
        return expectedAmount === currentAmount ? null : currentAmount - expectedAmount;
    }
    return null;
};

export const CashRegisterClose = ({ registerId, onSuccess, onCancel }) => {
    const [service] = useState(new CashRegisterService());
    const [lastSession, setLastSession] = useState();
    const [expectedAmount, setExpectedAmount] = useState(DEFAULT_VALUES.amount);
    const [expectedError, setExpectedError] = useState(null);
    const [requestErrors, setRequestErrors] = useState();
    const {
        control,
        setValue,
        formState: { errors },
        handleSubmit,
    } = useForm({ defaultValues: DEFAULT_VALUES });

    const currentAmount = useWatch({
        control,
        name: 'amount',
        defaultValue: DEFAULT_VALUES.amount,
    });

    useEffect(() => {
        const onRetrievedSession = (retrievedSession) => {
            if (retrievedSession?.isOpen) {
                const newExpectedAmount = parseMoney(retrievedSession?.closeAmount);
                setValue('amount', newExpectedAmount);
                setExpectedAmount(newExpectedAmount);
                setLastSession(retrievedSession);
            } else {
                onSuccess({ message: IS_CLOSED_MESSAGE });
            }
        };

        registerId
            ? service
                  .lastSession(registerId)
                  .then((response) => onRetrievedSession(response.data))
                  .catch((error) => setRequestErrors(genericRequestErrors(error)))
            : setLastSession(null);
    }, [service, setValue, registerId, onSuccess]);

    useEffect(() => {
        setExpectedError(computeExpectedError(expectedAmount, currentAmount));
    }, [expectedAmount, currentAmount]);

    const onExecute = (data) => {
        const request = {
            id: registerId,
            closeAmount: data.amount,
        };
        service
            .close(request)
            .then(onSuccess)
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    };

    const checkBeforeExecute = (data) => {
        // We recompute expectedError here, because when the submit event
        // executes the controlled/watched `currentAmount` field value, and
        // therefore the computed `expectedError`, might not be yet updated.
        const newExpectedError = computeExpectedError(expectedAmount, data.amount);

        const accept = () => onExecute(data);
        const confirmError = () => {
            confirmDialog({
                header: 'Confirmar error',
                message: closeErrorMessage(newExpectedError),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Confirmar',
                rejectLabel: 'Cancelar',
                accept,
            });
        };
        newExpectedError ? confirmError() : accept();
    };

    const closeErrorMessage = (value) =>
        `Error de cierre: $ ${formatMoney(value)} ${value > 0 ? '(Sobrante)' : '(Faltante)'}`;

    return (
        <ModalForm
            onSubmit={handleSubmit(checkBeforeExecute)}
            title="Cerrar Caja"
            buttons={{ executeLabel: 'Cerrar' }}
            onCancel={onCancel}
            requestErrors={requestErrors}
            style={{ width: '400px' }}
        >
            <ConfirmDialog />
            <div className="p-fluid">
                <AmountField
                    fieldLabel="Monto final en caja"
                    control={control}
                    errors={errors}
                    autoFocus
                    rules={{
                        required: true,
                        validate: (value) =>
                            value >= 0 || 'Debe ser cero o un número mayor que cero',
                    }}
                />
                <div>
                    {lastSession && (
                        <Message
                            severity="info"
                            text={`Importe esperado al cierre: $ ${lastSession.closeAmount}`}
                        />
                    )}
                    <br />
                    {expectedError && (
                        <Message severity="warn" text={closeErrorMessage(expectedError)} />
                    )}
                </div>
            </div>
        </ModalForm>
    );
};
