import { InputText } from 'primereact/inputtext';

import { FieldErrorMessages, errorClassName, invalidClassName } from '../field-error-messages';

export const EmailField = ({ register, errors, required = false, ...rest }) => {
    return (
        <div className="field">
            <label htmlFor="email" className={errorClassName(errors.email)}>
                E-mail
            </label>
            <InputText
                id="email"
                name="email"
                {...register('email', {
                    required: required,
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Dirección de e-mail inválida. E.g. persona@example.com',
                    },
                })}
                autoComplete="off"
                className={invalidClassName(errors.email)}
                {...rest}
            />
            <FieldErrorMessages name="email" errors={errors} />
        </div>
    );
};

EmailField.displayName = 'FirstNameField';
