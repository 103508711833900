import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Card } from 'primereact/card';

import { LoadingIndicator } from '@components/LoadingIndicator';
import { RequestMessages } from '@components/RequestMessages';
import { RefreshButton } from '@components/buttons';
import { genericRequestErrors } from '@services/index';

import { CashSessionService } from '@services/cashService';
import { CashRegisterAction } from '@treasury/cash/registers/action';
import { CashRegisterControl, CashSessionsLink } from '@treasury/cash/registers/control';
import { CashSessionDetail } from '@treasury/cash/sessions/detail';
import { TreasuryPaths } from '@treasury/routes';

const SESSION_CONTROL_ACTIONS = new Set([
    // prettier:off
    CashRegisterAction.FULL_STATUS,
    CashRegisterAction.VIEW_OPENED,
]);

export const CashSession = () => {
    const params = useParams();
    const [service] = useState(new CashSessionService());
    const [loading, setLoading] = useState(true);
    const [register, setRegister] = useState();
    const [session, setSession] = useState();
    const [requestErrors, setRequestErrors] = useState();

    // Callbacks --------------------------------------------------------------

    const read = useCallback(() => {
        setLoading(true);
        setRequestErrors(null);
        service
            .get(params.id)
            .then((response) => {
                setRegister(response.data?.register);
                setSession(response.data);
            })
            .catch((error) => setRequestErrors(genericRequestErrors(error)))
            .finally(() => setLoading(false));
    }, [params.id, service]);

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        read();
    }, [read]);

    // Render -----------------------------------------------------------------

    const refreshAction = <RefreshButton onRefresh={read} style={{ float: 'right' }} />;

    return (
        <div className="cash-session">
            <Card
                title={
                    <>
                        Sesión de Caja: {register?.name}
                        {refreshAction}
                    </>
                }
                subTitle={
                    <div className="flex flex-wrap gap-3">
                        <CashSessionsLink registerId={register?.id} />
                        <Link to={TreasuryPaths.LocalPayments.dashboard}>Efectivo y Cajas</Link>
                    </div>
                }
            >
                <CashRegisterControl
                    registerId={register?.id}
                    session={session}
                    actionSet={SESSION_CONTROL_ACTIONS}
                    isAuthorizedUser={register?.isAuthorizedUser}
                    onChanged={read}
                />
            </Card>
            <RequestMessages messages={requestErrors} />
            <LoadingIndicator loading={loading} />
            <br />
            <CashSessionDetail session={session} />
        </div>
    );
};
