import { Redirect, Route } from 'react-router-dom';

import { SwitchOrRedirect } from '@components/SwitchOrRedirect';
import { routesInfo as demoRoutesInfo } from '../demos/routes';
import { routesInfo as playgroundRoutesInfo } from '../playground/routes';
import { siteRoutesInfo, userRoutesInfo } from '../protected/routes';
import { configuration, defaultPathAuthenticated } from './configuration';

const devsRoutesInfo = [...demoRoutesInfo, ...playgroundRoutesInfo];

export const allRoutesInfo = [...siteRoutesInfo, ...userRoutesInfo, ...devsRoutesInfo];

export const AppRoutes = ({ auth }) => {
    return (
        <SwitchOrRedirect>
            <Route path="/" exact={true}>
                <Redirect to={defaultPathAuthenticated(auth.site)} />
            </Route>

            {/* auth.user is always set, because of AppWrapper */}
            {userRoutesInfo.map((routeInfo, index) => (
                <Route key={index} {...routeInfo} />
            ))}

            {/* auth.user is always set, because of AppWrapper */}
            {configuration.developMode &&
                devsRoutesInfo.map((routeInfo, index) => <Route key={index} {...routeInfo} />)}

            {/* if site is not set, redirect user to select site */}
            {!auth.site && (
                <Route>
                    <Redirect to={defaultPathAuthenticated(auth.site)} />
                </Route>
            )}
            {/* TODO: siteRoutes depend on wether auth.isSiteOwner,
            or more generally if user has permission for that view. */}
            {siteRoutesInfo.map((routeInfo, index) => (
                <Route key={index} {...routeInfo} />
            ))}
        </SwitchOrRedirect>
    );
};
