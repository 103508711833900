import { classNames } from 'primereact/utils';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AppMenu } from './AppMenu';
import { AppProfile } from './AppProfile';
import { AppTopbar } from './AppTopbar';

import { menuItems as appMenu } from '@app/AppMenuItems';
import { AppRoutes } from '@app/AppRoutes';

import { Tooltip } from 'primereact/tooltip';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import './assets/styles/app.scss';

import { useAuth } from '@hooks/use-auth';
import SumaLogoWhite from '@images/suma-logo-white.svg';

const makeMenuModel = (auth) => {
    // console.log('App.menuModel rendered....');
    const items = [];
    if (auth.site) {
        items.push(...appMenu(auth));
    }
    items.push({
        separator: true,
        items: [
            // { separator: true },
            {
                label: 'Salir',
                icon: 'pi pi-fw pi-power-off',
                command: () => auth.signOut(),
            },
        ],
    });
    return items;
};

export const App = () => {
    const [menuActive, setMenuActive] = useState(false);
    const [menuMode] = useState('static');
    const [darkMenu] = useState(false); // changed, not default
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [inlineMenuActive, setInlineMenuActive] = useState(false);
    const [profileMode] = useState('inline'); // changed, not default
    const [inputStyle] = useState('outlined');
    const [ripple] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    const auth = useAuth();
    const [menuModel, setMenuModel] = useState();
    const history = useHistory();

    let menuClick = false;
    let inlineMenuClick = false;

    useEffect(() => {
        setMenuModel(makeMenuModel(auth));
    }, [auth]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onDocumentClick = () => {
        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }
            hideOverlayMenu();
        }

        if (!inlineMenuClick && profileMode === 'inline' && isSlim() && !isMobile()) {
            setInlineMenuActive(false);
        }

        inlineMenuClick = false;
        menuClick = false;
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();

            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
    };

    const onMenuClick = () => {
        menuClick = true;

        if (inlineMenuActive && !inlineMenuClick) {
            setInlineMenuActive(false);
        }
    };

    const isMenuVisible = () => {
        if (isDesktop()) {
            if (menuMode === 'static') return !staticMenuDesktopInactive;
            else if (menuMode === 'overlay') return overlayMenuActive;
            else return true;
        } else {
            return true;
        }
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isOverlay() && !isMobile()) {
            setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
        } else {
            if (isDesktop()) {
                setStaticMenuDesktopInactive(
                    (prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive,
                );
            } else {
                setStaticMenuMobileActive(
                    (prevStaticMenuMobileActive) => !prevStaticMenuMobileActive,
                );
            }
        }

        event.preventDefault();
    };

    const onProfileButtonClick = (event) => {
        setInlineMenuActive((prevInlineMenuActive) => !prevInlineMenuActive);
        inlineMenuClick = true;
        hideOverlayMenu();

        if (isSlim() || isHorizontal()) {
            setMenuActive(false);
        }
        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const isDesktop = () => {
        return window.innerWidth > 896;
    };

    const isMobile = () => {
        return window.innerWidth <= 896;
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isStatic = () => {
        return menuMode === 'static';
    };

    const hasInlineProfile = profileMode === 'inline' && !isHorizontal();

    const containerClassName = classNames('layout-wrapper', {
        'layout-static': isStatic(),
        'layout-overlay': isOverlay(),
        'layout-overlay-active': overlayMenuActive,
        'layout-horizontal': isHorizontal(),
        'layout-slim': isSlim(),
        'layout-static-inactive': staticMenuDesktopInactive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-menu-dark': darkMenu,
        'layout-menu-light': !darkMenu,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple,
    });

    const menuContainerClassName = classNames('layout-menu-container', {
        'layout-menu-container-inactive': !isMenuVisible(),
    });

    return (
        <div className={containerClassName} onClick={onDocumentClick}>
            {/* Consider removing this, don't know what it is for */}
            <Tooltip
                ref={copyTooltipRef}
                target=".block-action-copy"
                position="bottom"
                content="Copied to clipboard"
                event="focus"
            />

            <AppTopbar onMenuButtonClick={onMenuButtonClick} />

            <div className={menuContainerClassName} onClick={onMenuClick}>
                <div className="layout-menu-logo">
                    <button className="p-link" onClick={() => history.push('/')}>
                        <img alt="Suma Logo" src={SumaLogoWhite} style={{ width: '84px' }} />
                    </button>
                </div>
                <div className="layout-menu-wrapper">
                    <div className="menu-scroll-content">
                        {hasInlineProfile && (
                            <AppProfile
                                inlineMenuActive={inlineMenuActive}
                                onProfileButtonClick={onProfileButtonClick}
                            />
                        )}
                        <AppMenu
                            model={menuModel}
                            menuMode={menuMode}
                            active={menuActive}
                            onMenuitemClick={onMenuitemClick}
                            onRootMenuitemClick={onRootMenuitemClick}
                        />
                    </div>
                </div>
            </div>

            <div className="layout-main">
                <div className="layout-content">
                    <AppRoutes auth={auth} />
                </div>
            </div>

            {staticMenuMobileActive && <div className="layout-mask"></div>}
        </div>
    );
};
