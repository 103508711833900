import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';

import { toMonthYearFromISOString } from '@utils/date-utils';

export const RegistrationPaymentChoices = ({ registration, onCancel }) => {
    return (
        <Dialog
            modal
            visible={true}
            onHide={onCancel}
            header="Opciones de Pago"
            style={{ width: '500px' }}
        >
            <DataTable value={registration.paymentChoices}>
                <Column header="Opción" field="paymentOption.name" />
                <Column
                    header="Desde"
                    field="since"
                    className="table-column-date"
                    body={(row) => toMonthYearFromISOString(row?.since, '-.-')}
                />
                <Column
                    header="Hasta"
                    field="until"
                    className="table-column-date"
                    body={(row) => toMonthYearFromISOString(row?.until, 'Abierto')}
                />
            </DataTable>
        </Dialog>
    );
};
