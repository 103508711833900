import { InputText } from 'primereact/inputtext';

import { FieldErrorMessages, errorClassName, invalidClassName } from '../field-error-messages';

export const FirstNameField = ({ register, errors, required = true, ...rest }) => {
    return (
        <div className="field">
            <label htmlFor="firstName" className={errorClassName(errors.firstName)}>
                Nombres
            </label>
            <InputText
                id="firstName"
                name="firstName"
                {...register('firstName', {
                    required: required,
                    minLength: { value: 2, message: 'Al menos dos letras' },
                })}
                type="text"
                autoComplete="off"
                className={invalidClassName(errors.firstName)}
                {...rest}
            />
            <FieldErrorMessages name="firstName" errors={errors} />
        </div>
    );
};

FirstNameField.displayName = 'FirstNameField';
