import { Login } from '@components/Login';
import SumaLogoWhite from '@images/suma-logo-white.svg';
import { email, website, whatsapp } from '@public/template';

// NOTE: Why do you make the SumaLogoDetail a clickable link?
// When someone messes up with the app URL (e.g. adding 'some-extra/#/'), the
// app redirects to the login page. But logging fails with 404 because the api
// url will still have 'some-extra' in it. Clicking the logo affords returning
// to the valid '/' root path, which will actually redirect user to /#/$URI.
// TODO: Auto redirect user removing 'some-extra' any time it is detected.

export const LoginPage = () => {
    return (
        <div className="login-page">
            <div className="login-main">
                <div className="login-main-logo">
                    <img src={SumaLogoWhite} alt="Suma" />
                </div>
                <div className="login-main-input">
                    <Login />
                </div>
                <hr />
                <div className="login-main-contact">
                    <p>Contactanos para crear tu cuenta:</p>
                    {whatsapp}
                    <br />
                    {email}
                    <br />
                    {website}
                </div>
            </div>
        </div>
    );
};
