import { useEffect, useState } from 'react';

import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';

import { RequestMessages } from '@components/RequestMessages';
import { genericRequestErrors } from '@services/index';
import { SiteService } from '@services/siteService';

/* Afford the user to use the browser back-button as `Cancel` / `Abort`

Make action-sub-path views work the same if the user uses the application
provided controls or uses the browser back-button to cancel a given action.

Using history.replace to navigate between a base-path an action-sub-paths
only works if the user uses the app controls, not the browser back-button.

To make the browser back-button work between base-path and action-sub-paths,
we need to push all action-sub-paths in the browser-history.

The problem with that is that when the user exits the view from a sub-path,
then clicking the browser back-button from the new location would redirect
to the action-sub-path, not the base-path. To overcome this we use:

We can´t clean the history on component umount, see:
https://stackoverflow.com/questions/60053624/react-router-v4-replace-history-when-component-is-unmounted

-------------------------------------------------------------------------------
PROBLEM: Navigation between action-sub-path, base-path and back
-------------------------------------------------------------------------------

    +-----------+----------+-------------+-----------------------
     FROM        USER       TO            history
    +-----------+----------+-------------+-----------------------
    this         action    this/action   (pushed)
    this/action  cancel    this          (replaced)
    this         action    this/action   (pushed)
>>  this/action  back-btn  this          (pushed by browser) 

    View displays `this` and browser url is correctly updated,
    but the view view still has the this.action = action set.
    The action was not cleared because the user used the browser
    instead of the application provided buttons.

SOLUTION: We detect the condition with `clearAction` and clear the action.

-------------------------------------------------------------------------------
PROBLEM: Navigation between action-sub-path, that-path and back
-------------------------------------------------------------------------------

    +-----------+----------+-------------+-----------------------
     FROM        USER       TO            history
    +-----------+----------+-------------+-----------------------
    this         action    this/action   (pushed)
    this/action  that      that          (pushed by browser)
>>  that         back-btn  this/action   (pushed by browser) UNEXPECTED!

SOLUTION: We detect this condition with `restartView` and make it so:

    this/action  that      that          (pushed by browser)
    that         back-btn  this/action   (pushed by browser)
    this/action  redirect  this          (pushed) EXPECTED!

From the user perspective, this looks like:

    this/action  that      that          (pushed by browser)
    that         back-btn  this          (view is restarted) EXPECTED!
*/

export const ModalSubPath = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const [service] = useState(new SiteService());
    const [sites, setSites] = useState(null);
    const [option, setOption] = useState();
    const [requestErrors, setRequestErrors] = useState();

    const goBack = (
        <Button
            label="goBack"
            onClick={() => {
                setOption(null);
                // Replace to keep history clean.
                // If the user clicks back-browser it will go to the
                // previous view, not to any of the actions paths.
                history.replace(match.path);
            }}
        />
    );

    useEffect(() => {
        service
            .staffSites()
            .then((response) => setSites(response.data.results))
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    }, [service]);

    const BaseView = () => (
        <>
            <h2>PlaygroundTwo</h2>
            <RequestMessages messages={requestErrors} />
            <div className="flex align-items-center gap-3">
                <Dropdown
                    value={option}
                    options={['una', 'dos', 'tres']}
                    onChange={(e) => {
                        setOption(e.value);
                        // Push so that  If the user clicks back-browser,
                        // we are returned to base view (e.g. like cancel)
                        history.push(`${match.path}/${e.value}`);
                    }}
                />
            </div>
            <hr />
            <DataTable value={sites}>
                <Column header="Name" field="name" />
            </DataTable>
        </>
    );

    const ActionOne = () => (
        <>
            <h2>ActionOne: {option}</h2>
            {goBack}
        </>
    );

    const ActionTwo = () => (
        <>
            <h2>ActionTwo: {option}</h2>
            {goBack}
        </>
    );

    const ActionThree = () => (
        <>
            <h2>ActionThree: {option}</h2>
            {goBack}
        </>
    );

    const restartView = option == null && match.path !== location.pathname;
    // Seems not to be needed - See AccountView & ChangePassword
    const clearAction = option != null && match.path === location.pathname;

    console.log('RENDER');
    console.log({
        restart: restartView,
        clearAction: clearAction,
        match: match.path,
        location: location.pathname,
    });

    if (clearAction) {
        setOption(null);
    }

    return (
        <div>
            <Switch>
                {restartView && <Redirect to={match.path} />}
                <Route path={`${match.path}/una`}>
                    <ActionOne />
                </Route>
                <Route path={`${match.path}/dos`}>
                    <ActionTwo />
                </Route>
                <Route path={`${match.path}/tres`}>
                    <ActionThree />
                </Route>
                <Route>
                    <BaseView />
                </Route>
            </Switch>
        </div>
    );
};
