import { TransactionItem } from './transactions/item';
import { TransactionSearch } from './transactions/search';

import { DocumentsSearch } from './documents/search';

import { ReceiptCreate } from './receipts/create';
import { ReceiptItem } from './receipts/item';
import { ReceiptsSearch } from './receipts/search';

import { CommercialTransactionCreate } from './commercial/create';

const BASE_PATH = '/accounting';
const COMMERCIAL_PATH = '/commercial-transactions';

export const AccountingPaths = Object.freeze({
    Transaction: {
        search: BASE_PATH + '/transactions',
        item: BASE_PATH + '/transactions/:id',
    },

    Document: {
        search: BASE_PATH + '/documents',
        item: BASE_PATH + '/documents/:id',
    },

    Receipt: {
        search: BASE_PATH + '/receipts',
        create: BASE_PATH + '/receipts/create',
        item: BASE_PATH + '/receipts/:id',
    },

    Commercial: {
        create: COMMERCIAL_PATH + '/create',
    },
});

export const routesInfo = [
    {
        path: AccountingPaths.Transaction.search,
        title: 'Transacciones',
        exact: true,
        component: TransactionSearch,
    },
    {
        path: AccountingPaths.Transaction.item,
        title: 'Transacción',
        exact: false,
        component: TransactionItem,
    },

    // ------------------------------------------------------------------------

    {
        path: AccountingPaths.Document.search,
        title: 'Comprobantes Fiscales',
        exact: true,
        component: DocumentsSearch,
    },

    // ------------------------------------------------------------------------

    {
        path: AccountingPaths.Receipt.search,
        title: 'Recibos',
        exact: true,
        component: ReceiptsSearch,
    },
    {
        path: AccountingPaths.Receipt.create,
        title: 'Nuevo Recibo',
        exact: false,
        component: ReceiptCreate,
    },
    {
        path: AccountingPaths.Receipt.item,
        title: 'Recibo',
        exact: false,
        component: ReceiptItem,
    },

    // ------------------------------------------------------------------------

    {
        path: AccountingPaths.Commercial.create,
        title: 'Nuevo Movimiento',
        exact: true,
        component: CommercialTransactionCreate,
    },
];
