import { Redirect, Switch } from 'react-router-dom';

import { DEFAULT_PATH_NOT_FOUND } from '@app/configuration';

/**
 * Provides a Switch (matches the first Route) with a final Redirect if none matches.
 *
 * The default redirect path is defaultErrorPath, which returns a 404 page.
 *
 * Based on:
 * https://levelup.gitconnected.com/handle-404-pages-for-nested-routes-in-react-router-2b31572a5b2f
 */

export function SwitchOrRedirect({ children, redirectPath = DEFAULT_PATH_NOT_FOUND }) {
    return (
        <Switch>
            {children}
            <Redirect key={children.length} push={false} to={redirectPath} />
        </Switch>
    );
}
