import { settings } from '../localization/settings';
const accounting = require('accounting');

// -----------------------------------------------------------------------------
// Utils : Formatter & Parser
// -----------------------------------------------------------------------------

export const EMPTY_MONEY_DISPLAY = '-.-';

// Minimum Grouping Digits for latin/spanish locales is 2.
// This produces unexpected formatting results:
//      fomatter.format( 9999, es-ar) >>   '9999,00' expected: '9.999,00'
//      fomatter.format(10000, es-ar) >> '10.000,00'
// https://es.stackoverflow.com/a/419643
//
// Options to solve this problems are:
// - Patch format function (https://es.stackoverflow.com/a/419643)
// - Use de-DE as a temporary override...

export const ES_AR_PATCHED_LOCALE = 'de-DE';

const formatter = new Intl.NumberFormat(ES_AR_PATCHED_LOCALE, {
    // style: 'currency',
    // currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const formatMoney = (value) => {
    return value == null ? EMPTY_MONEY_DISPLAY : formatter.format(value);
};

const DEFAULT_SEPARATOR = ',';

export const parseMoney = (value, separator = DEFAULT_SEPARATOR) => {
    return value == null ? value : accounting.unformat(value, separator);
};

// -----------------------------------------------------------------------------
// Templates
// -----------------------------------------------------------------------------

export const moneyOrEmpty = (value) => {
    return value == null ? EMPTY_MONEY_DISPLAY : value;
};

export const moneyTemplate = (data, fieldName) => {
    return moneyOrEmpty(data && data[fieldName]);
};

export const formatMoneyTemplate = (data, fieldName) => {
    return formatMoney(data && data[fieldName]);
};

export const revocableMoneyTemplate = (data, fieldName) => {
    const amount = data && data[fieldName] ? data[fieldName] : null;
    const isRevoked = data && data?.isRevoked ? data?.isRevoked : false;
    if (!amount) return EMPTY_MONEY_DISPLAY;
    return <span className={isRevoked ? 'revoked-amount' : ''}>{amount}</span>;
};

export const RevocableMoney = ({ amount, isRevoked }) => {
    if (!amount) return EMPTY_MONEY_DISPLAY;
    return <span className={isRevoked ? 'revoked-amount' : ''}>{amount}</span>;
};

// -----------------------------------------------------------------------------
// Date & Time templates
// -----------------------------------------------------------------------------
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date

// TODO: Move all of this to date-utils (renamed as dates and references with an alias)

export const toISOLocalDateString = (value) => {
    if (value == null) return null;
    const year = value.getFullYear();
    const month = (value.getMonth() + 1).toString().padStart(2, '0');
    const day = value.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const toISOLocalHourMinuteString = (value) => {
    return `${value.getHours().toString().padStart(2, '0')}:${value
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
};

export const toISOLocaDateTimeString = (value) => {
    return `${toISOLocalDateString(value)} | ${toISOLocalHourMinuteString(value)}`;
};

export const isoDateTemplate = (data, fieldName, empty) => {
    const value = data && data[fieldName];
    return value == null ? empty || settings.defaults.emptyLocalDate : value;
};

export const isoLocalDateTemplate = (data, fieldName, empty) => {
    const value = data && data[fieldName];
    return value == null
        ? empty || settings.defaults.emptyLocalDate
        : toISOLocalDateString(new Date(value));
};

export const isoLocalDateTimeTemplate = (data, fieldName, stacked = false, empty = null) => {
    const value = data ? data[fieldName] : null;
    if (value == null) {
        return empty || settings.defaults.emptyLocalDateTime;
    }
    const dateValue = new Date(value);
    if (stacked) {
        return (
            <>
                <div>{toISOLocalDateString(dateValue)}</div>
                <div>{toISOLocalHourMinuteString(dateValue)}</div>
            </>
        );
    }
    return toISOLocaDateTimeString(dateValue);
};

// -----------------------------------------------------------------------------
// Generic templates
// -----------------------------------------------------------------------------

export const valueOrEmpty = (data, fieldName, empty) => {
    const value = data == null ? null : data[fieldName];
    if (value == null) {
        return empty || settings.defaults.emptyValue;
    }
    return value;
};
