export const CashRegisterAction = Object.freeze({
    VIEW_OPENED: 'view-opened',
    VIEW_CLOSED: 'view-closed',
    // ------------------------------------------
    OPEN: 'open',
    CLOSE: 'close',
    // ------------------------------------------
    LAST_STATUS: 'last-status',
    FULL_STATUS: 'full-status',
    // ------------------------------------------
    INCOMING_CASH: 'incoming-cash',
    OUTGOING_CASH: 'outgoing-cash',
});

export const ALL_ACTIONS = new Set(Object.values(CashRegisterAction));
