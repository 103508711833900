import { useController } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';

import {
    FieldErrorMessages,
    errorClassName,
    invalidClassName,
} from '@components/field-error-messages';

const RelationField = ({
    fieldName,
    fieldLabel,
    control,
    errors,
    options,
    required = true,
    hideEmpty = true,
    ...rest
}) => {
    const { field, fieldState } = useController({
        control: control,
        name: fieldName,
        rules: {
            required: required,
        },
    });

    if (hideEmpty & (!options || options.length < 1)) {
        return null;
    }

    return (
        <div className="field">
            <label htmlFor={field.name} className={errorClassName(fieldState.error)}>
                {fieldLabel}
                {required ? ' *' : ''}
            </label>
            <Dropdown
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                options={options}
                optionLabel="name"
                placeholder={`Seleccione ${fieldLabel}`}
                className={invalidClassName(fieldState.error)}
                disabled={!options || options.length < 1}
                filter
                showClear
                showFilterClear
                resetFilterOnHide={true}
                optionDisabled={(option) => !option.enabled}
                {...rest}
            />
            <FieldErrorMessages name={field.name} errors={errors} />
        </div>
    );
};

export const MajorRelationField = ({ control, errors, options, required = true, ...rest }) => (
    <RelationField
        fieldName="relation"
        fieldLabel="Relación"
        control={control}
        errors={errors}
        options={options}
        required={required}
        {...rest}
    />
);
MajorRelationField.displayName = 'MajorRelationField';

export const MinorRelationField = ({ control, errors, options, required = true, ...rest }) => (
    <RelationField
        fieldName="minorRelation"
        fieldLabel="Detalle"
        control={control}
        errors={errors}
        options={options}
        required={required}
        {...rest}
    />
);
MinorRelationField.displayName = 'MinorRelationField';
