import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Fieldset } from 'primereact/fieldset';
import { Row } from 'primereact/row';
import { Tag } from 'primereact/tag';

import { RequestMessages } from '@components/RequestMessages';
import { genericRequestErrors } from '@services/index';
import { isoLocalDateTimeTemplate, revocableMoneyTemplate } from '@utils/money';
import { isEmpty } from '@utils/string-utils';

import { userDescription } from '@account/templates';
import { DownloadButton, PopupItemsButton, RefreshButton, ShareButton } from '@components/buttons';
import { PersonFormalNameLink } from '@persons/templates';

import { NewPaymentButton } from '@accounting/receipts/newPayment';
import { ReceiptLink, transactionDescription, transactionLink } from '@accounting/templates';

import { useAuth } from '@hooks/use-auth.js';
import { AccountingService } from '@services/accounting';
import { downloadBlob, shareDownloadBlob } from '@services/sharing';

// Not working...
// const ELLIPSIS_STYLE = {
//     display: 'inline-block',
//     whiteSpace: 'nowrap',
//     overflow: 'hidden',
//     textOverflow: 'ellipsis',
// };

const TransactionDetails = ({ transaction }) => {
    if (!transaction) return null;

    const labelStyle = { width: '120px', fontWeight: 600 };

    const row = (label, value) => (
        <div className="grid align-items-center">
            <div className="col-fixed" style={labelStyle}>
                {label}:
            </div>
            <div className="col">{value}</div>
        </div>
    );

    const description = () => {
        const value = transactionDescription(transaction);
        return isEmpty(value) ? null : row('Descripción', value);
    };

    const disclaimers =
        Array.isArray(transaction?.disclaimers) && transaction.disclaimers.length > 0 ? (
            <>
                {transaction.disclaimers.map((disclaimer, index) => (
                    <span key={index}>
                        {disclaimer}
                        <br />
                    </span>
                ))}
            </>
        ) : null;

    const dueDateTemplate = () => {
        if (transaction?.dueDate) {
            return row(
                'Vencimiento',
                <>
                    {transaction.dueDate}
                    {transaction.isOverdue && (
                        <Tag className="ml-2" value="Vencida" severity="danger" />
                    )}
                </>,
            );
        }
        return null;
    };

    // const invoices = (
    //     <>
    //         <Link>PF-DV Factura 00001-00045648</Link>
    //         <br />
    //         <Link>PF-RR Factura 00004-00000032</Link>
    //         <br />
    //     </>
    // );

    return (
        <Fieldset>
            {/* Display person, even when None, unless transaction.user exists
                If transaction.person == null, it might be required, unless it
                is a transaction.type.user_required */}
            {(transaction?.person || !transaction?.user) &&
                row(
                    'Persona',
                    transaction?.person ? (
                        <PersonFormalNameLink person={transaction.person} />
                    ) : (
                        '-.-'
                    ),
                )}
            {/* Only display user if not None - If present, it is required. */}
            {transaction?.user && row('Usuario', userDescription(transaction.user))}
            {description()}
            {row('Fecha', transaction.valueDate)}
            {dueDateTemplate()}
            {row('Importe', transaction.amount)}
            {transaction?.receipt && row('Recibo', <ReceiptLink receipt={transaction.receipt} />)}
            {/* {row('Número ID', <span style={ELLIPSIS_STYLE}>${transaction.id}</span>)} */}
            {row('Emitido', isoLocalDateTimeTemplate(transaction, 'issuedTime'))}
            {row('Número', <span>{transaction.id}</span>)}
            {transaction?.reversedBy && row('Reversión', transactionLink(transaction.reversedBy))}
            {disclaimers && row('Observaciones', disclaimers)}
            {/* {invoices && row('Factura/s', invoices)} */}
        </Fieldset>
    );
};

const CancelationDetails = ({ transaction, forceUpdate = null }) => {
    if (!transaction) return null;

    const cancelAction =
        transaction.uncanceledAmount && !transaction.isRevoked && transaction.person;

    // TODO: Use a regular large button with caption when space is available
    const dueFooter = (
        <div className="split-table-header">
            <span>Saldo</span>
            {cancelAction && (
                <NewPaymentButton
                    beneficiaryId={transaction.person.id}
                    isOverdue={transaction.isOverdue}
                    forceUpdate={forceUpdate}
                    showBalance={false}
                    buttonProps={{
                        label: '',
                        icon: 'pi pi-bolt',
                        className: 'p-button-rounded',
                    }}
                />
            )}
        </div>
    );

    const footerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column footer={dueFooter} />
                <Column className="table-column-money" footer={transaction.uncanceledAmount} />
            </Row>
        </ColumnGroup>
    );

    const cancelationTemplate = (cancelation) => {
        return (
            <>
                <div>{isoLocalDateTimeTemplate(cancelation.receipt, 'issuedTime')}</div>
                <ReceiptLink receipt={cancelation.receipt} text="Recibo" />
            </>
        );
    };

    return (
        <DataTable
            value={transaction?.cancelations}
            footerColumnGroup={footerColumnGroup}
            className="hide-table-header"
            emptyMessage="No tiene cancelaciones"
        >
            <Column header="Cancelaciones" body={cancelationTemplate} />
            <Column
                header="Importe"
                className="table-column-money"
                body={(cancelation) => revocableMoneyTemplate(cancelation, 'amount')}
            />
        </DataTable>
    );
};

export const TransactionItem = () => {
    const auth = useAuth();
    const params = useParams();
    const [service] = useState(new AccountingService());
    const [loading, setLoading] = useState(true);
    const [transaction, setTransaction] = useState(null);
    const [readTimestamp, setReadTimestamp] = useState();
    const [requestErrors, setRequestErrors] = useState([]);

    // Callbacks --------------------------------------------------------------

    const read = useCallback(() => {
        setLoading(true);
        setRequestErrors(null);
        service
            .transaction(params.id)
            .then((response) => {
                setTransaction(response.data);
                setReadTimestamp(new Date());
            })
            .catch((error) => setRequestErrors(genericRequestErrors(error)))
            .finally(() => setLoading(false));
    }, [params.id, service]);

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        read();
    }, [read]);

    // Events -----------------------------------------------------------------

    const confirmRevoke = () => {
        console.log('confirmRevoke');
        const accept = () => {
            service
                .revokeTransaction(params.id)
                .then(() => read())
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        };

        confirmDialog({
            icon: 'pi pi-exclamation-triangle',
            header: '¿Confirma anular transacción?',
            message: 'Recibos y cancelaciones asociadas serán anuladas.',
            defaultFocus: 'accept',
            acceptLabel: 'Confirmo anular transacción',
            accept,
        });
    };

    // Rendering --------------------------------------------------------------

    const menuItems = [
        {
            label: 'Anular',
            icon: 'pi pi-fw pi-times',
            disabled: transaction?.isRevoked,
            command: confirmRevoke,
        },
    ];

    const menuAction = <PopupItemsButton menuItems={menuItems} />;

    const downloadAction = (
        <DownloadButton
            onDownload={() => {
                downloadBlob(service.transactionPDF(transaction.id))
                    // .then((response) => alert(`Downloaded=${response.filename}`))
                    .catch((error) => setRequestErrors(genericRequestErrors(error)));
            }}
            disabled={!transaction}
        />
    );

    const shareAction = (
        <ShareButton
            onClick={() => {
                shareDownloadBlob({
                    ...service.transactionPDF(transaction.id),
                    title: `${auth.site.name} - ${transaction.type.label}`,
                    text: `Adjuntamos comprobante de ${transaction.type.label}. Atentamente, ${auth.site.name}`,
                }).catch((error) => setRequestErrors(genericRequestErrors(error)));
            }}
            disabled={!transaction}
        />
    );

    // const taxDocumentAction = (
    //     <Button label="Facturar" disabled={!transaction} style={{ marginLeft: '1rem' }} />
    // );

    const refreshAction = <RefreshButton onRefresh={read} />;

    const revokedTitle = transaction && transaction.isRevoked ? ' [ANULADA]' : '';

    const transactionTitle = transaction ? transaction.type.label : '';

    const title = (
        <div className="document-action-header">
            <div className="title">
                {menuAction}
                {`${transactionTitle}${revokedTitle}`}
            </div>
            <div className="actions">
                {downloadAction}
                {shareAction}
                <span className="spacer" />
                {refreshAction}
            </div>
        </div>
    );

    return (
        <Card title={title} className="transaction-item">
            <ConfirmDialog />
            <RequestMessages messages={requestErrors} />
            {loading && (
                <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: '2em', marginBottom: '15px' }}
                />
            )}
            <TransactionDetails transaction={transaction} />
            <br />
            <CancelationDetails transaction={transaction} forceUpdate={readTimestamp} />
        </Card>
    );
};
