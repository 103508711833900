import { PlaygroundOne } from './views/PlaygroundOne';
import { PlaygroundTwo } from './views/PlaygroundTwo';

export const routesInfo = [
    {
        path: '/playground/one',
        title: 'Playground One',
        component: PlaygroundOne,
    },
    {
        path: '/playground/two',
        title: 'Playground Two',
        component: PlaygroundTwo,
    },
];
