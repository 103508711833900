import { useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import { PasswordField } from '@components/fields';
import { ModalPathForm } from '@components/forms';
import { hookFormRequestError } from '@custom/hook-form';

import { SelfUserService } from '@services/userService';
import { AccountPaths } from './routes';

// WARNING: Password and usernames are visible via the browser developer tools.
// It seems this is as "designed" and even facebook, twitter and linkedIn will
// have user credentials display in clear text in those tools. Maybe they could
// be hashed in the client before they are submitted?
// https://stackoverflow.com/a/21515376
// https://stackoverflow.com/a/52184360

const DEFAULT_VALUES = {
    oldPassword: '',
    newPassword: '',
    repeatedNew: '',
};

const NEW_PASSWORD_RULES = {
    required: true,
    minLength: { value: 8, message: 'Debe tener al menos 8 caracteres' },
};

// NOTE: { onSuccess = null, onCancel = null } props guaranty that the
// ChangePassword view will not be reached using back and forth nav
// buttons. The view is effectively removed form the stack.

export const ChangePassword = ({
    // @prettier-off
    // onSuccess = null,
    // onCancel = null,
    from = null,
}) => {
    const location = useLocation();
    const history = useHistory();
    const [service] = useState(new SelfUserService());
    const [requestErrors, setRequestErrors] = useState();
    const {
        control,
        formState: { errors },
        watch,
        setError,
        handleSubmit,
    } = useForm({
        defaultValues: { ...DEFAULT_VALUES },
    });

    const newPassword = watch('newPassword');

    const getFrom = () => {
        return from || location.state?.from || AccountPaths.Account.dashboard;
    };

    const onExecute = (data) => {
        const request = {
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
        };
        service
            .changePassword(request)
            .then(() => history.replace(getFrom()))
            .catch((error) =>
                hookFormRequestError(
                    error,
                    ['oldPassword', 'newPassword'],
                    setError,
                    setRequestErrors,
                ),
            );
    };

    return (
        <ModalPathForm
            title="Cambiar password"
            from={getFrom()}
            onSubmit={handleSubmit(onExecute)}
            requestErrors={requestErrors}
            executeButtonsProps={{
                executeLabel: 'Cambiar password',
                cancelLabel: 'Dejar sin cambiar',
                // onCancel: onCancel,
            }}
            style={{ maxWidth: '400px', position: 'relative', margin: '0 auto' }}
        >
            <div className="p-fluid">
                <PasswordField
                    fieldLabel="Actual"
                    fieldName="oldPassword"
                    control={control}
                    errors={errors}
                    autoFocus
                />
                <PasswordField
                    fieldLabel="Nuevo"
                    fieldName="newPassword"
                    control={control}
                    errors={errors}
                    rules={NEW_PASSWORD_RULES}
                />
                <PasswordField
                    fieldLabel="Repetir Nuevo"
                    fieldName="repeatedNew"
                    control={control}
                    errors={errors}
                    rules={{
                        required: true,
                        validate: (value) => {
                            return value !== newPassword
                                ? 'Debe coincidir con el password nuevo'
                                : null;
                        },
                    }}
                />
            </div>
        </ModalPathForm>
    );
};
