import { useState } from 'react';

import { useForm } from 'react-hook-form';

import { DescriptionField } from '@components/fields';
import { ModalForm } from '@components/forms';
import { genericRequestErrors } from '@services/index';
import { StringUtils } from '@utils/string-utils';

import { CommissionPledgeService } from '@services/commissionsService';

export const ChangeComments = ({ pledge, onSuccess, onCancel }) => {
    const [service] = useState(new CommissionPledgeService());
    const [requestErrors, setRequestErrors] = useState([]);
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: { comments: StringUtils.valueOrEmpty(pledge?.comments, '') },
    });

    const onExecute = (data) => {
        setRequestErrors(null);
        data.comments = StringUtils.strippedOrNull(data.comments);
        service
            .update(pledge.id, data)
            .then((response) => onSuccess && onSuccess(response.data))
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    };

    return (
        <ModalForm
            onSubmit={handleSubmit(onExecute)}
            title="Editar comentarios"
            buttons={{ executeLabel: 'Guardar', showCancel: false }}
            onCancel={onCancel}
            style={{ width: '400px' }}
            requestErrors={requestErrors}
        >
            <div className="p-fluid">
                <DescriptionField
                    fieldLabel="Comentarios"
                    fieldName="comments"
                    control={control}
                    errors={errors}
                    autoFocus
                    rules={{ required: false }}
                />
            </div>
        </ModalForm>
    );
};
