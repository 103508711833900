import { useCallback, useEffect, useState } from 'react';

import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { RequestMessages } from '@components/RequestMessages';
import { RefreshButton } from '@components/buttons';
import { genericRequestErrors } from '@services/index';

import { paymentModeDescription } from '@accounting/templates';
import { AccountingService } from '@services/accounting';

export const PaymentModeSelector = ({ personId, iniPaymentMode, onSelected }) => {
    const [service] = useState(new AccountingService());
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [requestMessages, setRequestMessages] = useState([]);

    // Callbacks --------------------------------------------------------------

    // WARNING: Making unnecessary call to onSelected the first time that the
    // component is mounted, because option is already updated in the client.

    const updateSelectedOption = useCallback(
        (option) => {
            setSelectedOption(option);
            if (onSelected) {
                onSelected(option);
            }
        },
        [onSelected],
    );

    const search = useCallback(() => {
        service
            .paymentModes(personId)
            .then((response) => {
                const newOptions = response.data;
                let newSelectedOption = null;
                // If options are returned, pre select the best one
                if (Array.isArray(newOptions) && newOptions.length > 0) {
                    // If iniPaymentMode exists, reset that value
                    if (iniPaymentMode) {
                        newSelectedOption = newOptions.find((option) => {
                            return option.id === iniPaymentMode.id;
                        });
                    }
                    // Else select the first paymentOption available
                    if (newSelectedOption == null) {
                        newSelectedOption = newOptions[0];
                    }
                }
                updateSelectedOption(newSelectedOption);
                setOptions(newOptions);
            })
            .catch((error) => setRequestMessages(genericRequestErrors(error)));
    }, [service, personId, iniPaymentMode, updateSelectedOption]);

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        search();
    }, [search]);

    // Rendering --------------------------------------------------------------

    const title = (
        <div className="split-table-header">
            <span>Forma de Pago</span>
            <RefreshButton onRefresh={search} />
        </div>
    );

    return (
        <Card title={title}>
            <RequestMessages messages={requestMessages} />

            <DataTable
                dataKey="id"
                className="hide-columns-header"
                emptyMessage="No hay modos de pago disponibles."
                value={options}
                selection={selectedOption}
                selectionMode="single"
                onSelectionChange={(e) => updateSelectedOption(e.value)}
            >
                <Column selectionMode="single" style={{ width: '3em' }} />
                <Column body={paymentModeDescription} />
            </DataTable>
        </Card>
    );
};
