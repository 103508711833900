import { useState } from 'react';

import { useController, useForm } from 'react-hook-form';

import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';

import {
    AmountField,
    DescriptionField,
    PersonField,
    TransactionTypeField,
    UserField,
} from '@components/fields';
import { ModalForm } from '@components/forms';
import { genericRequestErrors } from '@services/index';
import { toISOLocalDateString } from '@utils/date-utils';

import { TransactionType } from '@enums/accounting';
import { AccountingService } from '@services/accounting';

const DEFAULT_VALUES = {
    // TODO: Consider setting a default based on `incoming`.
    // Right now, we choose no default to make the user think
    // and have the chance to display all options on selection.
    // Alternatively, display all options as radio buttons.
    typeId: null,
    user: null,
    person: null,
    amount: null,
    description: '',
    issueTransaction: false,
};

const INCOMING_TYPES = [
    {
        label: 'CLIENTE',
        code: 'CLIENT',
        items: [TransactionType.CASH_INCOMING_PAYMENT],
    },
    {
        label: 'INTERNO',
        code: 'INTERNAL',
        items: [TransactionType.CASH_REGISTER_DEPOSIT],
    },
];

const OUTGOING_TYPES = [
    {
        label: 'PROVEEDOR',
        code: 'VENDOR',
        items: [TransactionType.CASH_OUTGOING_PAYMENT],
    },
    {
        label: 'INTERNO',
        code: 'INTERNAL',
        items: [TransactionType.CASH_REGISTER_WITHDRAWAL],
    },
];

// const ITEM_TYPE_BULL = '- ';
const ITEM_TYPE_BULL = '';
const GROUP_TYPE_SEP = ' >> ';

const typeOptionTemplate = (option) => {
    return `${ITEM_TYPE_BULL}${option.label}`;
};

const selectedTypeOptionTemplate = (option, props) => {
    if (option == null) return <div>{props.placeholder}</div>;

    const groupName = () => {
        switch (option) {
            case TransactionType.CASH_INCOMING_PAYMENT:
                return 'CLIENTE';
            case TransactionType.CASH_OUTGOING_PAYMENT:
                return 'PROVEEDOR';

            case TransactionType.CASH_REGISTER_DEPOSIT:
            case TransactionType.CASH_REGISTER_WITHDRAWAL:
                return 'INTERNO';
            default:
                return null;
        }
    };

    return (
        <div>
            <span className="font-bold">{groupName()}</span>
            {GROUP_TYPE_SEP}
            {option.label}
        </div>
    );
};

const USER_TYPES = new Set([
    TransactionType.CASH_REGISTER_DEPOSIT,
    TransactionType.CASH_REGISTER_WITHDRAWAL,
]);

const PERSON_TYPES = new Set([
    TransactionType.CASH_INCOMING_PAYMENT,
    TransactionType.CASH_OUTGOING_PAYMENT,
]);

const PERSON_TYPES_DESCRIPTION = 'Movimiento económico con terceros (cliente o proveedor)';
const USER_TYPES_DESCRIPTION = 'Movimiento financiero interno (dueño, directivo o empleado)';

const INCOMING_TRANSACTION_PROMPT = 'Registrar una venta o ingreso asociado';
const OUTGOING_TRANSACTION_PROMPT = 'Registrar un gasto o egreso asociado';

const INCOMING_TRANSACTION_OPTION = 'Dejar como cobro a cuenta / adelanto';
const OUTGOING_TRANSACTION_OPTION = 'Dejar como pago a cuenta / adelanto';

const INCOMING_TRANSACTION_ALT = '(Sino, queda como un cobro a cuenta)';
const OUTGOING_TRANSACTION_ALT = '(Sino, queda como un pago a cuenta)';

const INCOMING_TRANSACTION_TYPE = TransactionType.REVENUE;
// TODO: Allow anonymous payments with EXPENSE_TICKET
const OUTGOING_TRANSACTION_TYPE = TransactionType.EXPENSE;

export const transactionDescription = (type) => {
    if (type != null) {
        if (USER_TYPES.has(type)) {
            return <p>{USER_TYPES_DESCRIPTION}</p>;
        }

        if (PERSON_TYPES.has(type)) {
            return <p>{PERSON_TYPES_DESCRIPTION}</p>;
        }
    }
    return null;
};

export const IssueTransactionCheck = ({ control, incoming }) => {
    const { field } = useController({
        control: control,
        name: 'issueTransaction',
    });

    return (
        <div className="flex align-items-center gap-2 mb-3">
            <Checkbox
                inputId={field.name}
                checked={field.value}
                inputRef={field.ref}
                onChange={(e) => field.onChange(e.checked)}
            />
            <label htmlFor={field.name}>
                {incoming ? INCOMING_TRANSACTION_PROMPT : OUTGOING_TRANSACTION_PROMPT}
                <br />
                {incoming ? INCOMING_TRANSACTION_ALT : OUTGOING_TRANSACTION_ALT}
            </label>
        </div>
    );
};

export const IssueTransactionRadio = ({ control, incoming }) => {
    const { field } = useController({
        control: control,
        name: 'issueTransaction',
    });

    return (
        <div className="gap-2 mb-3">
            <div className="flex align-items-center gap-2 mb-3">
                <RadioButton
                    inputId="true"
                    {...field}
                    inputRef={field.ref}
                    value={true}
                    checked={field.value === true}
                />
                <label htmlFor="true" className="ml-1">
                    {incoming ? INCOMING_TRANSACTION_PROMPT : OUTGOING_TRANSACTION_PROMPT}
                </label>
            </div>
            <div className="flex align-items-center gap-2 mb-3">
                <RadioButton
                    inputId="false"
                    {...field}
                    value={false}
                    checked={field.value === false}
                />
                <label htmlFor="false" className="ml-1">
                    {incoming ? INCOMING_TRANSACTION_OPTION : OUTGOING_TRANSACTION_OPTION}
                </label>
            </div>
        </div>
    );
};

const CashTransactionCreate = ({ incoming, sessionId, onSuccess, onCancel }) => {
    const [service] = useState(new AccountingService());
    const [requestErrors, setRequestErrors] = useState();
    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm({
        defaultValues: {
            ...DEFAULT_VALUES,
            typeId: incoming
                ? TransactionType.CASH_INCOMING_PAYMENT.id
                : TransactionType.CASH_OUTGOING_PAYMENT.id,
            // TODO: Consider using defaultValues.user: auth.user.
            // But maybe we do want to force the user to select
            // one user, so it is not assumed.
            // user: auth.user,
        },
    });

    const typeId = watch('typeId');
    const typeInstance = TransactionType.fromId(typeId);
    // TODO: This fields should be set to null when type changes:
    // - data.user
    // - data.person
    // - data.issueTransaction
    // If not cleared when not required for the type_instance,
    // when switching types the previously selected values will
    // stick around and be used by the method 'onExecute'.

    const onExecute = (data) => {
        // TODO: Implement a user selector and use that actual input
        const userId = typeInstance.userRequired ? data?.user?.[0]?.id || null : null;
        const personId = typeInstance.personRequired ? data?.person?.[0]?.id || null : null;

        const request = {
            userId,
            personId,
            transactionsRequests: [
                {
                    typeId: data.typeId,
                    sessionId: sessionId,
                    description: data.description,
                    amount: data.amount,
                },
            ],
        };

        if (PERSON_TYPES.has(typeInstance) && data.issueTransaction) {
            const transactionDate = toISOLocalDateString(new Date());
            request.transactionsRequests.unshift({
                typeId: incoming ? INCOMING_TRANSACTION_TYPE.id : OUTGOING_TRANSACTION_TYPE.id,
                value_date: transactionDate,
                due_date: transactionDate,
                description: data.description,
                amount: data.amount,
            });
        }

        service
            .issueReceipt(request)
            .then((response) => onSuccess && onSuccess(response.data))
            .catch(handleRequestError);
    };

    const handleRequestError = (error) => {
        setRequestErrors(genericRequestErrors(error));
    };

    return (
        <ModalForm
            onSubmit={handleSubmit(onExecute)}
            title={incoming ? 'Ingresar Efectivo' : 'Retirar Efectivo'}
            buttons={{ executeLabel: incoming ? 'Ingresar' : 'Retirar' }}
            onCancel={onCancel}
            requestErrors={requestErrors}
            style={{ width: '400px' }}
        >
            <div className="p-fluid">
                <TransactionTypeField
                    fieldLabel="Tipo"
                    control={control}
                    errors={errors}
                    required={true}
                    options={incoming ? INCOMING_TYPES : OUTGOING_TYPES}
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    itemTemplate={typeOptionTemplate}
                    valueTemplate={selectedTypeOptionTemplate}
                    autoFocus
                />

                {/* {transactionDescription(typeInstance)} */}

                {typeInstance && typeInstance.userRequired && (
                    <UserField
                        fieldLabel="Usuario"
                        control={control}
                        errors={errors}
                        required={true}
                        autoFocus
                        onRequestError={handleRequestError}
                    />
                )}

                {typeInstance && typeInstance.personRequired && (
                    <PersonField
                        control={control}
                        errors={errors}
                        // Alternatively, allow anonymous payments,
                        // using the transaction type TAKEN_TICKET.
                        required={true}
                        autoFocus
                        onRequestError={handleRequestError}
                    />
                )}

                {PERSON_TYPES.has(typeInstance) && (
                    <IssueTransactionCheck control={control} incoming={incoming} />
                    // <IssueTransactionRadio control={control} incoming={incoming} />
                )}

                <DescriptionField control={control} errors={errors} />

                <AmountField control={control} errors={errors} />
            </div>
        </ModalForm>
    );
};

export const IncomingCashTransaction = ({ sessionId, onSuccess, onCancel }) => (
    <CashTransactionCreate
        incoming={true}
        sessionId={sessionId}
        onSuccess={onSuccess}
        onCancel={onCancel}
    />
);

export const OutgoingCashTransaction = ({ sessionId, onSuccess, onCancel }) => (
    <CashTransactionCreate
        incoming={false}
        sessionId={sessionId}
        onSuccess={onSuccess}
        onCancel={onCancel}
    />
);
