import { useController } from 'react-hook-form';

import { InputText } from 'primereact/inputtext';

import { classNames } from 'primereact/utils';
import { FieldError, errorClassName, invalidClassName } from '../field-error-messages';

const DEFAULT_RULES = {
    required: false,
    minLength: { value: 2, message: 'Al menos dos letras' },
};

export const DescriptionField = ({
    fieldName = 'description',
    fieldLabel = 'Descripción',
    control,
    // TODO: Remove and test from all callers
    errors,
    rules = DEFAULT_RULES,
    fieldClassName,
    ...rest
}) => {
    const { field, fieldState } = useController({
        name: fieldName,
        rules: rules,
        control: control,
    });

    return (
        <div className={classNames('field', fieldClassName)}>
            {fieldLabel && (
                <label htmlFor={fieldName} className={errorClassName(fieldState.error)}>
                    {fieldLabel}
                    {rules.required ? ' *' : ''}
                </label>
            )}
            {/* WARNING:
                If there is no label, when editing in a dialog the upper part
                of the InputText is not correctly rendered, partially hidden. */}
            {/* Option 1 */}
            {/* {!fieldLabel && <br />} */}
            {/* Option 2 */}
            {/* Let the caller set style={{ marginTop: '10px' }} */}
            <InputText
                id={field.name}
                name={field.name}
                value={field.value}
                // This setting is being honored in Chrome,
                // regardless of value set at form level.
                autoComplete="off"
                className={invalidClassName(fieldState.error)}
                onChange={(e) => field.onChange(e.target.value)}
                {...rest}
            />
            <FieldError fieldError={fieldState.error} />
        </div>
    );
};

DescriptionField.displayName = 'DescriptionField';
