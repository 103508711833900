import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ModalForm } from '@components/forms';
import { useMountEffect } from '@hooks/use-mount-effect';

import { MonthFieldSelectorOption, MonthFieldSelectorOptions } from '@components/fields';
import { hookFormRequestError } from '@custom/hook-form';
import { genericRequestErrors } from '@services/index';
import { firstDayOfMonth, lastDayOfMonth, toISOLocalDateString } from '@utils/date-utils';

import {
    MajorPaymentOptionField,
    MajorRelationField,
    MinorPaymentOptionField,
    MinorRelationField,
    SinceField,
    UntilField,
} from './fields';

import { RegistrationService } from '@services/registrations';

const DETAIL_FIELDS = ['relation', 'paymentOption', 'since', 'until', 'minorRequests'];

const NEXT_MONTH_CAP_DAY = 20;

// TODO: Generic improvements
// - Preselect a default option, especially if there is a single one.
// - Disable selector (make it readonly) if there is a single option.

export const PersonRegistrationCreate = ({ personId, onCancel, onSuccess }) => {
    // State -------------------------------------------------------------------

    const [service] = useState(new RegistrationService());
    const [options, setOptions] = useState([]);
    const [requestErrors, setRequestErrors] = useState([]);

    // Hooks -------------------------------------------------------------------

    const {
        control,
        watch,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
    } = useForm({
        defaultValues: {
            relation: null,
            paymentOption: null,
            minorRelation: null,
            minorPaymentOption: null,
            since: MonthFieldSelectorOptions.slapped(
                NEXT_MONTH_CAP_DAY,
                MonthFieldSelectorOption.THIS_MONTH,
                MonthFieldSelectorOption.NEXT_MONTH,
            ),
            until: null,
        },
    });

    // WARNING: Watch triggers re-renders. Consider using useWatch hook
    // https://react-hook-form.com/api/useform/watch/
    const [relation, minorRelation, since] = watch([
        // @prettier:off
        'relation',
        'minorRelation',
        'since',
    ]);

    // Effects -----------------------------------------------------------------

    useMountEffect(() => {
        service
            .getAllOptions()
            .then((response) => setOptions(response.data))
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    });

    useEffect(() => {
        if (relation == null) {
            setValue('minorRelation', null);
            setValue('paymentOption', null);
        } else {
            // Not auto selecting the first minorRelation, because:
            // - If relation.minorRelations then there will be more than one.
            // With payment options, we only auto select a value if it is the
            // only payment option available, otherwise make the user select.
            if (relation?.paymentOptions?.length === 1) {
                setValue('paymentOption', relation.paymentOptions[0]);
            } else {
                setValue('paymentOption', null);
            }
        }
    }, [relation, setValue]);

    useEffect(() => {
        if (minorRelation == null) {
            setValue('minorPaymentOption', null);
        } else {
            if (minorRelation?.paymentOptions?.length === 1) {
                setValue('minorPaymentOption', minorRelation.paymentOptions[0]);
            } else {
                setValue('minorPaymentOption', null);
            }
        }
    }, [minorRelation, setValue]);

    // Execute -----------------------------------------------------------------

    const onExecute = (data) => {
        setRequestErrors(null);
        const apiRequest = {
            person: personId,
            relation: data.relation?.id,
            paymentOption: data.paymentOption?.id,
            // BETA_MODE ------------------------------------------------------
            // since: toISOLocalDateString(data.since),
            // until: toISOLocalDateString(data.until),
            since: toISOLocalDateString(firstDayOfMonth(data.since)),
            until: toISOLocalDateString(lastDayOfMonth(data.until)),
            // ----------------------------------------------------------------
            minorRequests: data.minorRelation
                ? [
                      {
                          relation: data.minorRelation?.id,
                          paymentOption: data.minorPaymentOption?.id,
                      },
                  ]
                : null,
        };
        service
            .registerPerson(apiRequest)
            .then(onSuccess)
            .catch((error) =>
                hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors),
            );
    };

    // Render ------------------------------------------------------------------

    return (
        <ModalForm
            title="Registrar persona"
            buttons={{ executeLabel: 'Registrar' }}
            requestErrors={requestErrors}
            onSubmit={handleSubmit(onExecute)}
            onCancel={onCancel}
            style={{ width: '400px' }}
        >
            <div className="p-fluid">
                <MajorRelationField
                    control={control}
                    errors={errors}
                    options={options}
                    hideEmpty={false}
                    // autoFocus parece no funcionar si no hay un valor por defecto
                    autoFocus
                />

                <MajorPaymentOptionField
                    control={control}
                    errors={errors}
                    options={relation?.paymentOptions}
                />

                <MinorRelationField
                    control={control}
                    errors={errors}
                    options={relation?.minorRelations}
                    required={relation?.minorRelations?.length > 1}
                />

                <MinorPaymentOptionField
                    control={control}
                    errors={errors}
                    options={minorRelation?.paymentOptions}
                    required={minorRelation?.paymentOptions?.length > 1}
                />

                <SinceField control={control} />

                <UntilField control={control} since={since} />
            </div>
        </ModalForm>
    );
};
