import { useCallback, useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';
import { TreeTable } from 'primereact/treetable';

import { RequestMessages } from '@components/RequestMessages';
import { ExpandButtons, PopupItemsButton, RefreshButton } from '@components/buttons';
import { genericRequestErrors } from '@services/index';
import { localDateNow, toMonthYearFromISOString } from '@utils/date-utils';

import { RegistrationService } from '@services/registrations';
import { AccrueRelationFees } from './accrueRelationFees';
import { RegistrationAction } from './actions';
import { ChangeMajorDuration } from './changeMajorDuration';
import { ChangeMajorRelation } from './changeMajorRelation';
import { ChangeMinorRelation } from './changeMinorRelation';
import { PersonRegistrationCreate } from './create';
import { DeleteRegistration } from './deleteRegistration';
import { RegistrationPaymentChoices } from './paymentChoices';

const convertRegistrations = (registrations) => {
    const isoToday = localDateNow();
    const result = registrations.map((registration) => {
        const { id: majorId, minorRegistrations, ...majorData } = registration;
        const children = minorRegistrations.map((minor) => {
            const { id: minorId, ...minorData } = minor;
            return {
                key: minorId,
                isMajor: false,
                // TODO: Use canChange property to gray out the option
                canChange: minor.until == null || isoToday <= minor.until,
                data: { id: minorId, majorRelation: majorData.relation, ...minorData },
            };
        });
        return {
            key: majorId,
            isMajor: true,
            // TODO: Use canChange property to gray out the option
            canChange: registration.until == null || isoToday <= registration.until,
            children: children,
            data: { id: majorId, ...majorData, minorRegistrations: minorRegistrations },
        };
    });
    return result;
};

const expandAllKeys = (data) => {
    const expandedKeys = {};
    data.forEach((item) => (expandedKeys[`${item.key}`] = true));
    return expandedKeys;
};

export const PersonRegistrations = () => {
    const params = useParams();
    const toast = useRef(null);

    const [action, setAction] = useState();
    const [service] = useState(new RegistrationService());
    const [showExpired, setShowExpired] = useState(false);
    const [registration, setRegistration] = useState(null);
    const [registrations, setRegistrations] = useState(null);
    const [expandedKeys, setExpandedKeys] = useState(null);
    const [requestMessages, setRequestMessages] = useState(null);

    // Effects ----------------------------------------------------------------

    const fetchData = useCallback(() => {
        setRequestMessages(null);
        service
            .getPersonNestedRegistrations(params.id, showExpired)
            .then((response) => {
                const newRegistrations = convertRegistrations(response.data);
                setRegistrations(newRegistrations);
                setExpandedKeys(expandAllKeys(newRegistrations));
            })
            .catch((error) => setRequestMessages(genericRequestErrors(error)));
    }, [service, params.id, showExpired]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Events -----------------------------------------------------------------

    const expandAll = () => {
        setExpandedKeys(expandAllKeys(registrations));
    };

    const collapseAll = () => {
        setExpandedKeys(null);
    };

    const onActionSuccess = () => {
        setAction(null);
        fetchData();
    };

    const onActionCancel = () => {
        setAction(null);
    };

    const onAccrueFeeSuccess = (accruedFeesCount) => {
        const content =
            accruedFeesCount > 1
                ? `${accruedFeesCount} cuotas nuevas generadas`
                : 'Una nueva cuota generada';
        setAction(null);
        toast.current.show({
            severity: 'success',
            summary: 'Generar cuotas',
            detail: content,
            closable: true,
            life: 5000,
        });
    };

    // Render -----------------------------------------------------------------

    const newButtonMenu = [
        {
            label: 'Generar cuotas',
            disabled: !(Array.isArray(registrations) && registrations.length > 0),
            command: () => {
                setAction(RegistrationAction.ACCRUE_FEES_PERSON);
            },
        },
    ];

    const relationMenu = (registrationView) => {
        const canChange = registrationView.canChange;
        const paymentChoices = registrationView?.data?.paymentChoices;
        const hasPaymentChoices = Array.isArray(paymentChoices) && paymentChoices.length > 0;

        const majorMenuItems = () => [
            // ----------------------------------------------------------------
            // TODO: Enabled if one major registration can have multiple minors
            // { label: 'Agregar detalle', disabled: true },
            // { separator: true },
            // ----------------------------------------------------------------
            {
                label: 'Cambiar relación',
                disabled: !canChange,
                command: () => {
                    setRegistration(registrationView.data);
                    setAction(RegistrationAction.CHANGE_MAJOR_RELATION);
                },
            },

            {
                label: 'Cambiar duración',
                disabled: !canChange,
                command: () => {
                    setRegistration(registrationView.data);
                    setAction(RegistrationAction.CHANGE_MAJOR_DURATION);
                },
            },

            // Alternative implementation with PopupTieredMenu
            // {
            //     label: 'Cambiar duración',
            //     items: [

            //         // Si está abierto: definir until con un valor
            //         { label: 'Cerrar', icon: 'pi pi-fw pi-plus', command: () => { } },
            //         // Si está cerrado: eliminar until con valor null
            //         { label: 'Abrir', icon: 'pi pi-fw pi-plus', command: () => { } },

            //         // Si está cerrado: eliminar until or new_until > old_until
            //         // Eliminar until: [x] Dejar abierto (duración indefinida)
            //         { label: 'Extender', icon: 'pi pi-fw pi-trash', command: () => { } },

            //         // Si está cerrado: ingresar new_until < old_until
            //         { label: 'Acortar', icon: 'pi pi-fw pi-trash', command: () => { } },
            //     ]
            // },
        ];

        const minorMenuItems = () => [
            // ----------------------------------------------------------------
            // TODO: Enabled if one major registration can have multiple minors
            // { label: 'Agregar detalle', disabled: true },
            // { separator: true },
            // ----------------------------------------------------------------
            {
                label: 'Cambiar detalle',
                disabled: !canChange,
                command: () => {
                    setRegistration(registrationView.data);
                    setAction(RegistrationAction.CHANGE_MINOR_RELATION);
                },
            },
            // {
            //     label: 'Cambiar duración',
            //     disabled: !canChange,
            //     command: () => {
            //         setRegistration(registrationView.data);
            //         setAction(RegistrationAction.CHANGE_MINOR_DURATION);
            //     },
            // },
        ];

        const items = registrationView.isMajor ? majorMenuItems() : minorMenuItems();
        if (hasPaymentChoices) {
            items.push(
                { separator: true },
                // descartado: { label: 'Cambiar opción pago'}, => Hacer desde Opciones de Pago
                {
                    label: 'Generar cuotas',
                    disabled: !hasPaymentChoices || !canChange,
                    command: () => {
                        setRegistration(registrationView.data);
                        setAction(RegistrationAction.ACCRUE_FEES_REGISTRATION);
                    },
                },
                {
                    label: 'Opciones de pago',
                    disabled: !hasPaymentChoices,
                    command: () => {
                        setRegistration(registrationView.data);
                        setAction(RegistrationAction.SHOW_PAYMENT_CHOICES);
                    },
                },
            );
        }

        items.push(
            { separator: true },
            {
                label: `Eliminar ${registrationView.isMajor ? 'relación' : 'detalle'}`,
                command: () => {
                    setRegistration(registrationView.data);
                    setAction(RegistrationAction.DELETE_REGISTRATION);
                },
            },
        );

        return items;
    };

    const relationTemplateButton = (registrationView) => {
        const labelBullet = registrationView.isMajor ? '' : '- ';
        return (
            <PopupItemsButton
                menuItems={relationMenu(registrationView)}
                buttonProps={{
                    icon: null,
                    label: `${labelBullet}${registrationView.data.relation.name}`,
                }}
            />
        );
    };

    const relationTemplate = (registrationView) => {
        return relationTemplateButton(registrationView);
    };

    const header = (
        <div className="split-table-header">
            <div className="table-actions-header">
                <SplitButton
                    icon="pi pi-plus"
                    label="Nuevo"
                    model={newButtonMenu}
                    onClick={() => setAction(RegistrationAction.CREATE)}
                />
                <div className="field-checkbox" style={{ marginBottom: '0px' }}>
                    <Checkbox
                        inputId="showExpired"
                        checked={showExpired}
                        onChange={(e) => setShowExpired(e.checked)}
                    />
                    <label htmlFor="showExpired">Mostrar Historial</label>
                </div>
            </div>
            <RefreshButton onRefresh={fetchData} className="hide-constrained-400" />
        </div>
    );

    const rowClassName = (node) => {
        return {
            'major-relation': node.isMajor,
            'major-relation-no-details': !Array.isArray(node.children) || node.children.length < 1,
            'minor-relation': !node.isMajor,
            's-disabled-color': !node.canChange,
        };
    };

    const expanderHeader = <ExpandButtons onExpand={expandAll} onCollapse={collapseAll} />;

    return (
        <Card>
            <Toast ref={toast} />
            <RequestMessages messages={requestMessages} />

            <TreeTable
                header={header}
                // autoLayout={true}
                value={registrations}
                className="person-registration-table"
                rowClassName={rowClassName}
                expandedKeys={expandedKeys}
                onToggle={(e) => setExpandedKeys(e.value)}
                emptyMessage={`No hay registraciones${showExpired ? '' : ' activas'}`}
            >
                <Column
                    header={expanderHeader}
                    field="relation.name"
                    expander
                    className="relation-column"
                    body={relationTemplate}
                />
                <Column
                    header="Desde"
                    field="since"
                    className="table-column-year-month"
                    body={(row) => toMonthYearFromISOString(row?.data?.since, '-.-')}
                />
                <Column
                    header="Hasta"
                    field="until"
                    className="table-column-year-month"
                    body={(row) => toMonthYearFromISOString(row?.data?.until, 'Abierto')}
                />
            </TreeTable>

            {action === RegistrationAction.CREATE && (
                <PersonRegistrationCreate
                    personId={params.id}
                    onSuccess={onActionSuccess}
                    onCancel={onActionCancel}
                />
            )}

            {action === RegistrationAction.CHANGE_MAJOR_RELATION && (
                <ChangeMajorRelation
                    registration={registration}
                    onSuccess={onActionSuccess}
                    onCancel={onActionCancel}
                />
            )}

            {action === RegistrationAction.CHANGE_MINOR_RELATION && (
                <ChangeMinorRelation
                    registration={registration}
                    onSuccess={onActionSuccess}
                    onCancel={onActionCancel}
                />
            )}

            {action === RegistrationAction.CHANGE_MAJOR_DURATION && (
                <ChangeMajorDuration
                    registration={registration}
                    onSuccess={onActionSuccess}
                    onCancel={onActionCancel}
                />
            )}

            {action === RegistrationAction.ACCRUE_FEES_PERSON && (
                <AccrueRelationFees
                    personId={params.id}
                    onSuccess={onAccrueFeeSuccess}
                    onCancel={onActionCancel}
                />
            )}

            {action === RegistrationAction.ACCRUE_FEES_REGISTRATION && (
                <AccrueRelationFees
                    registration={registration}
                    onSuccess={onAccrueFeeSuccess}
                    onCancel={onActionCancel}
                />
            )}

            {action === RegistrationAction.SHOW_PAYMENT_CHOICES && (
                <RegistrationPaymentChoices registration={registration} onCancel={onActionCancel} />
            )}

            {action === RegistrationAction.DELETE_REGISTRATION && (
                <DeleteRegistration
                    registration={registration}
                    onSuccess={onActionSuccess}
                    onCancel={onActionCancel}
                />
            )}
        </Card>
    );
};
