import { useHistory } from 'react-router-dom';

import { Card } from 'primereact/card';

import { BackButton } from '@components/buttons';
import { ExecuteCancelButtons } from '@components/ExecuteCancelButtons';
import { RequestMessages } from '@components/RequestMessages';

export const CardPageForm = ({
    from,
    title = null,
    subTitle = null,
    className = null,
    children,
    onSubmit,
    requestErrors,
    useBackButton = false,
    executeButtonsProps = {},
    autoComplete = 'off', // Off by default, but can be overridden
    ...rest
}) => {
    const history = useHistory();

    const goBack = (event) => {
        event.preventDefault();
        from != null ? history.replace(from) : history.goBack();
    };

    const executeProps = ExecuteCancelButtons.newProps(executeButtonsProps);
    executeProps.onCancel = executeProps.onCancel ? executeProps.onCancel : goBack;

    return (
        <div className={className}>
            <form onSubmit={onSubmit} autoComplete={autoComplete}>
                {useBackButton && <BackButton onClick={goBack} className="mr-3" />}
                <Card title={title} subTitle={subTitle} {...rest}>
                    <RequestMessages messages={requestErrors} />
                    {children}
                </Card>
                <br />
                <ExecuteCancelButtons {...executeProps} />
            </form>
        </div>
    );
};
