import { InputText } from 'primereact/inputtext';

import { FieldErrorMessages, errorClassName, invalidClassName } from '../field-error-messages';

export const LastNameField = ({ register, errors, required = true, ...rest }) => {
    return (
        <div className="field">
            <label htmlFor="lastName" className={errorClassName(errors.lastName)}>
                Apellidos
            </label>
            <InputText
                id="lastName"
                name="lastName"
                {...register('lastName', {
                    required: required,
                    minLength: { value: 2, message: 'Al menos dos letras' },
                })}
                type="text"
                autoComplete="off"
                className={invalidClassName(errors.lastName)}
                {...rest}
            />
            <FieldErrorMessages name="lastName" errors={errors} />
        </div>
    );
};

LastNameField.displayName = 'LastNameField';
