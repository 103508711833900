import { useCallback, useEffect, useState } from 'react';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { RequestMessages } from '@components/RequestMessages';
import { genericRequestErrors } from '@services/index';

import { CommissionService } from '@services/commissionsService';
import { CommissionPledgeTemplateEntries } from './entries';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

export const CommissionPledgeTemplateList = ({ commissionId, forceUpdate }) => {
    const [service] = useState(new CommissionService());

    // Params: Pagination
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);

    // Search results
    const [items, setItems] = useState([]);
    const [requestMessages, setRequestMessages] = useState();

    const [expandedRows, setExpandedRows] = useState();

    // Callbacks --------------------------------------------------------------

    const search = useCallback(
        (first, page, rows) => {
            if (forceUpdate == null) return;
            const searchParams = {
                pageSize: rows,
                page: page + 1,
                commissionId: commissionId,
            };
            setRequestMessages(null);
            service
                .templates(searchParams)
                .then((response) => {
                    const data = response.data;
                    setFirst(first);
                    setPage(page);
                    setRows(rows);
                    setItems(data.results);
                    setTotalRecords(data.count ? data.count : 0);
                })
                .catch((error) => setRequestMessages(genericRequestErrors(error)));
        },
        [service, commissionId, forceUpdate],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        search(0, 0, rows);
    }, [search, rows]);

    // Events -----------------------------------------------------------------

    const onPage = (event) => {
        search(event.first, event.page, event.rows);
    };

    // const rowClick = ({ data }) => {
    //     // TODO: Implement an event-less navigation option so that right-click works
    //     history.push(`${generatePath(CommissionsPath.Pledges.item, { id: data.id })}`);
    // };

    const descriptionTemplate = (template) => (
        <div className="template-name">
            {template.name}
            {template?.description != null ? ' - ' + template.description : ''}
        </div>
    );

    const expansionTemplate = (template) => {
        return <CommissionPledgeTemplateEntries entries={template?.entries} />;
    };

    return (
        <div className="commission-template-list">
            <RequestMessages messages={requestMessages} />
            <DataTable
                value={items}
                dataKey="id"
                emptyMessage="No hay opciones"
                className="hide-columns-header"
                // --------------------------------------------------------------------------
                expandedRows={expandedRows}
                rowExpansionTemplate={expansionTemplate}
                onRowToggle={(e) => setExpandedRows(e.data)}
                // --------------------------------------------------------------------------
                lazy
                paginator={true}
                rows={rows}
                first={first}
                page={page}
                onPage={onPage}
                totalRecords={totalRecords}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            >
                <Column expander={true} className="table-column-button-expander" />
                <Column body={descriptionTemplate} />
            </DataTable>
        </div>
    );
};
