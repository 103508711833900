import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ModalForm } from '@components/forms';
import { hookFormRequestError } from '@custom/hook-form';
import { genericRequestErrors } from '@services/index';
import {
    firstDayOfMonth,
    lastDayOfMonth,
    toISOLocalDateString,
    toMonthYearFromISOString,
} from '@utils/date-utils';

import {
    MajorPaymentOptionField,
    MajorRelationField,
    MinorPaymentOptionField,
    MinorRelationField,
    SinceField,
    UntilField,
} from './fields';

import { RegistrationService } from '@services/registrations';

const DETAIL_FIELDS = ['relation', 'paymentOption', 'since', 'until', 'minorRequests'];

// TODO: Generic improvements
// - Preselect a default option: only preselect if there is only one?
// - Disable selector (make it readonly) if there is a single option.

export const ChangeMajorRelation = ({ registration, onCancel, onSuccess }) => {
    // State -------------------------------------------------------------------

    const [service] = useState(new RegistrationService());
    const [options, setOptions] = useState([]);
    const [requestErrors, setRequestErrors] = useState([]);
    const [hasChangeOptions, setHasChangeOptions] = useState(true);

    // Hooks -------------------------------------------------------------------

    const {
        control,
        watch,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
    } = useForm({
        defaultValues: {
            relation: null,
            paymentOption: null,
            minorRelation: null,
            minorPaymentOption: null,
            since: null,
            until: null,
        },
    });

    // WARNING: Watch triggers re-renders. Consider using useWatch hook
    // https://react-hook-form.com/api/useform/watch/
    const [relation, minorRelation, since] = watch(['relation', 'minorRelation', 'since']);

    // Effects -----------------------------------------------------------------

    useEffect(() => {
        const handleOptions = (newOptions) => {
            let updatedOptions = newOptions;

            const currentOption = service.getCurrentRelationOption(newOptions, registration);

            if (currentOption != null) {
                // currentOption might not longer available from the server. If it
                // is available, prevent selection (disable) and update the caption.
                const updatedOption = {
                    ...currentOption,
                    name: `${currentOption.name} - Actual`,
                    enabled: false,
                };
                updatedOptions = newOptions.map((option) => {
                    return option.id === updatedOption.id ? updatedOption : option;
                });
            }

            const nextRelationOption = service.getNextRelationOption(updatedOptions, registration);
            const nextSinceValue = service.getNextRegistrationSince(
                nextRelationOption,
                registration,
            );

            setOptions(updatedOptions);
            // If this value is set, clearing the selected relation does not update the UI:
            // the value is set to null but the UI will always display this new value, e.g.
            // as it we had set it as default, even if the underlying value is not set.
            // setValue('relation', nextRelationOption);
            setValue('since', nextSinceValue);
            setHasChangeOptions(nextRelationOption != null);
        };

        service
            .getAllOptions()
            .then((response) => handleOptions(response.data))
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    }, [service, registration, setValue]);

    useEffect(() => {
        if (relation == null) {
            setValue('paymentOption', null);
            setValue('minorRelation', null);
        } else {
            // Not auto selecting the first minorRelation, because if there
            // are relation.minorRelations, then there will be more than one.
            // With payment options, we only auto select a value if it is the
            // only payment option available, otherwise make the user select.
            if (relation?.paymentOptions?.length === 1) {
                setValue('paymentOption', relation.paymentOptions[0]);
            } else {
                setValue('paymentOption', null);
            }
        }
    }, [relation, setValue]);

    useEffect(() => {
        if (minorRelation == null) {
            setValue('minorPaymentOption', null);
        } else {
            if (minorRelation?.paymentOptions?.length === 1) {
                setValue('minorPaymentOption', minorRelation.paymentOptions[0]);
            } else {
                setValue('minorPaymentOption', null);
            }
        }
    }, [minorRelation, setValue]);

    // Execute -----------------------------------------------------------------

    const onExecute = (data) => {
        setRequestErrors(null);
        const apiRequest = {
            relation: data.relation?.id,
            paymentOption: data.paymentOption?.id,
            // BETA_MODE ------------------------------------------------------
            // since: toISOLocalDateString(data.since),
            // until: toISOLocalDateString(data.until),
            since: toISOLocalDateString(firstDayOfMonth(data.since)),
            until: toISOLocalDateString(lastDayOfMonth(data.until)),
            // ----------------------------------------------------------------
            minorRequests: data.minorRelation
                ? [
                      {
                          relation: data.minorRelation?.id,
                          paymentOption: data.minorPaymentOption?.id,
                      },
                  ]
                : null,
        };
        service
            .changeRegistration(registration.id, apiRequest)
            .then(onSuccess)
            .catch((error) =>
                hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors),
            );
    };

    // Render ------------------------------------------------------------------

    return (
        <ModalForm
            title="Cambiar relación"
            buttons={{ executeLabel: 'Cambiar', executeDisabled: !hasChangeOptions }}
            requestErrors={requestErrors}
            onSubmit={handleSubmit(onExecute)}
            onCancel={onCancel}
            style={{ width: '400px' }}
        >
            {hasChangeOptions ? (
                <div className="p-fluid">
                    <MajorRelationField
                        control={control}
                        errors={errors}
                        options={options}
                        fieldLabel="Nueva relación"
                        placeholder="Seleccione nueva relación"
                        autoFocus
                    />

                    <MajorPaymentOptionField
                        control={control}
                        errors={errors}
                        options={relation?.paymentOptions}
                    />

                    <MinorRelationField
                        control={control}
                        errors={errors}
                        options={relation?.minorRelations}
                        required={relation?.minorRelations?.length > 1}
                    />

                    <MinorPaymentOptionField
                        control={control}
                        errors={errors}
                        options={minorRelation?.paymentOptions}
                        required={minorRelation?.paymentOptions?.length > 1}
                    />

                    <SinceField
                        control={control}
                        rules={{
                            required: true,
                            validate: {
                                afterSince: (since) => {
                                    const sinceValue = toISOLocalDateString(since);
                                    return sinceValue == null || registration.since <= sinceValue;
                                },
                            },
                        }}
                        customMessages={{
                            afterSince: `No puede ser anterior al inicio del registro: ${toMonthYearFromISOString(
                                registration.since,
                            )}`,
                        }}
                    />

                    <UntilField control={control} since={since} />
                </div>
            ) : (
                <div className="grid">
                    {' '}
                    {/*Not options error message */}
                    <div className="col-fixed" style={{ width: '3em' }}>
                        <span
                            className="pi pi-exclamation-triangle"
                            style={{
                                fontSize: '2em',
                                marginRight: '20px',
                                verticalAlign: 'middle',
                            }}
                        />
                    </div>
                    <div className="col">No hay opciones para cambiar esta relación.</div>
                </div>
            )}
        </ModalForm>
    );
};
