import { Button } from 'primereact/button';

/*
    menuRef must be a Reference to a ContextMenu instance. Use
    beforeShow to update menu item properties / actions, e.g.:

        const contextMenuRef = useRef();

        const contextActions = [
            {
                label: 'Some action',
            },
            {
                label: 'Some other action',
            },
        ]

        <ContextMenu ref={contextMenuRef} model={contextActions} />

        const updateContextTarget = (target) => {
            contextActions[0].url = $SOME_COMPUTED url based on taget;
            contextActions[1].command = $SOME_COMPUTED command based on target;
        }

        <PopupMenuButton
            menuRef={contextMenuRef}
            beforeShow={() => updateContextTarget(rowData)}
        />

*/

export const PopupMenuButton = ({ menuRef, beforeShow = null, buttonProps = null }) => {
    return (
        <Button
            icon="pi pi-ellipsis-v"
            className="p-button-rounded p-button-text"
            onClick={(event) => {
                beforeShow && beforeShow();
                menuRef.current.show(event);
            }}
            {...buttonProps}
        />
    );
};
