import { nextSubpath } from '../path-utils';

export const PathUtilsTest = () => {
    const nextSubpathCases = [
        {
            fullPath: '/accounting/account-due-summaries/overdue',
            currentPath: '/accounting',
            expects: 'account-due-summaries',
        },
        {
            fullPath: '/accounting/account-due-summaries/overdue',
            currentPath: '/accounting/account-due-summaries',
            expects: 'overdue',
        },
    ];

    return (
        <div>
            <h2>path-utils</h2>
            <h3>nextSubpath</h3>
            <hr />
            <pre>
                {nextSubpathCases.map((item, index) => (
                    <div key={index}>
                        {`fullPath:${item.fullPath}`}
                        <br />
                        {`currentPath:${item.currentPath}`}
                        <br />
                        {`expects:${item.expects}`}
                        <br />
                        {`actual:${nextSubpath(item.fullPath, item.currentPath)}`}
                        <br />
                        <hr />
                    </div>
                ))}
            </pre>
        </div>
    );
};
