export const FundEntryAction = Object.freeze({
    // CREATE: 'create',
    //============================================
    RECONCILE: 'reconcile',
    CANCEL: 'cancelation',
    //-------------------------------------------
    VIEW: 'view',
    UPDATE: 'update',
    REVOKE: 'revoke',
    REFRESH: 'refresh',
    //============================================
    CHANGE_PERSON: 'change-person',
    CHANGE_ACCOUNT: 'change-account',
});
