export const LogLevel = Object.freeze({
    TRACE: 5,
    DEBUG: 4,
    INFO: 3,
    WARN: 2,
    ERROR: 1,
    FATAL: 0,
});

export const globalLogLevel = LogLevel.WARN;

export const createLogger = (name, loggerLevel) => {
    const log = (level, message) => {
        if (level <= loggerLevel) {
            console.log(`[${name}] ${message}`);
        }
    };

    const trace = (message) => {
        log(LogLevel.TRACE, message);
    };

    const debug = (message) => {
        log(LogLevel.DEBUG, message);
    };

    const info = (message) => {
        log(LogLevel.INFO, message);
    };

    const warn = (message) => {
        log(LogLevel.WARN, message);
    };

    const error = (message) => {
        log(LogLevel.ERROR, message);
    };

    return {
        trace,
        debug,
        info,
        warn,
        error,
    };
};

export const logger = createLogger('GlobalLogger', globalLogLevel);

// TODO: Consider using aspect based global loggers.
// export const globalLog = createLogger('Global', someLevel)
// export const renderLog = createLogger('Render', someLevel)
// export const serviceLog = createLogger('Service', someLevel)
