/**
 * ### IMPORTANT ### Clients expect that hours be '0',
 * i.e. midnight at the beginning of day. Take care to
 * always `compute` values with that constraint.
 */

export const DateFieldSelectorOption = Object.freeze({
    //-------------------------------------------------------------------------
    SEPARATOR: { separator: true },
    //-------------------------------------------------------------------------
    TODAY: {
        label: 'Hoy',
        compute: () => {
            const date = new Date();
            date.setHours(0, 0, 0, 0);
            return date;
        },
    },
    YESTERDAY: {
        label: 'Ayer',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
        },
    },
    TOMORROW: {
        label: 'Mañana',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
        },
    },
    //-------------------------------------------------------------------------
    BEGINNING_OF_YEAR: {
        label: 'Inicio de año',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), 0, 1);
        },
    },
    BEGINNING_PREVIOUS_MONTH: {
        label: 'Inicio mes anterior',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() - 1, 1);
        },
    },
    BEGINNING_THIS_MONTH: {
        label: 'Inicio de mes',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth(), 1);
        },
    },
    BEGINNING_NEXT_MONTH: {
        label: 'Inicio mes próximo',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() + 1, 1);
        },
    },
    BEGINNING_TWO_MONTHS: {
        label: 'Inicio en dos meses',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() + 2, 1);
        },
    },
    //-------------------------------------------------------------------------
    END_PREVIOUS_MONTH: {
        label: 'Fin mes anterior',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth(), 0);
        },
    },
    END_THIS_MONTH: {
        label: 'Fin de mes',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() + 1, 0);
        },
    },
    END_NEXT_MONTH: {
        label: 'Fin mes próximo',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() + 2, 0);
        },
    },
    END_TWO_MONTHS: {
        label: 'Fin dos meses',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() + 3, 0);
        },
    },
    END_THREE_MONTHS: {
        label: 'Fin tres meses',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() + 4, 0);
        },
    },
    END_OF_YEAR: {
        label: 'Fin de año',
        compute: () => {
            const date = new Date();
            return new Date(date.getFullYear(), 12, 0);
        },
    },
    OPEN_ENDED: {
        label: 'Final abierto',
        compute: () => {
            return null;
        },
    },
    //-------------------------------------------------------------------------
    ONE_MONTH_AGO: {
        label: 'Un mes atrás',
        compute: () => {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            return date;
        },
    },
    TWO_MONTHS_AGO: {
        label: 'Dos meses atrás',
        compute: () => {
            const date = new Date();
            date.setMonth(date.getMonth() - 2);
            return date;
        },
    },
    THREE_MONTHS_AGO: {
        label: 'Tres meses atrás',
        compute: () => {
            const date = new Date();
            date.setMonth(date.getMonth() - 3);
            return date;
        },
    },
    SIX_MONTHS_AGO: {
        label: 'Seis meses atrás',
        compute: () => {
            const date = new Date();
            date.setMonth(date.getMonth() - 6);
            return date;
        },
    },
    //-------------------------------------------------------------------------
    ONE_YEAR_AGO: {
        label: 'Hace un año',
        compute: () => {
            const date = new Date();
            date.setFullYear(date.getFullYear() - 1);
            return date;
        },
    },
    TWO_YEARS_AGO: {
        label: 'Hace dos años',
        compute: () => {
            const date = new Date();
            date.setFullYear(date.getFullYear() - 2);
            return date;
        },
    },
});

/** Common DateSelectorOption configurations & menuItems utility */

export const DateFieldSelectorOptions = Object.freeze({
    ALL: Object.values(DateFieldSelectorOption).slice(1),
    SOME: [
        DateFieldSelectorOption.BEGINNING_OF_YEAR,
        DateFieldSelectorOption.BEGINNING_PREVIOUS_MONTH,
        DateFieldSelectorOption.BEGINNING_THIS_MONTH,
        DateFieldSelectorOption.SEPARATOR,
        DateFieldSelectorOption.YESTERDAY,
        DateFieldSelectorOption.TODAY,
        DateFieldSelectorOption.TOMORROW,
        DateFieldSelectorOption.SEPARATOR,
        DateFieldSelectorOption.END_THIS_MONTH,
        DateFieldSelectorOption.END_NEXT_MONTH,
        DateFieldSelectorOption.END_OF_YEAR,
    ],
    SINCE: [
        DateFieldSelectorOption.BEGINNING_THIS_MONTH,
        DateFieldSelectorOption.SEPARATOR,
        DateFieldSelectorOption.BEGINNING_PREVIOUS_MONTH,
        DateFieldSelectorOption.BEGINNING_NEXT_MONTH,
        DateFieldSelectorOption.BEGINNING_TWO_MONTHS,
        DateFieldSelectorOption.SEPARATOR,
        DateFieldSelectorOption.BEGINNING_OF_YEAR,
    ],
    UNTIL_CLOSED: [
        DateFieldSelectorOption.END_PREVIOUS_MONTH,
        DateFieldSelectorOption.SEPARATOR,
        DateFieldSelectorOption.END_THIS_MONTH,
        DateFieldSelectorOption.END_NEXT_MONTH,
        DateFieldSelectorOption.END_TWO_MONTHS,
        DateFieldSelectorOption.END_OF_YEAR,
    ],
    UNTIL_CLOSED_FUTURE: [
        DateFieldSelectorOption.END_THIS_MONTH,
        DateFieldSelectorOption.END_NEXT_MONTH,
        DateFieldSelectorOption.END_TWO_MONTHS,
        DateFieldSelectorOption.END_THREE_MONTHS,
        DateFieldSelectorOption.END_OF_YEAR,
    ],
    UNTIL_OPENED_FUTURE: [
        DateFieldSelectorOption.END_THIS_MONTH,
        DateFieldSelectorOption.END_NEXT_MONTH,
        DateFieldSelectorOption.END_TWO_MONTHS,
        DateFieldSelectorOption.END_THREE_MONTHS,
        DateFieldSelectorOption.END_OF_YEAR,
        DateFieldSelectorOption.SEPARATOR,
        DateFieldSelectorOption.OPEN_ENDED,
    ],
    REPORT: [
        DateFieldSelectorOption.TODAY,
        DateFieldSelectorOption.SEPARATOR,
        DateFieldSelectorOption.BEGINNING_NEXT_MONTH,
        DateFieldSelectorOption.END_PREVIOUS_MONTH,
        DateFieldSelectorOption.SEPARATOR,
        DateFieldSelectorOption.ONE_MONTH_AGO,
        DateFieldSelectorOption.TWO_MONTHS_AGO,
        DateFieldSelectorOption.THREE_MONTHS_AGO,
        DateFieldSelectorOption.SIX_MONTHS_AGO,
        DateFieldSelectorOption.SEPARATOR,
        DateFieldSelectorOption.ONE_YEAR_AGO,
        DateFieldSelectorOption.TWO_YEARS_AGO,
    ],

    menuItems: (options, valueSetter) => {
        if (Array.isArray(options) && options.length > 0) {
            return options.map((option) => {
                return option.separator
                    ? option
                    : {
                          label: option.label,
                          command: () => valueSetter(option.compute()),
                      };
            });
        }

        return null;
    },
});
