import { useCallback, useEffect, useState } from 'react';

import { Link, useHistory, useLocation } from 'react-router-dom';

import { hookFormRequestError } from '@custom/hook-form';
import { useForm } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

import {
    AmountField,
    DescriptionField,
    PersonField,
    TransactionTypeField,
    UserField,
    ValueDateField,
} from '@components/fields';
import { ModalPathForm } from '@components/forms';
import { TransactionType } from '@enums/accounting';
import { toISOLocalDateString } from '@utils/date-utils';

import { CounterpartTypeField, counterpartTypeLabeled } from '@components/fields/counterpartType';

import { EMPTY_FUND_ACCOUNT_ID, FundEntryService } from '@services/fundsService';
import { TreasuryPaths } from '@treasury/routes';
import { FundAccountField } from './fields/FundAccountField';
import { ReconciledField } from './fields/ReconciledField';

const FundEntryCreateButton = ({
    comeBack, // If true, onSuccess or onCancel returns to the calling view
    debit,
}) => {
    const location = useLocation();
    const pathname = debit ? TreasuryPaths.FundEntry.debit : TreasuryPaths.FundEntry.credit;
    const label = `${debit ? 'Retirar' : 'Ingresar'}`;
    const icon = debit ? 'pi pi-upload' : 'pi pi-download';
    return (
        <Link
            to={{
                pathname: pathname,
                // search: `?person=${personId}`,
                state: { from: comeBack ? location : null },
            }}
        >
            <Button label={label} icon={icon} />
        </Link>
    );
};

const DEBIT_OPTIONS = [
    // prettier:off
    TransactionType.FUND_OUTGOING_TRANSFER,
    TransactionType.FUND_ACCOUNT_WITHDRAWAL,
];

const CREDIT_OPTIONS = [
    // prettier:off
    TransactionType.FUND_INCOMING_TRANSFER,
    TransactionType.FUND_ACCOUNT_DEPOSIT,
];

const CounterpartType = counterpartTypeLabeled({
    unknown: 'Desconocido',
    person: 'Persona (Clientes y Proveedores)',
    user: 'Usuario (Movimiento Interno)',
});

const INCOMING_TYPES = [
    // @prettier:disabled
    CounterpartType.UNKNOWN,
    CounterpartType.PERSON,
    CounterpartType.USER,
];

const OUTGOING_TYPES = [
    // CounterpartType.UNKNOWN, // You MUST know whom the money goes to
    CounterpartType.PERSON,
    CounterpartType.USER,
];

const INITIAL_DATA = {
    counterPartType: CounterpartType.PERSON.value,
    // --------------------------------------
    typeId: null,
    valueDate: null,
    user: null,
    person: null,
    description: '',
    amount: null,
    // --------------------------------------
    fundAccount: EMPTY_FUND_ACCOUNT_ID,
    reconciled: false,
};

const DETAIL_FIELDS = [
    'typeId',
    'person',
    'user',
    'valueDate',
    'description',
    'amount',
    'fundAccount',
];

const FundEntryCreate = ({ debit }) => {
    // const from = useLocation().state?.from;
    const history = useHistory();
    const [service] = useState(new FundEntryService());
    const [requestErrors, setRequestErrors] = useState([]);
    const [unknownAccount, setUnknownAccount] = useState(false);
    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        clearErrors,
        resetField,
        watch,
    } = useForm({
        defaultValues: {
            ...INITIAL_DATA,
            typeId: debit
                ? TransactionType.FUND_OUTGOING_TRANSFER.id
                : TransactionType.FUND_INCOMING_TRANSFER.id,
            // We allways set empty account by default, and then
            // force the user to actually choose not to set one.
            // fundAccount: debit ? null : EMPTY_FUND_ACCOUNT_ID,
            fundAccount: null,
            valueDate: new Date(),
        },
    });

    // Events -----------------------------------------------------------------

    const [fundAccount, counterPartType] = watch(['fundAccount', 'counterPartType']);

    useEffect(() => {
        if (unknownAccount) {
            resetField('fundAccount');
            resetField('reconciled');
        }
    }, [resetField, unknownAccount]);

    // Execute -----------------------------------------------------------------

    const onExecute = (data) => {
        clearErrors();
        setRequestErrors(null);
        // WARNING: If person is undefined, no value will be sent in the request.
        // This is a problem if attempting to remove the selected person. Setting
        // the value to null instead of undefined, will send value null to server.
        // The same thing happens with fundAccount
        // WARNING: user and person are edited in a list like component
        const userId = data?.user?.[0]?.id || null;
        const personId = data?.person?.[0]?.id || null;
        const fundAccountId =
            data?.fundAccount && data.fundAccount !== EMPTY_FUND_ACCOUNT_ID
                ? data.fundAccount
                : null;
        const request = {
            typeId: data.typeId,
            userId: userId,
            personId: personId,
            fundAccountId: fundAccountId,
            valueDate: toISOLocalDateString(data.valueDate),
            description: data.description,
            amount: data.amount,
            reconciled: data.reconciled,
        };
        service
            .create(request)
            .then((response) => {
                // const nextStep =
                //     from || generatePath(TreasuryPaths.FundEntry.view, { id: response.data.id });
                // history.replace(nextStep);
                history.replace(TreasuryPaths.FundEntry.list);
            })
            .catch(handleRequestError);
    };

    const handleRequestError = useCallback(
        (error) => {
            hookFormRequestError(error, DETAIL_FIELDS, setError, setRequestErrors);
        },
        [setError],
    );

    // Render -----------------------------------------------------------------

    return (
        <ModalPathForm
            title={`${debit ? 'Retiro' : 'Ingreso'} de Fondos`}
            onSubmit={handleSubmit(onExecute)}
            requestErrors={requestErrors}
            executeButtonsProps={{
                executeLabel: `${debit ? 'Retirar' : 'Ingresar'} Fondos`,
                cancelLabel: `Volver sin ${debit ? 'retirar' : 'ingresar'} fondos`,
            }}
            style={{ maxWidth: '400px', position: 'relative', margin: '0 auto' }}
        >
            <div className="p-fluid">
                <ValueDateField control={control} errors={errors} fieldLabel="Fecha" autoFocus />

                <TransactionTypeField
                    fieldLabel="Tipo"
                    control={control}
                    errors={errors}
                    options={debit ? DEBIT_OPTIONS : CREDIT_OPTIONS}
                />

                <CounterpartTypeField
                    fieldLabel={debit ? 'Destino' : 'Origen'}
                    control={control}
                    errors={errors}
                    options={debit ? OUTGOING_TYPES : INCOMING_TYPES}
                />

                {counterPartType === CounterpartType.PERSON.value && (
                    <PersonField
                        control={control}
                        errors={errors}
                        required={true}
                        onRequestError={handleRequestError}
                    />
                )}

                {counterPartType === CounterpartType.USER.value && (
                    <UserField
                        control={control}
                        errors={errors}
                        required={true}
                        fieldLabel="Usuario"
                        onRequestError={handleRequestError}
                    />
                )}

                <DescriptionField
                    control={control}
                    errors={errors}
                    fieldLabel={`Descripción (e.g. Banco de ${debit ? 'destino' : 'origen'})`}
                />

                <AmountField control={control} errors={errors} />

                <FundAccountField
                    control={control}
                    errors={errors}
                    // You MUST know from where you take out money
                    // You MIGHT not know where your mony came from,
                    // but only if explicitly saying so: unknownAccount
                    required={debit || !unknownAccount}
                    onRequestError={handleRequestError}
                    disabled={unknownAccount}
                />

                {/* Only for credits, fundAccount might be explicitly set 
                    to null. But by default, fundAccount will be required */}
                {!debit && (
                    <div className="mb-3">
                        <Checkbox
                            inputId="unknownAccount"
                            onChange={(e) => setUnknownAccount(e.checked)}
                            checked={unknownAccount}
                        />
                        <label htmlFor="unknownAccount" className="ml-2">
                            Cuenta de destino desconocida
                        </label>
                    </div>
                )}

                <ReconciledField
                    control={control}
                    errors={errors}
                    required={false}
                    fundAccount={fundAccount}
                />
            </div>
        </ModalPathForm>
    );
};

export const FundEntryDebit = () => FundEntryCreate({ debit: true });

export const FundEntryCredit = () => FundEntryCreate({ debit: false });

export const FundEntryDebitButton = ({ comeBack = true }) =>
    FundEntryCreateButton({ comeBack: comeBack, debit: true });

export const FundEntryCreditButton = ({ comeBack = true }) =>
    FundEntryCreateButton({ comeBack: comeBack, debit: false });
