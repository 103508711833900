import { useState } from 'react';
import { Controller } from 'react-hook-form';

import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';

import { FieldErrorMessages, errorClassName } from '@components/field-error-messages';
import { personIdentityDescription } from '@persons/templates';
import { PersonDetail, PersonService } from '@services/personService';

// TODO: Merge this two classes into 'PersonField'
// If you need a 'PersonInput', use a PersonField without a label...

export const PersonInput = ({
    fieldName = 'person',
    fieldLabel = 'Persona',
    className,
    control,
    errors,
    single = true,
    required = true,
    onRequestError,
    ...rest
}) => {
    const [service] = useState(new PersonService());
    const [suggestions, setSuggestions] = useState(null);

    const searchPerson = (event) => {
        const query = event.query.trim();
        if (query.length) {
            service
                .search({
                    query: query,
                    detail: PersonDetail.IDENTITY,
                })
                .then((response) => setSuggestions(response.data['results']))
                .catch((error) => onRequestError && onRequestError(error));
        } else {
            setSuggestions(null);
        }
    };

    const itemTemplate = (item) => {
        return (
            <div className="flex align-items-center">
                <div>{personIdentityDescription({ person: item })}</div>
            </div>
        );
    };

    return (
        <>
            <Controller
                name={fieldName}
                control={control}
                rules={{
                    required: required,
                    validate: {
                        personRequired: (values) => {
                            return (
                                !required ||
                                (Array.isArray(values) &&
                                    (single ? values.length === 1 : values.length > 0))
                            );
                        },
                    },
                }}
                render={({ field, fieldState }) => (
                    <>
                        <AutoComplete
                            id={field.name}
                            value={field.value}
                            onChange={(e) => field.onChange(single ? e.value.slice(-1) : e.value)}
                            // We use 'multiple' to inherit the Chip's output.
                            // It is easier to know when a person is selected
                            // and when not with chips. It is also more intuitive
                            // to remove a person by clicking on the chip icon.
                            multiple
                            suggestions={suggestions}
                            completeMethod={searchPerson}
                            itemTemplate={itemTemplate}
                            field="formalName"
                            aria-label={fieldLabel}
                            className={classNames(className, 'person-input', {
                                'p-invalid': fieldState.error,
                            })}
                            {...rest}
                        />
                    </>
                )}
            />
        </>
    );
};

export const PersonField = ({
    fieldName = 'person',
    fieldLabel = 'Persona',
    errors,
    required = true,
    fieldClassName,
    ...rest
}) => {
    return (
        <div className={classNames('field', fieldClassName)}>
            {fieldLabel && (
                <label htmlFor={fieldName} className={errorClassName(errors && errors[fieldName])}>
                    {fieldLabel}
                    {required ? ' *' : ''}
                </label>
            )}

            <PersonInput
                fieldName={fieldName}
                fieldLabel={fieldLabel}
                errors={errors}
                required={required}
                {...rest}
            />

            <FieldErrorMessages
                name={fieldName}
                errors={errors}
                customMessages={{
                    personRequired: 'Requerido',
                }}
            />
        </div>
    );
};

PersonField.displayName = 'PersonField';
