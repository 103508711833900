import { useHistory } from 'react-router-dom';

import { ExecuteCancelButtons } from '@components/ExecuteCancelButtons';
import { RequestMessages } from '@components/RequestMessages';
import { BackButton } from '@components/buttons';

export const ModalPathForm = ({
    from,
    title,
    children,
    onSubmit,
    requestErrors,
    useBackButton = false,
    executeButtonsProps = {},
    autoComplete = 'off', // Off by default, but can be overridden
    ...rest
}) => {
    const history = useHistory();

    const goBack = (event) => {
        event.preventDefault();
        from != null ? history.replace(from) : history.goBack();
    };

    const executeProps = ExecuteCancelButtons.newProps(executeButtonsProps);
    executeProps.onCancel = executeProps.onCancel ? executeProps.onCancel : goBack;

    return (
        <div {...rest}>
            <h2>
                {useBackButton && <BackButton onClick={goBack} className="mr-3" />}
                {title}
            </h2>
            <form onSubmit={onSubmit} autoComplete={autoComplete}>
                <div className="card">
                    <RequestMessages messages={requestErrors} />
                    {children}
                </div>
                <ExecuteCancelButtons {...executeProps} />
            </form>
        </div>
    );
};
