import { SiteUserList } from './index';
import { SiteUserTransactionsBalanceDetail } from './transactions-balance/detail';
import { SiteUserTransactionsBalanceMaster } from './transactions-balance/master';

import { configuration } from '@app/configuration';

const ACTIVE_PATH = '/users';
const CONFIG_PATH = '/configuration';

export const SiteUserPath = Object.freeze({
    // Permissions: {
    //     item: ACTIVE_PATH + '/:userId/permissions',
    // },
    TransactionsBalance: {
        master: ACTIVE_PATH + '/transactions-balance',
        detail: ACTIVE_PATH + '/:userId/transactions-balance',
    },
});

export const SiteUserConfigPath = Object.freeze({
    User: {
        dashboard: CONFIG_PATH + ACTIVE_PATH,
    },
});

const activeRoutesInfo = [
    {
        path: SiteUserPath.TransactionsBalance.master,
        title: 'Movimientos Staff',
        exact: true,
        component: SiteUserTransactionsBalanceMaster,
    },
    {
        path: SiteUserPath.TransactionsBalance.detail,
        title: 'Movimientos Staff',
        exact: true,
        component: SiteUserTransactionsBalanceDetail,
    },
];

const configRoutesInfo = [
    {
        path: SiteUserConfigPath.User.dashboard,
        title: 'Usuarios',
        exact: true,
        component: SiteUserList,
    },
];

const makeRoutesInfo = () => {
    const routesInfo = [];
    routesInfo.push(...activeRoutesInfo);
    if (configuration.displayWIP) {
        routesInfo.push(...configRoutesInfo);
    }
    return routesInfo;
};

export const routesInfo = makeRoutesInfo();
