import { useCallback, useEffect, useState } from 'react';

import { RequestMessages } from '@components/RequestMessages';
import { useWindowDimensions } from '@hooks/useWindowDimensions';
import { settings } from '@localization/settings';
import { genericRequestErrors } from '@services/index';
import { StringUtils } from '@utils/string-utils';

import suma from '@apis/Suma';

const EMPTY_SERVER_INFORMATION = {
    target: settings.defaults.emptyValue,
    version: settings.defaults.emptyValue,
    revision: settings.defaults.emptyValue,
    timestamp: settings.defaults.emptyValue,
    environment: settings.defaults.emptyValue,
};

export const SystemInformationPanel = () => {
    const [serverInfo, setServerInfo] = useState(EMPTY_SERVER_INFORMATION);
    const [requestErrors, setRequestErrors] = useState();
    const { width, height } = useWindowDimensions();

    const getServerInfo = useCallback(() => {
        setRequestErrors(null);
        suma.get('core/system-info/')
            .then((response) => setServerInfo(response.data))
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    }, []);

    useEffect(() => {
        getServerInfo();
    }, [getServerInfo]);

    return (
        <div>
            <RequestMessages messages={requestErrors} />
            <div className="system-info-block">
                <div className="system-info-header">webapp</div>
                <div className="system-info-cell">
                    <div className="system-info-line">
                        {StringUtils.valueOrEmpty(process.env.REACT_APP_TARGET)}
                    </div>
                    <div className="system-info-line">
                        {StringUtils.valueOrEmpty(process.env.REACT_APP_VERSION)}
                    </div>
                    <div className="system-info-line">
                        {StringUtils.valueOrEmpty(process.env.REACT_APP_REVISION)}
                    </div>
                    <div className="system-info-line">
                        {StringUtils.valueOrEmpty(process.env.REACT_APP_TIMESTAMP)}
                    </div>
                </div>
            </div>
            <div className="system-info-block">
                <div className="system-info-header">server</div>
                <div className="system-info-cell">
                    <div className="system-info-line">
                        {StringUtils.valueOrEmpty(serverInfo.environment)}/
                        {StringUtils.valueOrEmpty(serverInfo.target)}
                    </div>
                    <div className="system-info-line">
                        {StringUtils.valueOrEmpty(serverInfo.version)}
                    </div>
                    <div className="system-info-line">
                        {StringUtils.valueOrEmpty(serverInfo.revision)}
                    </div>
                    <div className="system-info-line">
                        {StringUtils.valueOrEmpty(serverInfo.timestamp)}
                    </div>
                </div>
            </div>
            <div className="system-info-block">
                <div className="system-info-header">screen</div>
                <div className="system-info-cell">
                    <div className="system-info-line">{`${width}x${height}`}</div>
                </div>
            </div>
        </div>
    );
};
