import { useCallback, useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { RequestMessages } from '@components/RequestMessages';
import { ClearFilterButton, DownloadButton, RefreshButton } from '@components/buttons';
import { DescriptionField, PersonField } from '@components/fields';
import { DEFAULT_SELECTOR_OPTION, SinceUntilBlock } from '@components/fields/sinceUntilBlock';
import { PersonFormalNameLink } from '@persons/templates';
import { genericRequestErrors } from '@services/index';
import { PersonDetail, PersonService } from '@services/personService';
import { downloadBlob } from '@services/sharing';
import { sameDate } from '@utils/date-utils';
import { sameModels } from '@utils/iterable-utils';
import { isoLocalDateTimeTemplate } from '@utils/money';
import { strippedOrNull } from '@utils/string-utils';

import { TokenTypeField } from '@components/fields/tokenType';
import { TokenService } from '@services/tokenService';
import { CommentDisplayEditor } from './comment';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

const defaultSearchConfig = () => {
    return {
        typeId: null,
        persons: [],
        comment: null,
        ...DEFAULT_SELECTOR_OPTION.compute(),
    };
};

const defaultInitialValues = () => {
    return {
        ...defaultSearchConfig(),
        comment: '',
        period: DEFAULT_SELECTOR_OPTION,
    };
};

const changedConfig = (config1, config2) => {
    const sameComment = (comment1, comment2) => {
        const cleaned1 = strippedOrNull(comment1);
        const cleaned2 = strippedOrNull(comment2);
        return cleaned1 === cleaned2;
    };
    return [
        config1.typeId !== config2.typeId,
        !sameModels(config1.persons, config2.persons),
        !sameComment(config1.comment, config2.comment),
        !sameDate(config1.since, config2.since),
        !sameDate(config1.until, config2.until),
    ].some((item) => item);
};

export const TokenUseSearch = () => {
    const history = useHistory();
    const location = useLocation();
    const [service] = useState(new TokenService());
    const { control, watch, reset, setValue } = useForm({
        defaultValues: defaultInitialValues(),
    });

    // Params: Pagination
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);

    // Params: SearchConfig
    const [searchConfig, setSearchConfig] = useState(defaultSearchConfig());

    // Search results
    const [items, setItems] = useState([]);
    const [requestErrors, setRequestErrors] = useState();

    const [persons, typeId, comment, period, since, until] = watch([
        'persons',
        'typeId',
        'comment',
        'period',
        'since',
        'until',
    ]);

    // Callbacks --------------------------------------------------------------

    // search can be called:
    // - On demand: repeatSearch
    // - On change: searchConfig
    // - On change: pagination (rows/page)
    // When searchConfig changes, pagination hast to change too: Page == 0

    const search = useCallback(
        (searchConfig, first, page, rows) => {
            const searchParams = {
                pageSize: rows,
                page: page + 1,
                ...searchConfig,
            };
            setRequestErrors(null);
            service
                .uses(searchParams)
                .then((response) => {
                    const data = response.data;
                    setFirst(first);
                    setPage(page);
                    setRows(rows);
                    setItems(data.results);
                    setTotalRecords(data.count ? data.count : 0);
                })
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        },
        [service],
    );

    // Effects ----------------------------------------------------------------
    // TODO: IMPROVE - When a personId parameter is provided, an unnecessary
    // search without that parameter is executed before it is detected...

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const personId = query.get('personId');
        if (personId) {
            const service = new PersonService();
            service
                .get(personId, PersonDetail.IDENTITY)
                .then((response) => {
                    setValue('persons', [response.data]);
                    query.delete('personId');
                    history.replace({
                        search: query.toString(),
                    });
                })
                .catch((error) => setRequestErrors(genericRequestErrors(error)));
        }
    }, [setValue, history, location.search]);

    useEffect(() => {
        // Correct until here, so range errors can´t happen
        if (since != null && until != null && until < since) {
            setValue('until', since);
            return;
        }
        const newSearchConfig = { persons, typeId, since, until };
        newSearchConfig['comment'] = strippedOrNull(comment);
        if (changedConfig(searchConfig, newSearchConfig)) {
            setSearchConfig(newSearchConfig);
        }
    }, [searchConfig, persons, typeId, comment, since, until, setValue]);

    useEffect(() => {
        if (period && Object.prototype.hasOwnProperty.call(period, 'compute')) {
            const newRange = period.compute();
            setValue('since', newRange.since);
            setValue('until', newRange.until);
        }
    }, [period, setValue]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const personId = query.get('personId');
        if (personId) {
            // Wait until we have a person initialized to search
            // After the person is initialized, the query is cleared
            return;
        }
        search(searchConfig, 0, 0, rows);
    }, [search, searchConfig, rows, location.search]);

    // Events -----------------------------------------------------------------

    const onPage = (event) => {
        search(searchConfig, event.first, event.page, event.rows);
    };

    const repeatSearch = () => {
        search(searchConfig, first, page, rows);
    };

    const clearFilters = () => {
        setSearchConfig(defaultSearchConfig());
        reset(defaultInitialValues());
    };

    // const rowClick = ({ data }) => {
    //     // TODO: Implement an event-less navigation option so that right-click works
    //     history.push(generatePath(TokensPath.TokenType.item, { id: data.id }));
    // };

    // Rendering --------------------------------------------------------------

    const header = (
        <div className="search-filter p-fluid">
            <PersonField
                control={control}
                fieldName="persons"
                fieldLabel="Persona(s)"
                fieldClassName="person"
                required={false}
                single={false}
            />
            <DescriptionField
                control={control}
                fieldName="comment"
                fieldLabel="Comentario"
                fieldClassName="comment"
                rules={{ required: false }}
            />
            <TokenTypeField
                control={control}
                required={false}
                // ----------------------------------
                className="type-selector"
                // Afford deselecting => implicit all
                placeholder="Todos"
                showClear
            />
            <SinceUntilBlock periodLabel="Consumidos" control={control} />
            <div className="flex-grow-1" />
            <div>
                <DownloadButton
                    label="xls"
                    // Required, because otherwise it inherits p-fluid 100%
                    style={{ width: 'auto' }}
                    onDownload={() => {
                        downloadBlob(service.uses_download_request(searchConfig)).catch((error) =>
                            setRequestErrors(genericRequestErrors(error)),
                        );
                    }}
                    disabled={!items?.length}
                />
                <ClearFilterButton onClick={clearFilters} />
                <RefreshButton onRefresh={repeatSearch} />
            </div>
        </div>
    );

    const leftColumn = (use) => (
        <div className="token-use-row">
            <div className="pack-type-name">{use.pack.type.name}</div>
            <PersonFormalNameLink person={use.pack.person} aspect="tokens" />
            <div className="used-at">{isoLocalDateTimeTemplate(use, 'usedAt')}</div>
        </div>
    );

    const rightColumn = (use) => <CommentDisplayEditor use={use} />;

    return (
        <div className="token-use-search">
            <RequestMessages messages={requestErrors} />
            <DataTable
                value={items}
                dataKey="id"
                header={header}
                className="hide-columns-header"
                emptyMessage="No hay tokens consumidos"
                lazy
                // --------------------------------------------------------------------------
                // TODO: Implement an event-less navigation option so that right-click works
                // selectionMode="single"
                // onRowClick={rowClick}
                // --------------------------------------------------------------------------
                // Always displayed for this view, regardless of displayed records count
                // paginator={Boolean(totalRecords && totalRecords > MIN_PAGINATION_COUNT)}
                paginator
                rows={rows}
                first={first}
                onPage={onPage}
                totalRecords={totalRecords}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            >
                <Column body={leftColumn} />
                <Column body={rightColumn} />
            </DataTable>
        </div>
    );
};
