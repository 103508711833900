import { useCallback, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';

import { RequestMessages } from '@components/RequestMessages';
import { genericRequestErrors } from '@services/index';

import { CommissionPledgeList } from '@commissions/pledges/list';
import { CommissionPledgeTemplateList } from '@commissions/pledges/templates/list';
import { CommissionsPath } from '@commissions/routes';
import { CommissionService } from '@services/commissionsService';
import { CommissionHeader } from './header';

export const CommissionItem = () => {
    const params = useParams();
    const history = useHistory();
    const [service] = useState(new CommissionService());
    const [commission, setCommission] = useState();
    const [lastUpdate, setLastUpdate] = useState();
    const [requestMessages, setRequestMessages] = useState();

    // Callbacks --------------------------------------------------------------

    const read = useCallback(
        (commissionId) => {
            setRequestMessages(null);
            service
                .get(commissionId)
                .then((response) => {
                    setCommission(response.data);
                    setLastUpdate(new Date());
                })
                .catch((error) => setRequestMessages(genericRequestErrors(error)));
        },
        [service],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        read(params.id);
    }, [read, params.id]);

    // Events -----------------------------------------------------------------

    const createPledge = () =>
        history.push(`${CommissionsPath.Pledges.create}?commission=${params.id}`);

    // const createPayments = () =>
    //     history.push(generatePath(CommissionsPath.Commissions.createPayments, { id: params.id }));

    // Rendering --------------------------------------------------------------

    const globalData = (
        <div style={{ paddingBottom: '1rem' }}>
            <div>Fecha: {commission?.dueDate}</div>
            <div>Estado: {commission?.enabled ? 'Habilitada' : 'Deshabilitada'}</div>
            {/* TODO: Hidden while not enforced by the server */}
            {/* <div>Máximo / Persona: {commission?.maxPerPerson}</div> */}
            <div>Cantidad: {commission?.pledgesCount} (vigentes)</div>
        </div>
    );

    const globalAmounts = (
        <div style={{ paddingBottom: '1rem' }}>
            <div>Total: $ {commission?.incomingTotal}</div>
            <div>Cobrado: $ {commission?.incomingCanceled}</div>
            <div>Saldo: $ {commission?.incomingBalance}</div>
        </div>
    );

    const globalActions = (
        <div className="flex flex-wrap gap-2">
            <Button
                label="Compromiso"
                icon="pi pi-plus"
                onClick={createPledge}
                style={{ minWidth: '125px' }}
            />
            {/* <Button
                label="Rendición"
                icon="pi pi-plus"
                onClick={createPayments}
                style={{ minWidth: '125px' }}
            /> */}
        </div>
    );

    return (
        <div className="commission-item">
            <RequestMessages messages={requestMessages} />

            <CommissionHeader commission={commission} onRefresh={() => read(params.id)}>
                <div className="flex flex-wrap gap-4">
                    {globalData}
                    {globalAmounts}
                </div>
                {globalActions}
            </CommissionHeader>
            <br />
            <TabView className="commission-item-panels" renderActiveOnly={false}>
                {/* <TabPanel header="Resumen">
                    <CommissionSummary commission={commission} />
                </TabPanel> */}
                <TabPanel header="Compromisos">
                    <CommissionPledgeList commissionId={params.id} forceUpdate={lastUpdate} />
                </TabPanel>
                {/* <TabPanel header="Rendiciones">
                    <CommissionPaymentList commissionId={params.id} forceUpdate={lastUpdate} />
                </TabPanel> */}
                <TabPanel header="Opciones">
                    <CommissionPledgeTemplateList
                        commissionId={params.id}
                        forceUpdate={lastUpdate}
                    />
                </TabPanel>
            </TabView>
        </div>
    );
};
