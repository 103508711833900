import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

/**
 * Custom hook to sync query parameters with react state.
 *
 * Based on:
 * https://www.inkoop.io/blog/syncing-query-parameters-with-react-state/
 *
 * Differences:
 * - Uses standard URLSearchParams instead of qs library as dependency
 * - Can be configured to returnEmptyForNull (true by default). This is required
 *   when the output of this hook is used as the value of an input component. If
 *   returning null like the original component, React complaints about it with
 *   an error, requiring that empty string be used to clear the input component.
 *
 * @param {string} query Key / Name of the query parameter.
 * @param {boolean} returnEmptyForNull If true, return empty string when
 *                                     current value is not truthy.
 * @returns {string} Value of the query parameter. If values is not truthy,
 *                   returns null or empty depending on @returnEmptyForNull
 */

export const useQueryState = (query, returnEmptyForNull = true) => {
    const location = useLocation();
    const history = useHistory();

    const setQuery = useCallback(
        (value) => {
            const searchParams = new URLSearchParams(location.search);
            !value ? searchParams.delete(query) : searchParams.set(query, value);
            const queryString = searchParams.toString();
            history.push(`${location.pathname}?${queryString}`);
        },
        [history, location, query],
    );

    const getCurrentValue = () => {
        const value = new URLSearchParams(location.search).get(query);
        return value ? value : returnEmptyForNull ? '' : null;
    };

    return [getCurrentValue(), setQuery];
};
