import { useState } from 'react';

import { ModalForm } from '@components/forms';
import { RequestMessages } from '@components/RequestMessages';
import { genericRequestErrors } from '@services/index';

import { CanceledStatus, ReconciledStatus } from '@enums/index';
import { FundEntryService } from '@services/fundsService';

const getErrors = (entry) => {
    const errors = [];
    if (entry.isRevoked) {
        errors.push({
            sticky: true,
            severity: 'error',
            summary: 'Ya está anulada',
            closable: false,
        });
    }
    return errors;
};

const getWarnings = (entry) => {
    const warnings = [];
    if (entry.reconciledStatus === ReconciledStatus.ACCEPTED.value) {
        warnings.push({
            sticky: true,
            severity: 'warn',
            summary: 'Está marcada como conciliada.',
            closable: false,
        });
    }
    if (entry.receipt) {
        warnings.push({
            sticky: true,
            severity: 'warn',
            summary: 'Recibo existente será anulado.',
            closable: false,
        });
    }
    if (CanceledStatus.anyCancelations(entry)) {
        warnings.push({
            sticky: true,
            severity: 'warn',
            summary: 'Cancelaciones asociadas serán anuladas.',
            closable: false,
        });
    }
    return warnings;
};

// TODO: entry data could be retrieved again. If called from a not updated
// view, data could have changed since last retrieval and errors and warnings
// would be computed that not current / invalid data.
// TODO: It could return that new data to the calling client, even if the
// action is canceled, to update the calling view.
// This happens in:
// * FundEntryRevoke
// * CashEntryRevoke

export const FundEntryRevoke = ({ entry, onSuccess, onCancel }) => {
    const [service] = useState(new FundEntryService());
    const [entryErrors] = useState(getErrors(entry));
    const [entryWarnings] = useState(getWarnings(entry));
    const [requestErrors, setRequestErrors] = useState([]);

    const onExecute = (event) => {
        event.preventDefault();
        setRequestErrors(null);
        service
            .revoke(entry.id)
            .then((response) => onSuccess && onSuccess(response.data))
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    };

    const getContent = () => {
        return entryErrors.length > 0 ? (
            <div>
                <div>{`No puede anular "${entry.type.label}".`}</div>
                <RequestMessages messages={entryErrors} />
            </div>
        ) : (
            <div>
                <p>{`¿Confirma anular "${entry.type.label}"?`}</p>
                <p>Esta operación no puede ser revertida.</p>
                <RequestMessages messages={entryWarnings} />
            </div>
        );
    };

    return (
        <ModalForm
            onSubmit={onExecute}
            title="Anular transacción"
            buttons={{
                executeLabel: 'Anular',
                executeDisabled: entryErrors.length > 0,
                cancelLabel: 'Dejar sin cambios',
            }}
            onCancel={onCancel}
            requestErrors={requestErrors}
            style={{ width: '450px' }}
        >
            {getContent()}
        </ModalForm>
    );
};
