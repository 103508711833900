import { useEffect, useState } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';

import { useQuery } from '@custom/react-router';

import { RequestMessages } from '@components/RequestMessages';
import { Section } from '@components/Section';

import { personNotFoundError } from '@errors/index';
import { computePersonFormalName } from '@persons/templates';
import { genericRequestErrors } from '@services/index';
import { PersonService } from '@services/personService';

import { AccountingPaths } from '@accounting/routes';
import { TransactionType } from '@enums/accounting';
import { AccountingService } from '@services/accounting';
import { ReceiptCancelations } from './cancelations';
import { ReceiptPayments } from './payments';

const initReceiptCreate = {
    personId: null,
    summary: null,
    details: null,
    paymentMode: null,

    hasCancelations: function () {
        return (
            this.details &&
            this.details.some(
                (detail) =>
                    detail.transactions &&
                    detail.transactions.some((transaction) => transaction.hasCancelation()),
            )
        );
    },

    requiresPayment: function () {
        return this?.summary?.newPayment && this.summary.newPayment !== 0;
    },

    hasPaymentMode: function () {
        return Boolean(this.paymentMode);
    },

    missingPayment: function () {
        return this.requiresPayment() && !this.hasPaymentMode();
    },
};

export const ReceiptStep = Object.freeze({
    CANCELATIONS: 'cancelations',
    PAYMENTS: 'payments',
    // See .confirmation.js deprecation message
    // CONFIRMATION: 'confirmation',
});

const FIRST_STEP = ReceiptStep.CANCELATIONS;

const MISSING_PERSON_ERROR = {
    severity: 'error',
    summary: 'Falta persona del recibo',
    detail: 'Utilice los links del programa para generar un recibo.',
    sticky: true,
    closable: false,
};

export const ReceiptCreate = () => {
    // Hooks & Props ----------------------------------------------------------
    const query = useQuery();
    const history = useHistory();
    const personId = query.get('person');

    // State & Effects --------------------------------------------------------

    const [step, setStep] = useState();
    const [data, setData] = useState();
    const [person, setPerson] = useState();
    const [service] = useState(new AccountingService());
    const [personService] = useState(new PersonService());
    const [requestErrors, setRequestErrors] = useState();

    useEffect(() => {
        const resetReceipt = () => {
            setStep(null);
            setData(null);
            setPerson(null);
        };

        if (personId) {
            setRequestErrors(null);
            personService
                .get(personId)
                .then((response) => setPerson(response.data))
                .catch((error) => {
                    resetReceipt();
                    error?.response?.status === 404
                        ? setRequestErrors([personNotFoundError(personId)])
                        : setRequestErrors(genericRequestErrors(error));
                });
        } else {
            resetReceipt();
            setRequestErrors([MISSING_PERSON_ERROR]);
        }
    }, [personId, personService]);

    useEffect(() => {
        if (person) {
            setData({
                ...initReceiptCreate,
                personId: person.id,
            });
            setStep(FIRST_STEP);
        }
    }, [person]);

    // Events -----------------------------------------------------------------

    const onContinue = (newPart, stepTo = null) => {
        if (newPart == null) {
            if (stepTo != null) {
                setStep(stepTo);
            }
        } else {
            const newData = {
                ...data,
                ...newPart,
            };
            // let nextStep = ReceiptStep.CONFIRMATION;
            let nextStep = null;

            // Clear payment mode if none is required
            if (!newData.requiresPayment()) {
                newData.paymentMode = null;
            }

            if (!newData.hasCancelations()) {
                nextStep = ReceiptStep.CANCELATIONS;
            } else if (newData.missingPayment()) {
                nextStep = ReceiptStep.PAYMENTS;
            }
            // Important set newData before nextStep
            setData(newData);
            // setStep(nextStep);
            nextStep == null ? execute(newData) : setStep(nextStep);
        }
    };

    // We don't show 'Cancelar' button. If user want's to cancel:
    // - Refresh the view (to start over)
    // - Select another menu (to do something else)
    // const onCancel = (stepFrom) => {
    //     const fromConfirmation = stepFrom === ReceiptStep.CONFIRMATION;
    //     const cancelationsReady = data.hasCancelations();
    //     const paymentsReady = !data.requiresPayment() || data.hasPaymentMode();
    //     const readyToPost = cancelationsReady && paymentsReady;

    //     if (!fromConfirmation && readyToPost) {
    //         setStep(ReceiptStep.CONFIRMATION);
    //     } else {
    //         // This does not work if the previous URL is the same, e.g. was reloaded.
    //         // history.goBack();
    //         const defaultCancelPath = `/persons/${personId}/dues`;
    //         const from = location?.state?.from || defaultCancelPath;
    //         history.replace(from);
    //     }
    // };

    const execute = (data) => {
        const getCancelationsRequests = () => {
            const requests = [];
            data.details.forEach((detail) => {
                detail.transactions.forEach((transaction) => {
                    if (transaction.hasCancelation()) {
                        requests.push({
                            transactionId: transaction.id,
                            amount: Math.abs(transaction.newPayment),
                        });
                    }
                });
            });
            return requests;
        };

        const getTransactionsRequests = () => {
            const paymentMode = data?.paymentMode;
            const transactionType =
                data.summary.newPayment > 0
                    ? TransactionType.CASH_OUTGOING_PAYMENT
                    : TransactionType.CASH_INCOMING_PAYMENT;
            if (paymentMode && paymentMode?.type === 'treasury.cashsession') {
                return [
                    {
                        typeId: transactionType.id,
                        sessionId: paymentMode.id,
                        amount: Math.abs(data?.summary?.newPayment),
                    },
                ];
            }
            // TODO: Implement other paymentMode's, e.g. FUNDS_OUTGOING_TRANSFER
            return null;
        };

        const onCompleted = (responseData) => {
            // There are always cancelation through this IssueReceipt view.
            // Therefore, we can directly redirect to the generated receipt.
            const redirectPath = generatePath(AccountingPaths.Receipt.item, {
                id: responseData.receipt.id,
            });
            history.replace(redirectPath);
        };

        const request = {
            personId: data.personId,
            cancelationsRequests: getCancelationsRequests(),
            transactionsRequests: getTransactionsRequests(),
        };
        setRequestErrors(null);
        service
            .issueReceipt(request)
            .then((response) => onCompleted(response.data))
            .catch((error) => setRequestErrors(genericRequestErrors(error)));
    };

    return (
        <Section title={computePersonFormalName(person)} className="section issue-receipt">
            <RequestMessages messages={requestErrors} />

            {step == null && (
                <p>
                    {Array.isArray(requestErrors) && requestErrors ? (
                        <Link to="/persons">Buscar personas</Link>
                    ) : (
                        'Cargando...'
                    )}
                </p>
            )}

            {step === ReceiptStep.CANCELATIONS && (
                <ReceiptCancelations data={data} onContinue={onContinue} />
            )}

            {step === ReceiptStep.PAYMENTS && (
                <ReceiptPayments data={data} onContinue={onContinue} />
            )}

            {/* {step === ReceiptStep.CONFIRMATION && (
                <ReceiptConfirmation data={data} onReview={setStep} />
            )} */}
        </Section>
    );
};
