import { TokenPackCreate } from './packs/create';
import { TokenPackItem } from './packs/item';
import { TokenPackSearch } from './packs/search';
import { TokenTypeSearch } from './types/search';
import { TokenUseSearch } from './uses/search';

import { configuration } from '@app/configuration';

const ROOT_PATH = '/tokens';
// const CONFIG_PATH = '/configuration' + ROOT_PATH;

export const TokensPath = Object.freeze({
    TokenType: {
        search: ROOT_PATH,
        // item: ROOT_PATH + '/:id',
    },

    TokenPack: {
        search: ROOT_PATH + '/packs',
        create: ROOT_PATH + '/packs/create',
        item: ROOT_PATH + '/packs/:id',
    },

    TokenUse: {
        search: ROOT_PATH + '/uses',
    },
});

export const TokensConfigurationPath = Object.freeze({});

const activeRoutesInfo = [
    {
        path: TokensPath.TokenType.search,
        title: 'Tokens',
        exact: true,
        component: TokenTypeSearch,
    },
    {
        path: TokensPath.TokenPack.search,
        title: 'Tokens Generados',
        exact: true,
        component: TokenPackSearch,
    },
    {
        // *** WARNING *** Must be before TokenPack.item
        path: TokensPath.TokenPack.create,
        title: 'Tokens',
        exact: true,
        component: TokenPackCreate,
    },
    {
        path: TokensPath.TokenPack.item,
        title: 'Pack de Tokens',
        exact: true,
        component: TokenPackItem,
    },
    {
        path: TokensPath.TokenUse.search,
        title: 'Tokens Consumidos',
        exact: true,
        component: TokenUseSearch,
    },
];

const configRoutesInfo = [];

const makeRoutesInfo = () => {
    const routesInfo = [];
    routesInfo.push(...activeRoutesInfo);
    if (configuration.displayWIP) {
        routesInfo.push(...configRoutesInfo);
    }
    return routesInfo;
};

export const routesInfo = makeRoutesInfo();
