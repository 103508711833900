import { MoneyFormatDemo } from './accounting/money-format';
import { DniCuitDemo } from './components/dni-cuit-demo';
import { FieldValueDemo } from './components/field-value';
import { ModalSubPath } from './components/modal-sub-path';
import { MonthFieldDemo } from './components/monthFieldDemo';
import { SinceUntilBlockDemo } from './components/since-until-block';
import { UtilsDemo } from './utils';

export const routesInfo = [
    {
        path: '/demos/components/modal-sub-path',
        title: 'ModalSubPath',
        component: ModalSubPath,
    },
    {
        path: '/demos/accounting/money-format',
        title: 'Money Format',
        component: MoneyFormatDemo,
    },
    {
        path: '/demos/components/field-value',
        title: 'Field Value',
        component: FieldValueDemo,
    },
    {
        path: '/demos/components/month-field',
        title: 'MonthField (component)',
        component: MonthFieldDemo,
    },
    {
        path: '/demos/components/since-until-block',
        title: 'SinceUntil (Blocks)',
        component: SinceUntilBlockDemo,
    },
    {
        path: '/demos/components/dni-cuit',
        title: 'DNIField & CUITField (component)',
        component: DniCuitDemo,
    },
    {
        path: '/demos/utils',
        title: 'Utils',
        component: UtilsDemo,
    },
];
