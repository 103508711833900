import { Link } from 'react-router-dom';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Card } from 'primereact/card';

import { configuration } from '@app/configuration';
import { SystemInformationPanel } from '@public/SystemInformation';
import { copyright, email, website, whatsapp } from '@public/template';

const DEFAULT_ACTIVE_INDEX = [0];

if (configuration.displayWIP) {
    DEFAULT_ACTIVE_INDEX.push(1);
}

export const ServiceDashboard = () => {
    return (
        <Card
            title="Servicio SUMA"
            footer={copyright}
            className="service-dashboard"
            style={{ maxWidth: '500px', position: 'relative', margin: '0 auto' }}
        >
            <Accordion multiple activeIndex={DEFAULT_ACTIVE_INDEX}>
                <AccordionTab header="Contacto" className="contact">
                    {website}
                    {email}
                    {whatsapp}
                </AccordionTab>
                {configuration.displayWIP && (
                    <AccordionTab header="Backup">
                        <Link to="/service/backup" className="p-link">
                            Backup de Datos
                        </Link>
                    </AccordionTab>
                )}
                <AccordionTab header="Sistema">
                    <SystemInformationPanel />
                </AccordionTab>
            </Accordion>
        </Card>
    );
};
