import { TaxProfiles } from './profiles';

import { configuration } from '@app/configuration';

const CONFIG_PATH = '/configuration';

export const TaxConfigurationPath = Object.freeze({
    Profile: {
        dashboard: CONFIG_PATH + '/tax-profiles',
    },
});

const activeRoutesInfo = [];

const configRoutesInfo = [
    {
        path: TaxConfigurationPath.Profile.dashboard,
        title: 'Perfiles Fiscales',
        exact: true,
        component: TaxProfiles,
    },
];

const makeRoutesInfo = () => {
    const routesInfo = [];
    routesInfo.push(...activeRoutesInfo);
    if (configuration.displayWIP) {
        routesInfo.push(...configRoutesInfo);
    }
    return routesInfo;
};

export const routesInfo = makeRoutesInfo();
