import { useCallback, useEffect, useState } from 'react';

import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { TreeTable } from 'primereact/treetable';

import { RequestMessages } from '@components/RequestMessages';
import { ExpandButtons, RefreshButton } from '@components/buttons';
import { genericRequestErrors } from '@services/index';
import { toISOLocaDateTimeString } from '@utils/money';

import { TreasuryService } from '@services/treasuryService';

const dataToNodes = (groups) => {
    return groups.map((group, g) => {
        const { name, balance, items } = group;
        return {
            key: `${g}`,
            data: { name, balance, isGroup: true },
            children: items.map((item, i) => {
                return {
                    key: `${g}-${i}`,
                    data: { isGroup: false, ...item },
                };
            }),
        };
    });
};

export const CashReport = () => {
    const [time, setTime] = useState();
    const [total, setTotal] = useState();
    const [groups, setGroups] = useState();
    const [service] = useState(new TreasuryService());
    const [expandedKeys, setExpandedKeys] = useState();
    const [requestMessages, setRequestMessages] = useState();

    // Callbacks --------------------------------------------------------------

    const refresh = useCallback(() => {
        setRequestMessages(null);
        service
            .cashReport()
            .then((response) => {
                setTime(new Date());
                setTotal(response.data?.balance);
                setGroups(dataToNodes(response.data?.groups));
            })
            .catch((error) => setRequestMessages(genericRequestErrors(error)));
    }, [service]);

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        refresh();
    }, [refresh]);

    // Events -----------------------------------------------------------------

    const expandAll = () => {
        const _expandedKeys = {};
        groups.forEach((group) => (_expandedKeys[`${group.key}`] = true));
        setExpandedKeys(_expandedKeys);
    };

    const collapseAll = () => {
        setExpandedKeys(null);
    };

    // Render -----------------------------------------------------------------

    const leftHeader = <ExpandButtons onExpand={expandAll} onCollapse={collapseAll} />;

    const rightHeader = (
        <div className="flex align-items-center justify-content-end gap-2">
            <RefreshButton onRefresh={refresh} />
            Disponible
        </div>
    );

    const footerText = (
        <div className="flex align-items-center justify-content-between gap-2 flex-wrap-reverse">
            <span className="report-time">{time && toISOLocaDateTimeString(time)}</span>
            <span>TOTAL DISPONIBLE</span>
        </div>
    );

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer={footerText} />
                <Column footer={total} className="table-column-money" />
            </Row>
        </ColumnGroup>
    );

    const rowClassName = ({ data }) => {
        return {
            'report-group': data.isGroup,
            'report-item': !data.isGroup,
        };
    };

    const descriptionTemplate = ({ data }) => {
        if (data.isGroup) return data.name;

        return (
            <div>
                <div className="name">{data.name}</div>
                <div className="status">{data.status}</div>
            </div>
        );
    };

    return (
        <Card className="treasury-cash-report">
            <RequestMessages messages={requestMessages} />
            <TreeTable
                value={groups}
                className="hide-table-header"
                rowClassName={rowClassName}
                expandedKeys={expandedKeys}
                onToggle={(e) => setExpandedKeys(e.value)}
                footerColumnGroup={footerGroup}
            >
                <Column body={descriptionTemplate} expander header={leftHeader} />
                <Column field="balance" className="table-column-money" header={rightHeader} />
            </TreeTable>
        </Card>
    );
};
