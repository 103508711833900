import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';

export const CashSessionSummary = ({ session }) => {
    // TODO: session.summary contains unsorted summary lines. They sould be
    // explicitly sorted in the client for consistency, e.g. line.type.name.

    const closeAmountTitle = session.isOpen ? 'Efectivo en Caja (esperado)' : 'Monto Final';

    const anyRevoked = session.summary.some((line) => line?.revoked > 0);

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Descripción" />
                <Column header="Operaciones" className="table-column-center" />
                <Column header={`Total${anyRevoked ? ' *' : ''}`} className="table-column-money" />
            </Row>
            <Row>
                <Column header="Monto Inicial" colSpan={2} />
                <Column header={session.openAmount} className="table-column-money" />
            </Row>
        </ColumnGroup>
    );

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer={closeAmountTitle} colSpan={2} />
                <Column footer={session.closeAmount} className="table-column-money" />
            </Row>
            {!session.isOpen && (
                <Row>
                    <Column footer="Diferencia de Caja" colSpan={2} />
                    <Column
                        footer={session.isOpen ? 'No cerrada' : session.errorAmount}
                        className="table-column-money"
                    />
                </Row>
            )}
        </ColumnGroup>
    );

    const countTemplate = (line) => {
        const revokedTemplate = (line) => {
            if (line?.revoked == null || line.revoked === 0) return null;
            return `(${line.revoked} anulada${line.revoked > 1 ? 's' : ''})`;
        };

        const revoked = revokedTemplate(line);

        return (
            <>
                {line.count}
                {revoked && (
                    <>
                        <br />
                        {revoked}
                    </>
                )}
            </>
        );
    };

    return (
        <DataTable
            value={session?.summary}
            headerColumnGroup={headerGroup}
            footerColumnGroup={footerGroup}
            emptyMessage="Sin operaciones"
            footer={anyRevoked ? '* Neto de operaciones anuladas' : null}
        >
            <Column field="description" />
            <Column field="count" className="table-column-center" body={countTemplate} />
            <Column field="flowTotal" className="table-column-money" />
        </DataTable>
    );
};
