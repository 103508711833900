import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { transactionLink } from '@accounting/templates';
import { RequestMessages } from '@components/RequestMessages';
import { RefreshButton } from '@components/buttons';
import { genericRequestErrors } from '@services/index';
import { revocableMoneyTemplate } from '@utils/money';

import { AccountingService } from '@services/accounting';
import { SiteUserService } from '@services/userService';

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
const MIN_PAGINATION_COUNT = ROWS_PER_PAGE_OPTIONS[0];

export const SiteUserTransactionsBalanceDetail = () => {
    const { userId } = useParams();

    const [user, setUser] = useState();
    const [items, setItems] = useState();
    const [service] = useState(new AccountingService());
    const [userService] = useState(new SiteUserService());
    const [requestMessages, setRequestMessages] = useState();

    // Pagination & Lazy loading
    const [page, setPage] = useState(0);
    const [first, setFirst] = useState(0);
    const [pageSize, setPageSize] = useState(MIN_PAGINATION_COUNT);
    const [totalRecords, setTotalRecords] = useState(0);

    // Callbacks --------------------------------------------------------------

    const search = useCallback(
        ({ userId, fetchUser, page, first, pageSize }) => {
            const getData = () => {
                return service.userTransactionsBalance({
                    userId: userId,
                    page: page + 1,
                    pageSize: pageSize,
                });
            };

            const setData = (response) => {
                const data = response.data;
                setPage(page);
                setFirst(first);
                setPageSize(pageSize);
                setItems(data.results);
                setTotalRecords(data.count ? data.count : 0);
            };

            const handleError = (error) => {
                setRequestMessages(genericRequestErrors(error));
            };

            setRequestMessages(null);
            if (fetchUser) {
                Promise.all([getData(), userService.get(userId)])
                    .then((results) => {
                        setData(results[0]);
                        setUser(results[1].data);
                    })
                    .catch(handleError);
            } else {
                getData().then(setData).catch(handleError);
            }
        },
        [service, userService],
    );

    // Effects ----------------------------------------------------------------

    useEffect(() => {
        search({
            userId: userId,
            fetchUser: true,
            page: 0,
            first: 0,
            pageSize: MIN_PAGINATION_COUNT,
        });
    }, [search, userId]);

    // Events -----------------------------------------------------------------

    const onPage = (event) => {
        search({
            userId: userId,
            fetchUser: false,
            page: event.page,
            first: event.first,
            pageSize: event.rows,
        });
    };

    const refresh = () => {
        search({
            userId: userId,
            fetchUser: true,
            page: page,
            first: first,
            pageSize: pageSize,
        });
    };

    // Render -----------------------------------------------------------------

    const descriptionTemplate = (transaction) => {
        return (
            <>
                {transaction?.valueDate} <br />
                {transactionLink(transaction)}
            </>
        );
    };

    const header = (
        <span className="split-table-header">
            <h2>{user && user.formalName}</h2>
            <RefreshButton onRefresh={refresh} />
        </span>
    );

    return (
        <Card className="user-transactions-balance-detail">
            <RequestMessages messages={requestMessages} />
            <DataTable
                value={items}
                header={header}
                emptyMessage="Usuario sin transacciones."
                lazy
                paginator={Boolean(totalRecords && totalRecords > MIN_PAGINATION_COUNT)}
                first={first}
                rows={pageSize}
                totalRecords={totalRecords}
                onPage={onPage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                paginatorTemplate="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} a {last} de {totalRecords}"
            >
                <Column
                    className="table-column-left"
                    header="Descripción"
                    body={descriptionTemplate}
                />
                <Column
                    className="table-column-money"
                    header="Importe"
                    body={(row) => revocableMoneyTemplate(row, 'siteAmount')}
                />
                <Column className="table-column-money" header="Saldo" field="siteBalance" />
            </DataTable>
        </Card>
    );
};
