import { useRef, useEffect } from 'react';
import { Messages } from 'primereact/messages';
import { formatMessageContent } from '../libs/primereact/utils';

/**
 * Messages component that replaces, on each
 * render, all messages with props.messages.
 */

// TODO: This rendering will produce errors when messages
// not flattened in the form of key:value pairs, e.g.:
// {"paymentTransactions":[{"amount":["Este campo no puede ser nulo."]}]}
// This error types should be recursively rendered

export function RequestMessages({ messages, ...rest }) {
    const messagesRef = useRef(null);

    useEffect(() => {
        if (messages && messages.length > 0) {
            messagesRef.current.replace(messages.map(formatMessageContent));
        } else {
            messagesRef.current.clear();
        }
    }, [messages]);

    return <Messages {...rest} ref={messagesRef} />;
}
