import { userDescription } from '@account/templates';
import { isoLocalDateTimeTemplate } from '@utils/money';

export const openedBody = (session) => (
    <>
        <div className="one-liner">{userDescription(session.openedBy)}</div>
        <div>{isoLocalDateTimeTemplate(session, 'openedOn', false)}</div>
    </>
);

export const closedBody = (session) => (
    <>
        <div className="one-liner">{userDescription(session.closedBy)}</div>
        <div>{isoLocalDateTimeTemplate(session, 'closedOn', false)}</div>
    </>
);

export const fullStatusTemplate = (session) => (
    <>
        <div>
            <span className="font-semibold">Abrió: </span>
            <span className="one-liner">{userDescription(session.openedBy)}</span>
            <br />
            <span>{isoLocalDateTimeTemplate(session, 'openedOn', false)}</span>
        </div>
        <br />
        <div>
            <span className="font-semibold">Cerró: </span>
            {session.isOpen ? (
                <span>Abierta</span>
            ) : (
                <>
                    <span className="one-liner">{userDescription(session?.closedBy)}</span>
                    <br />
                    <span>{isoLocalDateTimeTemplate(session, 'closedOn', false)}</span>
                </>
            )}
        </div>
    </>
);
