import { AccountingReport } from './accounting';
import { RegistrationsReport } from './registrations';

const BASE_PATH = '/reports';

export const ReportPath = Object.freeze({
    Accounting: BASE_PATH + '/accounting',
    Registrations: BASE_PATH + '/registrations',
});

export const routesInfo = [
    {
        path: ReportPath.Accounting,
        title: 'Contabilidad',
        exact: true,
        component: AccountingReport,
    },
    {
        path: ReportPath.Registrations,
        title: 'Registraciones',
        exact: true,
        component: RegistrationsReport,
    },
];
