import { useRef } from 'react';

import { Menu } from 'primereact/menu';
import { TieredMenu } from 'primereact/tieredmenu';

import { PopupMenuButton } from '.';

export const PopupItemsButton = ({
    menuItems,
    tiered = false,
    menuProps = null,
    buttonProps = null,
}) => {
    const menu = useRef();
    const MenuClass = tiered ? TieredMenu : Menu;

    // There seems to be a bug in TieredMenu. Might work with newer versions
    // Collision detection for TieredMenu Submenus to keep them within viewport
    // https://github.com/primefaces/primeng/issues/4717

    return (
        <>
            <MenuClass popup ref={menu} model={menuItems} {...menuProps} />
            <PopupMenuButton menuRef={menu} aria-haspopup buttonProps={buttonProps} />
        </>
    );
};
